import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Institution } from '@models/institution.model';
import { Service } from '@models/service.model';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { Theme } from '@themes/theme.interface';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-institution-card',
  templateUrl: './institution-card.component.html',
  styleUrls: ['./institution-card.component.scss'],
})
export class InstitutionCardComponent extends BreakpointObserverComponent implements OnInit {
  protected theme: Theme;

  @Input() skeletonIsEmpty: boolean;
  @Input() emptySkeletonMessage: string;
  @Input() institution: Institution;

  @Output() moreInfoClick: EventEmitter<Institution> = new EventEmitter<Institution>();

  modalityOptions: ButtonOption[];

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly themeService: ThemeService
  ) {
    super(breakpointObserver);

    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.modalityOptions = this.convertModalitiesToButtonOptions();
  }

  private convertModalitiesToButtonOptions(): ButtonOption[] {
    return this.institution.modalities.map((m: Service) => {
      return {
        ...m.toButtonOption(true),
        isClickable: false,
        isActive: true,
      };
    });
  }
}
