import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversation-feedback-success-page',
  templateUrl: './conversation-feedback-success-page.component.html',
  styleUrls: ['./conversation-feedback-success-page.component.scss'],
})
@UntilDestroy()
export class ConversationFeedbackSuccessPageComponent {
  protected theme: Theme;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }
}
