<vh-dialog-frame [title]="visit?.title" (closeClicked)="close()">
  <mat-dialog-content class="visit-properties-grid">
    <!-- Patient data -->
    <div
      class="flex-col card"
      vhSkeleton
      [skeletonIsVisible]="isLoading"
      [skeletonIsEmpty]="!isLoading"
      skeletonHeight="200px"
      skeletonWidth="30vw">
      <ng-container *ngIf="!isLoading">
        <div class="flex-row-l-c" *ngIf="!isLoading">
          <vh-message
            class="not-clickable font-medium-16-19"
            iconLeftPath="assets/icons/user-square.svg"
            [text]="'admin.pages.dashboard.visitDialog.titlePatientData' | translate"
            iconLeftWidth="20"></vh-message>
          <vh-icon
            *ngIf="patientViewMode === 'read' && !isReadOnly"
            class="clickable align-end"
            src="assets/icons/pen.svg"
            [fill]="theme.colorScheme().secondaryGrey"
            [hoverFill]="theme.colorScheme().black"
            (click)="onEditPatientClicked()"></vh-icon>
          <div *ngIf="patientViewMode === 'edit'" class="flex-row-l-c gap-h-8 align-end">
            <vh-icon
              [ngClass]="{ clickable: !isUpdatingPatient, 'not-clickable': isUpdatingPatient }"
              src="assets/icons/save.svg"
              [width]="20"
              [height]="20"
              [fill]="theme.colorScheme().secondaryGrey"
              [hoverFill]="theme.colorScheme().primary"
              (click)="onSavePatientClicked()"></vh-icon>
            <vh-icon
              [ngClass]="{ clickable: !isUpdatingPatient, 'not-clickable': isUpdatingPatient }"
              src="assets/icons/cancel.svg"
              [width]="16"
              [height]="16"
              [fill]="theme.colorScheme().secondaryGrey"
              [hoverFill]="theme.colorScheme().error"
              (click)="onCancelEditPatientClicked()"></vh-icon>
          </div>
        </div>

        <div [vhMarginTop]="8" class="visit-properties-list" [formGroup]="patientFormGroup">
          <p>{{ 'admin.pages.dashboard.visitDialog.labelName' | translate }}</p>
          <div *ngIf="patientViewMode === 'read'" class="flex-row-l-c gap-h-8">
            <p *ngIf="visit.patient?.fullName">{{ visit.patient?.fullName }}</p>
            <p *ngIf="!visit.patient?.fullName">
              <i>{{ 'common.errors.noPatientName' | translate }}</i>
            </p>
            <vh-icon [src]="visit.patient?.genderIcon" width="16" height="16" [fill]="theme.colorScheme().primary"></vh-icon>
          </div>
          <div *ngIf="patientViewMode === 'edit'" class="flex-row-l-c gap-h-8">
            <vh-text-input [formControlName]="PROFILE_FORM_KEYS.get('firstname')" [style]="TextInputStyle.MATERIAL"></vh-text-input>
            <vh-text-input [formControlName]="PROFILE_FORM_KEYS.get('lastname')" [style]="TextInputStyle.MATERIAL"></vh-text-input>
          </div>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelBirthdate' | translate }}</p>
          <p *ngIf="patientViewMode === 'read'">{{ visit.patient?.birthdate | dfnsFormat : 'dd/MM/yyyy' }}</p>
          <vh-text-input
            *ngIf="patientViewMode === 'edit'"
            type="date"
            [formControlName]="PROFILE_FORM_KEYS.get('birthdate')"
            [placeholder]="'pages.register.birthdateInputLabel' | translate"
            [min]="DATE_BOUNDARIES.min.dateString"
            [max]="currentDate"
            [style]="TextInputStyle.MATERIAL">
          </vh-text-input>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelNationalRegisterNumber' | translate }}</p>
          <p *ngIf="patientViewMode === 'read'">{{ visit.patient?.socialSecurityNumber ?? '/' }}</p>
          <vh-text-input
            *ngIf="patientViewMode === 'edit'"
            [formControlName]="PROFILE_FORM_KEYS.get('socialSecurityNumber')"
            [placeholder]="'pages.register.socialSecurityNumberInputPlaceholder' | translate"
            [style]="TextInputStyle.MATERIAL">
          </vh-text-input>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelEmail' | translate }}</p>
          <div *ngIf="visit.patient?.email && patientViewMode === 'read'" class="flex-row-l-c gap-h-8">
            <a [href]="visit.patient?.emailHref">{{ visit.patient?.email }}</a>
            <vh-icon
              [matTooltip]="
                (visit.patient?.isEmailVerified
                  ? 'admin.pages.dashboard.visitDialog.patientVerifiedEmail'
                  : 'admin.pages.dashboard.visitDialog.patientDidntVerifyEmail'
                ) | translate
              "
              [src]="visit.patient?.isEmailVerified ? 'assets/icons/check-circle.svg' : 'assets/icons/times-circle.svg'"
              [fill]="visit.patient?.isEmailVerified ? theme.colorScheme().primary : theme.colorScheme().error"
              [width]="14"
              [height]="14">
            </vh-icon>
          </div>
          <p *ngIf="!visit.patient?.email">/</p>
          <vh-text-input
            *ngIf="patientViewMode === 'edit'"
            type="email"
            [formControlName]="PROFILE_FORM_KEYS.get('email')"
            [placeholder]="'pages.register.emailPlaceholder' | translate"
            [style]="TextInputStyle.MATERIAL">
          </vh-text-input>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelPhone' | translate }}</p>
          <p *ngIf="visit.patient?.phone && patientViewMode === 'read'">
            <a [href]="visit.patient?.phoneHref">{{ visit.patient?.phone }}</a>
          </p>
          <p *ngIf="!visit.patient?.phone && patientViewMode === 'read'">/</p>
          <vh-phone-input
            *ngIf="patientViewMode === 'edit'"
            [fieldControl]="patientFormGroup.get(PROFILE_FORM_KEYS.get('phone'))"
            [initialValue]="patientFormGroup.get(PROFILE_FORM_KEYS.get('phone'))?.value">
          </vh-phone-input>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelLanguage' | translate }}</p>
          <div class="flex-row-l-c">
            {{ visit.patient?.language | uppercase }}
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Visit data -->
    <div class="flex-col card" vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="!isLoading" skeletonHeight="200px">
      <ng-container *ngIf="!isLoading">
        <!-- Time and location -->
        <div class="flex-row-s-c">
          <vh-message
            class="not-clickable font-medium-16-19"
            iconLeftPath="assets/icons/calendar-alt.svg"
            [text]="'admin.pages.dashboard.visitDialog.titleTimeLocation' | translate"
            [iconLeftFill]="theme.colorScheme().black"
            iconLeftWidth="16"
            iconLeftHeight="16"></vh-message>

          <div class="flex-row-r-c gap-h-16">
            <vh-button
              *ngIf="visitViewMode === 'read' && !isReadOnly"
              [isDisabled]="!visit.patient || !visit.patient?.email"
              [style]="ButtonStyle.ICON_ONLY"
              [matTooltip]="visit.patient?.email ? ('admin.pages.dashboard.visitDialog.resendEmail' | translate) : ('admin.pages.dashboard.visitDialog.noEmailAddress' | translate)"
              [iconRightPath]="'assets/icons/mail-send.svg'"
              (buttonClick)="onResendEmailClicked()">
            </vh-button>
            <vh-icon
              *ngIf="visitViewMode === 'read' && !isReadOnly"
              class="clickable"
              src="assets/icons/pen.svg"
              [fill]="theme.colorScheme().secondaryGrey"
              [hoverFill]="theme.colorScheme().black"
              (click)="onEditVisitClicked()">
            </vh-icon>
            <vh-icon
              *ngIf="visitViewMode === 'edit' && !isReadOnly"
              class="clickable"
              src="assets/icons/times-circle.svg"
              [fill]="theme.colorScheme().secondaryGrey"
              [hoverFill]="theme.colorScheme().error"
              (click)="onCancelEditVisitClicked()">
            </vh-icon>
          </div>

        </div>
        <div [vhMarginTop]="8" class="visit-properties-list" [formGroup]="visitFormGroup">
          <p>{{ 'admin.pages.dashboard.visitDialog.labelLocation' | translate }}</p>
          <div>
            <p>{{ visit.institution?.parent ? visit.institution?.parent?.name : visit.institution?.name }}</p>
            <p
              *ngIf="visit.institution?.parent && visit.institution?.parent?.name.toLowerCase() !== visit.institution?.name.toLowerCase()"
              class="detail-text">
              {{ visit.institution.name }}
            </p>
          </div>

          <ng-container *vhRequiresRoles="getInstitutionRoles()">
            <p>{{ 'admin.pages.dashboard.visitDialog.labelRoom' | translate }}</p>
            <p>{{ visit.room?.name }}</p>
          </ng-container>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelDate' | translate }}</p>
          <p *ngIf="visitViewMode === 'read'">{{ visit.start | dfnsFormat : 'dd/MM/yyyy' }}</p>
          <vh-text-input
            *ngIf="visitViewMode === 'edit'"
            type="date"
            [formControlName]="VISIT_FORM_KEYS.get('startDate')"
            [style]="TextInputStyle.MATERIAL"
            (inputValueChange)="onVisitDateTimeChanged()"></vh-text-input>

          <p>{{ 'admin.pages.dashboard.visitDialog.labelStartTime' | translate }}</p>
          <p *ngIf="visitViewMode === 'read'">{{ visit.start | dfnsFormat : 'HH:mm' }}</p>
          <vh-text-input
            *ngIf="visitViewMode === 'edit'"
            type="time"
            [formControlName]="VISIT_FORM_KEYS.get('startTime')"
            [style]="TextInputStyle.MATERIAL"
            (inputValueChange)="onVisitDateTimeChanged()"
            vhPopoverHost="reschedule"
            [popoverTemplate]="reschedulePopover"
            popoverEnforcedPosition="bottom"></vh-text-input>

          <ng-container *vhRequiresRoles="getInstitutionRoles()">
            <p *ngIf="visitViewMode === 'read'">{{ 'admin.pages.dashboard.visitDialog.labelDuration' | translate }}</p>
            <p *ngIf="visitViewMode === 'read'">{{ visit.service.duration }} min</p>
          </ng-container>
          <ng-container *vhRequiresRoles="getAdminRoles()">
            <p>{{ 'admin.pages.dashboard.visitDialog.labelCreator' | translate }}</p>
            <p>{{ visit.creatorName ?? '/' }}</p>
          </ng-container>
        </div>

        <!-- Service -->
        <div>
            <vh-message
            class="not-clickable font-medium-16-19"
            [vhMarginTop]="16"
            iconLeftPath="assets/icons/file-medical-alt.svg"
            [text]="'admin.pages.dashboard.visitDialog.titleExamination' | translate"
            iconLeftWidth="20"></vh-message>
            <div [vhMarginTop]="8" class="visit-properties-list">
              <p>{{ 'admin.pages.dashboard.visitDialog.labelModality' | translate }}</p>
              <p>{{ visit.service?.parent?.shortName }}</p>

              <p>{{ 'admin.pages.dashboard.visitDialog.labelExamination' | translate }}</p>
              <p>{{ visit.service?.name }}</p>

              <p>{{ 'admin.pages.dashboard.visitDialog.labelReferringPhysician' | translate }}</p>
              <p>{{ visit.referringPhysicianName ?? '/' }}</p>
            </div>

            <div *vhRequiresRoles="getInstitutionRoles()" class="flex-row-l-c">
              <vh-message
                class="not-clickable font-medium-16-19"
                [vhMarginTop]="16"
                iconLeftPath="assets/icons/document-info.svg"
                [text]="'admin.pages.dashboard.visitDialog.titleStatus' | translate"
                iconLeftWidth="20"></vh-message>
              <vh-message
                @fadeInOutAnimation
                *ngIf="showSavedStatusSuccessfullyMessage"
                class="not-clickable has-text-tertiary font-light-12-14 align-end"
                iconLeftPath="assets/icons/check-circle.svg"
                [text]="'admin.pages.dashboard.visitDialog.saveSuccessDisclaimer' | translate"
                iconLeftWidth="20"></vh-message>
              <vh-icon
                class="clickable align-end"
                src="assets/icons/history.svg"
                [fill]="theme.colorScheme().secondaryGrey"
                [hoverFill]="theme.colorScheme().black"
                (click)="onShowVisitStatusHistoryClicked()"
                width="20"
                height="20"
                [vhPopoverHost]="'status_history'"
                [popoverTemplate]="statusHistoryPopover"
                [popoverCloseOnClickOutside]="true"
                [popoverPanelClass]="'popover-border'"
                [matTooltip]="'admin.pages.dashboard.visitDialog.historyTooltip' | translate"></vh-icon>
            </div>
            <div *vhRequiresRoles="getInstitutionRoles()" [vhMarginTop]="8" class="visit-properties-list">
              <p>{{ 'admin.pages.dashboard.visitDialog.labelCurrentStatus' | translate }}</p>
              <vh-select-input
                [options]="visitStatusOptions"
                [value]="visit.status?.id"
                (inputValueChange)="onVisitStatusSelected($event)"></vh-select-input>
            </div>
        </div>
      </ng-container>
    </div>

    <!-- Internal comment -->
    <div
      *vhRequiresRoles="getInstitutionRoles()"
      class="flex-col card"
      vhSkeleton
      [skeletonIsVisible]="isLoading"
      [skeletonIsEmpty]="!isLoading"
      skeletonHeight="100px">
      <ng-container *ngIf="!isLoading">
        <div class="flex-row-l-c">
          <vh-message
            class="not-clickable font-medium-16-19"
            iconLeftPath="assets/icons/comment-alt-message.svg"
            [text]="'admin.pages.dashboard.visitDialog.titleInternalComment' | translate"
            iconLeftWidth="20"></vh-message>
          <div class="flex-row-l-c gap-h-8 align-end">
            <vh-icon *ngIf="isSavingComment" src="assets/icons/hourglass.svg" [width]="12" [height]="12" [fill]="theme.colorScheme().secondaryGrey"></vh-icon>
            <vh-message
              @fadeInOutAnimation
              *ngIf="showSavedCommentSuccessfullyMessage"
              class="not-clickable has-text-tertiary font-light-12-14"
              iconLeftPath="assets/icons/check-circle.svg"
              [text]="'admin.pages.dashboard.visitDialog.saveSuccessDisclaimer' | translate"
              iconLeftWidth="20"></vh-message>
          </div>
        </div>
        <vh-textarea-input
          #commentInput
          [vhMarginTop]="16"
          [value]="visit?.internalComment"
          (inputValueChange)="onCommentChanged(commentInput.value)"></vh-textarea-input>
      </ng-container>
    </div>

    <!-- External comment -->
    <div
      *vhRequiresRoles="getInstitutionRoles()"
      class="flex-col card"
      vhSkeleton
      [skeletonIsVisible]="isLoading"
      [skeletonIsEmpty]="!isLoading"
      skeletonHeight="100px">
      <ng-container *ngIf="!isLoading">
        <div class="flex-row-l-c">
          <vh-message
            class="not-clickable font-medium-16-19"
            iconLeftPath="assets/icons/comment-alt-message.svg"
            [text]="'admin.pages.dashboard.visitDialog.titleExternalComment' | translate"
            iconLeftWidth="20"></vh-message>
        </div>
        <vh-textarea-input
          #commentInput
          [isDisabled]="true"
          [vhMarginTop]="16"
          [value]="visit?.externalComment">
        </vh-textarea-input>
      </ng-container>
    </div>

    <!-- Payment -->
    <div
      *vhRequiresRoles="getInstitutionRoles()"
      class="flex-col card"
      vhSkeleton
      [skeletonIsVisible]="isLoading"
      [skeletonIsEmpty]="!isLoading"
      skeletonHeight="100px">
      <ng-container *ngIf="!isLoading">
        <vh-message
          class="not-clickable font-medium-16-19"
          iconLeftPath="assets/icons/euro-circle.svg"
          [text]="'admin.pages.dashboard.visitDialog.titlePayment' | translate"
          iconLeftWidth="20"></vh-message>
        <div [vhMarginTop]="16" class="visit-properties-list" [formGroup]="visitFormGroup">
          <label>{{ 'admin.pages.dashboard.visitDialog.paymentFulfilled' | translate }}</label>
          <vh-switch [value]="visit?.wasPaid" [updateValueOnClick]="false" (click)="onPaymentToggled()"></vh-switch>
          <vh-message
            @fadeInOutAnimation
            *ngIf="showSavedPaymentSuccessfullyMessage"
            class="not-clickable has-text-tertiary font-light-12-14 col-span-full align-end"
            iconLeftPath="assets/icons/check-circle.svg"
            [text]="'admin.pages.dashboard.visitDialog.saveSuccessDisclaimer' | translate"
            iconLeftWidth="20"></vh-message>
        </div>
      </ng-container>
    </div>

    <!-- Tasks -->
    <div class="card" vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="!isLoading" skeletonHeight="100px">
      <ng-container *ngIf="!isLoading">
        <vh-message
          class="not-clickable font-medium-16-19"
          iconLeftPath="assets/icons/clipboard-notes.svg"
          [text]="'admin.pages.dashboard.visitDialog.titleTasks' | translate"
          iconLeftWidth="20"></vh-message>
        <vh-task-list [vhMarginTop]="16" [visit]="visit" [isReadOnly]="isReadOnly" (click)="dialogRef.close()"></vh-task-list>
      </ng-container>
    </div>
  </mat-dialog-content>

  <!-- Cancel button -->
  <mat-dialog-actions *ngIf="!isLoading" [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button
      *ngIf="!isLoading"
      matTooltipPosition="above"
      iconLeftPath="assets/icons/calendar-slash.svg"
      [label]="'admin.pages.dashboard.visitDialog.actionCancel' | translate"
      [isDisabled]="cancellationButtonTooltip !== null"
      [style]="ButtonStyle.DANGER"
      [matTooltip]="(cancellationButtonTooltip !== null ? cancellationButtonTooltip : '')"
      (buttonClick)="onCancelVisitClicked()">
    </vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>

<ng-template #reschedulePopover>
  <vh-visit-rescheduling-impact-result
    class="col-span-full"
    *ngIf="rescheduleVisitDuration"
    [visit]="visit"
    [newStartDateTime]="rescheduleVisitDuration?.start"
    [newEndDateTime]="rescheduleVisitDuration?.end"
    (cancelClicked)="onCancelEditVisitClicked()"
    (visitRescheduleSuccess)="onVisitDateTimeSaved($event)">
  </vh-visit-rescheduling-impact-result>
</ng-template>

<ng-template #statusHistoryPopover>
  <vh-visit-status-list [visit]="visit"></vh-visit-status-list>
</ng-template>
