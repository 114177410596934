import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HierarchicalPickerItem, HierarchicalPickerState } from '@modules/booking/models/hierarchy-state.model';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-hierarchical-picker',
  templateUrl: './hierarchical-picker.component.html',
  styleUrls: ['./hierarchical-picker.component.scss'],
})
export class HierarchicalPickerComponent {
  protected theme: Theme;

  @Input() state: HierarchicalPickerState;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() searchPlaceholder: string;
  @Input() showSearch: boolean = true;

  @Output() itemClick: EventEmitter<HierarchicalPickerItem> = new EventEmitter<HierarchicalPickerItem>();

  searchValue: string | null = null;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  onGoLevelBackClicked(): void {
    this.resetSearchValue();

    // Deselect the current selected item
    if (this.state.hasReachedDeepestLevel()) {
      this.state.pop();
    }

    // Only go back a level
    this.state.pop();
  }

  onItemClicked(item: HierarchicalPickerItem): void {
    this.resetSearchValue();
    this.state.select(item);
    this.itemClick.emit(item);
  }

  resetSearchValue(): void {
    this.searchValue = '';
  }
}
