import { Component, ElementRef, forwardRef, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormArray, NG_VALUE_ACCESSOR, UntypedFormArray } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { TextInputStyle } from '@enums/text-input-style.enum';

@Component({
  selector: 'vh-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: [
    './text-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent extends BaseInputComponent implements OnInit {
  protected readonly TextInputStyle: typeof TextInputStyle = TextInputStyle;

  @Input() iconLeftPath: string;
  @Input() iconLeftFill: string;
  @Input() iconLeftClass: string;

  @Input() iconRightPath: string;
  @Input() iconRightFill: string;
  @Input() iconRightClass: string;
  @Input() iconRightClickable: boolean = false;

  @Input() formArray: FormArray = null;
  @Input() index: number = null;
  @Input() hoverFill: string = null;

  @Input() pattern?: string | RegExp;
  @Input() type?: string;
  @Input() autocomplete: string = null;
  @Input() style: TextInputStyle = TextInputStyle.DEFAULT;
  @Input() max: string | number = null;
  @Input() min: string | number = null;

  @Input() mask: string;
  @Input() showMaskTyped: boolean;
  @Input() maskDropSpecialCharacters: boolean = false;
  @Input() maskPrefix: string;
  @Input() maskSuffix: string;
  @Input() maskPlaceholderCharacter: string;

  @Output() deleteFromFormFormArrayClick: EventEmitter<{ formArray: UntypedFormArray; index: number; }> = new EventEmitter<{ formArray: UntypedFormArray; index: number; }>();
  @Output() inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  onCalendarIconClicked(): void {
    this.inputElement.nativeElement.showPicker();
  }

  supportsCustomDatepickerIcon(): boolean {
    return !navigator.userAgent.includes('Firefox');
  }

  deleteFromFormArray(formArray: UntypedFormArray, index: number): void {
    this.deleteFromFormFormArrayClick.emit({ formArray, index });
  }

  writeValue(value: string): void {
    if (this.type === 'date') {
      this.value = value ?? this.value ?? null;

      return;
    }

    super.writeValue(value);
  }
}
