<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.privacy.title' | translate }}</h1>
  <p class="font-regular-18-22">{{ 'new.onboarding.privacy.intro' | translate }}</p>
</section>

<form class="flex-col gap-v-16" [vhMarginTop]="24" [formGroup]="formGroup">
  <vh-checkbox
    [formControlName]="REGISTER_FORM_KEYS.get('accepted_terms')">
    <label
      class="font-regular-18-22 clickable"
      [innerHTML]="'onboarding.credentials.termsAndConditionsLabel' | translate : { url: termsAndConditionsLink}"
      (click)="formGroup.get(REGISTER_FORM_KEYS.get('accepted_terms')).setValue(!formGroup.get(REGISTER_FORM_KEYS.get('accepted_terms')).value)">
    </label>
  </vh-checkbox>

  <vh-back-and-forward-buttons
    class="align-center"
    [backDisabled]="isSubmitting"
    [forwardDisabled]="!formGroup.get(REGISTER_FORM_KEYS.get('accepted_terms')).value || isSubmitting"
    [backLabel]="'common.back'"
    [forwardLabel]="'common.next'"
    (backClick)="onBackClicked()"
    (forwardClick)="onNextClicked()">
  </vh-back-and-forward-buttons>
</form>
