import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Room } from '@models/room.model';
import { CREATE_ROOM_FORM_KEYS } from '@constants/form-keys/create-room-form-keys.constant';
import { stringToColor } from '@utils/helpers/color.util';
import { generateRandomString } from '@utils/helpers/string.util';
import { ServiceGroupFormItem } from '@interfaces/room-settings-form.interface';
import { ServiceGroup } from '@models/service-group.model';
import { Service } from '@models/service.model';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Injectable({
  providedIn: 'root',
})
export class RoomFormService extends AbstractFormService {
  constructor(
    formBuilder: UntypedFormBuilder,
    private readonly themeService: ThemeService
  ) {
    super(formBuilder);
  }

  createFormGroup(room: Room = null): UntypedFormGroup {
    const serviceGroups: FormArray = new FormArray([]);

    room?.serviceGroups.forEach((group: ServiceGroup) => {
      serviceGroups.push(this.createServiceGroupForm(this.themeService.currentTheme, group));
    });

    return this.formBuilder.group({
      [CREATE_ROOM_FORM_KEYS.get('name')]: [
        room?.name,
        [Validators.required],
      ],
      [CREATE_ROOM_FORM_KEYS.get('description')]: [
        room?.description,
        [],
      ],
      [CREATE_ROOM_FORM_KEYS.get('color')]: [
        room?.color ?? stringToColor(generateRandomString()),
        [Validators.required],
      ],
      [CREATE_ROOM_FORM_KEYS.get('modality')]: [
        room?.modality?.id ?? null,
        [Validators.required],
      ],
      [CREATE_ROOM_FORM_KEYS.get('serviceGroups')]: [
        serviceGroups,
        [Validators.required],
      ],
    });
  }

  createServiceGroupForm(theme: Theme, group?: ServiceGroup): UntypedFormGroup {
    return this.formBuilder.group({
      [CREATE_ROOM_FORM_KEYS.get('serviceGroup')]: [
        group?.id ?? null,
        [Validators.required],
      ],
      [CREATE_ROOM_FORM_KEYS.get('color')]: [
        group?.color ?? theme.colorScheme().primary,
        [Validators.required],
      ],
    });
  }

  createRoomFromForm(formGroup: UntypedFormGroup, room: Room | null, serviceGroups: ServiceGroup[], services: Service[]): Room {
    const groups = (formGroup.get(CREATE_ROOM_FORM_KEYS.get('serviceGroups')).value as FormArray).value as ServiceGroupFormItem[];
    const selectedServiceGroups = [];
    groups.forEach((value: ServiceGroupFormItem) => {
      const group: ServiceGroup = serviceGroups.find((s: ServiceGroup) => s.id === value.serviceGroup);
      group.color = value.color;
      selectedServiceGroups.push(group);
    });

    const selectedModalityId = formGroup.get(CREATE_ROOM_FORM_KEYS.get('modality')).value;
    const selectedModality = services.find((s: Service) => s.id === selectedModalityId);

    return new Room(
      room?.id ?? null,
      formGroup.get(CREATE_ROOM_FORM_KEYS.get('name')).value,
      formGroup.get(CREATE_ROOM_FORM_KEYS.get('color')).value,
      selectedModality,
      [],
      formGroup.get(CREATE_ROOM_FORM_KEYS.get('description')).value,
      selectedServiceGroups
    );
  }
}
