import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { AuthenticationService } from '@services/authentication.service';
import { ChangeDetectorRef } from '@angular/core';
import { CARE_PROGRAM_FORM_KEYS } from '@constants/form-keys/care-program-form-keys.constant';
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { CareProgramService } from '@services/care-program.service';
import { ActivatedRoute } from '@angular/router';
import { CareProgram } from '@models/care-program.model';
import { NAVIGATION } from '@constants/navigation.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { CareProgramFormService } from '@services/form-services/care-program-form.service';
import { ButtonStyle } from '@enums/button-style.enum';
import { IObject } from '@app-types/iobject.type';
import { Doctor } from '@models/doctor.model';
import { SettingsCardStatus } from '@modules/settings/enums/settings-card-status.enum';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-care-programs-detail-page',
  templateUrl: './care-programs-detail-page.component.html',
  styleUrl: './care-programs-detail-page.component.scss',
})
@UntilDestroy()
export class CareProgramsDetailPageComponent implements OnInit, AfterViewChecked {
  protected readonly CARE_PROGRAM_FORM_KEYS: typeof CARE_PROGRAM_FORM_KEYS = CARE_PROGRAM_FORM_KEYS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  protected theme: Theme;

  careProgram: CareProgram;
  isLoading: boolean;
  isSaving: boolean;
  isNewCareProgram: boolean;
  isReadOnly: boolean;
  formGroup: UntypedFormGroup;
  steps: UntypedFormArray;

  get stepForms(): UntypedFormGroup[] {
    return this.steps?.controls as UntypedFormGroup[];
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly careProgramService: CareProgramService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertDialogService: AlertDialogService,
    private readonly careProgramFormService: CareProgramFormService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
    this.formGroup = this.careProgramFormService.createFormGroup();
  }

  ngOnInit(): void {
    this.getCareProgramFromRoute();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getTitleKey(): string {
    if (this.isLoading) {
      return 'common.loading';
    }

    if (this.careProgram?.isReadOnly) {
      return 'common.navigateBack';
    }

    return this.isNewCareProgram ? 'admin.pages.settings.careProgram.create' : 'admin.pages.settings.careProgram.edit';
  }

  private getCareProgramFromRoute(): void {
    if (this.activatedRoute.snapshot.routeConfig.path === NAVIGATION.adminDashboardSettings.createCareProgram.path) {
      this.isNewCareProgram = true;
      this.resetFormGroup();

      return;
    }

    this.isLoading = true;

    this.careProgramService.getCareProgramById$(this.activatedRoute.snapshot.params.careProgramId)
      .pipe(untilDestroyed(this))
      .subscribe((careProgram: CareProgram) => {
        this.careProgram = careProgram;
        this.isNewCareProgram = false;
        this.resetFormGroup();
        if (this.careProgram.status !== SettingsCardStatus.DRAFT) {
          this.isReadOnly = true;
        }
        this.isLoading = false;
      });
  }

  private resetFormGroup(): void {
    this.formGroup = this.careProgramFormService.createFormGroup(this.careProgram);
    if (this.isReadOnly) {
      this.formGroup.disable();
    }
    this.steps = this.formGroup.controls.steps as UntypedFormArray;
  }

  onLeadingChanged($event: string): void {
    this.formGroup = this.careProgramFormService.updateLeading($event);
    this.formGroup.markAllAsTouched();

  }

  onTimeToLeadingChanged($data: IObject): void {
    this.formGroup = this.careProgramFormService.updateTimeToLeading($data.id, $data.time, $data.unit);
    this.formGroup.markAllAsTouched();
  }

  onAddStepClicked(): void {
    this.steps.push(this.careProgramFormService.createStep());
  }

  onRemoveStepClicked($id: string): void {
    const selectedStep = this.steps.controls.find((step: UntypedFormGroup) => step.controls.id.value === $id) as UntypedFormGroup;
    if (selectedStep.controls.leading.value) {
      return;
    }
    this.steps.removeAt(this.steps.controls.findIndex((step: UntypedFormGroup) => step.controls.id.value === $id));
  }

  onRemoveDoctorClicked(doctor: Doctor): void {
    this.formGroup.patchValue({
      doctors: this.formGroup.value.doctors.filter((d: Doctor) => d.id !== doctor.id),
    });
  }

  onAddDoctorClicked(): void {
    //TODO: Implement this method
    /**
    * 1. Open a dialog to select a doctor
    * 2. Add the selected doctor to the form group
    * 3. Update the form group with the new doctor
    */
    throw new Error('Method not implemented.');
  }

  onSaveClicked(): void {
    this.saveCareProgram();
  }

  saveCareProgram(): void {
    const institutionId = this.authenticationService.institution.id;
    const careProgramPayload = this.careProgramFormService.toRequestObject(this.formGroup, institutionId);

    if (this.isNewCareProgram) {
      this.careProgramService.createCareProgram$(careProgramPayload).subscribe((careProgram: CareProgram) => {
        this.careProgram = careProgram;
        this.resetFormGroup();
      });
    }
    this.careProgramService.updateCareProgram$(careProgramPayload, this.careProgram.id).subscribe((careProgram: CareProgram) => {
      this.careProgram = careProgram;
      this.resetFormGroup();
    });
  }

  onActivateClicked(): void {
    this.careProgramService.setCareProgramStatus$(this.careProgram.id, 'ACTIVE').subscribe(() => {
      this.careProgram.activate();
      this.resetFormGroup();
    });
  }

  onArchiveClicked(): void {
    this.alertDialogService.open({
      titleTranslationKey: 'admin.pages.settings.careProgram.deleteDialog.title',
      messageTranslationKey: 'admin.pages.settings.careProgram.deleteDialog.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    }).pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.careProgramService.deleteCareProgram$(this.careProgram.id).subscribe(() => {
            history.back();
          });

        }
      });
  }

  navigateBack(): void {
    history.back();
  }
}
