<vh-page overflowY="hidden">
  <div class="flex-col container-wrapper">
    <div class="has-background-primary-gradient"></div>
    <div class="flex-row-l-c header">
      <div class="return-link-container">
        <vh-navigation-return-link
          *ngIf="showBackButton"
          [color]="theme.colorScheme().secondary"
          (navigateBackClick)="backButtonClick.emit()">
        </vh-navigation-return-link>
      </div>

      <div class="language-picker-container" *ngIf="showLanguagePicker">
        <vh-language-picker
          [showIcon]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
          [class.align-end]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
          [class.align-center]="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]">
        </vh-language-picker>
      </div>
    </div>

    <vh-content-layout
      [hasHorizontalSpacing]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]">
      <div class="flex-row container">
        <div class="flex-col-c-c form">
          <vh-icon [src]="theme.fullLogo()"></vh-icon>
          <div class="card"
            [vhMarginTop]="16"
            [class.padding-xl]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </vh-content-layout>
  </div>
</vh-page>
