import { Gender } from '@enums/gender.enum';
import { Role } from '@enums/role.enum';
import { Institution, InstitutionJson } from '@models/institution.model';
import { Doctor, DoctorJson } from '@models/doctor.model';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { DoctorOnboardingState } from '@enums/doctor-onboarding-state.enum';
import { OrganisationPosition } from '@enums/organisation-position.enum';

export class User {
  constructor(
    private _id: string,
    private _title: string,
    private _firstName: string,
    private _lastName: string,
    private _birthdate: Date,
    private _gender: Gender,
    private _socialSecurityNumber: string,
    private _phone: string,
    private _canPhoneBeUsedToContact: boolean,
    private _email: string,
    private _username: string,
    private _isEmailVerified: boolean,
    private _language: string,
    private readonly _roles: Set<Role>,
    private _institutions: Institution[],
    private _lastActive: Date | null = null,
    private _timezone: string | null = null,
    private _doctor: Doctor | null = null,
    private _termsAndConditionsAcceptedAt?: Date | null,
    private _privacyPolicyAcceptedAt?: Date | null,
    private _doctorOnboardingState: DoctorOnboardingState | null = null,
    private _organisationPosition: OrganisationPosition = OrganisationPosition.UNSPECIFIED,
    private readonly _hasActiveWhatsApp: boolean = false
  ) {}

  get id(): string {
    return this._id;
  }

  get title(): string {
    return this._title;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get fullName(): string {
    return this._title ? `${this._firstName} ${this._lastName}` : `${this._firstName} ${this._lastName}`;
  }

  get gender(): Gender {
    return this._gender;
  }

  get socialSecurityNumber(): string {
    return this._socialSecurityNumber;
  }

  get phone(): string {
    return this._phone;
  }

  get canPhoneBeUsedToContact(): boolean {
    return this._canPhoneBeUsedToContact;
  }

  get email(): string {
    return this._email;
  }

  get username(): string {
    return this._username;
  }

  get hasEmail(): boolean {
    return this._email && this._email !== '';
  }

  get isEmailVerified(): boolean {
    return this._isEmailVerified;
  }

  get language(): string {
    return this._language;
  }

  set language(value: string) {
    this._language = value;
  }

  get roles(): Set<Role> {
    return this._roles;
  }

  get institutions(): Institution[] {
    return this._institutions?.sort((institutionA: Institution, institutionB: Institution) =>
      institutionA.name.localeCompare(institutionB.name)
    );
  }

  get birthdate(): Date {
    return this._birthdate;
  }

  get lastActive(): Date {
    return this._lastActive;
  }

  get termsAndConditionsAcceptedAt(): Date {
    return this._termsAndConditionsAcceptedAt;
  }

  get hasAcceptedTermsAndConditions(): boolean {
    return !!this.termsAndConditionsAcceptedAt;
  }

  get privacyPolicyAcceptedAt(): Date | null {
    return this._privacyPolicyAcceptedAt;
  }

  get hasAcceptedPrivacyPolicy(): boolean {
    return !!this.privacyPolicyAcceptedAt;
  }

  get doctor(): Doctor | null {
    return this._doctor;
  }

  get doctorOnboardingState(): DoctorOnboardingState | null {
    return this._doctorOnboardingState;
  }

  get hasActiveWhatsApp(): boolean {
    return this._hasActiveWhatsApp;
  }

  get hasActiveWhatsAppConversation(): boolean {
    return this._hasActiveWhatsApp;
  }

  get organisationPosition(): OrganisationPosition | null {
    return this._organisationPosition;
  }

  get initials(): string {
    const fnInitials = this._firstName
      ?.trim()
      .split(' ')
      .map((namePart: string) => namePart.charAt(0))
      .join('');
    const lnInitials = this._lastName
      ?.trim()
      .split(' ')
      .map((namePart: string) => namePart.charAt(0))
      .join('');
    const fullInitials = fnInitials + lnInitials;

    return fullInitials && fullInitials !== '' ? fullInitials : this.emojiRepresentation;
  }

  get emojiRepresentation(): string {
    switch (this._gender) {
      case Gender.MALE:
        return '👨';
      case Gender.FEMALE:
        return '👩';
      default:
        return '🧑';
    }
  }

  get phoneHref(): string {
    return `tel:${this.phone}`;
  }

  get emailHref(): string {
    return `mailto:${this.email}`;
  }

  get genderIcon(): string {
    switch (this.gender) {
      case Gender.FEMALE:
        return 'assets/icons/gender-female.svg';
      case Gender.MALE:
        return 'assets/icons/gender-male.svg';
      default:
        return 'assets/icons/circle.svg';
    }
  }

  get riziv(): string {
    return this._doctor?.riziv;
  }

  get timezone(): string | null {
    return this._timezone;
  }

  hasInstitutionRole(): boolean {
    return (
      this.roles.has(Role.INSTITUTION_ADMIN) ||
      this.roles.has(Role.INSTITUTION_MANAGER) ||
      this.roles.has(Role.INSTITUTION_EDITOR) ||
      this.roles.has(Role.INSTITUTION_VIEWER)
    );
  }

  hasAnyRole(roles: Role | Role[]): boolean {
    return Array.isArray(roles) ? roles.some((role: Role) => this.roles.has(role)) : this.roles.has(roles);
  }

  hasRole(role: Role): boolean {
    return this.roles.has(role);
  }

  hasAnyInstitutionRole(): boolean {
    const allowedRoles = [Role.ADMIN, Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER];

    return Array.from(this?.roles ?? [])?.some((role: Role) => allowedRoles.includes(role)) ?? false;
  }

  hasAnyReferringPhysicianRole(): boolean {
    const allowedRoles = [Role.REFERRING_PHYSICIAN_ADMIN, Role.REFERRING_PHYSICIAN_EDITOR];

    return Array.from(this?.roles ?? [])?.some((role: Role) => allowedRoles.includes(role)) ?? false;
  }

  static fromJson(json: UserJson): User {
    if (!json) {
      return null;
    }

    const roles: Set<Role> = new Set<Role>(json.roles as Role[]);

    // Apply implicit roles
    if (roles.has(Role.INSTITUTION_EDITOR)) {
      roles.add(Role.INSTITUTION_VIEWER);
    }

    if (roles.has(Role.INSTITUTION_MANAGER)) {
      roles.add(Role.INSTITUTION_EDITOR);
      roles.add(Role.INSTITUTION_VIEWER);
    }

    if (roles.has(Role.INSTITUTION_ADMIN)) {
      roles.add(Role.INSTITUTION_MANAGER);
      roles.add(Role.INSTITUTION_EDITOR);
      roles.add(Role.INSTITUTION_VIEWER);
    }

    return new User(
      json.id,
      json.title,
      json.first_name,
      json.last_name,
      json.birthdate ? new Date(json.birthdate) : null,
      json.gender ? (json.gender as Gender) : null,
      json.social_security_number,
      json.phone,
      json.can_phone_be_used_to_contact,
      json.email,
      json.username,
      json.is_email_verified,
      json.language,
      roles,
      json.institutions?.map(Institution.fromJson),
      json.last_active ? new Date(json.last_active) : null,
      json.timezone ?? null,
      json.doctor?.id ? Doctor.fromJson(json.doctor) : null,
      json.terms_and_conditions_accepted_at ? new Date(json.terms_and_conditions_accepted_at) : null,
      json.privacy_policy_accepted_at ? new Date(json.privacy_policy_accepted_at) : null,
      json.doctor_onboarding_state ? DoctorOnboardingState[json.doctor_onboarding_state.toUpperCase()] : null,
      json.organisation_position ? OrganisationPosition[json.organisation_position.toUpperCase()] : OrganisationPosition.UNSPECIFIED,
      json.has_active_whatsapp
    );
  }

  toJson(): UserJson {
    return {
      id: this.id,
      title: this.title,
      first_name: this.firstName,
      last_name: this.lastName,
      birthdate: this.birthdate ? format(this.birthdate, DATE_FORMATS.serverDate) : null,
      gender: this.gender,
      social_security_number: this.socialSecurityNumber,
      phone: this.phone,
      can_phone_be_used_to_contact: this.canPhoneBeUsedToContact,
      email: this.email,
      username: this.username,
      is_email_verified: this.isEmailVerified,
      language: this.language,
      last_active: this._lastActive?.toDateString(),
      roles: Array.from(this.roles),
      institutions: this.institutions?.map((institution: Institution) => institution.toJson()) ?? [],
      terms_and_conditions_accepted_at: this.termsAndConditionsAcceptedAt?.toDateString() ?? null,
      privacy_policy_accepted_at: this._privacyPolicyAcceptedAt?.toDateString() ?? null,
      timezone: this.timezone,
      doctor_onboarding_state: this._doctorOnboardingState,
      has_active_whatsapp: this._hasActiveWhatsApp,
      organisation_position: this._organisationPosition,
    };
  }
}

export interface UserJson {
  id: string;
  username: string;
  title: string;
  first_name: string;
  last_name: string;
  birthdate: string;
  gender: string;
  social_security_number: string;
  can_phone_be_used_to_contact: boolean;
  phone: string;
  email: string;
  is_email_verified: boolean;
  language: string;
  last_active: string;
  roles: string[];
  institutions: InstitutionJson[];
  doctor?: DoctorJson | null;
  terms_and_conditions_accepted_at?: string;
  privacy_policy_accepted_at?: string;
  timezone?: string | null;
  doctor_onboarding_state?: string | null;
  has_active_whatsapp: boolean;
  organisation_position: string;
}
