import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonStyle } from '@enums/button-style.enum';
import { VisitReschedulingImpact } from '@models/visit-rescheduling-impact.model';
import { Visit } from '@models/visit.model';
import { isValid } from 'date-fns';
import { Subscription, switchMap } from 'rxjs';
import { VisitReschedulingService } from '@services/visits/visit.rescheduling.service';
import { VisitService } from '@services/visits/visit.service';
import { BookingStatus } from '@enums/booking-status.enum';
import { RecurrenceService } from '@services/recurrence.service';
import { PopoverService } from '@services/ui/popover.service';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { UntypedFormGroup } from '@angular/forms';
import { CancelVisitFormService } from '@services/form-services/cancel-visit-form.service';
import { CANCEL_VISIT_FORM_KEYS } from '@constants/form-keys/cancel-visit-form-keys.constant';
import { VisitCancellationService } from '@services/visits/visit-cancellation.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-other-visit-rescheduling-impact-result',
  templateUrl: './other-visit-rescheduling-impact-result.component.html',
  styleUrls: ['./other-visit-rescheduling-impact-result.component.scss'],
})
@UntilDestroy()
export class OtherVisitReschedulingImpactResultComponent implements OnInit, OnChanges {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly CANCEL_VISITS_POPOVER_ID: string = 'cancelVisitPopover';
  protected readonly DateFormat: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly VISIT_IDS_KEY: string = CANCEL_VISIT_FORM_KEYS.get('visitIds');

  protected theme: Theme;

  visitReschedulingImpactMessage: string | null = null;
  visitReschedulingImpact: VisitReschedulingImpact | null = null;

  visitReschedulingImpactCalculatingSubscription: Subscription | null = null;
  cancelVisitsSubscription: Subscription | null = null;

  isSavingVisit: boolean = false;
  overRuleAttemptNonAdmin: boolean = false;
  overRuleAttemptMessage: string = 'admin.pages.dashboard.rescheduleConfirmation.overRuleAttemptNonAdmin';
  confirmDisabledMessage: string | null = null;

  cancelOverlappingVisits: boolean = false;
  overlappingVisits: Visit[] = [];
  cancelVisitsFormGroup: UntypedFormGroup;

  popoverPreferredPositioning: PopoverPosition = 'right';

  @Input() visit: Visit;
  @Input() newStartDateTime: Date;
  @Input() newEndDateTime: Date;

  @Output() confirmClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() visitRescheduleSuccess: EventEmitter<Visit> = new EventEmitter<Visit>();
  @Output() visitRescheduleFailed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly visitReschedulingService: VisitReschedulingService,
    private readonly visitService: VisitService,
    private readonly recurrenceService: RecurrenceService,
    private readonly popoverService: PopoverService,
    private readonly cancelVisitFormService: CancelVisitFormService,
    private readonly visitCancellationService: VisitCancellationService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.cancelVisitsFormGroup = this.cancelVisitFormService.createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visit?.currentValue) {
      // Initialize  from visit when it changes
      this.cancelOverlappingVisits = this.visit.cancelOverlappingVisits;
    }

    if (changes.visit?.currentValue || changes.newStartDateTime?.currentValue || changes.newEndDateTime?.currentValue) {
      this.calculateVisitReschedulingImpact();
    }
  }

  onApproveTimeChangeClicked(): void {
    if (this.cancelOverlappingVisits) {
      this.getOverlappingVisits();
    } else {
      this.visitReschedulingImpact = null;
      this.visitReschedulingImpactMessage = null;
      this.confirmClicked.emit();
      this.rescheduleVisit();
    }
  }

  protected isConfirmDisabled(): boolean {
    return this.confirmDisabledMessage !== null || this.isSavingVisit || this.visitReschedulingImpactCalculatingSubscription !== null;
  }

  openPopover(popoverId: string): void {
    this.popoverService.open(popoverId);
  }

  closePopover(popoverId: string): void {
    this.popoverService.close(popoverId);
  }

  onCancelOverlappingVisitsCheckedChange(checked: boolean): void {
    this.cancelOverlappingVisits = checked;
  }

  onApproveCancelOverlappingVisitsClicked(): void {
    this.cancelTheOverlappingVisits();

    this.visitReschedulingImpact = null;
    this.visitReschedulingImpactMessage = null;
    this.confirmClicked.emit();
    this.rescheduleVisit();
  }

  onCancelClicked(): void {
    this.closePopover(this.CANCEL_VISITS_POPOVER_ID);
    this.cancelOverlappingVisits = false;
    this.overlappingVisits = [];
    this.cancelClicked.emit();
  }

  private rescheduleVisit(): void {
    this.isSavingVisit = true;

    // First update the visit, then reschedule it
    this.visitService
      .updateVisit$(this.visit.id, { cancellation_action_id: null, booking_status: BookingStatus.CONFIRMED, deleted_at: null })
      .pipe(
        // If updateVisit$ is successful, chain the rescheduleVisit$ call
        switchMap(() =>
          this.visitReschedulingService.rescheduleVisit$(this.visit.id, this.newStartDateTime, this.newEndDateTime, this.cancelOverlappingVisits)
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (visit: Visit): void => {
          this.visitReschedulingImpact = null;
          this.visitReschedulingImpactMessage = null;
          this.visitRescheduleSuccess.emit(visit);
          this.isSavingVisit = false;
        },
        error: (): void => {
          this.isSavingVisit = false;
          this.visitReschedulingImpact = null;
          this.visitReschedulingImpactMessage = null;
          this.visitRescheduleFailed.emit();
        },
      });
  }

  private calculateVisitReschedulingImpact(): void {
    if (!isValid(this.newStartDateTime) || !isValid(this.newEndDateTime)) {
      return;
    }

    this.confirmDisabledMessage = null;
    this.visitReschedulingImpact = null;
    this.visitReschedulingImpactMessage = 'admin.pages.dashboard.rescheduleConfirmation.calculatingImpact';

    this.visitReschedulingImpactCalculatingSubscription?.unsubscribe();
    this.visitReschedulingImpactCalculatingSubscription = this.visitReschedulingService
      .getVisitReschedulingImpact$(this.visit.id, this.newStartDateTime, this.newEndDateTime)
      .pipe(untilDestroyed(this))
      .subscribe((result: VisitReschedulingImpact): void => {
        this.visitReschedulingImpactCalculatingSubscription = null;
        this.visitReschedulingImpact = result;
        this.visitReschedulingImpactMessage = result.message;
        this.confirmDisabledMessage = this.visitReschedulingService.getReasonWhyReschedulingIsNotPossible(result);
      });
  }

  private getOverlappingVisits(): void {
    this.recurrenceService.getOverlappingVisits$(this.visit.room.id, this.newStartDateTime, this.newEndDateTime, this.visit.id)
      .pipe(untilDestroyed(this))
      .subscribe((visits: Visit[]) => {
        this.overlappingVisits = visits;

        if (this.overlappingVisits.length > 0) {
          this.openPopover(this.CANCEL_VISITS_POPOVER_ID);
        } else {
          this.visitReschedulingImpact = null;
          this.visitReschedulingImpactMessage = null;
          this.confirmClicked.emit();
          this.rescheduleVisit();
        }
      });
  }

  private cancelTheOverlappingVisits(): void {
    const visitIds = this.overlappingVisits.map((visit: Visit) => visit.id);

    this.cancelVisitsFormGroup.patchValue({
      [this.VISIT_IDS_KEY]: visitIds,
    });

    this.cancelVisitsSubscription = this.visitCancellationService.bulkCancelVisit$(this.cancelVisitsFormGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.cancelVisitsSubscription = null;
          this.closePopover(this.CANCEL_VISITS_POPOVER_ID);
          this.rescheduleVisit();
        },
        error: () => {
          this.cancelVisitsSubscription = null;
        },
      });
  }
}
