<div #container>
  <label *ngIf="label" [class.required]="isRequired && showRequired">
    <span [innerHTML]="label"></span>
  </label>
  <div
    class="flex-col input-wrapper"
    [ngClass]="{ clickable: !isDisabled, 'not-clickable': isDisabled }"
    [vhPopoverHost]="popoverId"
    [popoverTemplate]="optionsTemplate"
    [popoverEnforcedPosition]="'bottom'"
    [popoverCloseOnClickOutside]="true"
    [popoverWidth]="popoverWidth"
    (click)="toggleOptions()">
    <vh-text-input
      type="text"
      [ngClass]="{
        error: hasError || (formControl?.invalid && formControl?.touched)
      }"
      [placeholder]="placeholder || ''"
      [value]="displayedValue | translate"
      [iconLeftPath]="selectedOption?.icon"
      [iconLeftFill]="theme.colorScheme().black"
      [iconRightPath]="'assets/icons/chevron.svg'"
      [isReadonly]="true"
      [isDisabled]="isDisabled">
    </vh-text-input>
  </div>
  <p
    *ngIf="message"
    [vhMarginTop]="8"
    class="has-text-tertiary font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }">
    {{ message }}
  </p>
  <p
    *ngIf="errorMessage"
    [vhMarginTop]="8"
    class="has-text-error font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }">
    {{ errorMessage }}
  </p>
</div>

<ng-template #optionsTemplate>
  <div class="options" [ngStyle]="{ 'max-height': maxHeight || optionsHeight }">
    <div *ngIf="hasEmptyOption" (click)="handleOptionClick(null)">&nbsp;</div>

    <vh-message
      *ngIf="hasSelectAll"
      [id]="all"
      [text]="'common.all' | translate"
      class="all"
      (click)="handleOptionClick('all')"></vh-message>
    <hr *ngIf="hasSelectAll">

    <vh-message
      *ngFor="let option of options; let index = index"
      [class]="'option'"
      [id]="index"
      [iconLeftPath]="option.icon"
      [iconLeftFill]="highlightedIndex === index ? theme.colorScheme().white : theme.colorScheme().black"
      [text]="option.label | translate"
      [ngClass]="{ highlighted: highlightedIndex === index, clickable: !option.disabled, 'is-disabled not-clickable': option.disabled }"
      (click)="handleOptionClick(option.value)"></vh-message>
  </div>
</ng-template>
