<div class="menu flex-col-l-c font-regular-16-19">
  <div class="flex-row-s-c full-width">
    <p class="text-overflow font-medium-18-22">
      {{ 'navigationBar.helloUserText' | translate : { name: currentUser.fullName } }}
    </p>

    <vh-icon
      class="clickable align-end"
      width="24"
      height="24"
      src="assets/icons/logout.svg"
      [fill]="theme.colorScheme().black"
      [hoverFill]="theme.colorScheme().error"
      (click)="logout()">
    </vh-icon>
  </div>
  <p class="text-overflow">{{ currentUser.email }}</p>

  <ng-container *ngIf="shouldDisplayWallet">
    <!-- TODO: replace by vh-token-balance component -->
    <div class="text-overflow flex-row-l-c gap-h-4 tokens"
         [class.clickable]="canOpenWallet"
         [class.border-minimal]="shouldShowLowTokensWarning"
         [class.border-error]="shouldShowLowTokensWarning"
         [vhMarginTop]="8"
         (click)="onTokensClicked()">
      <vh-icon
        src="assets/icons/wallet.svg"
        width="16"
        height="16">
      </vh-icon>

      <p class="font-medium-18-22">{{ wallet?.tokens ?? '0' }}
        <span class="font-light-12-14">{{ 'navigationBar.tokens' | translate }}</span>
      </p>

      <div class="full-width">
        <p *ngIf="shouldShowLowTokensWarning" class="align-end font-medium-16-19 has-text-error">
          {{ 'navigationBar.topUp' | translate }}
        </p>
      </div>
    </div>
  </ng-container>

  <vh-horizontal-divider></vh-horizontal-divider>

  <label>{{ 'navigationBar.language' | translate }}</label>
  <vh-language-picker [vhMarginTop]="8" [value]="currentUser.language"></vh-language-picker>

  <ng-container *ngIf="shouldDisplayInstitutionsPicker">
    <vh-horizontal-divider></vh-horizontal-divider>

    <label>{{ 'navigationBar.institution' | translate }}</label>
    <vh-search-input
      class="full-width"
      *vhRequiresRoles="Role.ADMIN"
      [vhMarginTop]="8"
      [placeholder]="'navigationBar.searchInstitutionPlaceholder' | translate"
      [value]="institutionsQuery"
      (inputValueChange)="institutionsQuery = $event">
    </vh-search-input>
    <div class="scroll-container" [vhMarginTop]="8">
      <div class="institutions-picker" [vhMarginTop]="8">
        <div *ngFor="let institution of institutions | vhFilterInstitutions:institutionsQuery" class="picker-option clickable"
          (click)="onInstitutionSelected(institution)">
          <input type="radio" class="checkbox-s" tabindex="-1" [id]="'institution-' + institution.id" [checked]="institution.id === currentInstitution.id" />
          <span [ngClass]="{ 'has-text-primary': institution.id === currentInstitution.id }">{{ institution.name }}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <vh-horizontal-divider></vh-horizontal-divider>

  <div *ngIf="displayMode === Mode.ADMIN" class="flex-row-c-c gap-h-32 links">
    <vh-message
      class="has-text-black clickable"
      containerClass="full-width flex-row-c-c"
      iconRightPath="assets/icons/external-link-alt.svg"
      [text]="'navigationBar.support' | translate"
      [iconRightFill]="theme.colorScheme().black"
      [iconRightHeight]="12"
      [iconRightWidth]="12"
      (messageClick)="onSupportClicked()">
    </vh-message>
    <vh-message
      class="has-text-black clickable"
      containerClass="full-width flex-row-c-c"
      [text]="'navigationBar.contact' | translate"
      (messageClick)="onContactClicked()">
    </vh-message>
    <vh-message
      *ngIf="currentUser.roles.has(Role.ADMIN)"
      class="has-text-black clickable"
      containerClass="full-width flex-row-c-c"
      iconRightPath="assets/icons/external-link-alt.svg"
      [text]="'navigationBar.telescope' | translate"
      [iconRightFill]="theme.colorScheme().black"
      [iconRightHeight]="12"
      [iconRightWidth]="12"
      (messageClick)="onTelescopeClicked()">
      </vh-message>
  </div>

  <div class="flex-row-c-c gap-h-16" [vhMarginTop]="24">
    <vh-button
      [label]="'navigationBar.navProfile' | translate"
      [labelClass]="'font-regular-16-19'"
      [style]="ButtonStyle.QUINARY"
      [alignment]="'center'"
      (buttonClick)="onNavigateToProfileClicked()">
    </vh-button>

    <vh-button
      *vhRequiresRoles="getEditorRoles()"
      [style]="ButtonStyle.QUINARY"
      [label]="(displayMode === Mode.PRIVATE ? 'navigationBar.navAdminMode' : 'navigationBar.navPrivateMode') | translate"
      [labelClass]="'font-regular-16-19'"
      [alignment]="'center'"
      (buttonClick)="switchMode()">
    </vh-button>
  </div>
  <p class="font-regular-12-14 align-center" [vhMarginTop]="8">{{version}}</p>
</div>
