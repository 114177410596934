import { Component, OnInit } from '@angular/core';
import { ConversationService } from '@services/conversation.service';
import { UrlParserService } from '@services/url-parser.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConversationChat } from '@models/conversation-chat.model';
import { LanguageService } from '@services/language.service';
import { ConversationEventType } from '@enums/conversation-event-type.enum';
import { PrivacyPolicyService } from '@services/privacy-policy.service';
import { AuthenticationService } from '@services/authentication.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversation-page',
  templateUrl: './conversation-page.component.html',
  styleUrls: ['./conversation-page.component.scss'],
})
@UntilDestroy()
export class ConversationPageComponent implements OnInit {
  hasAcceptedPrivacyPolicy: boolean = false;
  showPrivacyPolicyText: boolean = false;
  showMustAcceptPrivacyPolicy: boolean = false;
  hasError: boolean = false;
  isWaitingForChatbotAnswer: boolean = false;
  isConversationInactive: boolean = false;
  isLoadingConversation: boolean = true;
  conversationId: string;
  conversationChat: ConversationChat;

  messages: any[] = [];

  protected theme: Theme;

  constructor(
    private readonly router: Router,
    private readonly conversationService: ConversationService,
    private readonly urlParserService: UrlParserService,
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly privacyPolicyService: PrivacyPolicyService,
    private readonly authenticationService: AuthenticationService,
    private readonly themeService: ThemeService
  ) {
    this.conversationId = this.urlParserService.extractUuid(this.router.url);
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.conversationService.newConversationEvent$(this.conversationId, ConversationEventType.OPEN_INTERFACE)
      .pipe(untilDestroyed(this))
      .subscribe();

    this.conversationService
      .getChatInfo$(this.conversationId)
      .pipe(untilDestroyed(this))
      .subscribe((chat: ConversationChat) => {
        if (chat.isInactive) {
          this.isConversationInactive = true;
          this.isLoadingConversation = false;

          return;
        }

        this.conversationChat = chat;
        this.messages = chat.transcript ?? [];

        this.privacyPolicyService.shouldShowPrivacyPolicy$()
          .pipe(untilDestroyed(this))
          .subscribe((shouldShowPrivacyPolicy: boolean) => {
            this.isLoadingConversation = false;
            this.showMustAcceptPrivacyPolicy = shouldShowPrivacyPolicy;
            this.hasAcceptedPrivacyPolicy = !shouldShowPrivacyPolicy;

            if (!this.showMustAcceptPrivacyPolicy) {
              this.startChatbot();
            }
          });
      });
  }

  scrollToBottom(): void {
    const messagesContainer = document.querySelector('.messages');
    messagesContainer.scrollTo(0, messagesContainer.scrollHeight);
  }

  startChatbot(): void {
    this.isWaitingForChatbotAnswer = true;

    setTimeout(() => {
      this.translateService.get('conversations.placeholder')
        .pipe(untilDestroyed(this))
        .subscribe((placeholder: string) => {
          document.querySelector('.textarea')?.setAttribute('data-placeholder', placeholder);
        });
    }, 0);

    if (this.messages.length > 0 && this.messages[this.messages.length - 1].role == 'assistant') {
      this.isWaitingForChatbotAnswer = false;

      return;
    }

    if (this.messages.length == 0) {
      const urlParams = new URLSearchParams(window.location.search);

      this.messages.push({
        role: 'info',
        pathology: this.conversationChat.pathology,
        question_list: this.conversationChat.questionList,
        chatbot_role: this.conversationChat.chatbotRole,
        conclusion: this.conversationChat.chatbotConclusion,
        version: this.conversationChat.version,
        language: this.languageService.getInstantTranslatedLanguageName(urlParams.get('language') || 'nl'),
        name: this.conversationChat.patient.firstName,
        lastname: this.conversationChat.patient.lastName,
        mail: this.conversationChat.patient.email,
        conversation_id: this.conversationId,
      });

      this.conversationService.newConversationEvent$(this.conversationId, ConversationEventType.BEGIN_INTERFACE).pipe(untilDestroyed(this)).subscribe();
    }

    void this.fetch();
  }

  // TODO: move to service with Observables
  async fetch(): Promise<void> {
    try {
      this.messages = [...this.messages, { role: 'assistant', content: '' }];

      const response = await fetch(environment.chatbot.url, {
        method: 'POST',
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.messages),
        cache: 'no-store',
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let finished = false;

      while (!finished) {
        const { value, done } = await reader.read();
        finished = done;
        const streamMessagesText = decoder.decode(value).trim().split('\n');

        for (const streamMessageText of streamMessagesText) {
          try {
            if (streamMessageText === '') continue;

            const streamMessage = JSON.parse(streamMessageText);
            if (typeof streamMessage !== 'object') continue;

            if (streamMessage.type === 'content') {
              this.messages[this.messages.length - 1].content += streamMessage.value;
            }

            if (streamMessage.type === 'result') {
              const responseContainer = document.querySelector('.response');
              if (responseContainer) {
                responseContainer.classList.add('biggerResponse');
              }

              if (streamMessage.value.complete) {
                this.conversationService.completeConversation$(this.conversationId).pipe(untilDestroyed(this)).subscribe();
                this.isConversationInactive = true;
              }

              this.conversationService.postTranscript$(this.conversationId, this.messages).subscribe();
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
      this.isWaitingForChatbotAnswer = false;
    } catch (e) {
      console.error(e);
      this.hasError = true;
    }
  }

  fetchAnswer(): void {
    if (this.isWaitingForChatbotAnswer /*|| (this.intake.whatsapp_id && !this.intake.closed_at)*/) {
      return;
    }

    const question: HTMLInputElement = document.querySelector('span.textarea');
    question.focus();

    if (question.textContent === '') {
      return;
    }

    this.isWaitingForChatbotAnswer = true;
    this.messages.push({ role: 'user', content: question.textContent });

    setTimeout(() => {
      question.textContent = '';
      this.scrollToBottom();
    }, 20);

    this.conversationService.postTranscript$(this.conversationId, this.messages)
      .subscribe(() => {
        void this.fetch();
      });
  }

  reset(): void {
    window.location.reload();
  }

  async handlePaste(event: ClipboardEvent): Promise<void> {
    event.preventDefault();

    const text: string = await navigator.clipboard.readText();

    const selection = window.getSelection();
    if (!selection?.rangeCount) {
      return;
    }

    selection.deleteFromDocument();

    const range = selection.getRangeAt(0);
    range.insertNode(document.createTextNode(text));

    range.setStartAfter(range.endContainer);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  acceptPolicy(): void {
    const acceptCheckbox: HTMLInputElement = document.querySelector('input#accept');

    this.hasAcceptedPrivacyPolicy = acceptCheckbox ? acceptCheckbox.checked : true;

    if (!this.hasAcceptedPrivacyPolicy) {
      this.showMustAcceptPrivacyPolicy = true;

      return;
    }

    this.privacyPolicyService.acceptPrivacyPolicy$(this.authenticationService.currentUser?.id ?? null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.showPrivacyPolicyText = false;
          this.showMustAcceptPrivacyPolicy = false;
          this.startChatbot();
        },
        error: () => {
          // If something went wrong marking the policy as accepted, then at least start the chatbot as it was accepted during the session
          this.showPrivacyPolicyText = false;
          this.showMustAcceptPrivacyPolicy = false;
          this.startChatbot();
        },
      });
  }
}
