import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TextInputStyle } from '@enums/text-input-style.enum';
import { VISIT_FORM_KEYS } from '@constants/form-keys/visit-form-keys.constant';
import { Visit } from '@models/visit.model';
import { VisitFormService } from '@services/form-services/visit-form.service';
import { addMinutes, format, isAfter, parse } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { BaseBookingFlowPageComponent } from '@modules/booking/pages/base-booking-flow-page/base-booking-flow.page';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { VisitService } from '@services/visits/visit.service';
import { BookingStatus } from '@enums/booking-status.enum';
import { finalize, Observable, of, Subscription, switchMap } from 'rxjs';
import { User } from '@models/user.model';
import { InstitutionService } from '@services/institution.service';
import { map, tap } from 'rxjs/operators';
import { Service } from '@models/service.model';
import { ServiceService } from '@services/service.service';
import { AuthenticationService } from '@services/authentication.service';
import { RoomService } from '@services/room.service';
import { Room } from '@models/room.model';
import { Option } from '@interfaces/option.interface';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';
import { PopoverService } from '@services/ui/popover.service';
import { RegisterComponent } from '@modules/account/components/register/register.component';
import { VisitReschedulingImpact } from '@models/visit-rescheduling-impact.model';
import { VisitReschedulingService } from '@services/visits/visit.rescheduling.service';
import { RecurrenceService } from '@services/recurrence.service';
import { Recurrence } from '@models/recurrence.model';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { TranslateService } from '@ngx-translate/core';
import { VisitType } from '@enums/visit-type.enum';
import { ButtonGroupStyle } from '@enums/button-style.enum';
import { VisitCancellationService } from '@services/visits/visit-cancellation.service';
import { CancelVisitFormService } from '@services/form-services/cancel-visit-form.service';
import { CANCEL_VISIT_FORM_KEYS } from '@constants/form-keys/cancel-visit-form-keys.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-create-event-popup',
  templateUrl: './create-event-popup.component.html',
  styleUrls: ['./create-event-popup.component.scss'],
})
@UntilDestroy()
export class CreateEventPopupComponent extends BaseBookingFlowPageComponent implements OnInit {
  protected readonly TextInputStyle: typeof TextInputStyle = TextInputStyle;
  protected readonly VISIT_FORM_KEYS: typeof VISIT_FORM_KEYS = VISIT_FORM_KEYS;
  protected readonly CANCEL_VISIT_FORM_KEYS: typeof CANCEL_VISIT_FORM_KEYS = CANCEL_VISIT_FORM_KEYS;
  protected readonly DateFormat: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly ButtonGroupStyle: typeof ButtonGroupStyle = ButtonGroupStyle;
  protected readonly POPOVER_ID: string = 'addPatientPopover';
  protected readonly CANCEL_VISITS_POPOVER_ID: string = 'cancelVisitPopover';
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  protected theme: Theme;

  @ViewChild('registerComponent') registerComponent: RegisterComponent;

  getPatientSuggestionsFunction$: (searchValue: string | null) => Observable<Option[]> = (): Observable<Option[]> => of([]);

  toggleModeButtonOptions: ButtonOption[] = [];
  visitType: VisitType = VisitType.MEDICAL;

  eventFormGroup: UntypedFormGroup;
  otherVisitFormGroup: UntypedFormGroup;

  visitSchedulingImpactCalculatingSubscription: Subscription | null = null;

  originalDate: Date;
  endTime: string;

  visitSchedulingImpactMessage: string | null = 'admin.pages.dashboard.rescheduleConfirmation.selectAllFields';
  visitSchedulingImpact: VisitReschedulingImpact | null = null;

  isLoadingPatients: boolean = false;
  users: User[] | null = null;
  patientOptions: Option[] = [];
  createdUserLabel: string | null = null;

  isLoadingServices: boolean = false;
  serviceOptions: Option[] = [];

  isLoadingRooms: boolean = false;
  roomOptions: Option[] = [];

  isRecurrenceFound: boolean = true;
  isNewUser: boolean = false;

  isSubmitting: boolean = false;

  popoverPreferredPositioning: PopoverPosition;

  cancelVisitsSubscription: Subscription;
  cancelVisitsFormGroup: UntypedFormGroup;
  overlappingVisits: Visit[] = [];

  isActiveOnForeground: boolean = true;

  @Input() selectedVisit: Visit;

  @Output() closePopup: EventEmitter<void> = new EventEmitter<void>();
  @Output() visitTimeUpdated: EventEmitter<Visit> = new EventEmitter<Visit>();

  constructor(
    private readonly visitFormService: VisitFormService,
    private readonly visitService: VisitService,
    private readonly institutionService: InstitutionService,
    private readonly serviceService: ServiceService,
    private readonly authenticationService: AuthenticationService,
    private readonly roomService: RoomService,
    private readonly recurrenceService: RecurrenceService,
    private readonly popoverService: PopoverService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly visitReschedulingService: VisitReschedulingService,
    protected readonly translateService: TranslateService,
    private readonly visitCancellationService: VisitCancellationService,
    private readonly cancelVisitFormService: CancelVisitFormService,
    private readonly themeService: ThemeService,
    bookingState: BookingFlowStateService,
    bookingFlowNavigationService: BookingFlowNavigationService,
    bookingFlowDialogService: BookingFlowDialogService
  ) {
    super(bookingState, bookingFlowNavigationService, bookingFlowDialogService);

    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.eventFormGroup = this.visitFormService.createVisitFormGroup(this.selectedVisit);
    this.otherVisitFormGroup = this.visitFormService.createOtherVisitFormGroup(this.selectedVisit);
    this.cancelVisitsFormGroup = this.cancelVisitFormService.createFormGroup();

    this.translateService.get('admin.pages.dashboard.createEventPopup.medicalVisit')
      .pipe(untilDestroyed(this))
      .subscribe((translation: string) => {
        this.toggleModeButtonOptions.push({ label: translation, isActive: this.visitType === VisitType.MEDICAL, isClickable: true });
      });

    this.translateService.get('admin.pages.dashboard.createEventPopup.otherVisit')
      .pipe(untilDestroyed(this))
      .subscribe((translation: string) => {
        this.toggleModeButtonOptions.push({ label: translation, isActive: this.visitType === VisitType.OTHER, isClickable: true });
      });

    this.onVisitDateTimeChanged();
    this.searchServices();
    this.searchRooms();

    this.eventFormGroup.patchValue({
      [VISIT_FORM_KEYS.get('visitType')]: this.visitType,
    });

    this.eventFormGroup.get(VISIT_FORM_KEYS.get('service')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onVisitDateTimeChanged();
        this.searchRooms();
      });

    this.eventFormGroup.get(VISIT_FORM_KEYS.get('room')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getRecurrence();
      });

    this.eventFormGroup.get(VISIT_FORM_KEYS.get('startDate')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getRecurrence();
      });

    this.eventFormGroup.get(VISIT_FORM_KEYS.get('startTime')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getRecurrence();
      });

    this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('room')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getRecurrenceForOtherVisit();
      });

    this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('startDate')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getRecurrenceForOtherVisit();
      });

    this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('startTime')).valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getRecurrenceForOtherVisit();
      });

    this.newPatientCreated = this.newPatientCreated.bind(this);
  }

  toggleMode(view: ButtonOption | number): void {
    // Get current values before switching forms
    const previousForm = this.visitType === VisitType.MEDICAL ? this.eventFormGroup : this.otherVisitFormGroup;
    const startDate = previousForm.get(VISIT_FORM_KEYS.get('startDate'))?.value;
    const startTime = previousForm.get(VISIT_FORM_KEYS.get('startTime'))?.value;

    // Switch the visit type
    this.visitType = view === 0 ? VisitType.MEDICAL : VisitType.OTHER;

    // Calculate start date and time
    const startDateAndTime = parse(`${startDate} ${startTime}`, DATE_FORMATS.serverDateTimeShort, new Date());
    let endDateAndTime: Date = null;

    if (this.visitType === VisitType.MEDICAL) {
      // For medical visits, use service duration or default 15 minutes
      const serviceId = this.eventFormGroup.get(VISIT_FORM_KEYS.get('service'))?.value;
      const selectedService = this.serviceOptions.find((option: Option) => option.value === serviceId);
      const visitDuration = selectedService?.extraNumber || 15;
      endDateAndTime = addMinutes(startDateAndTime, visitDuration);
    } else {
      // For other visits, always set to 1 hour duration when switching views
      endDateAndTime = addMinutes(startDateAndTime, 60);
    }

    const endTime = format(endDateAndTime, 'HH:mm');

    // Update both forms with the current values
    this.eventFormGroup.patchValue({
      [VISIT_FORM_KEYS.get('visitType')]: this.visitType,
      [VISIT_FORM_KEYS.get('startDate')]: startDate,
      [VISIT_FORM_KEYS.get('startTime')]: startTime,
      [VISIT_FORM_KEYS.get('endTime')]: endTime,
    });

    this.otherVisitFormGroup.patchValue({
      [VISIT_FORM_KEYS.get('visitType')]: this.visitType,
      [VISIT_FORM_KEYS.get('startDate')]: startDate,
      [VISIT_FORM_KEYS.get('startTime')]: startTime,
      [VISIT_FORM_KEYS.get('endTime')]: endTime,
    });

    // Update the selected visit times
    this.selectedVisit.start = startDateAndTime;
    this.selectedVisit.end = endDateAndTime;
    this.endTime = endTime;

    // Emit the updated visit
    this.visitTimeUpdated.emit(this.selectedVisit);
    this.searchRooms();
  }

  calculateScheduleImpact(): void {
    this.visitSchedulingImpact = null;
    this.visitSchedulingImpactMessage = 'admin.pages.dashboard.rescheduleConfirmation.calculatingImpact';

    this.visitSchedulingImpactCalculatingSubscription?.unsubscribe();

    this.visitSchedulingImpactCalculatingSubscription = this.visitReschedulingService
      .getVisitSchedulingImpact$(
        this.selectedVisit.start,
        this.selectedVisit.end,
        this.eventFormGroup.value.service,
        this.authenticationService.institution.id
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: VisitReschedulingImpact) => {
          this.visitSchedulingImpactCalculatingSubscription = null;
          this.visitSchedulingImpact = result;
          this.visitSchedulingImpactMessage = result.message;
        },
        error: (error: Error) => {
          console.error('Error occurred while calculating scheduling impact:', error);
        },
      });
  }

  getPatientSuggestions$ = (query?: string): Observable<Option[]> => {
    query = query?.trim();

    if (!query || query === '' || query.length < 3) {
      this.users = null;
      this.patientOptions = [];

      return of([]);
    }

    return this.institutionService.getUsersOfCurrentInstitution$({ search: query, limit: 10 }).pipe(
      tap((users: User[]) => this.users = users),
      map((users: User[]) =>
        users.map((user: User) => ({
          label: `${user.fullName} ${user.birthdate ? '- ' + format(new Date(user.birthdate), 'dd/MM/yyyy') : ''} ${user.socialSecurityNumber ? '- ' + user.socialSecurityNumber : ''}`,
          value: user.id,
        }))
      ),
      tap((options: Option[]) => this.patientOptions = options),
      finalize(() => this.isLoadingPatients = false)
    );
  };

  onPatientClicked(patient: string | User): void {
    this.eventFormGroup.patchValue({
      [VISIT_FORM_KEYS.get('patient')]: patient,
    });
  }

  openPopover(popoverId: string, preferredPosition: PopoverPosition = 'right'): void {
    this.popoverPreferredPositioning = preferredPosition;
    this.popoverService.open(popoverId);
    this.isActiveOnForeground = false;
  }

  closePopover(popoverId: string): void {
    this.popoverService.close(popoverId);
    this.isActiveOnForeground = true;
  }

  createNewPatient(): void {
    this.registerComponent.register();
  }

  newPatientCreated(user: User): void {
    this.createdUserLabel = `${user.fullName} - ${format(new Date(user.birthdate), 'dd/MM/yyyy')} - ${user.socialSecurityNumber}`;

    this.patientOptions = [...this.patientOptions, { label: this.createdUserLabel, value: user.id }];

    this.eventFormGroup.patchValue({
      [VISIT_FORM_KEYS.get('patient')]: user.id,
    });

    this.isNewUser = true;

    this.changeDetectorRef.detectChanges();

    this.popoverService.close(this.POPOVER_ID);
  }

  save(): void {
    this.isSubmitting = true;
    let updatedProperties = null;

    if (this.visitType === VisitType.MEDICAL) {
      const startDate = this.eventFormGroup.value.start_date;
      const startTime = this.eventFormGroup.value.start_time;
      const endTime = this.eventFormGroup.value.end_time;

      const startDateAndTime = parse(`${startDate} ${startTime}`, DATE_FORMATS.serverDateTimeShort, new Date());
      const endDateAndTime = parse(`${startDate} ${endTime}`, DATE_FORMATS.serverDateTimeShort, new Date());

      updatedProperties = {
        start: startDateAndTime,
        end: endDateAndTime,
        user_id: this.eventFormGroup.value.patient,
        service_id: this.eventFormGroup.value.service,
        room_id: this.eventFormGroup.value.room,
        recurrence_id: this.eventFormGroup.value.recurrence.id,
        booking_status: BookingStatus.CONFIRMED,
        send_confirmation: true,
        send_onboarding: this.isNewUser,
        visit_type: this.eventFormGroup.value.visit_type,
      };
    } else if (this.visitType === VisitType.OTHER) {
      const startDate = this.otherVisitFormGroup.value.start_date;
      const startTime = this.otherVisitFormGroup.value.start_time;
      const endTime = this.otherVisitFormGroup.value.end_time;

      const startDateAndTime = parse(`${startDate} ${startTime}`, DATE_FORMATS.serverDateTimeShort, new Date());
      const endDateAndTime = parse(`${startDate} ${endTime}`, DATE_FORMATS.serverDateTimeShort, new Date());

      updatedProperties = {
        start: startDateAndTime,
        end: endDateAndTime,
        room_id: this.otherVisitFormGroup.value.room,
        recurrence_id: this.otherVisitFormGroup.value.recurrence.id,
        booking_status: BookingStatus.CONFIRMED,
        visit_type: this.otherVisitFormGroup.value.visit_type,
        title: this.otherVisitFormGroup.value.custom_title,
        cancel_overlapping_visits: this.otherVisitFormGroup.value.cancel_overlapping_visits,
        user_id: null,
      };
    }

    this.visitService.createDummyVisit$()
      .pipe(
        untilDestroyed(this),
        switchMap((newVisit: Visit) => {
          return this.visitService.updateVisit$(newVisit.id, updatedProperties);
        })
      )
      .subscribe({
        next: () => {
          if (this.visitType === VisitType.OTHER && this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('cancelOverlappingVisits'))?.value === true) {
            this.cancelTheOverlappingVisits();
          }

          this.closePopup.emit();
        },
        error: (error: Error) => {
          console.error('Error occurred:', error);
        },
      });
  }

  cancel(): void {
    this.closePopup.emit();
  }

  private searchServices(): void {
    this.isLoadingServices = true;

    this.serviceService.getServicesForInstitutionFlat$(this.authenticationService.institution.id)
      .pipe(
        map((services: Service[]) =>
          services.map((service: Service) => ({
            label: service.displayFullName,
            value: service.id,
            extraNumber: service.duration,
          }))
        ),
        tap((options: Option[]) => {
          this.serviceOptions = options;
        }),
        finalize(() => this.isLoadingServices = false)
      )
      .subscribe({
        error: (error: Error) => {
          console.error('Error fetching services:', error);
        },
      });
  }

  private searchRooms(): void {
    this.isLoadingRooms = true;

    this.roomService.getRoomsOfInstitution$(true)
      .pipe(
        map((rooms: Room[]) =>
          rooms.map((room: Room) => ({ label: room.name, value: room.id }))
        ),
        tap((options: Option[]) => this.roomOptions = options),
        finalize(() => this.isLoadingRooms = false)
      )
      .subscribe({
        error: (error: Error) => {
          console.error('Error fetching rooms:', error);
        },
      });
  }

  private getRecurrence(): void {
    this.isRecurrenceFound = true;

    if (!this.selectedVisit.start || !this.selectedVisit.end || !this.eventFormGroup.get(VISIT_FORM_KEYS.get('service'))?.value || !this.eventFormGroup.get(VISIT_FORM_KEYS.get('room'))?.value) {
      return;
    }

    this.recurrenceService.findRecurrence$(this.selectedVisit.start, this.selectedVisit.end, this.eventFormGroup.get(VISIT_FORM_KEYS.get('service'))?.value, this.eventFormGroup.get(VISIT_FORM_KEYS.get('room'))?.value)
      .pipe(untilDestroyed(this))
      .subscribe((recurrence: Recurrence | null) => {
        if (!recurrence) {
          this.isRecurrenceFound = false;

          return;
        }

        this.eventFormGroup.patchValue({
          [VISIT_FORM_KEYS.get('recurrence')]: recurrence,
        });
        this.isRecurrenceFound = true;

        this.calculateScheduleImpact();
      });
  }

  private getRecurrenceForOtherVisit(): void {
    this.isRecurrenceFound = true;

    if (!this.selectedVisit.start || !this.selectedVisit.end || !this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('room'))?.value) {
      return;
    }

    this.recurrenceService.findRecurrence$(this.selectedVisit.start, this.selectedVisit.end, null, this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('room'))?.value)
      .pipe(untilDestroyed(this))
      .subscribe((recurrence: Recurrence | null) => {
        if (!recurrence) {
          this.isRecurrenceFound = false;

          return;
        }

        this.otherVisitFormGroup.patchValue({
          [VISIT_FORM_KEYS.get('recurrence')]: recurrence,
        });
        this.isRecurrenceFound = true;
      });
  }

  onVisitDateTimeChanged(): void {
    if (this.visitType === VisitType.MEDICAL) {
      const startDate = this.eventFormGroup.value.start_date;
      const startTime = this.eventFormGroup.value.start_time;
      const serviceId = this.eventFormGroup.get(VISIT_FORM_KEYS.get('service'))?.value;

      // Find the selected service from serviceOptions
      const selectedService = this.serviceOptions.find((option: Option) => option.value === serviceId);

      // Get the service duration, default to 15 if not found
      const visitDuration = selectedService?.extraNumber || 15;

      const startDateAndTime = parse(`${startDate} ${startTime}`, DATE_FORMATS.serverDateTimeShort, new Date());
      const endDateAndTime = addMinutes(startDateAndTime, visitDuration);

      this.selectedVisit.start = startDateAndTime;
      this.selectedVisit.end = endDateAndTime;

      this.endTime = format(endDateAndTime, 'HH:mm');

      // Update the form's end time
      this.eventFormGroup.patchValue({
        [VISIT_FORM_KEYS.get('endTime')]: this.endTime,
      }, { emitEvent: false });

      this.getRecurrence();
    } else if (this.visitType === VisitType.OTHER) {
      const startDate = this.otherVisitFormGroup.value.start_date;
      const startTime = this.otherVisitFormGroup.value.start_time;
      const startDateAndTime = parse(`${startDate} ${startTime}`, DATE_FORMATS.serverDateTimeShort, new Date());

      // Get current end time
      const endTime = this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('endTime')).value;
      let endDateAndTime: Date = null;

      if (endTime) {
        const currentEndDateAndTime = parse(`${startDate} ${endTime}`, DATE_FORMATS.serverDateTimeShort, new Date());
        // If end time is after start time, keep it, otherwise set to start time + 1 hour
        endDateAndTime = isAfter(currentEndDateAndTime, startDateAndTime) ? currentEndDateAndTime : addMinutes(startDateAndTime, 60);

        // Update the form's end time if it was adjusted
        if (!isAfter(currentEndDateAndTime, startDateAndTime)) {
          const newEndTime = format(endDateAndTime, 'HH:mm');
          this.otherVisitFormGroup.patchValue({
            [VISIT_FORM_KEYS.get('endTime')]: newEndTime,
          }, { emitEvent: false });
        }
      } else {
        // If no end time set, default to start time + 1 hour
        endDateAndTime = addMinutes(startDateAndTime, 60);
        const newEndTime = format(endDateAndTime, 'HH:mm');
        this.otherVisitFormGroup.patchValue({
          [VISIT_FORM_KEYS.get('endTime')]: newEndTime,
        }, { emitEvent: false });
      }

      this.selectedVisit.start = startDateAndTime;
      this.selectedVisit.end = endDateAndTime;
      this.endTime = format(endDateAndTime, 'HH:mm');

      this.getRecurrenceForOtherVisit();
    }

    this.visitTimeUpdated.emit(this.selectedVisit);
  }

  onApproveTimeChangeClicked(): void {
    if (this.otherVisitFormGroup.value.cancel_overlapping_visits === true) {
      this.getOverlappingVisits();
    } else {
      this.save();
    }
  }

  getOverlappingVisits(): void {
    this.recurrenceService.getOverlappingVisits$(this.otherVisitFormGroup.value.room, this.selectedVisit.start, this.selectedVisit.end)
      .pipe(untilDestroyed(this))
      .subscribe((visits: Visit[]) => {
        this.overlappingVisits = visits;

        if (this.overlappingVisits.length > 0) {
          this.openPopover(this.CANCEL_VISITS_POPOVER_ID);
        } else {
          this.otherVisitFormGroup.patchValue({
            [VISIT_FORM_KEYS.get('cancelOverlappingVisits')]: true,
          });

          this.save();
        }
      });
  }

  onApproveCancelOverlappingVisitsClicked(): void {
    this.save();
    this.cancelTheOverlappingVisits();
  }

  onDisapproveCancelOverlappingVisitsClicked(): void {
    this.otherVisitFormGroup.patchValue({
      [VISIT_FORM_KEYS.get('cancelOverlappingVisits')]: false,
    });

    this.closePopover(this.CANCEL_VISITS_POPOVER_ID);
  }

  cancelTheOverlappingVisits(): void {
    const visitIds = this.overlappingVisits.map((visit: Visit) => visit.id);
    this.cancelVisitsFormGroup.patchValue({
      [CANCEL_VISIT_FORM_KEYS.get('visitIds')]: visitIds,
    });

    this.cancelVisitsSubscription = this.visitCancellationService.bulkCancelVisit$(this.cancelVisitsFormGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.cancelVisitsSubscription = null;
        },
        error: () => {
          this.cancelVisitsSubscription = null;
        },
      });
  }
}
