import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-navigation-return-link',
  templateUrl: './navigation-return-link.component.html',
  styleUrls: ['./navigation-return-link.component.scss'],
})
export class NavigationReturnLinkComponent extends BreakpointObserverComponent {
  protected theme: Theme;

  @Input() icon: string = 'assets/icons/cross.svg';
  @Input() color: string;
  @Input() label: string | null = 'common.return';

  @Output() navigateBackClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    breakpointObserver: BreakpointObserver,
    themeService: ThemeService
  ) {
    super(breakpointObserver);

    this.theme = themeService.currentTheme;
    this.color = themeService.currentTheme.colorScheme().black;
  }
}
