<div class="container gap-v-16">
  <div class="flex-row gap-h-16">
    <vh-message
      class="font-regular-18-22"
      [ngClass]="{ clickable: !isLoading, 'not-clickable': isLoading }"
      [text]="
        (selectedUser
          ? 'admin.pages.settings.administrators.create.updateAdministrator'
          : 'admin.pages.settings.administrators.create.newAdministrator'
        ) | translate
      "
      [iconLeftPath]="isLoading ? 'assets/icons/hourglass.svg' : 'assets/icons/arrow-left.svg'"
      [iconLeftFill]="theme.colorScheme().black"
      [iconLeftAlt]="
        (selectedUser
          ? 'admin.pages.settings.administrators.create.updateAdministrator'
          : 'admin.pages.settings.administrators.create.newAdministrator'
        ) | translate
      "
      (messageClick)="navigateBack()">
    </vh-message>
  </div>

  <div class="registration-form" [formGroup]="formGroup">

    <!-- Email -->
    <p>{{ 'admin.pages.settings.administrators.create.email' | translate }}</p>
    <vh-text-input
      type="email"
      autocomplete="email"
      [formControlName]="INSTITUTION_USER_FORM_KEYS.get('email')"
      (keyup)="onEmailKeyUp($event)"
      [iconRightPath]="showCheckingIfUserExistsLoadingIndicator ? 'assets/icons/hourglass.svg' : ''">
    </vh-text-input>

    <ng-container *ngIf="isUpdateMode || showCreateUserFormControls">
      <!-- Title -->
      <div class="flex-row-l-c gap-h-4">
        <p>{{ 'pages.register.titleInputLabel' | translate }}</p>
        <vh-icon
          [src]="'assets/icons/question-circle.svg'"
          [matTooltip]="'pages.register.titleInfo' | translate"
          width="18"
          height="18"
          [fill]="theme.colorScheme().black">
        </vh-icon>
      </div>
      <vh-select-input [formControlName]="INSTITUTION_USER_FORM_KEYS.get('title')" [options]="titleOptions" [hasEmptyOption]="true"></vh-select-input>

      <!-- First name -->
      <p>{{ 'admin.pages.settings.administrators.create.firstName' | translate }}</p>
      <vh-text-input [formControlName]="INSTITUTION_USER_FORM_KEYS.get('firstName')" autocomplete="given-name"></vh-text-input>

      <!-- Last name -->
      <p>{{ 'admin.pages.settings.administrators.create.name' | translate }}</p>
      <vh-text-input [formControlName]="INSTITUTION_USER_FORM_KEYS.get('lastName')" autocomplete="family-name"></vh-text-input>

      <!-- Username -->
      <p>{{ 'common.fields.username' | translate }}</p>
      <vh-username-input
        [showValidationIcon]="!isUpdateMode"
        [shouldGenerateSuggestions]="!isUpdateMode"
        [formControlName]="INSTITUTION_USER_FORM_KEYS.get('username')"
        [firstname]="formGroup.get(INSTITUTION_USER_FORM_KEYS.get('firstName')).value"
        [lastname]="formGroup.get(INSTITUTION_USER_FORM_KEYS.get('lastName')).value"
        [emailField]="formGroup.get(INSTITUTION_USER_FORM_KEYS.get('email')).value">
      </vh-username-input>

      <!-- Roles -->
      <p>{{ 'admin.pages.settings.administrators.create.roles' | translate }}</p>
      <div class="flex-col-l-c">
        <div *ngFor="let role of assignableRoles" class="flex-row-l-c gap-h-8">
          <vh-checkbox
            [label]="'roles.' + role + '.displayName' | translate: { brand: theme.displayName() }"
            [isDisabled]="!canEditRole(role)"
            [value]="userRoles.has(role)"
            (valueChanged)="onRoleCheckedChanged($event, role)">
          </vh-checkbox>
          <vh-icon
            [src]="'assets/icons/question-circle.svg'"
            [matTooltip]="'roles.' + role + '.description' | translate: { brand: theme.displayName() }"
            width="18"
            height="18"
            [fill]="theme.colorScheme().black">
          </vh-icon>
        </div>
      </div>

      <!-- Organisation position -->
      <p>{{ 'admin.pages.settings.administrators.create.organisationPosition' | translate }}</p>
      <div class="flex-col-l-c gap-v-8">
        <div *ngFor="let position of assignablePositions" class="flex-row-l-c gap-h-8">
          <input type="radio" [value]="position" [formControlName]="INSTITUTION_USER_FORM_KEYS.get('organisationPosition')" />
          <label>{{ 'organisationPositions.' + position + '.displayName' | translate }}</label>
        </div>
      </div>
    </ng-container>
  </div>

  <vh-message
    *ngIf="errorMessageTranslationKey"
    class="not-clickable"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="errorMessageTranslationKey | translate"></vh-message>

  <div *ngIf="isUpdateMode || showCreateUserFormControls" class="flex-row-c-n">
    <vh-button
      class="save-button"
      [label]="'common.cancel' | translate"
      (buttonClick)="navigateBack()"
      [style]="ButtonStyle.TERTIARY"
      [isDisabled]="sendingUpdateRequest"></vh-button>
    <vh-button
      class="save-button"
      [label]="'common.save' | translate"
      (buttonClick)="saveUser()"
      [isDisabled]="formGroup.invalid || formGroup.disabled || sendingUpdateRequest"></vh-button>
  </div>
  <div *ngIf="!isUpdateMode && !showCreateUserFormControls" class="flex-row-c-n">
    <vh-button
      class="save-button"
      [label]="'common.ok' | translate"
      [isDisabled]="this.formGroup.get(INSTITUTION_USER_FORM_KEYS.get('email')).invalid"
      (buttonClick)="onEmailEntered()"></vh-button>
  </div>
</div>

<ng-template #userExistsDialogTemplate>
  <vh-dialog-frame
    [title]="'admin.pages.settings.addAdministrator.warning' | translate"
    (closeClicked)="userAlreadyExistsDialogRef.close()">
    <p>{{ 'admin.pages.settings.addAdministrator.userNameInUse' | translate }}</p>
    <p [vhMarginTop]="8" class="has-text-primary">{{ selectedUser?.fullName + ' (' + selectedUser?.email + ')' }}</p>
    <p [vhMarginTop]="16">{{ 'admin.pages.settings.addAdministrator.addAdmin' | translate : { user: selectedUser?.fullName } }}</p>
    <div [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
      <vh-button
        [label]="'common.cancel' | translate"
        [style]="ButtonStyle.TERTIARY"
        (buttonClick)="cancelExistingUser(selectedUser)"></vh-button>
      <vh-button
        [label]="'admin.pages.settings.addAdministrator.add' | translate"
        [style]="ButtonStyle.PRIMARY"
        (buttonClick)="editExistingUser(selectedUser)"></vh-button>
    </div>
  </vh-dialog-frame>
</ng-template>
