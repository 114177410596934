import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VisitService } from '@services/visits/visit.service';
import { TaskStatus } from '@enums/task-status.enum';
import { VisitTask } from '@models/visit-task.model';
import { Visit } from '@models/visit.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BOOKING_NAV } from '@modules/booking/constants/booking-nav.constant';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-view-visit-tasks-page',
  templateUrl: './view-visit-tasks-page.component.html',
  styleUrls: ['./view-visit-tasks-page.component.scss'],
})
@UntilDestroy()
export class ViewVisitTasksPageComponent implements OnInit {
  protected theme: Theme;

  state: 'loading' | 'empty' | 'error' | 'loaded' = 'loading';
  visit: Visit;
  requiredTasksAreCompleted: boolean;
  showAfterBookingInformation: boolean;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appointmentService: VisitService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
    this.showAfterBookingInformation = false; //!this.authService.currentUser?.hasAnyInstitutionRole();
  }

  ngOnInit(): void {
    this.loadAppointment();
  }

  loadAppointment(): void {
    this.state = 'loading';

    const visitId: string = this.activatedRoute.snapshot.paramMap.get(BOOKING_NAV.visitViewTasks.params.visitId);
    this.appointmentService.getVisitById$(visitId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (visit: Visit): void => {
          this.loadData(visit);
        },
        error: (): void => {
          this.state = 'error';
        },
      });
  }

  loadData(visit: Visit): void {
    this.visit = visit;
    this.state = visit.allTasks.length ? 'loaded' : 'empty';
    this.requiredTasksAreCompleted = this.visit.allTasks.some((task: VisitTask) => !task.optional && task.status !== TaskStatus.COMPLETED);
  }
}
