<label *ngIf="label" [innerHTML]="label"></label>
<div class="flex-col input-wrapper">
  <input
    type="text"
    [ngClass]="{
      error: hasError
    }"
    [ngModel]="value"
    [ngModelOptions]="{ standalone: true }"
    [placeholder]="placeholder || ''"
    [disabled]="isDisabled"
    (ngModelChange)="handleValueChangeWithCheck($event)" />
  <vh-icon
    *ngIf="!value"
    class="inline-icon inline-icon-right"
    src="assets/icons/search.svg"
    [fill]="theme.colorScheme().secondaryGrey">
  </vh-icon>
  <vh-icon
    *ngIf="value"
    class="clickable inline-icon inline-icon-right"
    src="assets/icons/cross.svg"
    width="12"
    height="12"
    [fill]="theme.colorScheme().secondaryGrey"
    [hoverFill]="theme.colorScheme().error"
    (click)="onClearClicked()">
  </vh-icon>
</div>
