<form [formGroup]="formGroup" class="flex-col">
  <vh-icon
    class="clickable align-end"
    src="assets/icons/trash-alt.svg"
    [hoverFill]="theme.colorScheme().error"
    [ariaLabel]="'admin.pages.slotsOverview.roomsPage.delete' | translate"
    (click)="deleteItemClick.emit()">
  </vh-icon>

  <div [vhMarginTop]="8" class="option-container gap-h-8">
    <p>{{ 'admin.pages.slotsOverview.roomsPage.serviceGroup' | translate }}</p>
    <vh-select-input
      *ngIf="options"
      class="input"
      [value]="formGroup.get(CREATE_ROOM_FORM_KEYS.get('serviceGroup')).value"
      [formControlName]="CREATE_ROOM_FORM_KEYS.get('serviceGroup')"
      [placeholder]="'admin.pages.slotsOverview.roomsPage.selectBody' | translate"
      [options]="options"
      (inputValueChange)="serviceGroupSelected.emit($event)">
    </vh-select-input>
    <vh-color-picker [formControlName]="CREATE_ROOM_FORM_KEYS.get('color')" [isReadonly]="true" [isDisabled]="true"></vh-color-picker>
  </div>
</form>
