import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { LanguageService } from '@services/language.service';
import { Option } from '@interfaces/option.interface';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { InstitutionService } from '@services/institution.service';
import { ToastService } from '@services/ui/toast.service';
import { InstitutionSettingsFormService } from '@services/form-services/institution-settings-form.service';
import { IObject } from '@app-types/iobject.type';
import { INSTITUTION_SETTINGS_FORM_KEYS } from '@constants/form-keys/institution-settings-form-keys.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { QrCodeService } from '@services/qr-code.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { BaseSettingsPageComponent } from '@modules/settings/pages/base-settings-page/base-settings-page.component';
import { ModuleKey } from '@enums/module-key.enum';
import { LanguagePickerComponent } from '@modules/shared/form/components/language-picker/language-picker.component';
import { InstitutionSettings } from '@models/institution-settings.model';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-general-settings-page',
  templateUrl: './general-settings-page.component.html',
  styleUrls: ['./general-settings-page.component.scss'],
})
@UntilDestroy()
export class GeneralSettingsPageComponent extends BaseSettingsPageComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ModuleKey: typeof ModuleKey = ModuleKey;

  @ViewChild('languagePicker') languagePickerComponent: LanguagePickerComponent;

  constructor(
    institutionService: InstitutionService,
    toastService: ToastService,
    authenticationService: AuthenticationService,
    institutionSettingsFormService: InstitutionSettingsFormService,
    languageService: LanguageService,
    themeService: ThemeService,
    private readonly alertDialogService: AlertDialogService,
    private readonly qrCodeService: QrCodeService,
    private readonly translate: TranslateService
  ) {
    super(institutionService, toastService, authenticationService, institutionSettingsFormService, languageService, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loadVesaliusLanguages();
  }

  resetForm(): void {
    this.formGroup = this.institutionSettingsFormService.createGeneralSettingsFormGroup(this.institution.settings);
  }

  onSupportedLanguageClicked(language: Option): void {
    if (language.value === this.formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('default_language')).value) {
      this.toastService.showInfo('admin.pages.settings.generalSettings.cantUnlinkDefaultLanguageMessage', 'common.ok')
        .pipe(untilDestroyed(this))
        .subscribe();

      return;
    }

    if (!this.institution.settings.supportedLanguages.has(language.value as string)) {
      this.institution.settings.supportedLanguages.add(language.value as string);
      this.loadSupportedLanguagesOfInstitution();
      this.saveSettings();

      return;
    }

    this.showConfirmDeleteSupportedLanguageDialog(language.value as string);
  }

  downloadQRCode(): void {
    combineLatest([
      this.qrCodeService.generate$(this.institution.reservationLink),
      this.translate.get('admin.pages.settings.generalSettings.qrCodeFilename', { institution: this.institution.name }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([data, filename]: [string, string]) => {
        const link: HTMLAnchorElement = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
        document.body.removeChild(link);
      });
  }

  private showConfirmDeleteSupportedLanguageDialog(language: string): void {
    this.alertDialogService.open({
      titleTranslationKey: 'admin.pages.settings.generalSettings.deleteLanguageConfirmationDialog.title',
      messageTranslationKey: 'admin.pages.settings.generalSettings.deleteLanguageConfirmationDialog.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    }).pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        if (!isConfirmed) {
          return;
        }

        this.institution.settings.supportedLanguages.delete(language);
        this.loadSupportedLanguagesOfInstitution();
        this.saveSettings();
      });
  }

  protected saveSettings(): void {
    const payload: IObject = this.buildSavePayload();

    this.institutionService.updateSettings$(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (settings: InstitutionSettings): void => {
          this.institution.settings = settings;
          this.languagePickerComponent?.reloadLanguageOptions();
          this.showSaveConfirmation();
        },
        error: (): void => {
          this.showGenericErrorToast();
        },
      });
  }

  protected buildSavePayload(): IObject {
    return {
      ...super.buildSavePayload(),
      supported_languages: Array.from(this.institution.settings.supportedLanguages),
    };
  }
}
