import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Visit } from '@models/visit.model';
import { Room } from '@models/room.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-visits-overview-printout',
  templateUrl: './visits-overview-printout.component.html',
  styleUrls: ['./visits-overview-printout.component.scss'],
})
@UntilDestroy()
export class VisitsOverviewPrintoutComponent implements OnChanges {
  state: 'empty' | 'list' = 'empty';

  DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() selectedDate: Date = new Date();
  @Input() rooms: Room[] = [];
  @Input() visits: Visit[];

  protected visitsPerRoom: Map<string, Visit[]> = new Map<string, Visit[]>();
  protected theme: Theme;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visits || changes.rooms) {
      this.regroupData();
    }
  }

  private regroupData(): void {
    this.visitsPerRoom.clear();
    this.visits?.forEach((visit: Visit) => {
      if (!this.visitsPerRoom.has(visit.room?.id)) {
        this.visitsPerRoom.set(visit.room?.id, []);
      }
      this.visitsPerRoom.get(visit.room?.id).push(visit);
    });
    this.state = this.visitsPerRoom.size > 0 ? 'list' : 'empty';
  }
}
