import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { SurveyFormService } from '@services/form-services/survey-form.service';
import { SURVEY_FORM_KEYS } from '@constants/form-keys/survey-form-keys.constant';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-question-settings',
  templateUrl: './question-settings.component.html',
  styleUrls: ['./question-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionSettingsComponent {
  protected readonly SURVEY_FORM_KEYS: typeof SURVEY_FORM_KEYS = SURVEY_FORM_KEYS;

  protected theme: Theme;

  @Input() formGroup: UntypedFormGroup;
  @Input() isDisabled: boolean = false;
  @Input() questionTypeOptions: Option[];
  @Input() translationPreview: string;
  @Input() translationAnswerOptionsPreview: string[];
  @Input() questionIndex: number;

  @Output() removeClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly surveyFormService: SurveyFormService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  get answerOptions(): UntypedFormArray {
    return this.formGroup.controls.answer_options as UntypedFormArray;
  }

  onAddAnswerOptionClicked(answerOptions: UntypedFormArray): void {
    answerOptions.push(this.surveyFormService.createAnswerOption());
  }

  onDeleteAnswerOptionClicked(params: { formArray: UntypedFormArray; index: number; }): void {
    params.formArray.removeAt(params.index);
  }

  onDeleteQuestionClicked(): void {
    this.removeClicked.emit();
  }

  getElementId(name: string): string {
    return `${name}-${this.questionIndex}`;
  }
}
