import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { User } from '@models/user.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { InstitutionService } from '@services/institution.service';
import { Router } from '@angular/router';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';

@UntilDestroy()
@Component({
  selector: 'vh-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit {
  protected readonly MIN_QUERY_LENGTH: number = 3;

  @Input() maxResults: number = 5;

  @Output() userSelected: EventEmitter<User> = new EventEmitter<User>();

  searchingUsersSubscription: Subscription | null;
  onSearchValueChange$: Subject<string> = new Subject<string>();
  users: User[] | null = null;
  selectedUser: User | null = null;

  isLoading: boolean = false;

  constructor(
    private readonly institutionService: InstitutionService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.onSearchValueChange$
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(500))
      .subscribe((query: string | undefined): void => {
        query = query?.trim();

        if (!query || query === '' || query.length < this.MIN_QUERY_LENGTH) {
          this.users = null;

          return;
        }

        this.searchUsers(query);
      });
  }

  onUserCardClick(user: User): void {
    this.selectedUser = user;
    this.userSelected.emit(user);
  }

  addNewPatient(): void {
    void this.router.navigate([CONVERSATION_NAV.create.newPatient.route]);
  }

  private searchUsers(query?: string): void {
    this.isLoading = true;
    this.searchingUsersSubscription?.unsubscribe();
    this.searchingUsersSubscription = this.institutionService.getUsersOfCurrentInstitution$({ search: query, limit: this.maxResults })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (results: User[]): void => {
          this.searchingUsersSubscription = null;
          this.users = results;
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse): void => {
          this.searchingUsersSubscription = null;
          this.isLoading = false;
          if (error.status === 404) {
            this.users = [];
          }
        },
      });
  }
}
