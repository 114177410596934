import { Component, Input } from '@angular/core';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
})
export class StatusMessageComponent {
  readonly SUCCESS_ICON_PATH: string = 'assets/icons/circle-check.svg';
  readonly FAILED_ICON_PATH: string = 'assets/icons/circle-x.svg';

  protected theme: Theme;

  @Input() isSuccessful: boolean;
  @Input() title: string;
  @Input() message: string;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }
}
