<div class="results">
  <div class="flex-row-s-c flex-row-l-t flex-col-l-c-when-small header">
    <!-- Title -->
    <h2>{{ 'conversations.details.results' | translate }}</h2>

    <!-- Action buttons -->
    <p-splitButton
      [label]="'conversations.details.copySummary' | translate"
      [model]="menuItems"
      (onClick)="copySummary()"
      [buttonDisabled]="!conversation.summary">
    </p-splitButton>
  </div>

  <!-- Summary -->
  <div class="summary gap-v-16">
    <!-- No summary -->
    <p class="no-results" *ngIf="!conversation.summary">{{ 'conversations.details.noResults' | translate }}</p>

    <div
      class="summary-group"
      *ngFor="let section of interpretedSummaryJson; let i = index"
    >
      <div class="group-header" (click)="toggleGroup(i)">
        <vh-icon
          [src]="'assets/icons/chevron.svg'"
          [class.expanded]="isExpanded[i]"
          width="20"
          height="20"
          [fill]="theme.colorScheme().secondaryGrey">
        </vh-icon>
        <h3>{{ section.label }}{{ section.score ? ' - ' + section.score : '' }}</h3>
      </div>

      <div
        class="group-value"
        *ngIf="isExpanded[i]"
      >
        <div *ngFor="let entry of section.values" class="flex-row gap-h-4">
          <span>{{ entry.label }}:</span>
          <span>{{ entry.value }}{{ entry.score ? ' - ' + entry.score : '' }}</span>
        </div>
      </div>
    </div>
  </div>

  <p *ngIf="!conversation.summaryJson && conversation.summary">{{ conversation.summary }}</p>
</div>
