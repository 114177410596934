import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { IObject } from '@app-types/iobject.type';

export interface FeedbackRequest {
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  constructor(
    private readonly http: HttpClient
  ) { }

  platformFeedback(data: IObject): Observable<void> {
    const endpoint: string = ENDPOINTS.platformFeedback.route;

    return this.http
      .post(environment.apiBaseUrl + endpoint, data)
      .pipe(switchToEmptyObservable());
  }

  rateSummary(data: IObject): Observable<void> {
    const endpoint: string = ENDPOINTS.rateSummary.route
      .replace(/{.*}/, data.conversation_uuid);

    return this.http
      .post(environment.apiBaseUrl + endpoint, data)
      .pipe(switchToEmptyObservable());
  }

  updateConversationFeedback(data: IObject): Observable<void> {
    const endpoint: string = ENDPOINTS.updateConversationFeedback.route
      .replace(/{.*}/, data.conversation_uuid);

    return this.http
      .patch(environment.apiBaseUrl + endpoint, data)
      .pipe(switchToEmptyObservable());
  }
}
