<div
  class="button-group flex-row-l-c"
  [ngClass]="{
    'button-group-gap': style === ButtonGroupStyle.SEPARATED,
    'button-group-wrap': style === ButtonGroupStyle.SEPARATED && shouldWrap
  }">
  <button
    *ngFor="let item of items; let index = index"
    class="flex-row-c-c font-regular-16-19 gap-h-4"
    [ngClass]="{
      active: item.isActive,
      clickable: item.isClickable,
      'not-clickable': !item.isClickable,
      separated: style === ButtonGroupStyle.SEPARATED,
      connected: style === ButtonGroupStyle.CONNECTED,
      calendar: style === ButtonGroupStyle.CALENDAR
    }"
    (click)="onItemClick(index)">
    <vh-icon
      *ngIf="item.iconLeftPath"
      [src]="item.iconLeftPath"
      [height]="16"
      [width]="16"
      [fill]="item.isActive ? theme.colorScheme().white : theme.colorScheme().secondaryGrey"></vh-icon>
    <span [innerHTML]="item.label | translate"></span>
    <vh-icon
      *ngIf="item.iconRightPath"
      [src]="item.iconRightPath"
      [height]="16"
      [width]="16"
      [fill]="item.isActive ? theme.colorScheme().white : theme.colorScheme().secondaryGrey"></vh-icon>
  </button>
</div>
