import { Component, Input } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';
import { Visit } from '@models/visit.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';

@Component({
  selector: 'vh-visit-status-list',
  templateUrl: './visit-status-list.component.html',
  styleUrls: ['./visit-status-list.component.scss'],
})
export class VisitStatusListComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() visit: Visit;
}
