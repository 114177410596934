import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { Router } from '@angular/router';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { NAVIGATION } from '@constants/navigation.constant';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '@environments/environment';
import { DOCTOR_FORM_KEYS } from '@constants/form-keys/doctor-form-keys.constant';
import { NewOnboardingFormService } from '@services/form-services/new-onboarding-form.service';
import { NewOnboardingService } from '@services/new-onboarding.service';
import { ThemeService } from '@services/theming/theme.service';
import { IObject } from '@app-types/iobject.type';

@Component({
  selector: 'vh-onboarding-privacy-page',
  templateUrl: './onboarding-privacy-page.component.html',
  styleUrls: ['./onboarding-privacy-page.component.scss'],
})
@UntilDestroy()
export class OnboardingPrivacyPageComponent extends BaseOnboardingPageComponent implements OnInit {
  protected readonly REGISTER_FORM_KEYS: typeof REGISTER_FORM_KEYS = REGISTER_FORM_KEYS;
  protected readonly DOCTOR_FORM_KEYS: typeof DOCTOR_FORM_KEYS = DOCTOR_FORM_KEYS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  termsAndConditionsLink: string = environment.links.termsAndConditions;
  formGroup: UntypedFormGroup;
  isSubmitting: boolean = false;
  onboardingPayload: IObject;

  constructor(
    private readonly newOnboardingFormService: NewOnboardingFormService,
    private readonly newOnboardingService: NewOnboardingService,
    router: Router,
    themeService: ThemeService
  ) {
    super(router, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.onboardingPayload = this.newOnboardingService.getOnboardingPayload();
    this.formGroup = this.newOnboardingFormService.createFormGroupForOnboardingAPrivacy(this.onboardingPayload?.accepted_terms);
  }

  setStep(): void {
    this.state.goToStep(this.STEP_PRIVACY);
  }

  onNextClicked(): void {
    this.newOnboardingService.setOnboardingPayload({ ...this.onboardingPayload, accepted_terms: true });
    void this.router.navigate([ONBOARDING_NAV.createInstitution.route]);
  }

  onBackClicked(): void {
    void this.router.navigate([ONBOARDING_NAV.credentials.route]);
  }
}
