<div class="flex-row-r-c flex-col-l-c-when-small gap-h-16" [ngClass]="{ 'not-clickable not-selectable disabled': isDisabled }">
  <div class="flex-row-c-c gap-h-16">
    <vh-icon
      [matTooltip]="
        'admin.pages.dashboard.calendar.actions.previous'
          | translate : { timeframe: 'common.timeframes.singular.' + calendarMode | translate | lowercase }
      "
      class="clickable"
      src="assets/icons/arrow-left-secondary.svg"
      (click)="updateSelectedDate(-1)">
    </vh-icon>
    <vh-icon
      [matTooltip]="'admin.pages.dashboard.calendar.actions.today' | translate"
      class="clickable"
      src="assets/icons/circle.svg"
      [fill]="theme.colorScheme().black"
      (click)="updateSelectedDate(0)">
    </vh-icon>
    <vh-icon
      [matTooltip]="
        'admin.pages.dashboard.calendar.actions.next'
          | translate : { timeframe: 'common.timeframes.singular.' + calendarMode | translate | lowercase }
      "
      class="clickable"
      src="assets/icons/arrow-right-secondary.svg"
      (click)="updateSelectedDate(1)">
    </vh-icon>
  </div>
  <vh-button-group
    *ngIf="supportedModes.length > 1"
    [items]="calenderModeOptions"
    [style]="ButtonGroupStyle.CONNECTED"
    [singleItemSelectable]="true"
    (itemClick)="onCalendarModeChangeClicked($event)">
  </vh-button-group>
  <vh-text-input
    #dateField
    type="date"
    [min]="DATE_BOUNDARIES.min.dateString"
    [max]="DATE_BOUNDARIES.max.dateString"
    [placeholder]="'admin.pages.slotsOverview.slotsPage.dateFormatPlaceholder' | translate"
    (keyup.enter)="onSpecificDateSelected(dateField.value)"
    (change)="onSpecificDateSelected(dateField.value)">
  </vh-text-input>
</div>
