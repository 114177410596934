<vh-primary-page [navigationItems]="navigationItems" [hasVerticalContentSpacing]="false"
  [hasHorizontalContentSpacing]="false" [profileTemplate]="profileTemplate">
  <div class="grid-container">
    <div *ngIf="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]" class="content-block-padding">
      <vh-filter-visits-sidebar
        [isCollapsed]="isFilterBarCollapsed"
        [filter]="filter"
        [isLoading]="isLoadingVisits"
        (filterChange)="onFilterChanged()"
        (clearFilterClick)="onClearFilterClicked()"
        (searchClick)="onSearchClicked()"
        (collapseIconClick)="onCollapseFilterBarClicked()"
        (roomFilterClick)="onRoomFilterClicked($event)">
      </vh-filter-visits-sidebar>
    </div>
    <div class="content-block-padding flex-col" *ngIf="mode === 'search'">
      <vh-message
        class="clickable font-medium-19-23"
        iconLeftPath="assets/icons/arrow-left.svg"
        [text]="'admin.pages.dashboard.searchResults.title' | translate"
        (messageClick)="onClearFilterClicked()">
      </vh-message>
      <vh-events-list
        class="scroll-container"
        infiniteScroll
        [vhMarginTop]="8"
        [events]="events"
        [isLoading]="isLoadingVisits"
        [scrollWindow]="false"
        (eventClick)="onSearchResultClicked($event)"
        (scrolled)="onScrolledToEndOfSearchResults()">
      </vh-events-list>
    </div>
    <vh-events-calendar
      *ngIf="mode === 'calendar'"
      class="content-block-padding"
      popoverPositioningStrategy="left_or_right"
      popoverConnectToFirstHtmlElementWithTagName="vh-visits-overview-page"
      [vhPopoverHost]="POPOVER_ID"
      [popoverTemplate]="popupTemplate"
      [popoverHasBackdrop]="true"
      [popoverPreferredPosition]="popoverPreferredPositioning"
      [locale]="locale"
      [calendarMode]="calendarMode"
      [weekStartsOn]="1"
      [events]="events"
      [selectedDate]="selectedDate"
      [eventTimeChangeConfirmationPopoverTemplate]="popoverTemplate"
      [isLoading]="isLoadingVisits"
      [isDisabled]="isLoadingVisits"
      [eventActionsTemplate]="eventsCalendarEventActionTemplate"
      [eventTooltipTemplate]="tooltipTemplate"
      [zoomLevel]="zoomLevel"
      [openings]="openings"
      [headerTemplate]="calendarHeaderControls"
      (weekViewDayClick)="onDateSelected($event)"
      (monthViewDayClick)="onDateSelected($event)"
      (eventClick)="onEventClicked($event)"
      (hourSegmentClick)="onHourSegmentClicked($event)"
      (eventTimeChange)="onEventTimeChanged($event)"
      (zoomLevelChange)="onZoomLevelChanged($event)">
    </vh-events-calendar>
    <vh-visits-overview-printout [selectedDate]="selectedDate" [rooms]="filter.selectedRoomsArray" [visits]="visits">
    </vh-visits-overview-printout>
  </div>
</vh-primary-page>

<!-- Calendar heading -->
<ng-template #calendarHeaderControls>
  <vh-visits-calendar-header
    [calendarMode]="calendarMode"
    [selectedDate]="selectedDate"
    [isLoading]="isLoadingVisits"
    [isDisabled]="isLoadingVisits"
    [zoomLevel]="zoomLevel"
    [isAutoRefreshEnabled]="isAutoRefreshEnabled"
    (zoomLevelChange)="onZoomLevelChanged($event)"
    (calendarModeChange)="onCalendarModeChangeClicked($event)"
    (refreshRequested)="onRefreshRequested($event)"
    (selectedDateChange)="onSelectedDateChange($event)"
    (exportClick)="onExportClicked()">
  </vh-visits-calendar-header>

  <vh-button
    [label]="'reservations.new' | translate"
    [vhMarginTop]="16"
    [isDisabled]="isLoadingReservationFlow"
    [isLoading]="isLoadingReservationFlow"
    (buttonClick)="onStartBookingFlowClicked()">
  </vh-button>
</ng-template>

<!-- Create new visit/event popover -->
<ng-template #popupTemplate>
  <div class="pop-up-container">
    <vh-create-event-popup
      vhOutsideClick
      vhEscapePressed
      #createEventPopupComponent
      [outsideClickDisabled]="!createEventPopupComponent.isActiveOnForeground"
      [selectedVisit]="selectedVisit"
      (visitTimeUpdated)="updatePlaceholderEventTime($event)"
      (closePopup)="onClosePopup()"
      (escapePressed)="onClosePopup(false)"
      (outsideClick)="onClosePopup(false)">
    </vh-create-event-popup>
  </div>
</ng-template>

<!-- Rescheduling impact Popover -->
<ng-template #popoverTemplate>
  <vh-visit-rescheduling-impact-result
    *ngIf="!selectedVisit.visitType || selectedVisit.visitType === VisitType.MEDICAL"
    class="col-span-full"
    [visit]="selectedVisit"
    [newStartDateTime]="lastCalendarEventTimesChangedEvent.newStart"
    [newEndDateTime]="lastCalendarEventTimesChangedEvent.newEnd"
    (visitRescheduleSuccess)="onRescheduleVisitSuccess($event)"
    (visitRescheduleFailed)="onRescheduleVisitFailed()"
    (cancelClicked)="onCancelRescheduleEventClicked()">
  </vh-visit-rescheduling-impact-result>

  <vh-other-visit-rescheduling-impact-result
    *ngIf="selectedVisit.visitType === VisitType.OTHER"
    [visit]="selectedVisit"
    [newStartDateTime]="lastCalendarEventTimesChangedEvent.newStart"
    [newEndDateTime]="lastCalendarEventTimesChangedEvent.newEnd"
    (visitRescheduleSuccess)="onRescheduleVisitSuccess($event)"
    (visitRescheduleFailed)="onRescheduleVisitFailed()"
    (cancelClicked)="onCancelRescheduleEventClicked()">
  </vh-other-visit-rescheduling-impact-result>
</ng-template>

<!-- Clickable icons for event -->
<ng-template #eventsCalendarEventActionTemplate let-event="event">
  <div *ngIf="event.meta?.visit?.visitType === VisitType.MEDICAL" [vhMarginTop]="4" class="flex-row"
    [ngClass]="{ 'flex-wrap': calendarMode !== 'day', 'gap-h-4': calendarMode === 'day' }">
    <vh-icon
      *ngFor="let option of visitStatusOptions"
      class="clickable"
      [matTooltip]="option.label"
      [src]="option.icon"
      [fill]="event.meta?.visit?.status?.id === option.value ? theme.colorScheme().black : theme.colorScheme().white"
      [hoverFill]="theme.colorScheme().primary"
      [width]="calendarMode === 'day' ? 20 : 14"
      [height]="calendarMode === 'day' ? 20 : 14"
      (click)="onEventActionClicked($event, event.meta?.visit, option)">
    </vh-icon>
    <vh-icon
      src="assets/icons/euro-circle.svg"
      class="clickable align-end"
      [ngClass]="{
        'paid-action': calendarMode === 'day',
        'anim-heart-beat': !event.meta?.visit?.wasPaid && event.meta?.visit?.wasExecuted
      }"
      [width]="calendarMode === 'day' ? 20 : 14"
      [height]="calendarMode === 'day' ? 20 : 14"
      [fill]="event.meta?.visit?.wasPaid ? theme.colorScheme().primary : theme.colorScheme().white"
      [matTooltip]="
        (event.meta?.visit?.wasPaid ? 'admin.pages.dashboard.markAsUnpaidTooltip' : 'admin.pages.dashboard.markAsPaidTooltip') | translate
      "
      (click)="onUpdatePaymentStatusClicked($event, event.meta?.visit)">
    </vh-icon>
  </div>
</ng-template>

<!-- Tooltip for event -->
<ng-template #tooltipTemplate let-event="event" let-placement="placement">
  <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
    <div class="cal-tooltip-arrow"></div>
    <div class="cal-tooltip-inner" [innerHtml]="event?.meta?.visit?.customTitle ? event?.meta?.visit?.customTitle : event?.meta?.visit?.multilineTitle"></div>
  </div>
</ng-template>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
