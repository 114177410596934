import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';
import { WizardState } from '@modules/shared/core/models/wizard-state.model';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-wizard-layout',
  templateUrl: './wizard-layout.component.html',
  styleUrls: ['./wizard-layout.component.scss'],
})
export class WizardLayoutComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  protected theme: Theme;

  @Input() progressIndicatorMaxWidth: number | string;
  @Input() state: WizardState;

  @Input() contentHasHorizontalSpacing: boolean;
  @Input() contentHasVerticalSpacing: boolean;
  @Input() contentMaxWidth: number;

  @Input() leftHeaderTemplate: TemplateRef<HTMLElement> | null;
  @Input() rightHeaderTemplate: TemplateRef<HTMLElement> | null;

  @Output() stepClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() nextStepClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() previousStepClicked: EventEmitter<void> = new EventEmitter<void>();

  contentHasReachedScrollEnd: boolean;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  setContentHasReachedScrollEnd = (contentElement: HTMLElement): void => {
    this.contentHasReachedScrollEnd = contentElement.offsetHeight + contentElement.scrollTop >= contentElement.scrollHeight;
  };
}
