<vh-centered-card-page>
  <vh-message
    *ngIf="!isSubmitted"
    textClass="has-text-primary font-light-14-17"
    [text]="'pages.forgotPassword.loginButtonLabel' | translate"
    [iconLeftPath]="'assets/icons/chevron-left.svg'"
    [iconLeftStroke]="theme.colorScheme().primary"
    [routerLink]="NAVIGATION.login.route">
  </vh-message>
  <div class="flex-col-c-c" [vhMarginTop]="16">
    <div class="flex-col">
      <ng-container *ngIf="!isSubmitted" [ngTemplateOutlet]="forgotPasswordFormTemplate"></ng-container>
      <ng-container *ngIf="isSubmitted" [ngTemplateOutlet]="submitSuccessfulTemplate"></ng-container>
    </div>
  </div>
</vh-centered-card-page>

<ng-template #forgotPasswordFormTemplate [formGroup]="formGroup">
  <h1>{{ 'pages.forgotPassword.title' | translate }}</h1>
  <p [vhMarginTop]="16" [vhFontClass]="'font-regular-18-22'">{{ 'pages.forgotPassword.text' | translate }}</p>
  <vh-text-input
    [vhMarginTop]="16"
    [formControlName]="FORGOT_PASSWORD_FORM_KEYS.get('username')"
    [label]="'common.fields.username' | translate"
    (keydown)="onUsernameEntered($event)">
  </vh-text-input>
  <vh-message
    *ngIf="submitUsernameErrorMessageTranslationKey"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="submitUsernameErrorMessageTranslationKey | translate">
  </vh-message>
  <vh-message
    class="has-text-primary font-light-14-17 align-end"
    [vhMarginTop]="8"
    [text]="'pages.forgotPassword.forgotUsernameLabel' | translate"
    (messageClick)="onForgotUsernameClicked()">
  </vh-message>
  <vh-button
    class="align-center"
    [vhMarginTop]="32"
    [label]="'pages.forgotPassword.submitButtonLabel' | translate"
    [iconRightPath]="'assets/icons/arrow-right-white.svg'"
    [isDisabled]="!username || submitUsernameButtonIsDisabled"
    (buttonClick)="submitUsername()">
  </vh-button>
</ng-template>

<ng-template #submitSuccessfulTemplate>
  <vh-post-action-status
    [isStroke]="true"
    [title]="('pages.forgotPassword.submitted.title' | translate)"
    [message]="'pages.forgotPassword.submitted.text' | translate"
    [actionText]="'pages.forgotPassword.submitted.loginButtonLabel' | translate"
    [routerLink]="NAVIGATION.login.route">
  </vh-post-action-status>
</ng-template>
