import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { isToday } from 'date-fns';
import { BreakpointObserver } from '@angular/cdk/layout';
import { VisitCalendarMetaType } from '@services/calendar-event-mapper.service';
import { getInstitutionRoles, Role } from '@enums/role.enum';
import { AuthenticationService } from '@services/authentication.service';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent extends BreakpointObserverComponent implements OnInit, OnChanges {
  protected readonly getInstitutionRoles: () => Role[] = getInstitutionRoles;

  rowClass: 'event-row-full' | 'event-row-truncated' = 'event-row-full';

  @Input() events: CalendarEvent<VisitCalendarMetaType>[];
  @Input() isLoading: boolean;

  @Output() eventClick: EventEmitter<CalendarEvent> = new EventEmitter<CalendarEvent>();

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly elementRef: ElementRef,
    private readonly authenticationService: AuthenticationService
  ) {
    super(breakpointObserver);
  }

  ngOnInit(): void {
    this.rowClass = this.authenticationService.currentUser.hasAnyInstitutionRole() ? 'event-row-full' : 'event-row-truncated';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events && !changes.events.isFirstChange() && this.events?.length > 0) {
      setTimeout(() => {
        this.scrollToMostRelevantCalendarEvent();
      }, 100);
    }
  }

  onEventClicked(event: CalendarEvent): void {
    this.eventClick.emit(event);
  }

  isToday(start: Date): boolean {
    return isToday(start);
  }

  private scrollToMostRelevantCalendarEvent(): void {
    const closestEvent = this.findCalendarEventClosestToCurrentTime();
    const element = this.getCalendarEventRowElement(closestEvent.id as string);

    element?.scrollIntoView({ block: 'center' });
  }

  private getCalendarEventRowElement(id: string): HTMLElement {
    const currentTimeElementSelector = `[id='${id}']`;

    return this.elementRef.nativeElement.querySelector(currentTimeElementSelector);
  }

  private findCalendarEventClosestToCurrentTime(): CalendarEvent {
    const today = new Date();
    const goal = 0;
    const diffEventTimesFromToday = this.events?.map((eventA: CalendarEvent) => {
      return eventA.start.getTime() - today.getTime();
    });

    if (!diffEventTimesFromToday || diffEventTimesFromToday.length === 0) {
      return null;
    }

    const closestDiffToToday = diffEventTimesFromToday?.reduce(function(prev: number, curr: number) {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
    });
    const index = diffEventTimesFromToday.indexOf(closestDiffToToday);

    if (index < 0) {
      return null;
    }

    return this.events[index];
  }
}
