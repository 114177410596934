<div class="flex-col has-background-white border-medium">
  <!-- Conditionally show copy EMR or no EMR -->
  <span
    class="clickable"
    *ngIf="flatSummary; else noSummaryTemplate"
    (click)="copyEmr()">
    {{ 'conversations.copyEMR' | translate }}
  </span>
  <ng-template #noSummaryTemplate>
    <span
      class="disabled">
      {{ 'conversations.noEMRToCopy' | translate }}
    </span>
  </ng-template>

  <!-- Conditionally show delete or undo soft delete -->
  <span
    class="clickable"
    *ngIf="conversation.status !== ConversationStatus.REMOVED; else undoSoftDeleteButton"
    (click)="clickDeleteConversation()">
    {{ 'common.delete' | translate }}
  </span>
  <ng-template #undoSoftDeleteButton>
    <span
      class="clickable"
      (click)="showUndoDeleteConfirmationDialog()">
      {{ 'common.undoSoftDelete' | translate }}
    </span>
  </ng-template>
</div>