import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseSettingsPageComponent } from '@modules/settings/pages/base-settings-page/base-settings-page.component';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionService } from '@services/institution.service';
import { ToastService } from '@services/ui/toast.service';
import { LanguageService } from '@services/language.service';
import { InstitutionSettingsFormService } from '@services/form-services/institution-settings-form.service';
import { Option } from '@interfaces/option.interface';
import { CommunicationMethodService } from '@services/communication-method.service';
import { CommunicationMethod } from '@enums/communication-methods';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversations-page.component',
  templateUrl: './conversations-page.component.html',
  styleUrls: ['./conversations-page.component.scss'],
})
@UntilDestroy()
export class ConversationsPageComponent extends BaseSettingsPageComponent implements OnInit {
  readonly CommunicationMethod: typeof CommunicationMethod = CommunicationMethod;

  communicationMethods: Option[] = [];

  constructor(
    authenticationService: AuthenticationService,
    institutionSettingsFormService: InstitutionSettingsFormService,
    institutionService: InstitutionService,
    toastService: ToastService,
    languageService: LanguageService,
    themeService: ThemeService,
    private readonly communicationMethodService: CommunicationMethodService
  ) {
    super(institutionService, toastService, authenticationService, institutionSettingsFormService, languageService, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.communicationMethods = this.communicationMethodService.getAsOptions();
  }

  resetForm(): void {
    this.formGroup = this.institutionSettingsFormService.createConversationSettingsFormGroup(this.institution.settings);
  }
}
