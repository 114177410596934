<mat-sidenav-container class="container" autosize="true">
  <mat-sidenav
    #sidenav
    [mode]="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium] ? 'over' : 'side'"
    [opened]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
    class="sidebar">
    <div class="flex-col side-bar-items selected">
      <div *ngFor="let navItem of navigationItems" class="nav-item">
        <a *ngIf="!navItem.subNavigationItems" [routerLink]="navItem.route">
          <span class="font-regular-18-22" [routerLinkActive]="'active'">
            {{ navItem.labelTranslationKey | translate: { brand: theme.displayName() } }}
          </span>
        </a>
        <a *ngIf="navItem.subNavigationItems" (click)="toggleItem(navItem)">
          <span class="font-regular-18-22">
            {{ navItem.labelTranslationKey | translate: { brand: theme.displayName() } }}
          </span>
          <vh-icon
            [@rotateAnimation]="isOpened(navItem)"
            *ngIf="navItem.subNavigationItems"
            [fill]="theme.colorScheme().black"
            src="assets/icons/angle-down.svg"
            class="dropdown-icon">
          </vh-icon>
        </a>

        <div @slideInOutAnimation *ngIf="navItem.subNavigationItems && isOpened(navItem)" class="side-bar-items">
          <div *ngFor="let subNavItem of navItem.subNavigationItems" class="sub-nav-item">
            <a [routerLink]="subNavItem.route">
              <span class="font-regular-18-22" [routerLinkActive]="'active'">{{ subNavItem.labelTranslationKey | translate: { brand: theme.displayName() } }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <vh-button
      *ngIf="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
      (click)="sidenav.toggle()"
      iconRightPath="assets/icons/angle-right-b.svg"
      iconRightHeight="40"
      iconRightWidth="40"
      [style]="ButtonStyle.SIDE_TOGGLE"
      class="side-toggle" />
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
