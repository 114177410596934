<div class="container">
  <div class="flex-row-l-c content">
    <div class="top-bar" [ngClass]="{ 'collapsed': !expanded }">
      <div class="logo">
        <img
          [ngClass]="{ clickable: !isRootRoute }"
          [src]="theme.logo()"
          [alt]="'navigationBar.logoImage' | translate: { brand: theme.displayName() }"
          (click)="navigateToHome()" />

        <div class="logo-text">
          <img
            [ngClass]="{ clickable: !isRootRoute }"
            [src]="theme.logoText()"
            [alt]="'navigationBar.logoImage' | translate: { brand: theme.displayName() }"
            (click)="navigateToHome()" />
        </div>
      </div>

      <div class="background"></div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
