import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { InstitutionFormService } from '@services/form-services/institution-form.service';
import { CREATE_INSTITUTION_FORM_KEYS } from '@constants/form-keys/create-institution-form-keys.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NewOnboardingService } from '@services/new-onboarding.service';
import * as countries from 'i18n-iso-countries';
import { Option } from '@interfaces/option.interface';
import { Observable, of } from 'rxjs';
import { CountryService } from '@services/county.service';
import { ThemeService } from '@services/theming/theme.service';
import { LanguageService } from '@services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { IObject } from '@app-types/iobject.type';

@Component({
  selector: 'vh-onboarding-institution-page',
  templateUrl: './onboarding-institution-page.component.html',
  styleUrls: ['./onboarding-institution-page.component.scss'],
})
@UntilDestroy()
export class OnboardingInstitutionPageComponent extends BaseOnboardingPageComponent implements OnInit {
  protected readonly CREATE_INSTITUTION_FORM_KEYS: typeof CREATE_INSTITUTION_FORM_KEYS = CREATE_INSTITUTION_FORM_KEYS;

  formGroup: UntypedFormGroup;
  countryOptions: Option[] = [];
  isSubmitting: boolean = false;
  detectedCountry: string;
  language: string;
  onboardingPayload: IObject;

  constructor(
    private readonly institutionFormService: InstitutionFormService,
    private readonly languageService: LanguageService,
    private readonly newOnboardingService: NewOnboardingService,
    private readonly countryService: CountryService,
    private readonly translate: TranslateService,
    router: Router,
    themeService: ThemeService
  ) {
    super(router, themeService);

    this.language = this.languageService.getPreferredLanguage();

    // Bind the method
    this.getCountrySuggestions$ = this.getCountrySuggestions$.bind(this);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.onboardingPayload = this.newOnboardingService.getOnboardingPayload();
    this.formGroup = this.institutionFormService.createFormGroup(this.onboardingPayload?.institution_name, this.onboardingPayload?.institution_country);

    if (this.onboardingPayload.institution_country) {
      this.detectedCountry = this.onboardingPayload.institution_country;
    } else {
      this.detectCountry();
    }
  }

  getCountrySuggestions$(query?: string): Observable<Option[]> {
    const countryNames = countries.getNames(this.language, { select: 'official' });

    this.countryOptions = Object.keys(countryNames).map((code: string) => ({
      label: countryNames[code],
      value: countryNames[code],
    }));

    if (!query) {
      return of(this.countryOptions);
    }

    const filteredOptions = this.countryOptions.filter((option: Option) =>
      option.label.toLowerCase().includes(query.trim().toLowerCase())
    );

    return of(filteredOptions);
  }

  private detectCountry(): void {
    this.countryService.getCountry$()
      .pipe(untilDestroyed(this))
      .subscribe((country: string) => {
        this.detectedCountry = this.translate.instant(`common.countries.${country}`);
        this.formGroup.patchValue({
          country: this.detectedCountry,
        });
      });
  }

  setStep(): void {
    this.state.goToStep(this.STEP_INSTITUTION);
  }

  onNextClicked(): void {
    this.newOnboardingService.setOnboardingPayload({
      ...this.onboardingPayload,
      institution_name: this.formGroup.value.name,
      institution_country: this.formGroup.value.country,
    });

    void this.router.navigate([ONBOARDING_NAV.createAccount.route]);
  }

  onBackClicked(): void {
    void this.router.navigate([ONBOARDING_NAV.privacy.route]);
  }
}
