<div class="institution-scope-header flex-row-l-c gap-h-16 has-background-grey">
  <img *ngIf="institution?.logo" [src]="institution?.logo" [title]="institution.name" />
  <div *ngIf="!institution?.logo" class="logo-alternative">
    <p class="font-regular-18-22">
      {{ institution.parent ? institution.parent.name : institution.name }}
    </p>
    <p
      class="has-text-tertiary"
      *ngIf="
          institution.parent &&
          institution.parent.name.toLowerCase() !== institution.name.toLowerCase()
        "
      [vhMarginTop]="4">
      {{ institution.name }}
    </p>
  </div>
  <ng-container *ngIf="institution.address">
    <div class="vertical-divider"></div>
    <p>{{ institution.address }}</p>
  </ng-container>
</div>
