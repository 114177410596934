import { CardService } from '@modules/shared/cards/services/card.service';
import { Component, Input, OnInit } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { CardType } from '@modules/settings/enums/card-type.enum';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteCardDialogComponent } from '@modules/settings/dialogs/delete-card-dialog/delete-card-dialog.component';
import { SettingsCard } from '@modules/settings/interfaces/settings-card.interface';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-settings-card-overview-settings-page',
  templateUrl: './card-overview-settings-page.component.html',
  styleUrls: ['./card-overview-settings-page.component.scss'],
})
@UntilDestroy()
export class CardOverviewSettingsPageComponent implements OnInit {
  protected theme: Theme;

  @Input() filterOptions: Option[];
  @Input() searchPlaceholder: string;
  @Input() filterPlaceholder: string;
  @Input() addLabel: string;
  @Input() addRoute: string;
  @Input() detailRoute: string;
  @Input() editRoute: string;
  @Input() cardType: CardType;
  @Input() impactedItemRoute: string;

  displayedCards: SettingsCard[] = [];
  isLoading: boolean;
  searchValue: string;
  selectedFilterOptions: Option[] = [];

  constructor(
    private readonly cardService: CardService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.loadCards();
    this.selectedFilterOptions = this.filterOptions;
  }

  isCareProgram(): boolean {
    return this.cardType === CardType.CARE_PROGRAM;
  }

  hasFilterActive(): boolean {
    return this.cardService.getCards(this.cardType).length > 0 &&
        !!this.searchValue ||
        this.selectedFilterOptions.length >= 0;
  }

  onSearchQueryEntered(query: string): void {
    this.searchValue = query;
    this.updateDisplayedCards();

  }

  onSelectedFilterOptionsChanged(selectedOptions: Option[]): void {
    this.selectedFilterOptions = selectedOptions;
    this.updateDisplayedCards();
  }

  onAddCardClicked(): void {
    void this.router.navigate([this.addRoute]);
  }

  onCardClicked(card: SettingsCard): void {
    const route = this.detailRoute.replace(':id', card.id);
    void this.router.navigate([route]);
  }

  onEditCardClicked(card: SettingsCard): void {
    const route = this.editRoute.replace(':id', card.id);

    void this.router.navigate([route]);
  }

  onDuplicateCardClicked(card: SettingsCard): void {
    this.cardService.duplicateCard$(card)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (duplicatedCard: SettingsCard) => {
          this.cardService.setCards(this.cardType, [...this.cardService.getCards(this.cardType), duplicatedCard]);
          this.updateDisplayedCards();
        },
      });
  }

  onRemoveCardClicked(card: SettingsCard): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      card: card,
    };

    this.dialog.open(DeleteCardDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.onDeleteCardConfirmed(card);
        }
      });
  }

  onDeleteCardConfirmed(card: SettingsCard): void {
    this.cardService.deleteCard$(card)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.cardService.setCards(this.cardType, this.cardService.getCards(this.cardType).filter((c: SettingsCard) => c.id !== card.id));
          this.updateDisplayedCards();
        },
      });
  }

  updateDisplayedCards(): void {
    let pool: SettingsCard[] = this.cardService.getCards(this.cardType);

    if (this.searchValue) {
      pool = this.cardService.getCards(this.cardType).filter((card: SettingsCard) => {
        return card.name.get().toLowerCase().includes(this.searchValue.toLowerCase());
      });
    }
    const options: string[] = this.selectedFilterOptions.map((option: Option) => option.value as string);
    pool = pool?.filter((card: SettingsCard) => {
      return options.includes(card.status);
    });

    this.displayedCards = pool;
  }

  loadCards(): void {
    this.isLoading = true;
    this.cardService.loadCards$(this.cardType)
      .pipe(untilDestroyed(this))
      .subscribe((cards: SettingsCard[]) => {
        this.isLoading = false;
        this.cardService.setCards(this.cardType, cards);
        this.updateDisplayedCards();
      });
  }
}
