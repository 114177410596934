import { Component, OnInit } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IObject } from '@app-types/iobject.type';
import { INSTITUTION_SETTINGS_FORM_KEYS } from '@constants/form-keys/institution-settings-form-keys.constant';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionSettingsFormService } from '@services/form-services/institution-settings-form.service';
import { InstitutionService } from '@services/institution.service';
import { ToastService } from '@services/ui/toast.service';
import { LanguageService } from '@services/language.service';
import { BaseSettingsPageComponent } from '@modules/settings/pages/base-settings-page/base-settings-page.component';
import { ModuleKey } from '@enums/module-key.enum';
import { Institution } from '@models/institution.model';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-communication-settings-page',
  templateUrl: './communication-settings-page.component.html',
  styleUrl: './communication-settings-page.component.scss',
})
@UntilDestroy()
export class CommunicationSettingsPageComponent extends BaseSettingsPageComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ModuleKey: typeof ModuleKey = ModuleKey;

  visitGuidelinesSelectedLanguage: string;

  errors: string[];
  fileToUpload: File | null = null;
  logo: string;
  isLoadingLogo: boolean = true;

  constructor(
    authenticationService: AuthenticationService,
    institutionSettingsFormService: InstitutionSettingsFormService,
    institutionService: InstitutionService,
    toastService: ToastService,
    languageService: LanguageService,
    themeService: ThemeService
  ) {
    super(institutionService, toastService, authenticationService, institutionSettingsFormService, languageService, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadVesaliusLanguages();
    this.visitGuidelinesSelectedLanguage = this.institution.settings.defaultLanguage;
    this.isLoadingLogo = true;
  }

  resetForm(): void {
    this.formGroup = this.institutionSettingsFormService.createCommunicationSettingsFormGroup(this.institution.settings, this.institution.settings.defaultLanguage);
  }

  onVisitGuidelinesLanguageChanged(newLanguage: string): void {
    this.visitGuidelinesSelectedLanguage = newLanguage;
  }

  sendVisitPreviewEmail(): void {
    this.institutionService.sendVisitPreviewEmail$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.toastService.showInfo('admin.pages.settings.generalSettings.sendPreviewEmailSuccess', 'common.ok')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
        error: (): void => {
          this.toastService.showError('common.unknownError', 'common.ok')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  onFilePicked = (files: FileList): void => {
    this.fileToUpload = files[0];
  };

  uploadLogo = (): void => {
    this.institutionService.uploadLogo$(this.fileToUpload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: Institution): void => {
          this.institution.logo = response.logo;
          this.fileToUpload = null;
        },
        error: (): void => {
          this.toastService.showError('common.unknownError', 'common.ok')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  };

  onDeleteLogoClicked = (): void => {
    this.institutionService.deleteLogo$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.institution.logo = null;
        },
        error: (): void => {
          this.toastService.showError('common.unknownError', 'common.ok')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  };

  protected saveSettings(): void {
    if (this.fileToUpload) {
      this.uploadLogo();
    }

    super.saveSettings();
  }

  protected buildSavePayload(): IObject {
    return {
      ...super.buildSavePayload(),
      supported_languages: Array.from(this.institution.settings.supportedLanguages),
      visit_guidelines: {
        [this.visitGuidelinesSelectedLanguage]: this.formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('visit_guidelines')).value,
      },
    };
  }

  protected reloadInstitution(): void {
    super.reloadInstitution();

    this.institutionService.getInstitutionByIdOrSlug$(this.institution.id, true)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (institution: Institution): void => {
          this.logo = institution.logo;
          this.isLoadingLogo = false;
        },
        error: (): void => {
          this.isLoadingLogo = false;
          this.toastService.showError('common.unknownError', 'common.ok')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }
}
