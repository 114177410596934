import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { isSameMonth, isSameWeek, isToday } from 'date-fns';
import { defaultPreferences } from '@interfaces/preferences.interface';
import { calendarZoomPresets } from '@modules/shared/calendar/components/events-calendar/calendar-zoom-presets';
import { EventsCalendarMode } from '@modules/shared/calendar/components/events-calendar/events-calendar.component';
import { AutoRefreshDirective } from '@modules/shared/core/directives/auto-refresh.directive';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

/**
 * This can be used to identify if a refresh was initiated manually by the user or automatically by the system.
 */
export type RefreshInitiator = 'manual' | 'auto';

@Component({
  selector: 'vh-visits-calendar-header',
  templateUrl: './visits-calendar-header.component.html',
  styleUrls: ['./visits-calendar-header.component.scss'],
})
export class VisitsCalendarHeaderComponent {
  protected theme: Theme;

  @Input() isAutoRefreshEnabled: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() calendarMode: EventsCalendarMode;
  @Input() selectedDate: Date = new Date();
  @Input() zoomLevel: number = defaultPreferences.calendar.zoom;

  @Output() exportClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() refreshRequested: EventEmitter<RefreshInitiator> = new EventEmitter<RefreshInitiator>();
  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() calendarModeChange: EventEmitter<typeof this.calendarMode> = new EventEmitter<typeof this.calendarMode>();
  @Output() zoomLevelChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(AutoRefreshDirective) autoRefreshDirective: AutoRefreshDirective;

  readonly zoomStepSize: number = 1;
  readonly minZoomLevel: number = 0;
  readonly maxZoomLevel: number = calendarZoomPresets.size;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  resetAutoRefresh(): void {
    this.autoRefreshDirective.resetAutoRefresh();
  }

  onPrintOverviewClicked(): void {
    window.print();
  }

  onExportClicked(): void {
    this.exportClick.emit();
  }

  updateZoomLevel(stepSize: number): void {
    this.zoomLevel += stepSize;
    this.zoomLevelChange.emit(this.zoomLevel);
  }

  resetZoomLevel(): void {
    this.zoomLevel = this.minZoomLevel;
    this.zoomLevelChange.emit(this.zoomLevel);
  }

  doesCalendarDisplayToday(): boolean {
    switch (this.calendarMode) {
      case 'day':
        return isToday(this.selectedDate);
      case 'week':
        return isSameWeek(this.selectedDate, new Date());
      default:
        return isSameMonth(this.selectedDate, new Date());
    }
  }
}
