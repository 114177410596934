<div class="flex-col has-background-white border-minimal" cdkMenu>
  <vh-message
    class="content-block-padding not-selectable clickable"
    [matTooltip]="(!canMarkAsDefault ? 'wallet.paymentMethods.alreadyDefaultPaymentMethodTooltip' : '') | translate"
    [class.menu-item-disabled]="!canMarkAsDefault"
    [class.clickable]="canMarkAsDefault"
    [class.has-text-primary]="!canMarkAsDefault"
    [iconLeftPath]="paymentMethod.isDefault ? 'assets/icons/check-circle.svg' : ''"
    [iconLeftFill]="theme.colorScheme().primary"
    [text]="(paymentMethod.isDefault ? 'wallet.paymentMethods.defaultLabelFull' : 'wallet.paymentMethods.actionMarkAsDefault') | translate "
    (messageClick)="onMarkAsDefaultClicked()">
  </vh-message>
  <p
    class="content-block-padding not-selectable has-text-alert"
    [matTooltip]="(!canDelete ? 'wallet.paymentMethods.deleteDisabledTooltip' : '') | translate"
    [class.menu-item-disabled]="!canDelete"
    [class.clickable]="canDelete"
    (click)="onDeleteClicked()">
    {{ 'common.delete' | translate  }}
  </p>
</div>
