import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { Room } from '@models/room.model';
import { ServiceService } from '@services/service.service';
import { Option } from '@interfaces/option.interface';
import { Service } from '@models/service.model';
import { ButtonStyle } from '@enums/button-style.enum';
import { RoomService } from '@services/room.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CREATE_ROOM_FORM_KEYS } from '@constants/form-keys/create-room-form-keys.constant';
import { ServiceGroupFormItem } from '@interfaces/room-settings-form.interface';
import { ServiceGroup } from '@models/service-group.model';
import { ServiceGroupService } from '@services/service-group.service';
import { combineLatest, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { RoomFormService } from '@services/form-services/room-form.service';
import { User } from '@models/user.model';
import { Role } from '@enums/role.enum';
import { AuthenticationService } from '@services/authentication.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-room-settings-page',
  templateUrl: './room-settings-page.component.html',
  styleUrls: ['./room-settings-page.component.scss'],
})
export class RoomSettingsPageComponent implements OnInit {
  protected readonly Role: typeof Role = Role;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly CREATE_ROOM_FORM_KEYS: typeof CREATE_ROOM_FORM_KEYS = CREATE_ROOM_FORM_KEYS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  private isCreationMode: boolean = false;
  private fetchRoomSubscription: Subscription;

  protected theme: Theme;

  createOrUpdateRoomSubscription: Subscription;

  navigationItems: NavigationItem[];

  selectedRoom: Room;

  formGroup: UntypedFormGroup;

  allModalityOptions: Option[];
  allServiceGroupOptions: Option[];

  filteredServiceGroupOptions: Option[];

  errorMessageTranslationKey: string;
  isLoading: boolean = false;

  currentUser: User;

  constructor(
    private readonly router: Router,
    private readonly serviceService: ServiceService,
    private readonly serviceGroupService: ServiceGroupService,
    private readonly roomService: RoomService,
    private readonly roomFormService: RoomFormService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly authenticationService: AuthenticationService
  ) {
    this.navigationItems = getNavigationItems();
  }

  get serviceGroupFormArray(): UntypedFormArray {
    return this.formGroup?.get(CREATE_ROOM_FORM_KEYS.get('serviceGroups')).value as UntypedFormArray ?? null;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.formGroup = this.roomFormService.createFormGroup();
    this.currentUser = this.authenticationService.currentUser;

    combineLatest([
      this.serviceGroupService.getServiceGroupsForCurrentInstitution$(),
      this.serviceService.getServicesForCurrentInstitution$(null, true),
    ]).subscribe(() => {
      this.isLoading = false;
      this.allModalityOptions = this.serviceService.servicesForCurrentInstitution.map((service: Service): Option => service.toOption(true));
      this.restoreRoomToForm();
    });
  }

  addServiceGroup(group: ServiceGroup = null): void {
    const serviceGroupForm: UntypedFormGroup = this.roomFormService.createServiceGroupForm(this.theme, group);
    this.serviceGroupFormArray?.push(serviceGroupForm);

    // If no group was passed it implies that we are adding a new service group, so we should mark the form as dirty
    if (!group) {
      this.formGroup.markAsTouched();
      this.formGroup.markAsDirty();
    }
  }

  updateServiceGroup(serviceGroupId: string): void {
    const selectedServiceGroup: ServiceGroup = this.serviceGroupService.serviceGroupsForCurrentInstitution.find((group: ServiceGroup) => group.id === serviceGroupId);

    // Patch the values so the form reflects the defaults of the chosen service group
    const controlIndex = this.serviceGroupFormArray.controls.findIndex((control: UntypedFormGroup) => control.get('serviceGroup').value === serviceGroupId);
    this.serviceGroupFormArray.controls[controlIndex].get('color').setValue(selectedServiceGroup.color);
  }

  removeServiceGroup = (form: UntypedFormGroup): void => {
    const groupId = (form.value as ServiceGroupFormItem).serviceGroup;
    const serviceGroup = this.serviceGroupService.serviceGroupsForCurrentInstitution.find((group: ServiceGroup) => group.id === groupId);
    const controlIndex = (Object.keys(this.serviceGroupFormArray.controls) as (keyof typeof UntypedFormControl)[])
      .findIndex((key: string) => {
        return this.serviceGroupFormArray.controls[key] === form;
      });

    if (controlIndex >= 0) {
      this.serviceGroupFormArray.removeAt(controlIndex);
      this.formGroup.updateValueAndValidity();
      this.formGroup.markAsDirty();
      this.formGroup.markAsTouched();
    }

    if (serviceGroup) {
      const selectedRoomSgIndex = this.selectedRoom.serviceGroups.findIndex((g: ServiceGroup) => g.id === serviceGroup.id);
      this.selectedRoom.serviceGroups.splice(selectedRoomSgIndex, 1);
    }
  };

  saveConfiguration(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {

      return;
    }

    const room: Room = this.roomFormService.createRoomFromForm(this.formGroup, this.selectedRoom, this.serviceGroupService.serviceGroupsForCurrentInstitution, this.serviceService.servicesForCurrentInstitution);

    if (!this.isCreationMode) {
      this.createOrUpdateRoomSubscription?.unsubscribe();
      this.createOrUpdateRoomSubscription = this.roomService.updateRoom$(this.selectedRoom.id, room)
        .subscribe({
          next: () => {
            this.createOrUpdateRoomSubscription = null;
            this.selectedRoom = room;
            this.formGroup.markAsPristine();
            this.isCreationMode = false;
          },
          error: () => {
            this.createOrUpdateRoomSubscription = null;
          },
        });

      return;
    }

    this.createOrUpdateRoomSubscription?.unsubscribe();
    this.createOrUpdateRoomSubscription = this.roomService.createRoom$(room)
      .subscribe({
        next: (result: Room) => {
          this.createOrUpdateRoomSubscription = null;
          this.selectedRoom = result;
          this.roomService.roomsOfInstitution.push(this.selectedRoom);
          this.formGroup.markAsPristine();
        },
        error: () => {
          this.createOrUpdateRoomSubscription = null;
        },
      });
  }

  deleteRoom(): void {
    this.roomService.deleteRoom$(this.selectedRoom.id).subscribe(() => {
      this.selectedRoom = this.roomService.roomsOfInstitution.length > 0 ? this.roomService.roomsOfInstitution[0] : null;
      void this.router.navigate([NAVIGATION.adminDashboardSettings.rooms.route]);
    });
  }

  cancel(): void {
    this.formGroup = this.roomFormService.createFormGroup(this.selectedRoom);
  }

  private setRoom(room?: Room): void {
    this.selectedRoom = room;
    this.formGroup = this.roomFormService.createFormGroup(this.selectedRoom);
    this.setServiceGroups();
  }

  private setServiceGroups(): void {
    this.allServiceGroupOptions = this.serviceGroupService.serviceGroupsForCurrentInstitution
      .map((group: ServiceGroup) => group.toOption(true));
    this.filteredServiceGroupOptions = this.allServiceGroupOptions;
  }

  private restoreRoomToForm(): void {
    const roomId: string = this.activatedRoute.snapshot.params.roomId;
    const room: Room = this.router.getCurrentNavigation()?.extras.state?.room as Room;

    if (!roomId) {
      this.isCreationMode = true;
      this.setRoom(new Room());

      return;
    }

    if (room) {
      this.setRoom(room);

      return;
    }

    this.fetchRoomSubscription?.unsubscribe();
    this.fetchRoomSubscription = this.roomService.getRoomById$(roomId)
      .subscribe((r: Room) => {
        this.fetchRoomSubscription = null;
        this.setRoom(r);
      });
  }

  goBack(): void {
    this.location.back();
  }

  getTitleKey(): string {
    if (this.isLoading) {
      return 'common.loading';
    }

    return !this.selectedRoom?.id ? 'admin.pages.slotsOverview.roomsPage.settings' : 'admin.pages.slotsOverview.roomsPage.settings';
  }
}
