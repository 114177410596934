import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { User } from '@models/user.model';
import { PROFILE_FORM_KEYS } from '@constants/form-keys/profile-form-keys.constant';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';

@Injectable({
  providedIn: 'root',
})
export class UserFormService extends AbstractFormService {
  createFormGroup(user?: User): UntypedFormGroup {
    return this.formBuilder.group({
      [PROFILE_FORM_KEYS.get('firstname')]: [
        user?.firstName ?? null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('lastname')]: [
        user?.lastName ?? null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('title')]: [
        user?.title ?? null,
        null,
      ],
      [PROFILE_FORM_KEYS.get('birthdate')]: [
        user?.birthdate ? format(user.birthdate, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('socialSecurityNumber')]: [
        user?.socialSecurityNumber ?? null,
        null,
      ],
      [PROFILE_FORM_KEYS.get('gender')]: [
        user?.gender ?? null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('phone')]: [
        user?.phone ?? null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('canPhoneBeUsedToContact')]: [
        user?.canPhoneBeUsedToContact ?? false,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('email')]: [
        user?.email ?? null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('language')]: [
        user?.language ?? null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('timezone')]: [
        user?.timezone ?? null,
        [Validators.required],
      ],
    });
  }

  createEmailOnlyFormGroup(user?: User): UntypedFormGroup {
    return this.formBuilder.group({
      [PROFILE_FORM_KEYS.get('email')]: [
        user?.email ?? null,
        [Validators.required],
      ],
    });
  }

  createOnboardingFinishingUpFormGroup(user?: User): UntypedFormGroup {
    return this.formBuilder.group({
      [PROFILE_FORM_KEYS.get('birthdate')]: [
        user?.birthdate ? format(user.birthdate, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [PROFILE_FORM_KEYS.get('socialSecurityNumber')]: [
        user?.socialSecurityNumber ?? null,
        null,
      ],
    });
  }
}
