import { Component, OnInit } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { ConversationNavigationService } from '@modules/conversation/services/conversation-flow-navigation.service';
import { ConversationService } from '@services/conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Conversation } from '@models/conversation.model';
import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { addDays, startOfDay } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { ConversationDisplay } from '@modules/conversation/enums/conversation-display.enum';
import { debounce } from '@utils/decorators/debounce.decorator';
import { ButtonGroupStyle, ButtonStyle } from '@enums/button-style.enum';
import { DoctorService } from '@services/doctor.service';
import { AuthenticationService } from '@services/authentication.service';
import { Option } from '@interfaces/option.interface';
import { Doctor } from '@models/doctor.model';
import { ConversationStatus, getConversationStatusOptions } from '@enums/conversation-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConversationQuestionListService } from '@services/conversation-question-list.service';
import { ConversationQuestionList } from '@models/conversation-question-list.model';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { ConversationFilter } from '@modules/conversation/pages/conversations-overview-page/conversation-filter.model';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';
import { LanguageService } from '@services/language.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversations-overview-page',
  templateUrl: './conversations-overview-page.component.html',
  styleUrls: ['./conversations-overview-page.component.scss'],
})
@UntilDestroy()
export class ConversationsOverviewPageComponent implements OnInit {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly ConversationDisplay: typeof ConversationDisplay = ConversationDisplay;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ButtonGroupStyle: typeof ButtonGroupStyle = ButtonGroupStyle;

  protected theme: Theme;

  filterBarIsOpened: boolean = false;

  selectedDate: Date = startOfDay(new Date());

  navigationItems: NavigationItem[];
  conversations: Conversation[] = [];
  filter: ConversationFilter = new ConversationFilter();

  toggleModeButtonOptions: ButtonOption[] = [];

  doctors: Option[] = [];
  statuses: Option[] = [];
  includeDeleted: Option[] = [];
  questionLists: Option[] = [];

  loading: boolean = true;
  private getConversationsSubscription: Subscription | null = null;

  currentUserTokens: number = 0;

  constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly navigationService: ConversationNavigationService,
    protected readonly conversationService: ConversationService,
    protected readonly doctorService: DoctorService,
    protected readonly translateService: TranslateService,
    protected readonly conversationQuestionListService: ConversationQuestionListService,
    protected readonly localStorageService: LocalStorageCacheService,
    protected readonly walletService: WalletService,
    private readonly languageService: LanguageService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
    Object.assign(this.filter, localStorageService.get(LOCAL_STORAGE_KEYS.conversationFilter));
    this.navigationItems = getNavigationItems();
    this.filter.areDateFiltersEnabled = false;
  }

  ngOnInit(): void {
    this.getTokens();

    this.loadAllConversations(true);
    this.statuses = getConversationStatusOptions(this.translateService);

    this.loadTranslatedLabels();
    this.listenForLanguageChange();

    this.doctorService.getDoctorsOfInstitution$(this.authenticationService.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((doctors: Doctor[]) => {
        doctors.sort((a: Doctor, b: Doctor) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
        doctors.map((doctor: Doctor) => this.doctors.push({ value: doctor.id, label: doctor.lastName + ' ' + doctor.firstName }));
      });

    this.conversationQuestionListService.getConversationQuestionListsForInstitution$(this.authenticationService.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((questionLists: ConversationQuestionList[]) => {
        this.questionLists = questionLists.map((questionList: ConversationQuestionList) => (
          {
            value: questionList.id,
            label: questionList.title,
          }
        ));
      });

    if (this.filter.displayMode === ConversationDisplay.BY_DAY) {
      this.filter.areDateFiltersEnabled = true;
      this.setSelectedDate(this.filter.from);
    }
  }

  onScroll(): void {
    if (!this.loading) {
      this.loading = true;
      this.loadMore();
    }
  }

  goToDetails(conversation: Conversation, event: Event): void {
    const targetElement = event.target as HTMLElement;

    const openMenuElement = targetElement.closest('.open-menu');

    if (!openMenuElement && conversation?.id) {
      this.navigationService.navigateToDetails(conversation.id);
    }
  }

  private getTokens(): void {
    // We reload the user for good measure as it contains the wallet balance
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.currentUserTokens = wallet?.tokens;
      });
  }

  private loadAllConversations(init: boolean = false): void {
    // Calculate amount of items needed to make infinite scroll work
    // Not subtracting header to have a buffer
    const minAmountOfItems = window.innerHeight / (this.filter.displayMode === ConversationDisplay.BY_DAY ? 70 : 40);
    this.loading = true;

    this.localStorageService.set(LOCAL_STORAGE_KEYS.conversationFilter, this.filter);

    if (init) {
      this.filter.currentPage = 1;
    }

    this.getConversationsSubscription?.unsubscribe();
    this.getConversationsSubscription = this.conversationService.getAllConversations(this.filter)
      .pipe(untilDestroyed(this))
      .subscribe((result: PaginatedModel<Conversation>): void => {
        this.filter.currentPage = result.meta.current_page;
        this.conversations = this.filter.currentPage > 1
          ? this.conversations.concat(result.data)
          : result.data;

        this.loading = false;

        if (result.data.length === this.filter.pageSize && this.conversations.length < minAmountOfItems) {
          this.loadMore();
        }
      });
  }

  loadMore(): void {
    this.filter.currentPage++;
    this.loadAllConversations();
  }

  addConversation(): void {
    this.navigationService.navigateToCreation();
  }

  updateSelectedDate(amountToAddOrSubtract?: number): void {
    if (amountToAddOrSubtract === 0) {
      this.selectedDate = startOfDay(new Date());
    }

    this.setSelectedDate(addDays(this.selectedDate, amountToAddOrSubtract));
  }

  setSelectedDate(date: Date | null): void {
    date = new Date(date);
    this.selectedDate = date;

    this.filter.from = date ? date : null;
    this.filter.to = date ? date : null;
    this.reset();
  }

  toggleMode(option: number): void {
    this.filter.displayMode = option === 0 ? ConversationDisplay.BY_DAY : ConversationDisplay.AS_LIST;
    this.setSelectedDate(this.filter.displayMode === ConversationDisplay.BY_DAY ? startOfDay(new Date()) : null);

    this.filter.areDateFiltersEnabled = this.filter.displayMode === ConversationDisplay.BY_DAY;
    this.reset();
  }

  @debounce(500)
  onSearchChange(value: string): void {
    this.filter.patient = value;

    this.reset();
  }

  onDoctorSelected(value: string): void {
    this.filter.doctorId = value === 'all' ? undefined : value;
    this.reset();
  }

  onIncludeDeletedSelected(value: string): void {
    this.filter.includeDeleted = value === 'yes';
    this.reset();
  }

  onStatusSelected(value: string): void {
    this.filter.status = value === 'all' ? undefined : value as ConversationStatus;
    this.reset();
  }

  onQuestionListSelected(value: string): void {
    this.filter.questionList = value === 'all' ? undefined : value;
    this.reset();
  }

  resetFilters(): void {
    const currentSelectedDate = this.filter.displayMode === ConversationDisplay.BY_DAY ? this.filter.from : null;
    this.filter = new ConversationFilter();

    if (currentSelectedDate) {
      this.setSelectedDate(currentSelectedDate);
    } else {
      this.filter.from = undefined;
      this.filter.to = undefined;
    }

    this.localStorageService.set(LOCAL_STORAGE_KEYS.conversationFilter, this.filter);
    this.reset();
  }

  reset(): void {
    this.conversations = [];
    this.loading = true;
    this.filter.currentPage = 1;
    this.loadAllConversations();
  }

  private loadTranslatedLabels(): void {
    this.toggleModeButtonOptions = [];
    this.includeDeleted = [];

    this.translateService.get('conversations.displayModes.byDay')
      .pipe(untilDestroyed(this))
      .subscribe((translation: string) => {
        // Check if the option already exists
        if (!this.toggleModeButtonOptions.some((option: ButtonOption) => option.label === translation)) {
          this.toggleModeButtonOptions.push({
            label: translation,
            isActive: this.filter.displayMode === ConversationDisplay.BY_DAY,
            isClickable: true,
          });
        }
      });

    this.translateService.get('conversations.displayModes.asList')
      .pipe(untilDestroyed(this))
      .subscribe((translation: string) => {
        // Check if the option already exists
        if (!this.toggleModeButtonOptions.some((option: ButtonOption) => option.label === translation)) {
          this.toggleModeButtonOptions.push({
            label: translation,
            isActive: this.filter.displayMode === ConversationDisplay.AS_LIST,
            isClickable: true,
          });
        }
      });

    this.translateService.get('conversations.filters.includeDeletedNo')
      .pipe(untilDestroyed(this))
      .subscribe((translation: string) => {
        this.includeDeleted.push({ value: 'no', label: translation });
      });

    this.translateService.get('conversations.filters.includeDeletedYes')
      .pipe(untilDestroyed(this))
      .subscribe((translation: string) => {
        this.includeDeleted.push({ value: 'yes', label: translation });
      });
  }

  private listenForLanguageChange(): void {
    // This will reload the translations when the language is changed
    this.languageService.languageSetSuccessfully$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadTranslatedLabels();
      });
  }
}
