import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { recurrenceFrequencyOptionsPlural$, recurrenceFrequencyOptionsSingular$ } from '@enums/recurrence-frequency.enum';
import { Room } from '@models/room.model';
import { Occurrence } from '@models/occurrence.model';
import { RecurrenceService } from '@services/recurrence.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { ServiceGroup } from '@models/service-group.model';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { addDays, format, isEqual } from 'date-fns';
import { ServiceGroupService } from '@services/service-group.service';
import { LanguageService } from '@services/language.service';
import { ImpactedPatientsDialogComponent, ImpactedPatientsDialogResult } from '@modules/settings/dialogs/impacted-patients-dialog/impacted-patients-dialog.component';
import { Visit } from '@models/visit.model';
import { PopoverService } from '@services/ui/popover.service';
import { IObject } from '@app-types/iobject.type';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { PlanningService } from '@services/planning.service';
import { Role } from '@enums/role.enum';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@models/user.model';
import { RecurrenceException } from '@models/recurrence-exception.model';
import { CalendarEventMapperService } from '@services/calendar-event-mapper.service';
import { DoctorService } from '@services/doctor.service';
import { Doctor } from '@models/doctor.model';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-planning-calendar',
  templateUrl: './planning-calendar.component.html',
  styleUrls: ['./planning-calendar.component.scss'],
})
@UntilDestroy()
export class PlanningCalendarComponent implements OnInit, OnChanges {
  protected readonly Role: typeof Role = Role;
  protected readonly POPOVER_ID: string = 'popup';
  private readonly NEW_EVENT_ID: string = 'new-event';

  @Input() room: Room;
  @Input() weekStartDate: Date;
  @Input() isDisabled: boolean;
  @Input() headerTemplate: TemplateRef<unknown>;

  @Output() navigateToRoomSettingsClick: EventEmitter<void> = new EventEmitter<void>();

  private apiSubscriptions: Subscription[] = [];
  private impactedPatientsDialogRef: MatDialogRef<ImpactedPatientsDialogComponent>;

  protected theme: Theme;

  events: CalendarEvent[] = [];
  exceptions: RecurrenceException[];
  locale: string;
  shouldShowNoServiceGroupConfiguredError: boolean;

  recurrenceOptionsSingular: Option[];
  recurrenceOptionsPlural: Option[];
  serviceGroupOptions: Option[] = [];
  doctorOptions: Option[] = [];

  slotEditorMode: 'read' | 'create' | 'update' = 'read';
  popoverPreferredPositioning: PopoverPosition;

  selectedOccurrence: Occurrence | null = null;
  originalOccurrence: Occurrence | null = null;

  errorMessageTranslationKey: string;

  user: User;

  constructor(
    private readonly planningService: PlanningService,
    private readonly serviceGroupService: ServiceGroupService,
    private readonly recurrenceService: RecurrenceService,
    private readonly translate: TranslateService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly popoverService: PopoverService,
    private readonly dialogService: MatDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly calendarEventMapperService: CalendarEventMapperService,
    private readonly doctorService: DoctorService,
    private readonly themeService: ThemeService,
    languageService: LanguageService
  ) {
    this.locale = languageService.getPreferredLanguage();
    this.user = this.authenticationService.currentUser;
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    combineLatest([
      recurrenceFrequencyOptionsSingular$(this.translate),
      recurrenceFrequencyOptionsPlural$(this.translate),
    ]).subscribe(([singularOptions, pluralOptions]: [Option[], Option[]]) => {
      this.recurrenceOptionsSingular = singularOptions;
      this.recurrenceOptionsPlural = pluralOptions;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.room?.currentValue) {
      this.closePopup();
      this.cancelPendingApiSubscriptions();
      this.loadSlots(this.weekStartDate);
      this.apiSubscriptions.push(
        this.serviceGroupService.getServiceGroupsForRoom$(changes.room.currentValue.id)
          .subscribe((data: ServiceGroup[]) => {
            this.room.serviceGroups = data;
            this.serviceGroupOptions = this.room.serviceGroups.map((service: ServiceGroup): Option => service.toOption());
            this.shouldShowNoServiceGroupConfiguredError = this.room.serviceGroups?.length === 0;
          }),
        this.doctorService.getDoctorsOfInstitution$(this.authenticationService.institution.id).subscribe((doctors: Doctor[]) => {
          this.doctorOptions = doctors.map((doctor: Doctor): Option => doctor.toOption());
          this.doctorOptions.push({ value: null, label: this.translate.instant('common.selectNone') });
        }));
    }

    if (!changes.weekStartDate?.isFirstChange()) {
      this.loadSlots(this.weekStartDate);
    }
  }

  onHourSegmentClicked(date: Date): void {
    if (isEqual(this.selectedOccurrence?.start, date)) {
      this.openPopup(this.determinePopupPositionByClickedDate(date));

      return;
    }

    this.closePopup();
    this.addNewEvent(date);
  }

  onEventClicked(event: CalendarEvent): void {
    if (this.selectedOccurrence?.equalsEvent(event) || this.exceptions?.find((exception: RecurrenceException) => exception.equalsEvent(event))) {
      return;
    }

    if (this.selectedOccurrence) {
      this.discardPendingChanges();
    }

    this.slotEditorMode = 'update';
    this.selectedOccurrence = this.room.occurrences.find((s: Occurrence) => s.equalsEvent(event));
    this.openPopup(this.determinePopupPositionByClickedDate(event.start));
  }

  onSaveClick(updatedRecurrence: IObject): void {
    if (this.slotEditorMode === 'update') {
      this.recurrenceService.updateRecurrence$(this.selectedOccurrence.recurrenceId, updatedRecurrence)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.closePopup();
          this.loadSlots(this.weekStartDate);
        });

      return;
    }

    this.recurrenceService.createRecurrence$(updatedRecurrence)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.closePopup();
        this.loadSlots(this.weekStartDate);
      });
  }

  onDeleteClicked(occurrence: Occurrence): void {
    if (!occurrence?.recurrenceId) {
      this.closePopup();

      return;
    }

    this.recurrenceService.getImpactedVisitsBeforeDeletion$(occurrence.recurrenceId)
      .pipe(untilDestroyed(this))
      .subscribe((visits: Visit[]) => {
        this.changeDetector.detectChanges();
        this.openImpactedPatientsDialog(visits);
      });
  }

  onDeleteConfirmClicked(cancelMetadata: IObject): void {
    if (!this.selectedOccurrence?.recurrenceId) {
      this.closePopup();

      return;
    }

    this.recurrenceService.deleteRecurrence$(this.selectedOccurrence.recurrenceId, cancelMetadata)
      .subscribe(() => {
        this.selectedOccurrence = null;
        this.originalOccurrence = null;
        this.closePopup();
        this.loadSlots(this.weekStartDate);
        this.dialogService.closeAll();
      });
  }

  onEventDragged(event: CalendarEventTimesChangedEvent): void {
    // TODO: this is currently disabled and needs a dialog confirmation asking the user if he wants to move the whole series or just this occurrence
    // const index = this.room.occurrences.findIndex((s: Occurrence) => s.equalsEvent(event.event));
    //
    // if (index < 0) {
    //   return;
    // }
    //
    // this.selectedOccurrence = this.room.occurrences[index];
    //
    // this.room.occurrences[index].start = event.newStart;
    // this.room.occurrences[index].end = event.newEnd;
    // this.recreateEvents();
    //
    // this.recurrenceService.getRecurrence$(this.room.occurrences[index].recurrenceId)
    //   .pipe(untilDestroyed(this))
    //   .subscribe((recurrence: Recurrence) => {
    //     const formGroup = this.recurrenceFormHelperService.createFormGroup(recurrence);
    //
    //     this.onSaveClick(
    //       this.recurrenceFormHelperService.formGroupToRequest(
    //         formGroup,
    //         this.room.occurrences[index].start,
    //         this.room.occurrences[index].end,
    //         this.room.id
    //       )
    //     );
    //   });
  }

  onOccurrenceChanged(occurrence: Occurrence): void {
    const index = this.events.findIndex((event: CalendarEvent) => event.id === this.NEW_EVENT_ID);

    if (index < 0) {
      return;
    }

    this.events[index].start = occurrence.start;
    this.events[index].end = occurrence.end;
    this.events[index].title = occurrence.serviceGroup?.name;
    this.events[index].color.primary = occurrence.serviceGroup?.color;
    this.events[index].color.secondary = occurrence.serviceGroup?.color;

    this.events = [...this.events];
  }

  onNavigateToRoomSettingsClick(): void {
    this.navigateToRoomSettingsClick.emit();
  }

  openPopup(preferredPosition: PopoverPosition = 'right'): void {
    this.popoverPreferredPositioning = preferredPosition;
    this.popoverService.open(this.POPOVER_ID);
  }

  closePopup(discardPendingChanges: boolean = false): void {
    // If the impacted patients dialog is open, we don't want to dismiss the pending changes
    if (discardPendingChanges && this.impactedPatientsDialogRef) {
      return;
    }

    if (discardPendingChanges) {
      this.discardPendingChanges();
    }

    this.popoverService.close(this.POPOVER_ID);
    this.slotEditorMode = 'read';
  }

  isFetchingData(): boolean {
    return this.apiSubscriptions.some((sub: Subscription) => !sub.closed);
  }

  private openImpactedPatientsDialog(impactedVisits: Visit[]): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      visits: impactedVisits,
      panelClass: 'dialog-size-medium',
    };

    this.impactedPatientsDialogRef = this.dialogService.open(ImpactedPatientsDialogComponent, dialogConfig);
    this.impactedPatientsDialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: ImpactedPatientsDialogResult): void => {
        this.impactedPatientsDialogRef = null;

        if (result.status !== 'ok') {
          return;
        }

        this.onDeleteConfirmClicked(result.formData);
      });
  }

  private determinePopupPositionByClickedDate(date: Date): PopoverPosition {
    return date.getDay() > 3 || date.getDay() === 0 ? 'right' : 'left';
  }

  private addNewEvent(date: Date): void {
    this.discardPendingChanges();
    this.slotEditorMode = 'create';

    const serviceGroup: ServiceGroup = this.room.serviceGroups[0];
    this.selectedOccurrence = Occurrence.createDummy(date, serviceGroup);

    this.events = [
      ...this.events,
      {
        ...this.calendarEventMapperService.occurrenceToCalendarEvent(this.selectedOccurrence),
        id: this.NEW_EVENT_ID,
        title: serviceGroup.name,
        cssClass: 'anim-pulsating',
        color: {
          primary: serviceGroup.color,
          secondary: serviceGroup.color,
          secondaryText: '#ffffff',
        },
      },
    ];
    this.openPopup(this.determinePopupPositionByClickedDate(date));
  }

  private discardPendingChanges(): void {
    if (this.slotEditorMode === 'create') {
      this.events = this.events.filter((event: CalendarEvent) => event.id !== this.NEW_EVENT_ID);
    } else if (this.slotEditorMode === 'update') {
      this.onOccurrenceChanged(this.originalOccurrence);
    }

    this.selectedOccurrence = null;
    this.originalOccurrence = null;
  }

  private loadSlots(currentDate: Date): void {
    if (!this.room) {
      return;
    }

    this.apiSubscriptions.push(
      this.planningService
        .getPlanningOfRoom$(
          this.room.id,
          format(currentDate, DATE_FORMATS.serverDate),
          format(addDays(currentDate, 6), DATE_FORMATS.serverDate)
        )
        .subscribe((occurrences: Occurrence[]) => {
          this.room.occurrences = occurrences;
          this.recreateEvents();
        }), this.planningService.getPlanningExceptionsOfRoom$(
        this.room.id,
        format(currentDate, DATE_FORMATS.serverDate),
        format(addDays(currentDate, 6), DATE_FORMATS.serverDate))
        .subscribe((exceptions: RecurrenceException[]) => {
          this.exceptions = exceptions;
          this.recreateEvents();
        })
    );
  }

  private cancelPendingApiSubscriptions(): void {
    this.apiSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.apiSubscriptions = [];
  }

  private recreateEvents(): void {
    this.selectedOccurrence = null;
    this.originalOccurrence = null;
    this.events = this.calendarEventMapperService.occurrencesToCalendarEvents(this.room.occurrences);

    if (this.exceptions?.length > 0) {
      this.events.push(...this.calendarEventMapperService.recurrenceExceptionsToCalendarEvents(this.exceptions));
    }
  }
}
