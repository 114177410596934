import { MASKS } from '@constants/masks.constant';
import { LINK_RIZIV_FORM_KEYS } from '@constants/form-keys/link-riziv-form-keys.constant';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { User } from '@models/user.model';
import { UntypedFormGroup } from '@angular/forms';
import { LinkRizivFormService } from '@services/form-services/link-riziv-form.service';
import { DoctorService } from '@services/doctor.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface LinkRizivDialogData {
  user: User;
}
@Component({
  selector: 'vh-link-riziv-dialog',
  templateUrl: './link-riziv-dialog.component.html',
  styleUrl: './link-riziv-dialog.component.scss',
})
@UntilDestroy()
export class LinkRizivDialogComponent implements OnInit {
  protected readonly LINK_RIZIV_FORM_KEYS: typeof LINK_RIZIV_FORM_KEYS = LINK_RIZIV_FORM_KEYS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly MASKS: typeof MASKS = MASKS;
  linkSuccess: boolean = false;
  errorMessage: string | null = null;
  user: User;
  formGroup: UntypedFormGroup;

  constructor(
    private readonly dialog: MatDialogRef<LinkRizivDialogComponent>,
    private readonly linkRizivFormService: LinkRizivFormService,
    private readonly doctorService: DoctorService,
    @Inject(MAT_DIALOG_DATA) data: LinkRizivDialogData
  ) {
    this.user = data.user;
  }

  ngOnInit(): void {
    this.formGroup = this.linkRizivFormService.createFormGroup();
  }

  close(): void {
    this.dialog.close(this.linkSuccess);
  }

  onLinkClicked(): void {
    if (this.formGroup.get(LINK_RIZIV_FORM_KEYS.get('linkRizivNumber')).valid) {
      const riziv = this.formGroup.get(LINK_RIZIV_FORM_KEYS.get('linkRizivNumber')).value;
      this.linkRizivNumber(riziv);
    }
  }

  linkRizivNumber(rizivNumber: string): void {
    this.doctorService.linkDoctorToUser$(rizivNumber, this.user).subscribe({
      next: () => {
        this.linkSuccess = true;
        this.close();
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.doctorService.createDoctorFromRiziv$(rizivNumber, this.user).subscribe(() => {
            this.doctorService.linkDoctorToUser$(rizivNumber, this.user).subscribe(() => {
              this.linkSuccess = true;
              this.close();
            });
          });
        }
        if (error.status === 422 && error.error.data?.riziv_number[0] === 'doctor_already_linked') {
          this.errorMessage = 'admin.pages.dashboard.linkRiziv.errorMessage.title';
        }
      },
    });
  }

  resetFormGroup(): void {
    if (!this.formGroup) {
      this.formGroup = this.linkRizivFormService.createFormGroup();
    }
    this.formGroup.reset();
    this.formGroup.markAsPristine();
  }
}
