<label *ngIf="label" [class.required]="isRequired || showRequired">
  <span [innerHTML]="label"></span>
</label>
<div class="flex-col">
  <div
    class="flex-col input-wrapper"
    [ngClass]="{
      default: style === TextInputStyle.DEFAULT,
      material: style === TextInputStyle.MATERIAL
    }">
    <vh-icon
      class="inline-icon"
      [ngClass]="iconLeftClass"
      *ngIf="iconLeftPath"
      [src]="iconLeftPath"
      [fill]="iconLeftFill ? iconLeftFill : theme.colorScheme().secondaryGrey"
      draggable="false"
      (click)="inputElement.focus()"></vh-icon>
    <input
      #inputElement
      [ngClass]="{
        'has-text-tertiary' : mask && !value,
        'error': hasError,
        'input-icon-padding-right': type === 'date' && supportsCustomDatepickerIcon(),
        'input-icon-padding-left': iconLeftPath,
      }"
      [ngStyle]="{
        'padding-right': iconRightPath ? 'var(--24px)' : 'unset',
      }"
      [mask]="mask"
      [showMaskTyped]="showMaskTyped"
      [dropSpecialCharacters]="maskDropSpecialCharacters"
      [prefix]="maskPrefix ? maskPrefix : ''"
      [suffix]="maskSuffix ? maskSuffix : ''"
      [placeHolderCharacter]="maskPlaceholderCharacter ? maskPlaceholderCharacter : '_'"
      [type]="type"
      [placeholder]="placeholder || ''"
      [disabled]="isDisabled || formControl?.disabled"
      [readonly]="isReadonly"
      [ngModel]="value"
      [pattern]="pattern"
      [attr.max]="max"
      [attr.min]="min"
      [attr.autocomplete]="autocomplete"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="handleValueChangeWithCheck($event)"
      (blur)="handleValueChangeOnBlurWithCheck()"
      (click)="inputClick.emit($event)" />
    <vh-icon
      class="inline-icon inline-icon-right"
      [ngClass]="iconRightClass"
      *ngIf="iconRightPath"
      [src]="iconRightPath"
      [fill]="iconRightFill ? iconRightFill : theme.colorScheme().secondaryGrey"
      draggable="false"
      (click)="inputElement.focus()">
    </vh-icon>
    <vh-icon
      class="inline-icon inline-icon-right clickable"
      [ngClass]="iconRightClass"
      *ngIf="iconRightPath && iconRightClickable"
      [src]="iconRightPath"
      draggable="false"
      (click)="deleteFromFormArray(formArray, index)">
    </vh-icon>
    <vh-icon
      *ngIf="(type === 'date' || type === 'datetime-local') && supportsCustomDatepickerIcon()"
      class="inline-icon inline-icon-right clickable"
      [ngClass]="iconRightClass"
      src="assets/icons/calendar-alt.svg"
      (click)="onCalendarIconClicked()">
    </vh-icon>
  </div>
  <p
    *ngIf="message"
    [vhMarginTop]="8"
    class="has-text-tertiary font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }"
    [innerHTML]="message">
  </p>
  <p
    *ngIf="errorMessage"
    [vhMarginTop]="8"
    class="has-text-error font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }"
    [innerHTML]="errorMessage">
  </p>
</div>
