<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
<ng-container [ngTemplateOutlet]="eventsCalendarTemplate"></ng-container>

<ng-template #eventsCalendarTemplate>
  <div vhCtrlZoom class="calendar-container flex-col" [vhMarginTop]="16"
    [ngClass]="{ 'not-clickable not-selectable disabled': isDisabled }" (zoomIn)="updateZoomLevel(zoomStepSize)"
    (zoomOut)="updateZoomLevel(-zoomStepSize)">
    <mwl-calendar-day-view
      *ngIf="calendarMode === 'day'"
      [viewDate]="selectedDate"
      [locale]="locale"
      [events]="events"
      [hourSegments]="currentCalendarZoomPreset.segments"
      [hourDuration]="currentCalendarZoomPreset.duration"
      [validateEventTimesChanged]="canReschedule"
      [eventTemplate]="eventTimeChangeConfirmationPopoverTemplate ? eventTemplate : null"
      [eventActionsTemplate]="eventActionsTemplate ?? null"
      [tooltipTemplate]="eventTooltipTemplate ?? null"
      [hourSegmentTemplate]="hourSegmentTemplate"
      (hourSegmentClicked)="onHourSegmentClicked($event.date)"
      (eventClicked)="onEventClicked($event)"
      (eventTimesChanged)="onEventTimesChanged($event)">
    </mwl-calendar-day-view>
    <mwl-calendar-week-view
      *ngIf="calendarMode === 'week'"
      [weekStartsOn]="weekStartsOn"
      [viewDate]="selectedDate"
      [locale]="locale"
      [events]="events"
      [hourSegments]="currentCalendarZoomPreset.segments"
      [hourDuration]="currentCalendarZoomPreset.duration"
      [headerTemplate]="weekHeaderTemplate"
      [eventTemplate]="eventTimeChangeConfirmationPopoverTemplate ? eventTemplate : null"
      [eventActionsTemplate]="eventActionsTemplate ?? null"
      [validateEventTimesChanged]="canReschedule"
      [tooltipTemplate]="eventTooltipTemplate ?? null"
      [hourSegmentTemplate]="hourSegmentTemplate"
      (dayHeaderClicked)="onDayHeaderClicked($event.day.date)"
      (hourSegmentClicked)="onHourSegmentClicked($event.date)"
      (eventTimesChanged)="onEventTimesChanged($event)"
      (eventClicked)="onEventClicked($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-month-view
      *ngIf="calendarMode === 'month'"
      [weekStartsOn]="weekStartsOn"
      [viewDate]="selectedDate"
      [locale]="locale"
      [events]="events"
      [cellTemplate]="monthCellTemplate"
      (dayClicked)="onMonthViewDayClicked($event.day.date)"
      (eventClicked)="onEventClicked($event)"
      (eventTimesChanged)="onEventTimesChanged($event)">
    </mwl-calendar-month-view>
  </div>
</ng-template>

<ng-template
  #weekHeaderTemplate
  let-days="days"
  let-locale="locale"
  let-dayHeaderClicked="dayHeaderClicked"
  let-eventDropped="eventDropped">
  <div class="cal-day-headers">
    <vh-icon
      *ngIf="isLoading"
      class="calendar-loading-indicator anim-rotating"
      src="assets/icons/sync-secondary.svg"
      width="30"
      [fill]="theme.colorScheme().black">
    </vh-icon>
    <div
      *ngFor="let day of days"
      class="cal-header flex-col-c-c"
      [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture"
      [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass"
      (mwlClick)="dayHeaderClicked.emit({ day: day })"
      mwlDroppable
      dragOverClass="cal-drag-over"
      (drop)="eventDropped.emit({ event: $event.dropData.event, newStart: day.date })">
      <b>{{ day.date | calendarDate : 'weekViewColumnHeader' : locale }}</b>
      <span>{{ day.date | calendarDate : 'weekViewColumnSubHeader' : locale }}</span>
      <div *ngIf="showEventCounterBadges" [vhMarginTop]="4" class="cal-day-badge has-background-primary has-text-white">
        {{ getEventsCountForDay(day.date) }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #eventTemplate
  let-weekEvent="weekEvent"
  let-tooltipPlacement="tooltipPlacement"
  let-locale="locale"
  let-column="column"
  let-daysInWeek="daysInWeek"
  let-tooltipDisabled="tooltipDisabled"
  let-tooltipDelay="tooltipDelay">
  <mwl-calendar-week-view-event
    [vhPopoverHost]="weekEvent.event.meta?.popoverId"
    [popoverTemplate]="eventTimeChangeConfirmationPopoverTemplate"
    popoverScrollStrategy="noop"
    [weekEvent]="weekEvent"
    [tooltipPlacement]="tooltipPlacement"
    [locale]="locale"
    [column]="column"
    [daysInWeek]="daysInWeek"
    [tooltipDelay]="tooltipDelay"
    [tooltipDisabled]="tooltipDisabled"
    [tooltipTemplate]="eventTooltipTemplate ?? null"
    [eventActionsTemplate]="eventActionsTemplate ?? null"
    (eventClicked)="onEventClicked({ event: weekEvent.event, sourceEvent: $event.sourceEvent })">
  </mwl-calendar-week-view-event>
</ng-template>

<ng-template
  #hourSegmentTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel"
  let-daysInWeek="daysInWeek">
  <div
    [attr.aria-hidden]="{} | calendarA11y : (daysInWeek === 1 ? 'hideDayHourSegment' : 'hideWeekHourSegment')"
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass">
    <div class="cal-time flex-row-r-c" *ngIf="isTimeLabel">
      <b *ngIf="segment.displayDate.getMinutes() === 0">{{
        segment.displayDate | calendarDate : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour') : locale
        }}</b>
      <span *ngIf="segment.displayDate.getMinutes() !== 0">{{
        segment.displayDate | calendarDate : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour') : locale
        }}</span>
    </div>
    <div *ngIf="calendarMode === 'day' || !isTimeLabel" class="cal-event-available flex-row">
      <ng-container *ngFor="let opening of openings">
        <div
          class="cal-opening"
          [class.cal-opening-day]="calendarMode === 'day'"
          *ngIf="isDateTimeWithinOpeningInterval(opening, segment.date)"
          [style.background-color]="opening.color"></div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #monthCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top" [attr.aria-label]="{ day: day, locale: locale } | calendarA11y : 'monthCell'">
    <span aria-hidden="true">
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
      <span class="cal-day-number">{{ day.date | calendarDate : 'monthViewDayNumber' : locale }}</span>
    </span>
  </div>
  <div class="cal-event-available flex-row">
    <div *ngFor="let opening of getOpeningsForDay(day.date)" class="cal-opening" [style.background-color]="opening.color">
    </div>
  </div>
</ng-template>
