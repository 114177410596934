import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@services/ui/alert-dialog.service';
import { ButtonStyle } from '@enums/button-style.enum';

@Component({
  selector: 'vh-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, protected dialogRef: MatDialogRef<AlertDialogComponent>) {}

  cancel(): void {
    this.close(false);
  }

  close(value: boolean): void {
    this.dialogRef.close(value);
  }

  confirm(): void {
    this.close(true);
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    this.close(false);
  }
}
