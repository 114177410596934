import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonStyle } from '@enums/button-style.enum';
import { VisitReschedulingImpact } from '@models/visit-rescheduling-impact.model';
import { Visit } from '@models/visit.model';
import { isValid } from 'date-fns';
import { AuthenticationService } from '@services/authentication.service';
import { getInstitutionManageRoles } from '@enums/role.enum';
import { Subscription, switchMap } from 'rxjs';
import { VisitReschedulingService } from '@services/visits/visit.rescheduling.service';
import { VisitService } from '@services/visits/visit.service';
import { BookingStatus } from '@enums/booking-status.enum';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-visit-rescheduling-impact-result',
  templateUrl: './visit-rescheduling-impact-result.component.html',
  styleUrls: ['./visit-rescheduling-impact-result.component.scss'],
})
@UntilDestroy()
export class VisitReschedulingImpactResultComponent implements OnChanges {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  protected theme: Theme;

  visitReschedulingImpactMessage: string | null = null;
  visitReschedulingImpact: VisitReschedulingImpact | null = null;

  visitReschedulingImpactCalculatingSubscription: Subscription | null = null;

  isSavingVisit: boolean = false;
  overRuleAttemptNonAdmin: boolean = false;
  overRuleAttemptMessage: string = 'admin.pages.dashboard.rescheduleConfirmation.overRuleAttemptNonAdmin';
  confirmDisabledMessage: string | null = null;

  @Input() visit: Visit;
  @Input() newStartDateTime: Date;
  @Input() newEndDateTime: Date;

  @Output() confirmClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() visitRescheduleSuccess: EventEmitter<Visit> = new EventEmitter<Visit>();
  @Output() visitRescheduleFailed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly visitReschedulingService: VisitReschedulingService,
    private readonly authenticationService: AuthenticationService,
    private readonly visitService: VisitService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visit?.currentValue || changes.newStartDateTime?.currentValue || changes.newEndDateTime?.currentValue) {
      this.calculateVisitReschedulingImpact();
    }
  }

  onConfirmClicked(): void {
    if (this.visitReschedulingImpact.hasImpact() && !this.authenticationService.currentUser.hasAnyRole(getInstitutionManageRoles())) {
      this.overRuleAttemptNonAdmin = true;

      return;
    }

    this.visitReschedulingImpact = null;
    this.visitReschedulingImpactMessage = null;
    this.confirmClicked.emit();
    this.rescheduleVisit();
  }

  protected isConfirmDisabled(): boolean {
    return this.confirmDisabledMessage !== null || this.isSavingVisit || this.visitReschedulingImpactCalculatingSubscription !== null;
  }

  private rescheduleVisit(): void {
    this.isSavingVisit = true;

    // First update the visit, then reschedule it
    this.visitService
      .updateVisit$(this.visit.id, { cancellation_action_id: null, booking_status: BookingStatus.CONFIRMED, deleted_at: null })
      .pipe(
        // If updateVisit$ is successful, chain the rescheduleVisit$ call
        switchMap(() =>
          this.visitReschedulingService.rescheduleVisit$(this.visit.id, this.newStartDateTime, this.newEndDateTime)
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (visit: Visit): void => {
          this.visitReschedulingImpact = null;
          this.visitReschedulingImpactMessage = null;
          this.visitRescheduleSuccess.emit(visit);
          this.isSavingVisit = false;
        },
        error: (): void => {
          this.isSavingVisit = false;
          this.visitReschedulingImpact = null;
          this.visitReschedulingImpactMessage = null;
          this.visitRescheduleFailed.emit();
        },
      });
  }

  private calculateVisitReschedulingImpact(): void {
    if (!isValid(this.newStartDateTime) || !isValid(this.newEndDateTime)) {
      return;
    }

    this.confirmDisabledMessage = null;
    this.visitReschedulingImpact = null;
    this.visitReschedulingImpactMessage = 'admin.pages.dashboard.rescheduleConfirmation.calculatingImpact';

    this.visitReschedulingImpactCalculatingSubscription?.unsubscribe();
    this.visitReschedulingImpactCalculatingSubscription = this.visitReschedulingService
      .getVisitReschedulingImpact$(this.visit.id, this.newStartDateTime, this.newEndDateTime)
      .pipe(untilDestroyed(this))
      .subscribe((result: VisitReschedulingImpact): void => {
        this.visitReschedulingImpactCalculatingSubscription = null;
        this.visitReschedulingImpact = result;
        this.visitReschedulingImpactMessage = result.message;
        this.confirmDisabledMessage = this.visitReschedulingService.getReasonWhyReschedulingIsNotPossible(result);
      });
  }
}
