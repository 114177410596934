<div class="flex-row gap-h-16 align-center">
  <vh-button
    *ngIf="showBackButton"
    [style]="ButtonStyle.TERTIARY"
    [iconLeftPath]="backIconPath"
    [iconLeftStroke]="backIconStroke"
    [isDisabled]="backDisabled"
    [label]="backLabel | translate"
    (buttonClick)="onBack()">
  </vh-button>
  <vh-button
    *ngIf="showForwardButton"
    [iconRightPath]="forwardIconPath"
    [iconRightStroke]="forwardIconStroke"
    [isDisabled]="forwardDisabled"
    [label]="forwardLabel | translate"
    (buttonClick)="onForward()">
  </vh-button>
</div>
