<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <vh-message
    class="font-regular-18-22"
    [ngClass]="{ clickable: !isLoading, 'not-clickable': isLoading }"
    [text]="getTitleKey() | translate"
    [iconLeftPath]="isLoading ? 'assets/icons/hourglass.svg' : 'assets/icons/arrow-left.svg'"
    [iconLeftFill]="theme.colorScheme().black"
    [iconLeftAlt]="getTitleKey() | translate"
    (messageClick)="navigateBack()">
  </vh-message>
  <vh-survey-settings [survey]="survey" [isLoading]="isLoading" class="gap-v-16 container flex-col"> </vh-survey-settings>
</vh-content-layout>
