import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Option } from '@interfaces/option.interface';
import { ConversationQuestionList } from '@models/conversation-question-list.model';
import { Doctor } from '@models/doctor.model';
import { User } from '@models/user.model';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { CreateConversationService } from '@modules/conversation/services/create-conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { ConversationQuestionListService } from '@services/conversation-question-list.service';
import { DoctorService } from '@services/doctor.service';
import { map, Observable, of } from 'rxjs';
import { format } from 'date-fns';
import { LanguageService } from '@services/language.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-create-conversation-planning-page',
  templateUrl: './create-conversation-planning-page.component.html',
  styleUrl: './create-conversation-planning-page.component.scss',
})
@UntilDestroy()
export class CreateConversationPlanningPageComponent implements OnInit {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  protected theme: Theme;

  user: User;
  doctors: Doctor[] = [];
  questionLists: ConversationQuestionList[] = [];
  selectedDoctor: string | null;
  selectedQuestionList: string | null;
  selectedDueDate: string | null;

  isLoadingDoctors: boolean = true;
  isLoadingQuestionLists: boolean = true;

  currentDate: string;

  constructor(
    protected readonly conversationService: CreateConversationService,
    private readonly router: Router,
    private readonly doctorService: DoctorService,
    private readonly conversationQuestionListService: ConversationQuestionListService,
    private readonly authenticationService: AuthenticationService,
    private readonly languageService: LanguageService,
    private readonly themeService: ThemeService
  ) {
    this.conversationService.setStep(2);
    this.theme = this.themeService.currentTheme;
    this.selectedDoctor = this.conversationService.doctor?.id;
    this.selectedQuestionList = this.conversationService.questionList?.id;
    this.selectedDueDate = this.conversationService.dueDate ? format(new Date(), 'yyyy-MM-dd HH:II') : null;

    this.currentDate = format(new Date(), 'yyyy-MM-dd HH:II');

    this.user = this.conversationService.user;
    this.conversationService.language = this.conversationService.language ?? this.user.language;
  }

  ngOnInit(): void {
    this.doctorService
      .getDoctorsOfInstitution$(this.authenticationService.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((doctors: Doctor[]) => {
        this.doctors = doctors;

        this.isLoadingDoctors = false;
      });

    this.conversationQuestionListService
      .getConversationQuestionListsForInstitution$(this.authenticationService.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((questionLists: ConversationQuestionList[]) => {
        this.questionLists = questionLists;

        this.isLoadingQuestionLists = false;
      });
  }

  backToSelectPatient(): void {
    void this.router.navigate([CONVERSATION_NAV.create.selectPatient.route]);
  }

  getDoctorSuggestions$ = (searchValue: string = ''): Observable<Option[]> => {
    return of(this.doctors).pipe(
      map((doctors: Doctor[]): Option[] => {
        // Filter doctors based on the search value
        const filteredDoctors = doctors.filter((doctor: Doctor) =>
          doctor.fullName.toLowerCase().includes(searchValue.toLowerCase())
        );

        // Transform the filtered doctors into an array of options
        return filteredDoctors.map((doctor: Doctor): Option => {
          return {
            label: doctor.fullName,
            value: doctor.id,
          };
        });
      })
    );
  };

  getQuestionListSuggestions$ = (searchValue: string = ''): Observable<Option[]> => {
    return of(this.questionLists).pipe(
      map((questionLists: ConversationQuestionList[]): Option[] => {
        // Filter question lists based on the search value
        const filteredQuestionLists = questionLists.filter((questionList: ConversationQuestionList) =>
          questionList.title.toLowerCase().includes(searchValue.toLowerCase())
        );

        // Transform the filtered question lists into an array of options
        return filteredQuestionLists.map((questionList: ConversationQuestionList): Option => {
          return {
            label: questionList.title,
            value: questionList.id,
          };
        });
      })
    );
  };

  getLanguageSuggestions$ = (searchValue: string = ''): Observable<Option[]> => {
    return this.languageService.getConversationLanguagesAsOptions$().pipe(
      map((languages: Option[]): Option[] => {
        return languages.filter((language: Option) => language.label.toLowerCase().includes(searchValue.toLowerCase()));
      })
    );
  };

  onDoctorChange(doctorId: string): void {
    if (!doctorId) {
      this.selectedDoctor = undefined;

      return;
    }

    this.selectedDoctor = doctorId;
    this.conversationService.doctor = this.doctors.find((doctor: Doctor) => doctor.id === doctorId);
  }

  onQuestionListChange(questionListId: string): void {
    if (!questionListId) {
      this.selectedQuestionList = undefined;

      return;
    }

    this.selectedQuestionList = questionListId;
    this.conversationService.questionList = this.questionLists.find(
      (questionList: ConversationQuestionList) => questionList.id === questionListId
    );
  }

  onLanguageSelected(value: string | null): void {
    this.conversationService.language = value;
  }

  goToNextStep(): void {
    this.conversationService.dueDate = this.selectedDueDate ? new Date(this.selectedDueDate) : null;

    void this.router.navigate([CONVERSATION_NAV.create.confirm.route]);
  }

  goBack(): void {
    history.back();
  }
}
