import { Component, Inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QrCodeService } from '@services/qr-code.service';

export interface QrDialogData {
  url: string;
  title: string;
}

@Component({
  selector: 'vh-qr-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['./qr-dialog.component.scss'],
})
@UntilDestroy()
export class QrDialogComponent {
  title: string;
  qrCode: string;

  constructor(
    protected readonly dialog: MatDialogRef<QrDialogComponent>,
    private readonly qrCodeService: QrCodeService,
    @Inject(MAT_DIALOG_DATA) public data: QrDialogData
  ) {
    this.qrCodeService.generate$(data.url).subscribe((qrCode: string) => {
      this.qrCode = qrCode;
    });
  }
}
