export const PROFILE_FORM_KEYS = new Map<string, string>([
  ['firstname', 'first_name'],
  ['lastname', 'last_name'],
  ['title', 'title'],
  ['birthdate', 'birthdate'],
  ['socialSecurityNumber', 'social_security_number'],
  ['gender', 'gender'],
  ['phone', 'phone'],
  ['canPhoneBeUsedToContact', 'can_phone_be_used_to_contact'],
  ['email', 'email'],
  ['language', 'language'],
  ['timezone', 'timezone'],
]);
