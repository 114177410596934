import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'vh-login-and-register-page',
  templateUrl: './login-and-register-page.component.html',
  styleUrls: ['./login-and-register-page.component.scss'],
})
export class LoginAndRegisterPageComponent extends BreakpointObserverComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly ONBOARDING_NAV: typeof ONBOARDING_NAV = ONBOARDING_NAV;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  currentRoute: string;

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    super(breakpointObserver);
  }

  ngOnInit(): void {
    this.currentRoute = this.activatedRoute.snapshot.routeConfig.path;
  }

  navigateToRedirectOrDashboardPage = (): void => {
    const redirectUrl = this.route.snapshot.queryParams.redirectUrl ?? null;

    // eslint-disable-next-line
    redirectUrl
      ? void this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl)
      : redirectToMostRelevantHome(this.authenticationService, this.router, { replaceUrl: true });
  };

  navigateToLoginPage = (): void => {
    void this.router.navigate([NAVIGATION.login.route]);
  };
}
