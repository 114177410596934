<div class="dialog-container">
  <div class="dialog-header">
    <h2 [innerHTML]="title"></h2>
    <vh-icon
      *ngIf="showCloseButton"
      class="clickable"
      src="assets/icons/cross.svg"
      [width]="16"
      [height]="16"
      [stroke]="theme.colorScheme().black"
      [hoverStroke]="theme.colorScheme().error"
      (click)="closeClicked.emit()">
    </vh-icon>
  </div>

  <div [vhMarginTop]="marginBetweenTitleAndBody" class="dialog-body" matDialogContent>
    <ng-content></ng-content>
  </div>
</div>
