<div class="images-container flex-col-l-c-when-small">
  <div
    class="drag-and-drop-container border-primary"
    [ngClass]="isHovering ? 'active' : ''"
    vhDragAndDrop
    (fileDropped)="onFileDropped($event)"
    (fileDragLeave)="onFileDragLeave()"
    (fileDragOver)="onFileDragOver()">
    <input
      type="file"
      #fileInputRef
      id="fileDropRef"
      (change)="onBrowseFileSelected($event)"
      [accept]="accept"
      [attr.multiple]="maxFiles > 1 ? true : null"
    />
    <div class="drag-and-drop-instructions-container">
      <vh-icon
        src="assets/icons/upload.svg"
        [class]="'up-and-down-animation'"
        [width]="50"
        [height]="50"
        [fill]="theme.colorScheme().secondaryGrey">
      </vh-icon>
      <p [vhMarginTop]="12" class="highlight" (click)="fileInputRef.click()">{{ 'components.upload.browseActionShort' | translate }}</p>
      <p [vhMarginTop]="12">{{ 'components.upload.dragActionImage' | translate }}</p>
    </div>
  </div>

  <div class="file-grid" [ngClass]="{'grid-1': maxFiles === 1}">
    <vh-uploaded-image
      *ngFor="let file of files; let i = index"
      [file]="file"
      [index]="i"
      (deleteFileClicked)="onDeleteFileClicked($event)">
    </vh-uploaded-image>
  </div>
</div>

<div [vhMarginTop]="8" *ngIf="errors?.length > 0">
  <div class="error-text" *ngFor="let errorKey of errors">{{ errorKey | translate }}</div>
</div>
