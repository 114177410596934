import { Injectable } from '@angular/core';
import { MetaConfig } from '@themes/theme.interface';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  applyFromConfig(config: MetaConfig): void {
    const head = document.head;

    const cacheBuster = `?v=${new Date().getTime()}`;

    this.removeExistingFavicons(head);

    this.addOrUpdateLink(head, 'apple-touch-icon', {
      sizes: '180x180',
      href: `${config.appleTouchIcon180x180}${cacheBuster}`,
    });

    this.addOrUpdateLink(head, 'icon', {
      type: 'image/png',
      sizes: '32x32',
      href: `${config.favicon32x32}${cacheBuster}`,
    });

    this.addOrUpdateLink(head, 'icon', {
      type: 'image/png',
      sizes: '16x16',
      href: `${config.favicon16x16}${cacheBuster}`,
    });

    this.addOrUpdateLink(head, 'manifest', {
      href: `${config.manifest}${cacheBuster}`,
    });

    this.addOrUpdateLink(head, 'mask-icon', {
      href: `${config.safariPinnedTabIcon}${cacheBuster}`,
      color: config.safariPinnedTabIconColor,
    });

    this.addOrUpdateMeta(head, 'msapplication-TileColor', {
      content: config.msTileColor,
    });

    this.addOrUpdateMeta(head, 'theme-color', {
      media: '(prefers-color-scheme: light)',
      content: config.themeColor,
    });

    this.addOrUpdateMeta(head, 'color-scheme', {
      content: config.colorScheme,
    });
  }

  private removeExistingFavicons(head: HTMLElement): void {
    const faviconLinks = head.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"], link[rel="mask-icon"]');
    faviconLinks.forEach((link: Element) => {
      link.remove();
    });
  }

  private addOrUpdateLink(
    head: HTMLElement,
    rel: string,
    attributes: Record<string, string>
  ): void {
    const existingLink = head.querySelector(`link[rel="${rel}"]`);

    if (existingLink) {
      Object.entries(attributes).forEach(([key, value]) => {
        existingLink.setAttribute(key, value);
      });
    } else {
      const link = document.createElement('link');
      link.setAttribute('rel', rel);
      Object.entries(attributes).forEach(([key, value]) => {
        link.setAttribute(key, value);
      });
      head.appendChild(link);
    }
  }

  private addOrUpdateMeta(
    head: HTMLElement,
    name: string,
    attributes: Record<string, string>
  ): void {
    const existingMeta = head.querySelector(`meta[name="${name}"]`);

    if (existingMeta) {
      Object.entries(attributes).forEach(([key, value]) => {
        existingMeta.setAttribute(key, value);
      });
    } else {
      const meta = document.createElement('meta');
      meta.setAttribute('name', name);
      Object.entries(attributes).forEach(([key, value]) => {
        meta.setAttribute(key, value);
      });
      head.appendChild(meta);
    }
  }
}
