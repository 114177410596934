<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.institution.title' | translate }}</h1>
</section>

<form class="flex-col gap-v-16" [vhMarginTop]="16" [formGroup]="formGroup">
  <vh-text-input
    [formControlName]="CREATE_INSTITUTION_FORM_KEYS.get('name')"
    [label]="'onboarding.institution.organisationName' | translate">
  </vh-text-input>

  <vh-suggestions-text-input
    [label]="'new.onboarding.institution.country' | translate"
    [placeholder]="'new.onboarding.institution.countryPlaceholder' | translate"
    [getOptionsFunction$]="getCountrySuggestions$"
    [maxOptionsHeight]="'190px'"
    [suggestionOnClick]="true"
    [formControlName]="CREATE_INSTITUTION_FORM_KEYS.get('country')"
    [valueToDisplay]="detectedCountry">
  </vh-suggestions-text-input>

  <vh-back-and-forward-buttons
    class="align-center"
    [forwardDisabled]="formGroup.invalid"
    [backLabel]="'common.back'"
    [forwardLabel]="'common.next'"
    (backClick)="onBackClicked()"
    (forwardClick)="onNextClicked()">
  </vh-back-and-forward-buttons>

  <vh-loader *ngIf="isSubmitting"></vh-loader>
</form>
