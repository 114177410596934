<vh-secondary-page>
  <div class="flex-row-c-c gap-h-48 container">
    <div class="grid-container">
      <div class="flex-col description-container">
        <p>{{ 'pages.contact.question' | translate }}</p>
        <h4 [vhMarginTop]="24">{{ 'pages.contact.areYouDoctor' | translate }}</h4>
        <p [vhMarginTop]="8">{{ 'pages.contact.flyersPatients' | translate: { brand: theme.displayName() } }}</p>
        <h4 [vhMarginTop]="24">{{ 'pages.contact.AreYouInstitution' | translate }}</h4>
        <p [vhMarginTop]="8">{{ 'pages.contact.contactDemo' | translate }}</p>
      </div>
      <h1>{{ 'pages.contact.title' | translate }}</h1>

      <ng-container *ngIf="!finishedSubmittingSuccessfully">
        <div class="flex-col-r-c gap-v-16 form-container" [formGroup]="formGroup">
          <vh-message
            *ngIf="errorTranslationKey"
            [vhMarginTop]="16"
            [vhFontClass]="'font-regular-18-22'"
            [vhTextColor]="'var(--color-alert)'"
            [text]="errorTranslationKey | translate"></vh-message>
          <vh-text-input [formControlName]="'first_name'" [placeholder]="'pages.contact.info.firstname' | translate"></vh-text-input>
          <vh-text-input [formControlName]="'last_name'" [placeholder]="'pages.contact.info.lastname' | translate"></vh-text-input>
          <vh-text-input [formControlName]="'email'" [placeholder]="'pages.contact.info.email' | translate"></vh-text-input>
          <vh-text-input [formControlName]="'institution'" [placeholder]="'pages.contact.info.institution' | translate"></vh-text-input>
          <vh-select-input
            [formControlName]="'type'"
            [placeholder]="'pages.contact.info.question' | translate"
            [options]="questionOptions"></vh-select-input>
          <vh-textarea-input [formControlName]="'message'" [placeholder]="'pages.contact.info.write' | translate"></vh-textarea-input>

          <vh-button
            [label]="'pages.contact.submitButtonLabel' | translate"
            [isDisabled]="formGroup.invalid || isSubmitting"
            [iconRightPath]="'assets/icons/arrow-right-white.svg'"
            (buttonClick)="submit()">
          </vh-button>
        </div>
      </ng-container>

      <ng-container *ngIf="finishedSubmittingSuccessfully">
        <div class="flex-col-r-c gap-v-16 container">
          <h1>{{ 'pages.contact.submitted.title' | translate }}</h1>
          <p>{{ 'pages.contact.submitted.description' | translate }}</p>

          <vh-button
            [vhMarginTop]="16"
            [label]="'pages.contact.goBackLabel' | translate"
            iconLeftPath="assets/icons/arrow-left.svg"
            [iconLeftFill]="theme.colorScheme().white"
            (buttonClick)="goBack()">
          </vh-button>
        </div>
      </ng-container>
    </div>
  </div>
</vh-secondary-page>
