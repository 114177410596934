<ng-container *ngIf="isInitialized" [formGroup]="formGroup">
  <div *ngIf="headerIsVisible" class="flex-row-s-c header">
    <h1>{{ 'pages.register.title' | translate }}</h1>
    <vh-message
      *ngIf="loginButtonIsVisible"
      class="clickable align-end"
      [iconLeftStroke]="theme.colorScheme().primary"
      [iconLeftPath]="'assets/icons/chevron-left.svg'"
      textClass="has-text-primary font-light-14-17"
      [text]="'pages.register.loginButtonLabel' | translate"
      [routerLink]="NAVIGATION.login.route">
    </vh-message>
  </div>
  <vh-text-input
    [formControlName]="REGISTER_FORM_KEYS.get('socialSecurityNumber')"
    [label]="'pages.register.socialSecurityNumberInputLabel' | translate"
    [placeholder]="'pages.register.socialSecurityNumberInputLabel' | translate"
    (inputValueChange)="onSocialSecurityNumberChanged($event)"></vh-text-input>
  <vh-text-input
    [formControlName]="REGISTER_FORM_KEYS.get('firstName')"
    [label]="'pages.register.firstNameInputLabel' | translate"
    [placeholder]="'pages.register.firstNameInputLabel' | translate"
    [showRequired]="true"
    autocomplete="given-name"></vh-text-input>
  <vh-text-input
    [formControlName]="REGISTER_FORM_KEYS.get('lastName')"
    [label]="'pages.register.lastNameInputLabel' | translate"
    [placeholder]="'pages.register.lastNameInputLabel' | translate"
    [showRequired]="true"
    autocomplete="family-name"></vh-text-input>
  <vh-text-input
    type="date"
    [formControlName]="REGISTER_FORM_KEYS.get('birthdate')"
    [label]="'pages.register.birthdateInputLabel' | translate"
    [placeholder]="'pages.register.birthdateInputLabel' | translate"
    [min]="DATE_BOUNDARIES.min.dateString"
    [max]="currentDate"></vh-text-input>
  <vh-select-input
    [formControlName]="REGISTER_FORM_KEYS.get('gender')"
    [label]="'pages.register.genderInputLabel' | translate"
    [placeholder]="'pages.register.genderInputLabel' | translate"
    [showRequired]="true"
    [options]="genderOptions"></vh-select-input>
  <vh-select-input
    [formControlName]="REGISTER_FORM_KEYS.get('language')"
    [label]="'pages.register.languageInputLabel' | translate"
    [placeholder]="'pages.register.languageInputLabel' | translate"
    [showRequired]="true"
    [options]="languageOptions"></vh-select-input>
  <div class="phone-input-container" [vhMarginTop]="16">
    <label
      class="font-regular-18-22"
      [ngClass]="{'required': true}">
      {{ 'pages.register.phoneInputLabel' | translate }}
    </label>
    <vh-phone-input
      [fieldControl]="formGroup.get(REGISTER_FORM_KEYS.get('phone'))"
      [initialValue]="formGroup.get(REGISTER_FORM_KEYS.get('phone'))?.value">
    </vh-phone-input>
    <p
      *ngIf="!registerSelf"
      class="font-regular-14-17 has-text-primary"
      [vhMarginTop]="4">
        {{ 'pages.register.phoneOrEmailRequiredLabel' | translate }}
    </p>
  </div>
  <vh-text-input
    type="email"
    [formControlName]="REGISTER_FORM_KEYS.get('email')"
    [label]="'pages.register.emailInputLabel' | translate"
    [placeholder]="'pages.register.emailInputLabel' | translate"
    [showRequired]="true"
    autocomplete="email">
  </vh-text-input>
  <p
    *ngIf="!registerSelf"
    class="font-regular-14-17 has-text-primary"
    [vhMarginTop]="4">
      {{ 'pages.register.phoneOrEmailRequiredLabel' | translate }}
  </p>

  <vh-username-input
    [label]="'common.fields.username' | translate"
    [formControlName]="REGISTER_FORM_KEYS.get('username')"
    [firstname]="formGroup.get(REGISTER_FORM_KEYS.get('firstName')).value"
    [lastname]="formGroup.get(REGISTER_FORM_KEYS.get('lastName')).value"
    [emailField]="formGroup.get(REGISTER_FORM_KEYS.get('email')).value"
    [birthdate]="formGroup.get(REGISTER_FORM_KEYS.get('birthdate')).value">
  </vh-username-input>
  <vh-password-input
    *ngIf="hasPasswordInput"
    [formControlName]="REGISTER_FORM_KEYS.get('password')"
    [label]="'pages.register.passwordInputLabel' | translate"
    [placeholder]="'pages.register.passwordInputLabel' | translate"
    [showRequired]="true"
    autocomplete="new-password">
  </vh-password-input>
  <vh-password-feedback
    *ngIf="hasPasswordInput"
    [form]="formGroup"
    [passwordControlName]="REGISTER_FORM_KEYS.get('password')">
  </vh-password-feedback>
  <vh-checkbox
    *ngIf="hasPrivacyPolicyInput"
    vhMarginTop="16"
    [formControlName]="REGISTER_FORM_KEYS.get('accepted_privacy_policy')"
    [value]="privacyStatementIsRead">
    <label
      class="font-regular-18-22 clickable"
      (click)="privacyStatementIsRead = !privacyStatementIsRead"
      [innerHTML]="'pages.register.privacyStatementIsReadInputLabel' | translate: { url: privacyStatementUrl }"></label>
  </vh-checkbox>
  <vh-message
    *ngIf="errorMessageTranslationKey"
    class="not-clickable"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="errorMessageTranslationKey | translate">
  </vh-message>
  <vh-message
    *ngIf="existingPatientId"
    class="not-clickable"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-warning)'"
    [text]="'pages.register.socialSecurityNumberAlreadyKnown' | translate : { brand: theme.displayName() }">
  </vh-message>

  <div class="flex-row-l-c gap-h-8">
    <vh-button
      *ngIf="registerButtonIsVisible"
      [vhMarginTop]="32"
      [label]="'pages.register.registerButtonLabel' | translate"
      [isDisabled]="registerCallIsInProgress || existingPatientId !== null"
      [iconRightPath]="registerCallIsInProgress ? 'assets/icons/hourglass.svg' : 'assets/icons/arrow-right.svg'"
      [iconRightFill]="theme.colorScheme().white"
      [alignment]="'center'"
      (buttonClick)="register()">
    </vh-button>
    <vh-button
      *ngIf="existingPatientId !== null"
      [vhMarginTop]="32"
      [label]="'pages.register.continueWithExistingPatientLabel' | translate"
      [isDisabled]="registerCallIsInProgress"
      [iconRightPath]="registerCallIsInProgress ? 'assets/icons/hourglass.svg' : 'assets/icons/arrow-right.svg'"
      [iconRightFill]="theme.colorScheme().white"
      [alignment]="'center'"
      (buttonClick)="continueWithExistingPatient()">
    </vh-button>
  </div>
</ng-container>
