import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObject } from '@app-types/iobject.type';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(
    private readonly http: HttpClient
  ) {}

  getCountry$(): Observable<string> {
    return this.http.get<IObject>('http://ip-api.com/json').pipe(
      map((response: IObject) => {
        return response.countryCode;
      })
    );
  }
}
