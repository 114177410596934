import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { Task } from '@models/task.model';
import { VisitTaskKey } from '@enums/visit-task-key.enum';
import { Document } from '@models/document.model';
import { DocumentTaskService } from '@services/document-task.service';
import { Visit } from '@models/visit.model';
import { FileDownloaderService } from '@services/file-downloader.service';
import { TaskStatus } from '@enums/task-status.enum';
import { BOOKING_NAV } from '@modules/booking/constants/booking-nav.constant';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent {
  @Input() visit: Visit;
  @Input() title: string;
  @Input() isReadOnly: boolean;

  protected theme: Theme;

  documentBeingDownloaded: Document;

  constructor(
    private readonly router: Router,
    private readonly documentTaskService: DocumentTaskService,
    private readonly fileService: FileDownloaderService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  handleTaskClick = (task: Task): void => {
    if (task.key === VisitTaskKey.REFERRAL_DOCUMENT.toString()) {
      this.handleReferralDocumentTaskClicked(task);

      return;
    }

    const route: string = NAVIGATION.survey.route
      .replace(`:${NAVIGATION.survey.params.taskId}`, task.id)
      .replace(`:${NAVIGATION.survey.params.visitId}`, this.visit.id);

    void this.router.navigate([route]);
  };

  handleReferralDocumentTaskClicked = (task: Task): void => {
    const route: string = BOOKING_NAV.visitManageDocumentTasks.route
      .replace(`:${BOOKING_NAV.visitManageDocumentTasks.params.appointmentId}`, this.visit.id)
      .replace(`:${BOOKING_NAV.visitManageDocumentTasks.params.taskId}`, task.id);

    void this.router.navigate([route]);
  };

  onDocumentClicked = (task: Task, document: Document): void => {
    this.documentBeingDownloaded = document;

    this.documentTaskService.getDocumentFile$(this.visit, task, document)
      .subscribe((buffer: ArrayBuffer) => {
        this.documentBeingDownloaded = null;
        this.fileService.open(buffer, document.filename);
      });
  };

  onDocumentDownloadClicked = (task: Task, document: Document): void => {
    this.documentBeingDownloaded = document;

    this.documentTaskService.getDocumentFile$(this.visit, task, document)
      .subscribe((buffer: ArrayBuffer) => {
        this.documentBeingDownloaded = null;
        this.fileService.download(buffer, document.filename);
      });
  };

  getStatusColor(task: Task): string {
    switch (task.status) {
      case TaskStatus.OPEN:
        return this.theme.colorScheme().error;
      case TaskStatus.STARTED:
        return this.theme.colorScheme().warning;
      case TaskStatus.COMPLETED:
        return this.theme.colorScheme().primary;
    }
  }

  getStatusIcon(task: Task): string {
    switch (task.status) {
      case TaskStatus.OPEN:
        return 'assets/icons/times-circle.svg';
      case TaskStatus.STARTED:
        return 'assets/icons/clock-three.svg';
      case TaskStatus.COMPLETED:
        return 'assets/icons/check-circle.svg';
    }
  }

  getStatusText(task: Task): string {
    switch (task.status) {
      case TaskStatus.OPEN:
        return 'common.taskStatus.notStarted';
      case TaskStatus.STARTED:
        return 'common.taskStatus.inProgress';
      case TaskStatus.COMPLETED:
        return 'common.taskStatus.completed';
    }
  }
}
