import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { Institution } from '@models/institution.model';
import { Mode } from '@enums/mode.enum';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopoverService } from '@services/ui/popover.service';
import { getEditorRoles, Role } from '@enums/role.enum';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { ButtonStyle } from '@enums/button-style.enum';
import { slideUpDownAnimation } from '@modules/shared/core/animations/slide/slide-in-out.animation';
import { WalletService } from '@services/wallet.service';
import { BaseNavigationProfileComponent } from '@modules/account/components/base-navigation-profile/base-navigation-profile.component';
import { InstitutionService } from '@services/institution.service';
import { scrollToElementById } from '@utils/helpers/scroll.util';
import { WalletPolicyService } from '@services/policies/wallet-policy.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '@modules/shared/core/dialogs/feedback-dialog/feedback-dialog.component';
import { FeedbackService } from '@services/feedback.service';
import { ToastService } from '@services/ui/toast.service';
import { Feedback } from '@modules/shared/core/dialogs/feedback-dialog/feedback-dialog.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-navigation-profile-details',
  templateUrl: './navigation-profile-details.component.html',
  styleUrls: ['./navigation-profile-details.component.scss'],
  animations: [slideUpDownAnimation],
})
@UntilDestroy()
export class NavigationProfileDetailsComponent extends BaseNavigationProfileComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly getEditorRoles: () => Role[] = getEditorRoles;
  protected readonly Role: typeof Role = Role;

  shouldDisplayInstitutionsPicker: boolean = false;
  shouldDisplayWallet: boolean = false;
  version: string = environment.version;
  institutions: Institution[] = [];

  @HostBinding('@animation') animationState: boolean = true;
  canOpenWallet: boolean = false;
  institutionsQuery: string = '';

  constructor(
    private readonly router: Router,
    private readonly popoverService: PopoverService,
    private readonly institutionService: InstitutionService,
    private readonly walletPolicy: WalletPolicyService,
    private readonly dialogService: MatDialog,
    private readonly feedbackService: FeedbackService,
    private readonly toastService: ToastService,
    authenticationService: AuthenticationService,
    walletService: WalletService,
    themeService: ThemeService
  ) {
    super(authenticationService, walletService, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.shouldDisplayWallet = this.walletPolicy.canViewWallet();
    this.canOpenWallet = this.walletPolicy.canEditWallet();
    this.shouldDisplayInstitutionsPicker = this.determineIfInstitutionsPickerShouldBeShown();

    this.loadInstitutions();
  }

  onInstitutionSelected(institution: Institution): void {
    this.institutionService.getInstitutionByIdOrSlug$(institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((reloadedInstitution: Institution) => {
        this.authenticationService.institution = reloadedInstitution;
        this.currentInstitution = reloadedInstitution;
        location.reload();
      });
  }

  switchMode(): void {
    if (this.displayMode === Mode.ADMIN) {
      this.displayMode = Mode.PRIVATE;
      this.authenticationService.mode = Mode.PRIVATE;
      this.popoverService.close('profileMenu');
      redirectToMostRelevantHome(this.authenticationService, this.router);

      return;
    }

    this.displayMode = Mode.ADMIN;
    this.authenticationService.mode = Mode.ADMIN;
    this.popoverService.close('profileMenu');
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }

  logout(): void {
    this.authenticationService.logout$()
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.popoverService.close('profileMenu');
        void this.router.navigate([NAVIGATION.login.route]);
      });
  }

  onNavigateToProfileClicked(): void {
    void this.router.navigate([NAVIGATION.profile.route]);
  }

  onSupportClicked(): void {
    window.open(environment.links.supportUrl, '_blank');
  }

  onContactClicked(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'navigationBar.contactDialog.title',
      showTextarea: true,
      showStarRating: false,
      textareaRequired: true,
      textareaPlaceholder: 'navigationBar.contactDialog.textAreaPlaceholder',
      submitButtonText: 'navigationBar.contactDialog.submitButtonText',
    };

    this.dialogService.open(FeedbackDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((feedback: Feedback) => {
        if (feedback) {
          this.onSubmitFeedbackClicked(feedback);
        }
      });
  }

  onSubmitFeedbackClicked(feedback: Feedback): void {
    this.feedbackService.platformFeedback({ message: feedback.message })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastService.showInfo('navigationBar.contactDialog.success', 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
        },
        error: () => {
          this.toastService.showError('common.errors.generic')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  onTelescopeClicked(): void {
    window.open(environment.links.telescopeUrl, '_blank');
  }

  onTokensClicked(): void {
    if (!this.canOpenWallet) {
      return;
    }

    void this.router.navigate([NAVIGATION.wallet.route]);
  }

  private loadInstitutions(): void {
    if (!this.currentUser.hasRole(Role.ADMIN)) {
      this.institutions = this.currentUser.institutions.sort((a: Institution, b: Institution) => a.name.localeCompare(b.name));
      this.shouldDisplayInstitutionsPicker = this.determineIfInstitutionsPickerShouldBeShown();

      return;
    }

    this.institutionService.getInstitutions$({ 'sort': '+name' })
      .pipe(untilDestroyed(this))
      .subscribe((institutions: Institution[]) => {
        this.institutions = institutions.sort((a: Institution, b: Institution) => a.name.localeCompare(b.name));
        this.shouldDisplayInstitutionsPicker = this.determineIfInstitutionsPickerShouldBeShown();
        scrollToElementById(`institution-${this.authenticationService.institution.id}`);
      });
  }

  private determineIfInstitutionsPickerShouldBeShown(): boolean {
    if (this.currentUser.hasRole(Role.ADMIN) && this.displayMode === Mode.ADMIN) {
      return true;
    }

    return this.authenticationService.currentUser.hasAnyInstitutionRole() &&
      this.institutions.length > 1 &&
      this.displayMode === Mode.ADMIN;
  }
}
