import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ServiceService } from '@services/service.service';
import { Service } from '@models/service.model';
import { Option } from '@interfaces/option.interface';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Institution } from '@models/institution.model';
import { AuthenticationService } from '@services/authentication.service';
import { FileDownloaderService } from '@services/file-downloader.service';
import { Role } from '@enums/role.enum';
import { ToastService } from '@services/ui/toast.service';
import { fadeInAnimation } from '@modules/shared/core/animations/fade/fade-in.animation';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-services-settings-page',
  templateUrl: './service-settings-page.component.html',
  styleUrls: ['./service-settings-page.component.scss'],
  animations: [fadeInAnimation],
})
@UntilDestroy()
export class ServiceSettingsPageComponent implements OnInit {
  protected readonly Role: typeof Role = Role;

  attachedServices: Service[];
  selectedModality: Service;
  institution: Institution;
  modalityOptions: ButtonOption[];
  serviceOptions: Option[];
  searchValue: string;
  isServicePendingCreation: boolean;
  fetchServicesSubscription: Subscription;

  selectedLanguage: string;
  defaultLanguage: string;

  protected theme: Theme;

  @ViewChild('uploadCsvInput') uploadCsvInput: ElementRef<HTMLInputElement>;

  constructor(
    private readonly serviceService: ServiceService,
    private readonly alertDialogService: AlertDialogService,
    private readonly authenticationService: AuthenticationService,
    private readonly downloadService: FileDownloaderService,
    private readonly toastService: ToastService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.institution = this.authenticationService.institution;
    this.selectedLanguage = this.authenticationService.institution.settings.defaultLanguage;
    this.defaultLanguage = this.authenticationService.institution.settings.defaultLanguage;
    this.fetchServices();
  }

  onModalitySelected(selectedIndex: number): void {
    const service = this.serviceService.allServices[selectedIndex];

    if (service.id === this.selectedModality?.id) {
      return;
    }

    this.selectedModality = service;
    this.isServicePendingCreation = false;

    this.modalityOptions = this.serviceService.allServices?.map((s: Service, index: number) => {
      const option = s.toButtonOption(true);
      option.isActive = selectedIndex === index;
      option.isClickable = !option.isActive;

      return option;
    });

    this.rebuildServiceOptions();
  }

  onSearchQueryEntered(query: string): void {
    this.searchValue = query;
    this.updateDisplayedServices();
  }

  onAddServiceClicked(): void {
    this.attachedServices.push(Service.dummy());
    this.isServicePendingCreation = true;
  }

  onRemoveServiceClicked(service?: Service): void {
    if (!service) {
      this.attachedServices.pop();
      this.isServicePendingCreation = false;

      return;
    }

    this.alertDialogService.open({
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
      titleTranslationKey: 'admin.pages.settings.services.deleteDialog.title',
      messageTranslationKey: 'admin.pages.settings.services.deleteDialog.message',
    })
      .pipe(untilDestroyed(this))
      .subscribe((isOkay: boolean) => {
        if (isOkay) {
          this.onRemoveServiceConfirmed(service);
        }
      });
  }

  onRemoveServiceConfirmed(service?: Service): void {
    this.serviceService.detachServiceFromCurrentInstitution$(service.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchServices(true);
      });
  }

  onSaveClicked(): void {
    this.isServicePendingCreation = this.isDummyServiceInList();
    this.fetchServices(true);
  }

  onLanguageChanged(language: string): void {
    this.selectedLanguage = language;
  }

  fetchServices(forceLoad: boolean = false): void {
    this.fetchServicesSubscription?.unsubscribe();
    this.fetchServicesSubscription = combineLatest([
      this.serviceService.getServices$(null, true),
      this.serviceService.getServicesForCurrentInstitution$({ loadAllTranslations: true }, forceLoad),
    ]).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchServicesSubscription = null;

        if (!this.selectedModality) {
          this.onModalitySelected(0);
        }

        this.rebuildServiceOptions();
      });
  }

  private updateDisplayedServices = (): void => {
    const pool = this.serviceService.servicesForCurrentInstitution
      .find((s: Service) => s.id === this.selectedModality.id)?.children ?? [];

    if (this.searchValue) {
      this.attachedServices = pool?.filter((service: Service): boolean => {
        return service.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          service.code.toLowerCase().includes(this.searchValue.toLowerCase());
      });

      return;
    }

    this.attachedServices = pool;

    if (this.attachedServices.length === 0) {
      this.isServicePendingCreation = true;
    }

    // If there are no service groups for the selected service then we automatically show the form to create the first one
    if (this.isServicePendingCreation && !this.isDummyServiceInList()) {
      this.onAddServiceClicked();
    }
  };

  private isDummyServiceInList(): boolean {
    return this.attachedServices.some((s: Service) => s.id === null);
  }

  private rebuildServiceOptions = (): void => {
    const serviceIds = this.serviceService.servicesForCurrentInstitution
      .find((s: Service) => s.id === this.selectedModality.id)?.children
      .map((s: Service) => s.id) ?? [];

    this.serviceOptions = this.selectedModality.children
      .filter((s: Service) => !serviceIds.includes(s.id))
      .map((s: Service) => s.toOption(true));

    this.updateDisplayedServices();
  };

  onExportClicked(): void {
    this.serviceService.downloadInstitutionServicesCsv$(this.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((buffer: ArrayBuffer) => {
        this.downloadService.download(buffer, 'services.csv');
      });
  }

  onImportClicked(): void {
    this.uploadCsvInput.nativeElement.showPicker();
  }

  onFileToImportSelected(event: Event): void {
    if (!this.uploadCsvInput.nativeElement.files[0]) {
      return;
    }

    const fileInput = event.target as HTMLInputElement;

    this.serviceService.uploadInstitutionServicesCsv$(this.institution.id, fileInput.files[0])
      .subscribe({
        next: () => {
          this.uploadCsvInput.nativeElement.files = null;
          this.uploadCsvInput.nativeElement.value = null;

          this.toastService.showInfo('admin.pages.settings.services.importFeedback.success', 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
          this.fetchServices(true);
        },
        error: () => {
          this.uploadCsvInput.nativeElement.files = null;
          this.uploadCsvInput.nativeElement.value = null;

          this.toastService.showError('admin.pages.settings.services.importFeedback.error')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }
}
