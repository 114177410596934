import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInputStyle } from '@enums/text-input-style.enum';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';

type AutocompleteOption = 'current-password' | 'new-password';

@Component({
  selector: 'vh-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: [
    './password-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent extends BaseInputComponent implements OnInit {
  protected readonly TextInputStyle: typeof TextInputStyle = TextInputStyle;

  protected showPassword: boolean = false;

  @Input() style: TextInputStyle = TextInputStyle.DEFAULT;
  @Input() autocomplete: AutocompleteOption | null = null;
}
