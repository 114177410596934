<ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>
<ng-container *ngIf="events?.length > 0" [ngTemplateOutlet]="resultsTemplate"></ng-container>
<ng-container *ngIf="events?.length === 0 && !isLoading" [ngTemplateOutlet]="noResultsTemplate"></ng-container>

<ng-template #resultsTemplate>
  <div *ngFor="let event of events" [id]="event.id" class="clickable event-row" [ngClass]="rowClass"
    (click)="onEventClicked(event)">
    <p class="font-medium-19-23 day-circle" [ngClass]="{ 'highlight-day-circle': isToday(event.start) }">
      {{ event.start | dfnsFormat : 'dd' }}
    </p>
    <p>{{ event.start | dfnsFormat : 'MMMM yyyy, EE' }}</p>
    <div *vhRequiresRoles="getInstitutionRoles()" class="room-circle" [ngStyle]="{ background: event.color.primary }"
      [matTooltip]="event.meta?.visit?.room?.name ?? null">
    </div>
    <p class="time-container">{{ event.start | dfnsFormat : 'HH:mm' }} - {{ event.end | dfnsFormat : 'HH:mm' }}</p>
    <vh-icon *vhRequiresRoles="getInstitutionRoles()" class="status-icon" [matTooltip]="
        (event.meta?.visit?.wasExecuted
          ? 'admin.pages.dashboard.searchResults.conducted'
          : 'admin.pages.dashboard.searchResults.notConductedYet'
        ) | translate
      " [src]="event.meta?.visit?.wasExecuted ? 'assets/icons/check-circle.svg' : 'assets/icons/minus-circle.svg'"
      [fill]="event.meta?.visit?.wasExecuted ? theme.colorScheme().secondaryGreen : theme.colorScheme().warning">
    </vh-icon>
    <p>
      <b>{{ event.meta?.visit?.title }}</b>
    </p>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <vh-loader class="padding-xl"></vh-loader>
</ng-template>

<ng-template #noResultsTemplate>
  <p class="no-results-message has-text-tertiary font-medium-16-19">{{ 'common.noResults' | translate }}</p>
</ng-template>
