<vh-primary-page [profileTemplate]="profileTemplate" [navigationItems]="navigationItems">
  <div class="container">
    <div>
      <div class="flex-row-s-c">
        <h1>{{ 'pages.dashboard.futureAppointmentsTitle' | translate }}</h1>
        <img
          *ngIf="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
          class="clickable"
          src="assets/icons/calendar-alt.svg"
          (click)="openCalendarDialog()" />
      </div>
      <vh-visit-card-group
        *ngIf="!getFutureAppointmentsErrorMessageTranslationKey"
        [vhMarginTop]="24"
        [noResultsMessage]="{
          text: 'pages.dashboard.noResultsText' | translate,
          showSkeletons: futureAppointmentDates?.length !== 0
        }"
        [actionsTemplate]="cardActionsTemplate"
        [visits]="slotsWithAppointments"
        [dateCardBackgroundColor]="'var(--color-primary-8-tranparent)'"
        [onCardClickFunction]="onFutureSlotClicked">
      </vh-visit-card-group>
      <vh-message
        *ngIf="getFutureAppointmentsErrorMessageTranslationKey"
        [vhMarginTop]="8"
        [vhFontClass]="'font-regular-18-22'"
        [vhTextColor]="'var(--color-alert)'"
        [text]="getFutureAppointmentsErrorMessageTranslationKey | translate"></vh-message>
      <h1 *ngIf="getExpiredAppointmentsErrorMessageTranslationKey || expiredSlotsWithAppointments?.length" [vhMarginTop]="32">
        {{ 'pages.dashboard.expiredAppointmentsTitle' | translate }}
      </h1>
      <vh-visit-card-group
        *ngIf="!getExpiredAppointmentsErrorMessageTranslationKey && expiredSlotsWithAppointments?.length"
        [vhMarginTop]="24"
        [visits]="expiredSlotsWithAppointments"
        [actionsTemplate]="cardActionsTemplate"
        [onCardClickFunction]="onExpiredSlotClicked">
      </vh-visit-card-group>
      <vh-message
        *ngIf="getExpiredAppointmentsErrorMessageTranslationKey"
        [vhMarginTop]="8"
        [vhFontClass]="'font-regular-18-22'"
        [vhTextColor]="'var(--color-alert)'"
        [text]="getExpiredAppointmentsErrorMessageTranslationKey | translate"></vh-message>
    </div>
    <div *ngIf="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]">
      <ng-container [ngTemplateOutlet]="calendarTemplate"></ng-container>
    </div>
  </div>
</vh-primary-page>

<ng-template #calendarTemplate>
  <vh-calendar [highlightedDates]="futureAppointmentDates"></vh-calendar>
  <vh-button
    *ngIf="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
    [vhMarginTop]="32"
    [label]="'common.close' | translate"
    [alignment]="'center'"
    (buttonClick)="calendarDialog.close()"></vh-button>
</ng-template>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>

<ng-template #cardActionsTemplate let-visit=visit>
  <vh-visit-card-actions [visit]="visit"></vh-visit-card-actions>
</ng-template>
