<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <h1>{{ 'wallet.paymentMethods.title' | translate }}</h1>

  <vh-loader *ngIf="isLoading"></vh-loader>
  <div *ngIf="!isLoading" class="payment-methods-grid" [vhMarginTop]="32">
    <vh-payment-method-card
      *ngFor="let paymentMethod of paymentMethods"
      [paymentMethod]="paymentMethod"
      [menuTemplate]="paymentMethodMenuTemplate">
    </vh-payment-method-card>
    <div class="card card-dashed clickable flex-col-c-c" (click)="onAddCreditCardClicked()">
      <vh-message
        class="has-text-primary"
        [text]="'wallet.paymentMethods.actionAdd' | translate"
        [iconLeftHeight]="24"
        [iconLeftWidth]="24"
        [iconLeftClass]="'icon-margin'"
        iconLeftPath="assets/icons/circle-plus.svg"
        [iconLeftStroke]="theme.colorScheme().primary">
      </vh-message>
    </div>
  </div>
</vh-content-layout>

<ng-template #paymentMethodMenuTemplate let-paymentMethod=paymentMethod>
  <vh-payment-method-settings-menu
    [paymentMethod]="paymentMethod"
    [canDelete]="paymentMethods.length > 1 && !paymentMethod.isDefault"
    [canMarkAsDefault]="paymentMethods.length > 1 && !paymentMethod.isDefault"
    (markAsDefaultClick)="onMarkPaymentMethodAsDefaultClicked(paymentMethod)"
    (deleteClick)="onDeletePaymentMethodClicked(paymentMethod)">
  </vh-payment-method-settings-menu>
</ng-template>
