<div class="gradient-container">
  <div class="gradient-inner-container">
    <div class="header">
      <svg-icon class="logo" [src]="theme.fullLogo()" />
      <p class="info">{{'conversations.feedback.intro' | translate}}</p>
    </div>

    <vh-status-message
      [vhMarginTop]="16"
      [isSuccessful]="true"
      [title]="'conversations.feedback.success.title' | translate"
      [message]="'conversations.feedback.success.intro' | translate">
    </vh-status-message>
  </div>
</div>
