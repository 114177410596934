import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SettingsCard } from '@modules/settings/interfaces/settings-card.interface';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-settings-card',
  templateUrl: './settings-card.component.html',
  styleUrls: ['./settings-card.component.scss'],
})
@UntilDestroy()
export class SettingsCardComponent {
  @Input() card: SettingsCard;

  @Output() cardEditClicked: EventEmitter<SettingsCard> = new EventEmitter<SettingsCard>();
  @Output() cardRemoveClicked: EventEmitter<SettingsCard> = new EventEmitter<SettingsCard>();
  @Output() cardClicked: EventEmitter<SettingsCard> = new EventEmitter<SettingsCard>();
  @Output() cardDuplicateClicked: EventEmitter<SettingsCard> = new EventEmitter<SettingsCard>();

  protected theme: Theme;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  onEditClicked(event: Event): void {
    event.stopPropagation();

    this.cardEditClicked.emit(this.card);
  }

  onRemoveClicked(event: Event): void {
    event.stopPropagation();

    this.cardRemoveClicked.emit(this.card);
  }

  onCardClicked(event: Event): void {
    event.stopPropagation();

    this.cardClicked.emit(this.card);
  }

  onCopyClicked(event: Event): void {
    event.stopPropagation();

    this.cardDuplicateClicked.emit(this.card);
  }
}
