import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { User, UserJson } from '@models/user.model';
import { IObject } from '@app-types/iobject.type';
import { environment } from '@environments/environment';
import { Institution, InstitutionJson } from '@models/institution.model';
import { NAVIGATION } from '@constants/navigation.constant';
import { Router } from '@angular/router';
import { Mode } from '@enums/mode.enum';
// eslint-disable-next-line @typescript-eslint/naming-convention
import { JwtToken } from '@interfaces/jwt-token.interface';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { stripFalsyPropertiesFromObject } from '@utils/helpers/form.util';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private _currentUser: User;
  private _selectedInstitution: Institution;
  private _mode: Mode;

  private readonly institution$: Subject<Institution> = new Subject<Institution>();
  userLoggedInSuccessfully$: Subject<User> = new Subject<User>();

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly localStorageCacheService: LocalStorageCacheService
  ) {}

  get currentUser(): User {
    if (this._currentUser) {
      return this._currentUser;
    }

    this._currentUser = this.loadUserFromLocalStorage();

    return this._currentUser;
  }

  set currentUser(value: User) {
    this._currentUser = value;
    this.persistLoggedInUser();
  }

  /**
   * This fetches the currently set institution, or returns undefined if none is set.
   */
  get institution(): Institution {
    if (!this.currentUser?.hasAnyInstitutionRole() && !this.currentUser?.hasAnyReferringPhysicianRole()) {
      return null;
    }

    if (this._selectedInstitution) {
      return this._selectedInstitution;
    }

    if (this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.selectedInstitution)) {
      this._selectedInstitution = Institution.fromJson(this.localStorageCacheService.get(LOCAL_STORAGE_KEYS.selectedInstitution));
      this.institution$.next(this._selectedInstitution);

      return this._selectedInstitution;
    }

    // Institution admin without institutions is impossible and not allowed on admin dashboard.
    if (!this.currentUser.institutions?.[0]) {
      this.logout$().subscribe((): void => {
        void this.router.navigate([NAVIGATION.login.route]);
      });

      // TODO - MED-341 Roles and permissions implementation
      console.error('User has administrator role but is not connected to any institution!');

      return undefined;
      // if user has institutions, but no institution is set, try to set based on localstorage
    } else {
      this.getInstitutionFromStorage();

      return this._selectedInstitution;
    }
  }

  set institution(institution: Institution) {
    if (institution) {
      this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.selectedInstitution, institution.toJson());
      this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.lastSelectedInstitutionId, institution.id);
    } else {
      this.localStorageCacheService.remove(LOCAL_STORAGE_KEYS.selectedInstitution);
    }

    this._selectedInstitution = institution ?? null;
    this.institution$.next(institution);
  }

  get mode(): Mode {
    if (this._mode) {
      return this._mode;
    }

    if (this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.displayMode)) {
      this._mode = Mode[this.localStorageCacheService.get<string>(LOCAL_STORAGE_KEYS.displayMode)];

      return this._mode;
    }

    this._mode = Mode.PRIVATE;

    return this._mode;
  }

  set mode(mode: Mode) {
    if (this.currentUser?.hasAnyInstitutionRole() || this.currentUser?.hasAnyReferringPhysicianRole()) {
      this._mode = mode;
      this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.displayMode, Mode[this._mode]);
    }
  }

  /**
  * gets institution from localstorage based on id, or first institution in user's list if none is found.
  * @returns Institution
  */
  getInstitutionFromStorage(): void {
    if (this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.selectedInstitution)) {
      this._selectedInstitution = Institution.fromJson(this.localStorageCacheService.get(LOCAL_STORAGE_KEYS.selectedInstitution));
    } else if (this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.lastSelectedInstitutionId)) {
      const lastSelectedId = this.localStorageCacheService.get<string>(LOCAL_STORAGE_KEYS.lastSelectedInstitutionId);
      this._selectedInstitution = this.currentUser.institutions.find((i: Institution) => i.id === lastSelectedId);
    }
  }

  login$ = (credentials: IObject): Observable<User> => {
    this.removeLoggedInUser();

    // Here the following steps are executed:
    // 1. Login the user
    // 2. Store the JwtToken if successful
    // 3. Fetch the user with the new token, assign it to currentUser and persist the user to localstorage
    return this.http.post<JwtToken>(environment.apiBaseUrl + ENDPOINTS.login.route, credentials)
      .pipe(
        tap((token: JwtToken): void => {
          this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.token, token);
        }),
        concatMap(() => {
          return this.getCurrentUser$().pipe(
            tap((user: User): void => {
              this._currentUser = user;

              this.persistLoggedInUser();
              this.userLoggedInSuccessfully$.next(user);
            })
          );
        })
      );
  };

  refreshToken$ = (refreshToken: string = null): Observable<JwtToken> => {
    if (!refreshToken) {
      refreshToken = this.localStorageCacheService.get<JwtToken>(LOCAL_STORAGE_KEYS.token)?.refresh_token;
    }

    return this.http
      .post(environment.apiBaseUrl + ENDPOINTS.refreshToken.route, { refresh_token: refreshToken })
      .pipe(tap((token: JwtToken): void => {
        this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.token, token);
      }));
  };

  hasToken(): boolean {
    return !!this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.token);
  }

  isLoggedIn = (): boolean => {
    return this.hasToken() && !!this.currentUser;
  };

  removeLoggedInUser = (): void => {
    this._currentUser = undefined;
    this._mode = undefined;
    this._selectedInstitution = undefined;

    this.localStorageCacheService.remove(LOCAL_STORAGE_KEYS.loggedInUser);
    this.localStorageCacheService.remove(LOCAL_STORAGE_KEYS.token);
    this.localStorageCacheService.remove(LOCAL_STORAGE_KEYS.selectedInstitution);
    this.localStorageCacheService.remove(LOCAL_STORAGE_KEYS.displayMode);
  };

  logout$ = (): Observable<void> => {
    return this.http.post(environment.apiBaseUrl + ENDPOINTS.logout.route, null).pipe(
      switchToEmptyObservable(),
      // A 401 error can be ignored since logging out will remove authentication anyway
      catchError((error: HttpErrorResponse | unknown): Observable<void | never> => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return of(void 0);
        }

        return throwError(() => error);
      }),
      // Always remove the logged-in user from the local storage, whether the API request was successful or not
      finalize((): void => {
        this.removeLoggedInUser();
      })
    );
  };

  register$ = (user: IObject, setCurrentUser: boolean = true): Observable<User> => {
    user = stripFalsyPropertiesFromObject(user);

    return this.http.post(environment.apiBaseUrl + ENDPOINTS.register.route, user).pipe(
      tap((loggedInUser: UserJson): void => {
        if (setCurrentUser) {
          this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.loggedInUser, loggedInUser);
        }
      })
    ).pipe(map((json: UserJson): User => User.fromJson(json)));
  };

  getCurrentUser$ = (): Observable<User> => {
    return this.http.get(environment.apiBaseUrl + ENDPOINTS.usersMe.route)
      .pipe(map((user: UserJson) => {
        const userWasAlreadyLoaded = !!this._currentUser;
        this.currentUser = User.fromJson(user);

        // Get institution
        if (!this._selectedInstitution && this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.selectedInstitution)) {
          this._selectedInstitution = Institution.fromJson(this.localStorageCacheService.get<InstitutionJson>(LOCAL_STORAGE_KEYS.selectedInstitution));
        }

        // Only set the mode/institution if the user is loaded for the first time, like for example on login
        if (this.currentUser?.hasAnyInstitutionRole() || this.currentUser?.hasAnyReferringPhysicianRole() && this._currentUser.institutions.length > 0 && !userWasAlreadyLoaded) {
          this.mode = Mode.ADMIN;
          this.getInstitutionFromStorage();
          this.institution = this._selectedInstitution ?? this.currentUser.institutions[0];
        }

        return this._currentUser;
      }));
  };

  persistLoggedInUser(): void {
    this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.loggedInUser, this._currentUser.toJson());
  }

  persistCurrentInstitution(): void {
    this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.selectedInstitution, this._selectedInstitution.toJson());
    this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.lastSelectedInstitutionId, this._selectedInstitution.id);
  }

  forgotUsername$ = (body: IObject): Observable<void> => {
    return this.http.post(environment.apiBaseUrl + ENDPOINTS.forgotUsername.route, body).pipe(switchToEmptyObservable());
  };

  private loadUserFromLocalStorage = (): User | null => {
    return this.localStorageCacheService.has(LOCAL_STORAGE_KEYS.loggedInUser)
      ? User.fromJson(this.localStorageCacheService.get<UserJson>(LOCAL_STORAGE_KEYS.loggedInUser))
      : null;
  };
}
