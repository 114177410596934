<div class="file-item border-dashed">
  <div class="file-preview">
    <img [src]="file.url" [alt]="alternativeTitle | translate" class="preview-image" />
  </div>

  <vh-icon
    class="file-delete"
    height="25"
    width="25"
    [hoverFill]="file?.isUpdating ? theme.colorScheme().primary : theme.colorScheme().error"
    [fill]="theme.colorScheme().primary"
    [ngClass]="file?.isUpdating ? 'anim-rotating' : 'clickable'"
    [src]="file?.isUpdating ? 'assets/icons/sync-secondary.svg' : 'assets/icons/cancel.svg'"
    (click)="onDeleteFileClicked()">
  </vh-icon>
</div>
