<vh-primary-page [navigationItems]="navigationItems" [hasVerticalContentSpacing]="false"
  [hasHorizontalContentSpacing]="true" [profileTemplate]="profileTemplate">

  <vh-back-button
    [marginTop]="20"
    [backTo]="'conversations.title' | translate"
    (buttonClick)="toConversations()"></vh-back-button>

  <div *ngIf="!loading && conversation" class="flex-col gap-v-24" [vhMarginTop]="24">
    <ng-container [ngTemplateOutlet]="header"></ng-container>

    <div class="flex-row-l-t flex-col-l-c-when-small gap-h-16 container">
      <ng-container [ngTemplateOutlet]="summaryTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="detailsTemplate"></ng-container>
    </div>
  </div>

  <div *ngIf="loading" class="loader" [vhMarginTop]="24">
    <vh-loader></vh-loader>
  </div>
</vh-primary-page>

<ng-template #header>
  <div class="flex-col full-width gap-v-8">
    <vh-conversation-status [status]="conversation.status" [size]="'large'"></vh-conversation-status>
    <p>{{ 'conversations.status.description.' + conversation.status | translate }}</p>

    <vh-copy-to-clipboard
      [text]="conversation.chatUrl"
      [confirmationMessage]="'common.copied' | translate"
      [showQr]="true">
    </vh-copy-to-clipboard>
  </div>
</ng-template>

<ng-template #summaryTemplate>
  <div>
    <vh-conversation-summary [conversation]="conversation"></vh-conversation-summary>
    <div class="extra">
      <h2 class="clickable" (click)="openLogbook()">{{ 'conversations.details.logbook' | translate }}</h2>
      <hr />
      <h2 class="clickable" (click)="openTranscript()">{{ 'conversations.details.transcript' | translate }}</h2>
    </div>
  </div>
</ng-template>

<ng-template #detailsTemplate>
  <div>
    <vh-conversation-info
      [conversation]="conversation"
      [transparent]="true"
      [vertical]="true">
    </vh-conversation-info>
    <vh-button
      *ngIf="conversation.status !== ConversationStatus.REMOVED"
      [vhMarginTop]="8"
      [label]="'common.delete' | translate"
      [style]="ButtonStyle.DANGER"
      (buttonClick)="clickDeleteConversation()">
    </vh-button>
    <vh-button
      *ngIf="conversation.status === ConversationStatus.REMOVED"
      [vhMarginTop]="8"
      [label]="'common.undoSoftDelete' | translate"
      [style]="ButtonStyle.TERTIARY"
      (buttonClick)="showUndoDeleteConfirmationDialog()">
    </vh-button>
  </div>
</ng-template>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
