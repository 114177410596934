import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionSettingsFormService } from '@services/form-services/institution-settings-form.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoomService } from '@services/room.service';
import { Room } from '@models/room.model';
import { InstitutionService } from '@services/institution.service';
import { ToastService } from '@services/ui/toast.service';
import { LanguageService } from '@services/language.service';
import { IObject } from '@app-types/iobject.type';
import { BaseSettingsPageComponent } from '@modules/settings/pages/base-settings-page/base-settings-page.component';
import { Option } from '@interfaces/option.interface';
import { getDayOfWeekAsOptions } from '@utils/helpers/date.util';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-reservation-settings-page',
  templateUrl: './reservation-settings-page.component.html',
  styleUrl: './reservation-settings-page.component.scss',
})
@UntilDestroy()
export class ReservationSettingsPageComponent extends BaseSettingsPageComponent implements OnInit {
  protected readonly dayOptions: Option[] = getDayOfWeekAsOptions();

  amountOfRooms: number = 1;

  constructor(
    institutionService: InstitutionService,
    toastService: ToastService,
    authenticationService: AuthenticationService,
    institutionSettingsFormService: InstitutionSettingsFormService,
    languageService: LanguageService,
    themeService: ThemeService,
    private readonly roomService: RoomService
  ) {
    super(institutionService, toastService, authenticationService, institutionSettingsFormService, languageService, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadRoomCount();
  }

  resetForm(): void {
    this.formGroup = this.institutionSettingsFormService.createReservationSettingsFormGroup(this.institution.settings);
  }

  fillMaxSimultaneousBookingsDefaultValueIfNeeded(isEnabled: boolean): void {
    if (!isEnabled || this.formGroup.get('booking_restriction_max_simultaneous_bookings').value) {
      return;
    }

    this.formGroup.get('booking_restriction_max_simultaneous_bookings').setValue(1);
  }

  protected buildSavePayload(): IObject {
    const payload: IObject = super.buildSavePayload();

    if (!this.institution.settings.isMaxSimultaneousBookingsEnabled) {
      payload.bookingRestrictionMaxSimultaneousBookings = 0;
    }

    return payload;
  }

  private loadRoomCount(): void {
    this.roomService.getRoomsOfInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((rooms: Room[]): void => {
        this.amountOfRooms = rooms.length;
      });
  }
}
