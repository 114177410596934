<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col gap-v-16">
    <ng-container *ngIf="state === 'list'" [ngTemplateOutlet]="defaultTemplate"></ng-container>
    <ng-container *ngIf="state === 'empty'" [ngTemplateOutlet]="emptyTemplate"></ng-container>
    <ng-container *ngIf="state === 'loading'" [ngTemplateOutlet]="loadingTemplate"></ng-container>
  </div>
</vh-content-layout>

<ng-template #defaultTemplate>
  <div class="flex-row-l-c gap-h-16">
    <vh-search-input
      class="flex-grow"
      [placeholder]="'admin.pages.settings.serviceGroups.searchName' | translate"
      [value]="searchValue"
      (inputValueChange)="onSearchQueryEntered($event)">
    </vh-search-input>
    <vh-button
      [label]="'admin.pages.settings.serviceGroups.addGroup' | translate"
      iconLeftPath="assets/icons/plus.svg"
      [iconLeftFill]="theme.colorScheme().white"
      [isDisabled]="isGroupPendingCreation || modalityOptions?.length === 0"
      (buttonClick)="onAddServiceGroupClicked()">
    </vh-button>
  </div>

  <h2>{{ 'admin.pages.settings.serviceGroups.pickModality' | translate }}</h2>
  <vh-radio-button-group [items]="modalityOptions" (itemClick)="onModalitySelected($event)"></vh-radio-button-group>

  <vh-service-group-settings
    *ngFor="let group of displayedServiceGroups"
    [id]="group.id"
    [ngClass]="{ 'anim-ripple': highlightedServiceGroup !== null && highlightedServiceGroup?.id === group.id }"
    @fadeInAnimation
    [serviceGroup]="group"
    [surveysForInstitution]="surveys"
    [parentService]="selectedModality"
    [isLoading]="state === 'loading'"
    (editClick)="onEditServiceGroupClicked()"
    (removeClick)="onRemoveServiceGroupClicked($event)"
    (serviceGroupCreated)="onServiceGroupSaved()"
    (serviceGroupUpdated)="onServiceGroupSaved()">
  </vh-service-group-settings>
</ng-template>

<ng-template #emptyTemplate>
  <div class="flex-row-l-c gap-h-8">
    <vh-message
      class="font-medium-16-19 has-text-tertiary not-clickable"
      [text]="'admin.pages.settings.serviceGroups.noServicesYet' | translate">
    </vh-message>
    <a class="font-medium-16-19" [routerLink]="NAVIGATION.adminDashboardSettings.services.route">{{
      'admin.pages.settings.serviceGroups.noServicesCallToAction' | translate
    }}</a>
  </div>
</ng-template>

<ng-template #loadingTemplate></ng-template>
