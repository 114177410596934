import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorMessageService } from '@services/error-message.service';
import { Subscription } from 'rxjs';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

/*
 * Steps to create an input component that extends the base input component:
 * 1. Add the BaseInputComponent stylesheet in the component decorator (optional)
 * 1. Add NG_VALUE_ACCESSOR as a provider in the component decorator
 * 2. Extend the component class with the BaseInputComponent class
 * 3. Inject the ControlContainer class with an Optional decorator in the constructor if it contains content and pass it to the super constructor
 * 4. Call super.ngOnInit() if the OnInit life cycle hook is used
 * 5. Call this.formControlOnValueChangeFunction?.(this.value) whenever this.value gets updated
 *    Don't forget the optional chaining operator since this.formControl can be undefined and thus this.formControlOnValueChangeFunction as well
 *
 * Prevent using FormControls with AsyncValidator functions that contain timer, delay, debounce, etc. rxjs operators
 * For your own debugging safety :)
 */

@Component({
  template: '',
  styleUrls: ['./base-input.component.scss'],
})
@UntilDestroy()
export abstract class BaseInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formControl: UntypedFormControl;
  @Input() formControlName: string;
  protected formControlOnValueChangeFunction: (value: number | boolean | string | string[]) => void;
  protected formControlOnBlurFunction: (value: number | boolean | string | string[]) => void;

  @Input() label: string | null;
  @Input() placeholder: string | null;
  @Input() isDisabled: boolean | null = null;
  @Input() isReadonly: boolean = false;
  @Input() showRequired: boolean = false;
  @Input() required: boolean = false;
  @Input() valueToDisplay: string;

  @Input() message: string | null;

  @Input() messageVerticalAlignment: 'start' | 'center' | 'end' = 'start';

  @Input() automaticErrorHandling: boolean = true;
  @Input() errorMessage: string | null;
  protected hasError: boolean;

  @Input() value: boolean | string | number | string[];
  @Output() inputValueChange: EventEmitter<number | boolean | string | string[]>;

  protected previousValue: string;
  @Output() inputValueChangeOnBlur: EventEmitter<string>;

  protected valueChangesSubscription: Subscription;
  protected theme: Theme;

  constructor(
    @Optional() protected readonly controlContainer: ControlContainer,
    protected readonly errorMessageService: ErrorMessageService,
    protected readonly themeService: ThemeService
  ) {
    this.inputValueChange = new EventEmitter<boolean | string>();
    this.inputValueChangeOnBlur = new EventEmitter<string>();
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.resolveFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formControl && !changes.formControl.isFirstChange() || changes.formControlName && !changes.formControlName.isFirstChange()) {
      this.resolveFormControl();
    }
  }

  get isRequired(): boolean {
    if (this.required) {
      return this.required;
    }

    if (!this.formControl?.validator) {
      return false;
    }

    const validatorResult = this.formControl.validator(this.formControl);

    return validatorResult?.required;
  }

  /* eslint-disable */
  registerOnChange(fn: any): void {
    this.formControlOnValueChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.formControlOnBlurFunction = fn;
  }
  /* eslint-enable */

  // This function updates this.value when it changes from the outside
  writeValue(value: boolean | string | number | string[]): void {
    // this.value is defined by the component input decorator: [value]="..."
    // value is defined by the FormControl: new FormControl('...', []) or FormControl.setValue(...)
    // Prioritize this.value from the input decorator over value from the FormControl

    if (this.formControlName) {
      this.value = value || null;

      return;
    }

    this.value = this.value || value || null;
  }

  handleValueChangeWithCheck(value: number | string | string[] | boolean): void {
    if (this.value === value) {
      return;
    }

    this.handleValueChange(value);
  }

  handleValueChange(value: number | string | string[] | boolean): void {
    this.value = value || null;
    this.formControlOnValueChangeFunction?.(this.value);
    this.inputValueChange.emit(this.value);
  }

  handleValueChangeOnBlurWithCheck(): void {
    if (this.previousValue === this.value) {
      return;
    }

    this.handleValueChangeOnBlur();
  }

  handleValueChangeOnBlur(): void {
    this.previousValue = this.value as string;
    this.formControlOnBlurFunction?.(this.value);
    this.inputValueChangeOnBlur.emit(this.value as string);
    this.checkForErrors();
  }

  /**
   * This function is called when the validation/enabled/disabled status of the FormControl changes.
   * Listening for this event helps to display the error message whenever the validation event was triggered externally.
   */
  handleStatusChange(status: string, shouldCheckForErrors: boolean = true): void {
    this.isDisabled = status === 'DISABLED';

    if (shouldCheckForErrors) {
      this.updateErrorState();
    }
  }

  protected resolveFormControl(): void {
    // When this.formControl is not defined, get it from this.controlContainer by its name,
    // or assign null when this.formControlName is not defined either
    this.formControl = this.formControl || (this.controlContainer?.control.get(this.formControlName) as UntypedFormControl) || null;
    this.subscribeToFormControlStatusChanges();
  }

  protected subscribeToFormControlStatusChanges(): void {
    if (!this.formControl) {
      return;
    }

    // Set the initial status of the FormControl
    if (this.formControl.status && this.isDisabled === null) {
      this.handleStatusChange(this.formControl.status, false);
    }

    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = this.formControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe((status: string) => {
        this.handleStatusChange(status);
      });
  }

  protected checkForErrors(): void {
    if (!this.automaticErrorHandling || !this.formControl?.touched || !this.formControl?.dirty) {
      return;
    }

    this.updateErrorState();
  }

  protected updateErrorState(): void {
    this.errorMessage = this.errorMessageService.resolveErrorToMessage(this.formControl.errors, this.label);
    this.hasError = this.formControl.errors !== null;
  }
}
