<ng-container *ngIf="state === 'loading'" [ngTemplateOutlet]="loadingTemplate"></ng-container>
<ng-container *ngIf="state !== 'loading'" [ngTemplateOutlet]="formTemplate"></ng-container>

<ng-template #loadingTemplate>
  <vh-loader></vh-loader>
</ng-template>

<ng-template #formTemplate>
  <div class="flex-row-s-c">
    <h1>{{ selectedOccurrence?.displayName ?? 'admin.pages.slotsOverview.slotsPage.newSlot' | translate }}</h1>
    <vh-icon
      class="clickable delete-btn"
      src="assets/icons/trash-alt.svg"
      [hoverFill]="theme.colorScheme().error"
      (click)="deleteClick?.emit(selectedOccurrence)"></vh-icon>
  </div>

  <div [vhMarginTop]="8">
    <p *ngIf="serviceGroupOptions?.length === 0" class="has-text-error">
      {{ 'admin.pages.slotsOverview.popup.noServiceGroupsConfigured' | translate }}
    </p>
    <h2>{{ 'admin.pages.slotsOverview.popup.settings' | translate }}</h2>
  </div>

  <ng-container [formGroup]="formGroup">
    <div class="grid-container" [vhMarginTop]="8" >
      <p>{{ 'admin.pages.slotsOverview.popup.examination' | translate }}</p>
      <vh-select-input
        [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('serviceGroupId')"
        [options]="serviceGroupOptions"
        [placeholder]="'RX'"
        (inputValueChange)="onServiceGroupSelected($event)">
      </vh-select-input>

      <p>{{ 'admin.pages.slotsOverview.popup.color' | translate }}</p>
      <vh-color-picker
        value="{{ selectedOccurrence?.serviceGroup?.color }}"
        [isReadonly]="true"
        [isDisabled]="true">
      </vh-color-picker>

      <p>{{ 'admin.pages.slotsOverview.popup.start' | translate }}</p>
      <vh-text-input
        [mask]="MASKS.time"
        [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('occurrenceStart')"
        [placeholder]="'hh:mm'"
        (change)="hourChange($event, 'start')">
      </vh-text-input>

      <p>{{ 'admin.pages.slotsOverview.popup.end' | translate }}</p>
      <vh-text-input
        [mask]="MASKS.time"
        [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('occurrenceEnd')"
        [placeholder]="'hh:mm'"
        (change)="hourChange($event, 'end')">
      </vh-text-input>

      <p>{{ 'admin.pages.slotsOverview.popup.duration' | translate }}</p>
      <vh-text-input
        [isDisabled]="true"
        [value]="formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('duration')).value | vhMinutesToTimeString"
        [placeholder]="'hh:mm'">
      </vh-text-input>

      <p>{{ 'admin.pages.slotsOverview.popup.doctor' | translate }}</p>
      <vh-select-input
        [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('doctorRizivNumber')"
        [options]="doctorOptions"
        [placeholder]="'admin.pages.slotsOverview.popup.selectDoctor' | translate"
        (inputValueChange)="onDoctorSelected($event)"></vh-select-input>
    </div>

    <hr [vhMarginTop]="16" />

    <div class="grid-container" [vhMarginTop]="16">
      <h2 [vhMarginTop]="8">{{ 'admin.pages.slotsOverview.popup.repeat' | translate }}</h2>
      <vh-checkbox [value]="recurrence.isRecurring" (click)="changeRecurrenceFlag()"></vh-checkbox>

      <p>{{ 'admin.pages.slotsOverview.popup.repeatEveryXLabel' | translate }}</p>
      <div class="recurrence-controls-container gap-h-8">
        <vh-text-input
          type="number"
          [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('interval')"
          [min]="1">
        </vh-text-input>
        <vh-select-input
          [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('frequency')"
          [options]="formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).value > 1 ? recurrenceOptionsPlural : recurrenceOptionsSingular">
        </vh-select-input>
      </div>

      <h2 class="span-col">{{ 'admin.pages.slotsOverview.popup.endsOnTitle' | translate }}</h2>

      <div class="flex-row-l-c gap-h-8 span-col" [vhMarginTop]="8">
        <input
          type="radio"
          id="recurrence-option-never"
          value="never"
          [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')" />
        <label for="recurrence-option-never">{{ 'admin.pages.slotsOverview.popup.neverLabel' | translate }}</label>
      </div>

      <div class="flex-row-l-c gap-h-8">
        <input
          type="radio"
          id="recurrence-option-ends_on_date"
          value="ends_on_date"
          [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')" />
        <label for="recurrence-option-ends_on_date">{{ 'admin.pages.slotsOverview.popup.endsOnDateLabel' | translate }}</label>
      </div>
      <vh-text-input
        type="date"
        [min]="currentDate.toDateString()"
        [max]="DATE_BOUNDARIES.max.dateString"
        [isDisabled]="formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')).value !== 'ends_on_date' || !recurrence.isRecurring"
        [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('end')"
        [placeholder]="'dd/mm/yyyy'"
        (change)="endDateChange($event)">
      </vh-text-input>

      <div class="flex-row-l-c gap-h-8">
        <input
          type="radio"
          id="recurrence-option-after_x_occurrences"
          value="after_x_occurrences"
          [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')" />
        <label for="recurrence-option-after_x_occurrences">{{ 'admin.pages.slotsOverview.popup.afterXOccurrencesLabel' | translate }}</label>
      </div>
      <div class="flex-row-l-c gap-h-8 input-with-suffix-label">
        <vh-text-input
          type="number"
          [formControlName]="ADD_RECURRENCE_FORM_KEYS.get('amount')"
          [isDisabled]="formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')).value !== 'after_x_occurrences' || !recurrence.isRecurring"
          [min]="1">
        </vh-text-input>
        <p>{{ 'admin.pages.slotsOverview.popup.afterXOccurrencesSuffix' | translate }}</p>
      </div>
    </div>
  </ng-container>

  <vh-message
    *ngIf="errorMessageTranslationKey"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="errorMessageTranslationKey | translate">
  </vh-message>

  <hr [vhMarginTop]="16" />

  <div class="flex-row save-button" [vhMarginTop]="16">
    <vh-button
      [label]="(state === 'update' ? 'admin.pages.slotsOverview.popup.saveChanges' : 'common.save') | translate"
      [type]="'submit'"
      [isDisabled]="formGroup.disabled"
      (buttonClick)="onSaveClicked()">
    </vh-button>
  </div>

</ng-template>
