<vh-back-button (buttonClick)="goBack()"></vh-back-button>
<h2>{{ 'conversations.create.missingInfo.title' | translate }}</h2>
<p>{{ 'conversations.create.missingInfo.description' | translate: {name: user?.fullName} }}</p>

<vh-text-input
  type="email"
  autocomplete="email"
  [label]="'common.fields.email' | translate"
  [placeholder]="'common.fields.email' | translate"
  [(ngModel)]="email">
</vh-text-input>

<vh-text-input
  [label]="'common.fields.phone' | translate"
  [placeholder]="'common.fields.phone' | translate"
  [(ngModel)]="phone">
</vh-text-input>

<vh-button
  [label]="'common.next' | translate"
  [vhMarginTop]="32"
  (buttonClick)="goToNextStep()"
  [centered]="true"
  [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
  [iconRightWidth]="20"
  [iconRightHeight]="20"
  [iconRightStroke]="theme.colorScheme().white">
</vh-button>
