<p *ngIf="title">{{ title }}</p>
<p *ngIf="visit.tasks.length === 0" class="has-text-tertiary">{{ 'pages.viewAppointmentTasks.noTasksFound' | translate }}</p>
<ul [vhMarginTop]="16">
  <li *ngFor="let task of visit.tasks" [vhMarginTop]="8">
    <vh-message
      [ngClass]="{
        'not-clickable': isReadOnly
      }"
      [iconLeftTooltip]="getStatusText(task) | translate"
      [iconRightPath]="!isReadOnly ? 'assets/icons/arrow-right.svg' : ''"
      [iconLeftPath]="getStatusIcon(task)"
      [iconLeftFill]="getStatusColor(task)"
      [isDisplayedInline]="true"
      [iconRightFill]="theme.colorScheme().black"
      (messageClick)="handleTaskClick(task)">
      <span>
        {{ task.description }}
        <span *ngIf="task?.optional" [vhTextColor]="'var(--color-secondary-grey)'">{{
          'pages.viewAppointmentTasks.optionalLabel' | translate
        }}</span>
      </span>
    </vh-message>
    <ul *ngIf="task.documents?.length > 0" [vhMarginTop]="8" class="font-light-14-17 document-list">
      <li *ngFor="let document of task.documents" class="highlight">
        <vh-message
          [iconLeftPath]="'assets/icons/file-check-alt.svg'"
          [iconRightPath]="documentBeingDownloaded?.id === document.id ? 'assets/icons/hourglass.svg' : 'assets/icons/download-alt.svg'"
          [iconRightAlt]="'pages.viewAppointmentTasks.downloadAltText' | translate : { filename: document.filename }"
          [isDisabled]="documentBeingDownloaded?.id === document.id"
          (messageClick)="onDocumentClicked(task, document)"
          (rightIconClick)="onDocumentDownloadClicked(task, document)">
          <u>{{ document.description ? document.description + ' (' + document.filename + ')' : document.filename }}</u>
        </vh-message>
      </li>
    </ul>
  </li>
</ul>
