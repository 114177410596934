<div
  #trigger="cdkOverlayOrigin"
  [ngClass]="{ 'collapsed': !expanded, 'expanded': expanded, 'menu-open': detailsOpen }"
  class="flex-column-s-c clickable profile"
  cdkOverlayOrigin
  (click)="detailsOpen = true">

  <div class="profile-icon-container">
    <vh-icon
      class="profile-icon"
      src="assets/icons/profile.svg"
      width="20"
      height="20"
      [fill]="theme.colorScheme().secondaryGreen"
      [hoverFill]="theme.colorScheme().primary">
    </vh-icon>

    <div *ngIf="shouldShowLowTokensWarning" class="tokens-low-indicator"></div>
  </div>

  <div class="profile-details">
    <span class="font-regular-16-19">{{ currentUser.fullName }}</span>
    <div class="font-regular-12-14">
      <p *ngIf="displayMode === Mode.ADMIN">{{ currentInstitution.name }}</p>
      <p *ngIf="displayMode !== Mode.ADMIN">{{ 'navigationBar.modePrivate' | translate }}</p>
    </div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="detailsOpen"
  (overlayOutsideClick)="detailsOpen = false"
  (detach)="detailsOpen = false"
  [cdkConnectedOverlayPositions]="[
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
      },
    ]"
  cdkConnectedOverlayPush="true"
  [cdkConnectedOverlayViewportMargin]="8"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy">
  <vh-navigation-profile-details></vh-navigation-profile-details>
</ng-template>
