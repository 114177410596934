import { Component, OnInit } from '@angular/core';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import * as sentry from '@sentry/browser';
import { WalletService } from '@services/wallet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { ToastService } from '@services/ui/toast.service';
import { catchError, interval, of, startWith, switchMap, takeWhile } from 'rxjs';
import { Router } from '@angular/router';
import { MolliePaymentStatus } from '@enums/mollie-payment-status.emun';
import { UserService } from '@services/user.service';
import { DoctorOnboardingState } from '@enums/doctor-onboarding-state.enum';
import { AuthenticationService } from '@services/authentication.service';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-onboarding-wallet-page',
  templateUrl: './onboarding-wallet-page.component.html',
  styleUrls: ['./onboarding-wallet-page.component.scss'],
})
@UntilDestroy()
export class OnboardingWalletPageComponent extends BaseOnboardingPageComponent implements OnInit {

  mollieToken: string | null = null;
  demoTokensAmount: number = 0;
  isSubmitting: boolean = false;
  pollingInterval: number = 5000;
  pollingActive: boolean = false;
  error: string | null = null;

  constructor(
    private readonly walletService: WalletService,
    private readonly toastService: ToastService,
    private readonly userService: UserService,
    private readonly authenticationService: AuthenticationService,
    router: Router,
    themeService: ThemeService
  ) {
    super(router, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loadDemoTokensAmount();
  }

  onNextClicked(): void {
    this.isSubmitting = true;

    this.walletService.addPaymentMethodAndGetCheckoutUrl$(this.mollieToken, PAYMENT_NAV.paymentRedirect.route)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ({ checkout_url, payment_id }: { checkout_url: string; payment_id: string; }) => {
          this.startPolling(payment_id);
          window.open(checkout_url, '_blank');
        },
        error: () => {
          this.isSubmitting = false;
          this.toastService.showError('common.errors.generic')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  setStep(): void {
    this.state.goToStep(this.STEP_WALLET);
  }

  onMollieTokenReady(token: string): void {
    this.mollieToken = token;
  }

  onValidationStateChanged(isValid: boolean): void {
    if (!isValid) {
      this.mollieToken = null;
    }
  }

  onMollieTokenError(error: unknown): void {
    sentry.captureException(error);
  }

  protected loadDemoTokensAmount(): void {
    this.walletService.getDemoTokensAmount$()
      .pipe(untilDestroyed(this))
      .subscribe((amount: number) => {
        this.demoTokensAmount = amount;
      });
  }

  startPolling(paymentId: string): void {
    this.pollingActive = true;

    interval(this.pollingInterval)
      .pipe(
        startWith(0),
        switchMap(() =>
          this.walletService.getPaymentStatus$(paymentId).pipe(
            catchError(err => {
              console.error('Error fetching payment status:', err);

              return of(null);
            })
          )
        ),
        takeWhile(() => this.pollingActive)
      )
      .subscribe({
        next: (status: string) => {
          if (status === MolliePaymentStatus.OPEN || status === MolliePaymentStatus.PENDING) {
            return;
          }

          this.pollingActive = false;
          this.isSubmitting = false;

          if (status === MolliePaymentStatus.PAID) {
            this.userService.updateUser$(this.authenticationService.currentUser.id, { doctor_onboarding_state: DoctorOnboardingState.PAYMENT_OPTION_ADDED })
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                void this.router.navigate([ONBOARDING_NAV.finishingUp.route]);
              });
          }

          this.error = status;
        },
        error: err => {
          console.error('Error fetching payment status:', err);
          this.pollingActive = false;
          this.isSubmitting = false;
          this.error = 'general';
        },
      });
  }
}
