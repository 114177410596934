import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { Mode } from '@enums/mode.enum';
import { ButtonStyle } from '@enums/button-style.enum';
import { environment } from '@environments/environment';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent extends BreakpointObserverComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly environment: typeof environment = environment;

  @Input() expanded: boolean;

  theme: Theme;

  @Input() logoIsHiddenOnMobile: boolean;

  isRootRoute: boolean;
  isLoggedInAsEmployee: boolean;

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    protected readonly authenticationService: AuthenticationService,
    private readonly themeService: ThemeService
  ) {
    super(breakpointObserver);

    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.isRootRoute = this.activatedRoute.snapshot.url.length === 0;
    this.isLoggedInAsEmployee = this.authenticationService.mode === Mode.ADMIN;
  }

  navigateToHome(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}
