import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { Visit } from '@models/visit.model';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CancelVisitFormService } from '@services/form-services/cancel-visit-form.service';
import { VisitCancellationService } from '@services/visits/visit-cancellation.service';

export interface CancelVisitDialogData {
  visit: Visit;
}

export interface CancelVisitDialogResult {
  status: CancelVisitDialogResultStatus;
}

export type CancelVisitDialogResultStatus = 'ok' | 'cancel';

@Component({
  selector: 'vh-cancel-visit-dialog',
  templateUrl: './cancel-visit-dialog.component.html',
  styleUrls: ['./cancel-visit-dialog.component.scss'],
})
@UntilDestroy()
export class CancelVisitDialogComponent implements OnInit {
  ButtonStyle: typeof ButtonStyle = ButtonStyle;

  cancelVisitSubscription: Subscription;
  cancelFailedErrorMessage: string;

  formGroup: UntypedFormGroup;

  visit: Visit;

  constructor(
    private readonly visitCancellationService: VisitCancellationService,
    private readonly cancelVisitFormService: CancelVisitFormService,
    private readonly dialog: MatDialogRef<CancelVisitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: CancelVisitDialogData
  ) {
    this.visit = data.visit;
  }

  ngOnInit(): void {
    this.formGroup = this.cancelVisitFormService.createFormGroup();
  }

  onConfirmClicked(): void {
    this.cancelFailedErrorMessage = null;

    this.cancelVisitSubscription = this.visitCancellationService.cancelVisit$(this.visit.id, this.formGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.cancelVisitSubscription = null;
          this.close('ok');
        },
        error: () => {
          this.cancelVisitSubscription = null;
          this.cancelFailedErrorMessage = 'admin.pages.dashboard.cancelVisitDialog.cancellationErrorMessage';
        },
      });
  }

  close(result: CancelVisitDialogResultStatus): void {
    this.dialog.close({ status: result });
  }
}
