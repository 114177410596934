// import { Directive, OnInit } from '@angular/core';
// import { COLORS } from '@constants/colors.constant';
// import { WizardState } from '@modules/shared/core/models/wizard-state.model';
// import { SESSION_KEYS } from '@constants/local-storage-keys.constant';

// @Directive()
// export abstract class BaseOnboardingPageComponent implements OnInit {
//   protected readonly COLORS: typeof COLORS = COLORS;

//   protected readonly STEP_CREDENTIALS: number = 1;
//   protected readonly STEP_INSTITUTION: number = 2;
//   protected readonly STEP_WALLET: number = -1;
//   protected readonly STEP_FINISHING_UP: number = 3;

//   protected state: WizardState;

//   protected isResuming: boolean = false;

//   abstract onNextClicked(): void;
//   abstract setStep(): void;

//   ngOnInit(): void {
//     this.state = new WizardState([
//       'onboarding.steps.credentials',
//       'onboarding.steps.institution',
//       // 'onboarding.steps.wallet',
//       'onboarding.steps.finishingUp',
//     ]);
//     this.setStep();
//     this.isResuming = sessionStorage.getItem(SESSION_KEYS.isPerformingDoctorOnboardingDuringFirstSession) !== 'true';
//   }
// }

import { Directive, OnInit } from '@angular/core';
import { WizardState } from '@modules/shared/core/models/wizard-state.model';
import { SESSION_KEYS } from '@constants/local-storage-keys.constant';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { Router } from '@angular/router';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Directive()
export abstract class BaseOnboardingPageComponent implements OnInit {
  protected readonly ONBOARDING_NAV: typeof ONBOARDING_NAV = ONBOARDING_NAV;

  protected readonly STEP_CREDENTIALS: number = 1;
  protected readonly STEP_PRIVACY: number = 2;
  protected readonly STEP_INSTITUTION: number = 3;
  protected readonly STEP_WALLET: number = -1;
  protected readonly STEP_ACCOUNT: number = 4;
  protected readonly STEP_FINISHING_UP: number = -1;

  protected state: WizardState;
  protected theme: Theme;
  protected isResuming: boolean = false;

  abstract onNextClicked(): void;
  abstract setStep(): void;

  protected constructor(
    protected readonly router: Router,
    protected readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.state = new WizardState([
      'onboarding.steps.credentials',
      'onboarding.steps.privacy',
      'onboarding.steps.institution',
      'onboarding.steps.account',
    ]);
    this.setStep();
    this.isResuming = sessionStorage.getItem(SESSION_KEYS.isPerformingDoctorOnboardingDuringFirstSession) !== 'true';
  }

  onStepClicked(step: number): void {
    this.state.goToStep(step);
    switch (step) {
      case 1:
        void this.router.navigate([ONBOARDING_NAV.credentials.route]);
        break;

      case 2:
        void this.router.navigate([ONBOARDING_NAV.privacy.route]);
        break;

      case 3:
        void this.router.navigate([ONBOARDING_NAV.createInstitution.route]);
        break;

      case 4:
        void this.router.navigate([ONBOARDING_NAV.createAccount.route]);
        break;

      default:
        break;
    }
  }
}
