<div class="container gap-v-16">
  <div class="flex-row gap-h-16">
    <vh-button
      *ngIf="!survey?.isReadOnly && !isLoading"
      class="align-end"
      [iconLeftPath]="isSaving ? 'assets/icons/hourglass.svg' : ''"
      [iconLeftFill]="theme.colorScheme().white"
      [label]="'common.save' | translate"
      [isDisabled]="isSaving || formGroup.invalid"
      (buttonClick)="saveSurvey()"></vh-button>
  </div>

  <ng-container *ngIf="!isLoading" [ngTemplateOutlet]="surveyForm"></ng-container>
  <ng-container *ngIf="isLoading" [ngTemplateOutlet]="surveySkeleton"></ng-container>
</div>

<ng-template #surveyForm [formGroup]="formGroup">
  <div class="flex-row gap-h-16">
    <h2>{{ 'admin.pages.settings.surveys.create.language' | translate }}</h2>
    <vh-language-picker
      [value]="selectedLanguage"
      [scopeToInstitutionSettings]="true"
      [changeAppLanguageOnValueChange]="false"
      (languageChanged)="onLanguageChanged($event)"></vh-language-picker>
  </div>

  <div class="grid-container" [vhMarginTop]="18">
    <p>{{ 'admin.pages.settings.surveys.create.name' | translate }}</p>
    <vh-text-input
      [value]="formGroup.get(SURVEY_FORM_KEYS.get('name'))?.value"
      [formControlName]="SURVEY_FORM_KEYS.get('name')"
      [placeholder]="'admin.pages.settings.surveys.create.name' | translate"
      [isDisabled]="survey?.isReadOnly"
      [message]="
        (survey && selectedLanguage !== defaultLanguage ? 'common.i18nOriginalValue' : '')
          | translate : { value: survey?.name.get(defaultLanguage) ?? '/' }
      ">
    </vh-text-input>

    <p>{{ 'admin.pages.settings.surveys.create.description' | translate }}</p>
    <vh-text-input
      [value]="formGroup.get(SURVEY_FORM_KEYS.get('description'))?.value"
      [formControlName]="SURVEY_FORM_KEYS.get('description')"
      [placeholder]="'admin.pages.settings.surveys.create.description' | translate"
      [isDisabled]="survey?.isReadOnly"
      [message]="
        (survey && selectedLanguage !== defaultLanguage ? 'common.i18nOriginalValue' : '')
          | translate : { value: survey?.description.get(defaultLanguage) ?? '/' }
      ">
    </vh-text-input>
  </div>
  <div [vhMarginTop]="30">
    <h2>{{ 'admin.pages.settings.surveys.create.questions' | translate }}</h2>
    <div
      class="drag-container gap-v-16"
      [vhMarginTop]="16"
      cdkDropList
      (cdkDropListDropped)="onQuestionDropped($event)"
      formArrayName="questions">
      <vh-question-settings
        *ngFor="let questionForm of questionForms; let questionIndex = index"
        cdkDrag
        class="flex-row drag-card card"
        [formGroup]="questionForm"
        [isDisabled]="survey?.isReadOnly"
        [formGroupName]="questionIndex"
        [cdkDragDisabled]="survey?.isReadOnly"
        [questionTypeOptions]="questionTypeOptions"
        [questionIndex]="questionIndex"
        [translationPreview]="
          (survey && selectedLanguage !== defaultLanguage ? 'common.i18nOriginalValue' : '')
            | translate : { value: survey?.questions[questionIndex].question.get(defaultLanguage) ?? '/' }
        "
        [translationAnswerOptionsPreview]="getTranslatedAnswerOptionsPreview(questionIndex)"
        (removeClicked)="removeQuestion(questionIndex)">
        <vh-icon
          *ngIf="!survey?.isReadOnly"
          class="draggable"
          src="assets/icons/drag-handle-dots.svg"
          [hoverFill]="theme.colorScheme().black"
          cdkDragHandle></vh-icon>
      </vh-question-settings>
    </div>
  </div>
  <div *ngIf="!survey?.isReadOnly" class="flex-row-c-n gap-h-16">
    <vh-message
      [vhMarginTop]="18"
      class="clickable has-text-tertiary align-center"
      iconLeftPath="assets/icons/plus.svg"
      [text]="'admin.pages.settings.surveys.create.addQuestion' | translate"
      (messageClick)="addQuestion()">
    </vh-message>
  </div>
</ng-template>

<ng-template #surveySkeleton>
  <div class="grid-container" style="height: 100px">
    <div class="survey-field-skeleton" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
    <div class="survey-field-skeleton" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
    <div class="survey-field-skeleton" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
    <div class="survey-field-skeleton" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
  </div>
  <ng-container *ngFor="let i of [].constructor(5); let index = index" [ngTemplateOutlet]="questionSkeleton"></ng-container>
</ng-template>

<ng-template #questionSkeleton>
  <div class="card survey-question-skeleton flex-col gap-v-16">
    <div class="skeleton-title" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
    <div class="skeleton-subtitle" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
  </div>
</ng-template>
