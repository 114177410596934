import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SurveyService } from '@services/survey.service';
import { Survey } from '@models/survey.model';
import { ActivatedRoute } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-survey-settings-page',
  templateUrl: './survey-settings-page.component.html',
  styleUrls: ['./survey-settings-page.component.scss'],
})
@UntilDestroy()
export class SurveySettingsPageComponent implements OnInit {
  protected theme: Theme;

  survey: Survey;

  isLoading: boolean;
  isNewSurvey: boolean;

  constructor(
    private readonly surveyService: SurveyService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.getSurveyFromRoute();
  }

  private getSurveyFromRoute(): void {
    if (this.activatedRoute.snapshot.routeConfig.path === NAVIGATION.adminDashboardSettings.createSurvey.path) {
      this.isNewSurvey = true;

      return;
    }

    this.isLoading = true;

    this.surveyService.getSurveyById$(this.activatedRoute.snapshot.params.surveyId, null, null, true)
      .pipe(untilDestroyed(this))
      .subscribe((survey: Survey) => {
        this.isLoading = false;
        this.survey = survey;
        this.isNewSurvey = false;
      });
  }

  navigateBack(): void {
    history.back();
  }

  getTitleKey(): string {
    if (this.isLoading) {
      return 'common.loading';
    }

    if (this.survey?.isReadOnly) {
      return 'common.navigateBack';
    }

    return this.isNewSurvey ? 'admin.pages.settings.surveys.newSurvey' : 'admin.pages.settings.surveys.editSurvey';
  }
}
