import { ButtonStyle } from '@enums/button-style.enum';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { rotateAnimation } from '@modules/shared/core/animations/rotate/rotate.animation';
import { slideInOutAnimation } from '@modules/shared/core/animations/slide/slide-in-out.animation';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [rotateAnimation, slideInOutAnimation],
})
export class SideNavComponent extends BreakpointObserverComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  @Input() navigationItems: NavigationItem[] = [];

  protected theme: Theme;

  constructor(
    private readonly localStorageService: LocalStorageCacheService,
    private readonly themeService: ThemeService,
    breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    // When first loading the page, the navigationItem corresponding to the default route is set to selected. This needs to be stored in case of page reload.
    const preset: NavigationItem = this.navigationItems.find((navItem: NavigationItem) => navItem.selected);
    if (preset) {
      this.storeSelectedNavItem(preset);
    }

    this.setSelectedNavItems();
  }

  toggleItem(item: NavigationItem): void {
    item.selected = item.selected ? !item.selected : true;
    this.storeSelectedNavItem(item);
  }

  isOpened(navItem: NavigationItem): boolean {
    return navItem.selected;
  }

  storeSelectedNavItem(item: NavigationItem): void {
    const selectedItems = this.localStorageService.get<string[] | null>(LOCAL_STORAGE_KEYS.openedSideNavOption) ?? [];
    if (item.selected) {
      selectedItems.push(item.labelTranslationKey);
      this.localStorageService.set(LOCAL_STORAGE_KEYS.openedSideNavOption, selectedItems);

      return;
    }
    const filteredSelectedItems = selectedItems.filter((labelTranslationKey: string) => labelTranslationKey !== item.labelTranslationKey);
    this.localStorageService.set(LOCAL_STORAGE_KEYS.openedSideNavOption, filteredSelectedItems);
  }

  setSelectedNavItems(): void {
    this.localStorageService.get<string[] | null>(LOCAL_STORAGE_KEYS.openedSideNavOption)?.forEach((labelTranslationKey: string) => {
      const selectedItem: NavigationItem = this.navigationItems.find((navItem: NavigationItem) => navItem.labelTranslationKey === labelTranslationKey);

      if (selectedItem) {
        selectedItem.selected = true;
      }
    });
  }
}
