<div class="phone-input-container" [class.error]="!fieldControl.valid">
  <ngx-material-intl-tel-input
    [fieldControl]="fieldControl"
    [enableSearch]="true"
    [initialValue]="initialValue"
    [autoSelectCountry]="true"
    [autoSelectedCountry]="'be'"
    [preferredCountries]="['be']"
    [iconMakeCall]="false"
    [textLabels]="{
      mainLabel: '',
      nationalNumberLabel: '',
      codePlaceholder: 'pages.register.phoneInputCodePlaceholder' | translate,
      searchPlaceholderLabel: 'pages.register.phoneInputSearchPlaceholder' | translate,
      noEntriesFoundLabel: 'pages.register.phoneInputNoEntriesFound' | translate,
      hintLabel: '',
      invalidNumberError: 'pages.register.phoneInputInvalidNumber' | translate,
      requiredError: 'pages.register.phoneInputRequired' | translate
    }">
  </ngx-material-intl-tel-input>
</div>
