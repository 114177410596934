<vh-dialog-frame [title]="'admin.pages.dashboard.linkRiziv.message' | translate" (closeClicked)="close()"></vh-dialog-frame>
<form [formGroup]="formGroup">
  <vh-text-input
    [label]="'admin.pages.dashboard.linkRiziv.rizivNumber' | translate"
    [placeholder]="'common.rizivFormat' | translate"
    [formControlName]="LINK_RIZIV_FORM_KEYS.get('linkRizivNumber')"
    [mask]="MASKS.riziv"></vh-text-input>
</form>
<div *ngIf="errorMessage">
  <vh-message
    textAlignment="center"
    iconLeftPath="assets/icons/exclamation-triangle.svg"
    [iconLeftFill]="theme.colorScheme().error"
    class="not-clickable has-text-error">
    {{ errorMessage | translate }}
  </vh-message>

  <p class="error-message">{{ 'admin.pages.dashboard.linkRiziv.errorMessage.paragraph1' | translate }}</p>
  <p class="error-message">{{ 'admin.pages.dashboard.linkRiziv.errorMessage.paragraph2' | translate }}</p>
  <p class="error-message">{{ 'admin.pages.dashboard.linkRiziv.errorMessage.paragraph3' | translate }}</p>
</div>
<div class="flex-row-c-n">
  <vh-button [label]="'common.cancel' | translate" (buttonClick)="close()" [style]="ButtonStyle.TERTIARY">cancel</vh-button>
  <vh-button [label]="'link' | translate" (buttonClick)="onLinkClicked()"></vh-button>
</div>
