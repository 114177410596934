import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { Title } from '@angular/platform-browser';
import { LanguageService } from '@services/language.service';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { AnalyticsService } from '@services/analytics.service';
import { ButtonStyle } from '@enums/button-style.enum';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@UntilDestroy()
export class AppComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  cookiePopupIsClosed: boolean;
  isCookiesAccepted: boolean;

  constructor(
    private readonly title: Title,
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly localStorageService: LocalStorageCacheService,
    private readonly analyticsService: AnalyticsService,
    private readonly themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.cookiePopupIsClosed = this.localStorageService.get<boolean>(LOCAL_STORAGE_KEYS.cookiePopupIsClosed);
    this.isCookiesAccepted = this.localStorageService.get<boolean>(LOCAL_STORAGE_KEYS.isCookiesAccepted);

    this.themeService.bootstrap();
    this.languageService.bootstrapTranslations();

    this.translateService.get('meta.title', { brand: this.themeService.currentTheme.displayName() })
      .pipe(untilDestroyed(this))
      .subscribe((title: string) => {
        this.title.setTitle(title);
      });

    if (this.isCookiesAccepted) {
      this.analyticsService.bootstrap();
    }
  }

  onAcceptCookies(): void {
    this.setCookies(true);
    this.loadAnalyticsScript();
  }

  onDeclineCookies(): void {
    this.setCookies(false);
  }

  private setCookies(accepted: boolean): void {
    this.localStorageService.set(LOCAL_STORAGE_KEYS.cookiePopupIsClosed, true);
    this.localStorageService.set(LOCAL_STORAGE_KEYS.isCookiesAccepted, accepted);

    this.cookiePopupIsClosed = true;
    this.isCookiesAccepted = accepted;
  }

  private loadAnalyticsScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function(window, document, dataLayerName, id) {
        window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});
        var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
        function stgCreateCookie(a,b,c){
          var d="";
          if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}
          document.cookie=a+"="+b+d+f+"; path=/"
        }
        var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");
        stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
        var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");
        var qPString=qP.length>0?("?"+qP.join("&")):"";
        tags.async=!0,tags.src="https://vesalius.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
        !function(a,n,i){a[n]=a[n]||{};
          for(var c=0;c<i.length;c++)!function(i){
            a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){
              var a=[].slice.call(arguments,0);
              "string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})
            }
          }(i[c])
        }(window,"ppms",["tm","cm"]);
      })(window, document, 'dataLayer', '8aafdffe-199c-4e92-aad1-061f8a6fb952');
    `;
    document.head.appendChild(script);
  }
}
