<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="flex-row gap-h-8 align-end">
      <vh-button [label]="'common.save' | translate" (buttonClick)="saveSettings()" [isDisabled]="formGroup.invalid"></vh-button>
      <vh-button [label]="'common.cancel' | translate" (buttonClick)="resetForm()"></vh-button>
    </div>

    <div [vhMarginTop]="16" class="settings-list" [formGroup]="formGroup">
      <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.generalCommunicationTitle' | translate"></vh-horizontal-divider>

      <!-- Support email -->
      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.supportEmailLabel' | translate }}</label>
      <div class="flex-col">
        <vh-text-input [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('support_email')"></vh-text-input>
        <vh-checkbox
          [vhMarginTop]="8"
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('support_email_as_reply_to')"
          [label]="'admin.pages.settings.generalSettings.supportEmailUseAsReplyTo' | translate"
          [isDisabled]="
            !formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('support_email')).value ||
            !formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('support_email')).valid
          ">
        </vh-checkbox>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.supportEmailDescription' | translate }}
        </p>
      </div>

      <!-- Support phone -->
      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.supportPhoneLabel' | translate }}</label>
      <div class="flex-col">
        <vh-text-input
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('support_phone')"
          [message]="'admin.pages.settings.generalSettings.supportPhoneDescription' | translate"
          [messageVerticalAlignment]="'end'">
        </vh-text-input>
      </div>

      <!-- Logo -->
      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.logo' | translate }}</label>
      <div class="flex-col" *ngIf="!isLoadingLogo">
        <vh-upload-images-input-form
          [maxFiles]="1"
          [originalImageUrl]="logo"
          [errors]="errors"
          (deleteClick)="onDeleteLogoClicked()"
          (filePick)="onFilePicked($event)">
        </vh-upload-images-input-form>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.logoDescription' | translate: { brand: theme.displayName() } }}
        </p>
      </div>

      <vh-loader *ngIf="isLoadingLogo"></vh-loader>

      <!-- Main color -->
      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.mainColor' | translate }}</label>
      <div class="flex-col">
        <vh-color-picker class="input" [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('main_color')"> </vh-color-picker>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.mainColorDescription' | translate: { brand: theme.displayName() } }}
        </p>
      </div>

      <!-- Accent color -->
      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.accentColor' | translate }}</label>
      <div class="flex-col">
        <vh-color-picker class="input" [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('accent_color')"> </vh-color-picker>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.accentColorDescription' | translate: { brand: theme.displayName() } }}
        </p>
      </div>

      <vh-horizontal-divider [vhMarginTop]="16" [title]="'admin.pages.settings.generalSettings.visitCommunicationTitle' | translate"></vh-horizontal-divider>

      <ng-container *vhRequiresModule="ModuleKey.BOOKING">
        <!-- Hide visit details -->
        <label>{{ 'admin.pages.settings.generalSettings.hideVisitDetailsInEmails' | translate }}</label>
        <vh-switch
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('hide_service_details_in_visit_mails')"
          [value]="institution.settings.hideServiceDetailsInVisitMails"
          [message]="'admin.pages.settings.generalSettings.hideVisitDetailsInEmailsDescription' | translate">
        </vh-switch>

        <!-- Visit guidelines -->
        <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.visitGuidelinesLabel' | translate }}</label>
        <div class="flex-col">
          <vh-wysiwyg-input
            [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('visit_guidelines')"
            [maxlength]="65535"
            [isMultiLanguage]="true"
            [languageOptions]="institutionLanguageOptions"
            [selectedLanguage]="visitGuidelinesSelectedLanguage"
            (languageChanged)="onVisitGuidelinesLanguageChanged($event)">
          </vh-wysiwyg-input>
          <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
            {{ 'admin.pages.settings.generalSettings.visitGuidelinesDescription' | translate }}
          </p>
        </div>

        <vh-button
          class="col-end align-end"
          [style]="ButtonStyle.SECONDARY"
          [label]="'admin.pages.settings.generalSettings.sendTestEmail' | translate"
          [matTooltip]="'admin.pages.settings.generalSettings.sendTestEmailTooltip' | translate"
          [matTooltipShowDelay]="500"
          (buttonClick)="sendVisitPreviewEmail()">
        </vh-button>
      </ng-container>
    </div>
  </div>
</vh-content-layout>
