import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AngularCalendarScrollHelperService {

  private _calendarElementRef: ElementRef<HTMLElement>;

  set calendarElementRef(value: ElementRef<HTMLElement>) {
    this._calendarElementRef = value;
  }

  isCurrentTimeDisplayedOnCalendar(): boolean {
    return !!this.getCurrentTimeMarkerElement();
  }

  isAnyEventDisplayedOnCalendar(): boolean {
    return !!this.getFirstCalendarEventElement();
  }

  scrollToCurrentTimeInCalendar(): void {
    this.getCurrentTimeMarkerElement()?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  scrollToFirstEventInCalendar(): void {
    this.getFirstCalendarEventElement()?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  scrollToCurrentTimeInCalendarRegardlessOfDate(): void {
    this.getCurrentHourElement()?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  bestEffortScrollToRelevantViewportInCalendar(): void {
    if (this.isCurrentTimeDisplayedOnCalendar()) {
      this.scrollToCurrentTimeInCalendar();

      return;
    }

    if (this.isAnyEventDisplayedOnCalendar()) {
      this.scrollToFirstEventInCalendar();

      return;
    }

    this.scrollToCurrentTimeInCalendarRegardlessOfDate();
  }

  getCurrentTimeMarkerElement(): HTMLElement {
    const currentTimeElementSelector: string = '.cal-current-time-marker';

    return this._calendarElementRef.nativeElement.querySelector(currentTimeElementSelector);
  }

  getAllCalendarEventElements(): NodeListOf<HTMLElement> {
    const currentTimeElementSelector: string = '.cal-event';

    return this._calendarElementRef.nativeElement.querySelectorAll(currentTimeElementSelector);
  }

  getFirstCalendarEventElement(): HTMLElement {
    const currentTimeElementSelector: string = '.cal-event';

    return this._calendarElementRef.nativeElement.querySelector(currentTimeElementSelector);
  }

  getCurrentHourElement(): HTMLElement {
    // This functions gets all hour segments, and then iterates over them to find the one that matches the current hour
    const currentHour = new Date().getHours();
    const hourElements = this._calendarElementRef.nativeElement.querySelectorAll<HTMLElement>('.cal-hour-start');
    let selectedElement = null;

    hourElements.forEach((hourElement: HTMLElement) => {
      const timeElement = hourElement.querySelector('.cal-time b');
      const hourText = timeElement?.textContent || '';

      // Match the text format (e.g., "00:00", "01:00", etc.)
      if (hourText.startsWith(currentHour.toString().padStart(2, '0'))) {
        selectedElement = hourElement;
      }

    });

    return selectedElement;
  }
}
