import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-centered-card-page',
  templateUrl: './centered-card-page.component.html',
  styleUrls: ['./centered-card-page.component.scss'],
})
export class CenteredCardPageComponent extends BreakpointObserverComponent {
  @Input() showBackButton: boolean = false;
  @Input() showLanguagePicker: boolean = true;

  @Output() backButtonClick: EventEmitter<void> = new EventEmitter<void>();

  theme: Theme;

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly themeService: ThemeService
  ) {
    super(breakpointObserver);

    this.theme = this.themeService.currentTheme;
  }
}
