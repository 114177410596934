import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UploadedFile } from '@modules/shared/form/interfaces/uploaded-file.interface';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-uploaded-image',
  templateUrl: './uploaded-image.component.html',
  styleUrls: ['./uploaded-image.component.scss'],
})
@UntilDestroy()
export class UploadedImageComponent {
  protected theme: Theme;

  @Input() file: UploadedFile;
  @Input() index: number;
  @Input() alternativeTitle: string = 'common.imageAlternativeTitle';

  @Output() deleteFileClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  onDeleteFileClicked(): void {
    if (this.file?.isUpdating) {
      return;
    }

    this.deleteFileClicked.emit(this.index);
  }
}
