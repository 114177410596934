import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DocumentTaskService } from '@services/document-task.service';
import { Visit } from '@models/visit.model';
import { VisitTaskKey } from '@enums/visit-task-key.enum';
import { Task } from '@models/task.model';
import { Document } from '@models/document.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpErrorResponse } from '@angular/common/http';
import { VisitService } from '@services/visits/visit.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounce } from '@utils/decorators/debounce.decorator';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';
import { UploadedFile } from '@modules/shared/form/interfaces/uploaded-file.interface';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-step-upload-referral-letter',
  templateUrl: './step-upload-referral-letter.component.html',
  styleUrls: ['./step-upload-referral-letter.component.scss'],
  animations: [fadeInOutAnimation],
})
@UntilDestroy()
export class StepUploadReferralLetterComponent implements OnInit, OnChanges {
  protected theme: Theme;

  readonly fakeDelay: number = 400;

  @Input() visit: Visit | null = null;
  @Input() isCompleted: boolean = false;
  @Input() hideContinueOnMobileBanner: boolean = false;

  task: Task;
  uploadedFiles: UploadedFile[] = [];
  errors: string[];
  isDesktop: boolean;
  showSavedPhysicianSuccessfullyMessage: boolean;

  allowedExtensions: string[] = [
    '.jpeg',
    '.jpg',
    '.png',
    '.gif',
    '.heic',
    '.pdf',
    '.bmp',
    '.docx',
    '.doc',
  ];

  constructor(
    private readonly documentTaskService: DocumentTaskService,
    private readonly visitService: VisitService,
    themeService: ThemeService,
    deviceService: DeviceDetectorService
  ) {
    this.theme = themeService.currentTheme;
    this.isDesktop = deviceService.isDesktop();
  }

  ngOnInit(): void {
    this.reloadData();
  }

  ngOnChanges(): void {
    this.reloadData();
  }

  onDeleteClicked = (index: number): void => {
    this.documentTaskService.deleteDocumentTask$(this.visit, this.task, this.task.documents[index])
      .subscribe(() => {
        this.visit.tasks.find((t: Task) => t.key === this.task.key).documents = this.task.documents;
        this.task.documents.splice(index, 1);
        this.isCompleted = this.calculateIsCompleted();
      });
  };

  onFilePicked = (files: FileList): void => {
    if (files.length === 0) {
      return;
    }

    this.errors = [];

    this.documentTaskService.uploadDocumentTask$(this.visit, this.task, files)
      .subscribe({
        next: (documents: Document[]) => {
          this.visit.tasks.find((t: Task) => t.key === this.task.key).documents = this.task.documents;
          this.task.documents = documents;
          this.isCompleted = this.calculateIsCompleted();
          this.reloadData();
        },
        error: (err: HttpErrorResponse) => {
          this.uploadedFiles.splice(this.uploadedFiles.length - files.length, files.length);

          if (err.status === 422) {
            const errorMessage: string = this.uploadedFiles.length === 1
              ? 'pages.createAppointment.step2.errors.disallowedFileType'
              : 'pages.createAppointment.step2.errors.disallowedFileTypeMultiple';
            this.errors.push(errorMessage);

            return;
          }

          this.errors.push('pages.createAppointment.step2.errors.unknownFileUploadError');
        },
      });
  };

  onDescriptionUpdated = (file: UploadedFile): void => {
    const document = this.task.documents.find((doc: Document) => doc.id === file.id);

    if (!document || document.description === file.description) {
      return;
    }

    this.errors = [];

    file.isUpdating = true;

    this.documentTaskService.updateDocumentTask$(this.visit, this.task, document, null, file.description)
      .subscribe({
        next: (doc: Document) => {
          setTimeout(() => file.isUpdating = false, this.fakeDelay);

          this.task.documents.find((d: Document) => d.id === file.id).description = doc.description;
        },
        error: () => {
          setTimeout(() => file.isUpdating = false, this.fakeDelay);

          this.errors.push('pages.createAppointment.step2.errors.updateFileFailedError');
        },
      });
  };

  @debounce(1000)
  onReferringPhysicianChanged(value: string): void {
    this.visitService.updateVisit$(this.visit.id, { referring_physician_name: value })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.showSavedPhysicianSuccessfullyMessage = true;
        this.visit.referringPhysicianName = value;
        setTimeout(() => this.showSavedPhysicianSuccessfullyMessage = false, 3000);
      });
  }

  private reloadData(): void {
    if (!this.visit) {
      return;
    }

    this.task = this.visit.getTaskByKey(VisitTaskKey.REFERRAL_DOCUMENT);

    if (!this.task?.documents) {
      return;
    }

    this.isCompleted = this.calculateIsCompleted();
    this.convertDocumentsToUploadedFile(this.task.documents);
  }

  private convertDocumentsToUploadedFile = (documents: Document[]): void => {
    this.uploadedFiles = documents.map((document: Document) => ({
      id: document.id,
      file: null,
      filename: document.filename,
      description: document.description,
    }));
  };

  private calculateIsCompleted(): boolean {
    return this.task.documents.length > 0 && !!this.visit.referringPhysicianName;
  }
}
