import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversation-status',
  templateUrl: './conversation-status.component.html',
  styleUrls: ['./conversation-status.component.scss'],
})
export class ConversationStatusComponent implements OnChanges {
  @Input() status: ConversationStatus;
  @Input() position: 'left' | 'right' | 'center' = 'left';
  @Input() size: 'normal' | 'large' = 'normal';

  refreshStatus: boolean = false;

  constructor(private readonly themeService: ThemeService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.refreshStatus = true;
      setTimeout(() => {
        this.refreshStatus = false;
      }, 0); // Short delay to ensure the DOM is updated
    }
  }

  getColor(): string {
    switch (this.status) {
      case ConversationStatus.PENDING:
        return this.themeService.currentTheme.colorScheme().statusPending;
      case ConversationStatus.REMOVED:
        return this.themeService.currentTheme.colorScheme().statusRemoved;
      case ConversationStatus.ONGOING:
        return this.themeService.currentTheme.colorScheme().statusOngoing;
      case ConversationStatus.FINISHED:
        return this.themeService.currentTheme.colorScheme().statusFinished;
      case ConversationStatus.EXPIRED:
        return this.themeService.currentTheme.colorScheme().statusExpired;
      default:
        return '';
    }
  }

  isFillableIcon(): boolean {
    switch (this.status) {
      case ConversationStatus.PENDING:
      case ConversationStatus.REMOVED:
      case ConversationStatus.FINISHED:
      case ConversationStatus.EXPIRED:
        return true;
      case ConversationStatus.ONGOING:
        return false;
    }
  }
}
