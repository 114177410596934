import { Component, Inject } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InstitutionService } from '@services/institution.service';
import { EXPORT_INSTITUTION_DATA_FORM_KEYS } from '@constants/form-keys/export-institution-data-form-keys.constant';
import { UntypedFormGroup } from '@angular/forms';
import { ExportInstitutionDataFormService } from '@services/form-services/export-institution-data-form.service';
import { triggerFormValidation } from '@utils/helpers/form.util';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';

export interface ExportInstitutionDataDialogData {
  from: Date;
  to: Date;
}

@Component({
  selector: 'vh-export-institution-data-dialog',
  templateUrl: './export-institution-data-dialog.component.html',
  styleUrls: ['./export-institution-data-dialog.component.scss'],
  animations: [fadeInOutAnimation],
})
@UntilDestroy()
export class ExportInstitutionDataDialogComponent {
  protected readonly EXPORT_INSTITUTION_DATA_FORM_KEYS: typeof EXPORT_INSTITUTION_DATA_FORM_KEYS = EXPORT_INSTITUTION_DATA_FORM_KEYS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  password: string | null = null;
  formGroup: UntypedFormGroup | null = null;
  errorMessage: string | null = null;
  currentUser: User;

  constructor(
    private readonly institutionService: InstitutionService,
    private readonly formService: ExportInstitutionDataFormService,
    private readonly dialogRef: MatDialogRef<ExportInstitutionDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ExportInstitutionDataDialogData,
    authenticationService: AuthenticationService
  ) {
    this.formGroup = this.formService.createFormGroup(data.from, data.to);
    this.currentUser = authenticationService.currentUser;
  }

  close(): void {
    this.dialogRef.close();
  }

  onConfirmClicked(): void {
    if (!this.formGroup?.valid) {
      triggerFormValidation(this.formGroup);

      return;
    }

    this.formGroup.disable();

    this.institutionService.exportData$(this.formGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (password: string) => {
          this.password = password;
          this.formGroup.enable();
        },
        error: () => {
          this.formGroup.enable();
          this.password = null;
          this.errorMessage = 'common.errors.generic';
        },
      });
  }
}
