<div class="container">
  <div class="conversation">
    <div class="header">
      <svg-icon class="logo" [src]="theme.fullLogo()" />
      <p class="info">{{'conversations.description' | translate}}</p>
    </div>

    <div *ngIf="isLoadingConversation" class="loader" [vhMarginTop]="32">
      <vh-loader></vh-loader>
    </div>

    <div *ngIf="!isLoadingConversation && !conversationChat && isConversationInactive" class="complete">
      {{ 'conversations.chatUnavailable' | translate }}
    </div>

    <div *ngIf="!isLoadingConversation && hasAcceptedPrivacyPolicy && conversationChat" class="messages" vhAutoScrollToBottom>
      <div *ngFor="let message of messages; let i = index; trackBy: trackByIndex"
        [ngClass]="message.role === 'user' ? 'text-user' : 'text-assistant'"
        [attr.key]="'m-' + i">

        <img
          *ngIf="message.content !== '' && message.role === 'assistant'"
          class="text-assistant"
          src="/assets/icons/assistant-text-icon.svg"
          alt="Assistant Icon" />

        <p *ngIf="message.role !== 'info' && message.content !== ''">
          {{ message.content }}
        </p>
      </div>

      <div
        *ngIf="(isWaitingForChatbotAnswer && (messages.length === 0 || messages[messages.length - 1]?.role !== 'assistant')) || messages[messages.length - 1]?.content === ''"
        class="text-assistant">
        <img class="text-icon" src="/assets/icons/assistant-text-icon.svg" alt="Loading Icon" />
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoadingConversation && !hasAcceptedPrivacyPolicy && conversationChat">
      <div class="privacy-policy">
        <div *ngIf="!showPrivacyPolicyText" class="request">
          <h1>{{ 'conversations.privacyPolicy.title' | translate }}</h1>
          <p>{{ 'conversations.privacyPolicy.line1' | translate }}</p>

          <div>
            <input type="checkbox" id="accept" />
            <label for="accept">
              {{ 'conversations.privacyPolicy.acceptanceLine.front' | translate }}
              <span class="link" (click)="showPrivacyPolicyText = true">{{ 'conversations.privacyPolicy.acceptanceLine.link' |
                translate }}</span>
              {{ 'conversations.privacyPolicy.acceptanceLine.back' | translate }}
            </label>
          </div>

          <vh-button (click)="acceptPolicy()" [label]="'conversations.privacyPolicy.continue' | translate"></vh-button>

          <vh-message *ngIf="showMustAcceptPrivacyPolicy" [vhMarginTop]="16" [vhTextColor]="'var(--color-alert)'"
                      [text]="'conversations.privacyPolicy.mustAccept' | translate">
          </vh-message>
        </div>
        <div *ngIf="showPrivacyPolicyText">
          <vh-back-button (buttonClick)="showPrivacyPolicyText = !showPrivacyPolicyText"></vh-back-button>
          <vh-privacy-statement-page></vh-privacy-statement-page>
          <vh-button (click)="acceptPolicy()" [label]="'conversations.privacyPolicy.accept' | translate"></vh-button>
        </div>
      </div>
    </div>

    <div *ngIf="hasAcceptedPrivacyPolicy && conversationChat">
      <div *ngIf="conversationChat?.activeWhatsapp" class="response">
        <span class="textarea bold">{{ 'conversations.activeWhatsapp' | translate }}</span>
      </div>

      <div *ngIf="!isConversationInactive && !hasError" class="response">
        <div>
          <span class="textarea" role="textbox" contenteditable (keydown.enter)="fetchAnswer()"
          (paste)="handlePaste($event)">
          </span>

          <vh-icon
            class="clickable align-end"
            src="assets/icons/send.svg"
            width="18"
            height="18"
            [stroke]="theme.colorScheme().secondary"
            (click)="fetchAnswer()">
          </vh-icon>
        </div>
      </div>

      <div *ngIf="isConversationInactive" class="response results">
        <p>{{'conversations.complete' | translate}}</p>
      </div>
      <div *ngIf="hasError" class="response has-text-error">
        <p>{{'conversations.error' | translate}}</p>
      </div>
    </div>
  </div>
</div>
