import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionService } from '@services/institution.service';
import { ToastService } from '@services/ui/toast.service';
import { InstitutionSettingsFormService } from '@services/form-services/institution-settings-form.service';
import { ButtonStyle } from '@enums/button-style.enum';
import { LanguageService } from '@services/language.service';
import { BaseSettingsPageComponent } from '@modules/settings/pages/base-settings-page/base-settings-page.component';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-super-admin-settings-page',
  templateUrl: './super-admin-settings-page.component.html',
  styleUrls: ['./super-admin-settings-page.component.scss'],
})
@UntilDestroy()
export class SuperAdminSettingsPageComponent extends BaseSettingsPageComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  constructor(
    authenticationService: AuthenticationService,
    institutionService: InstitutionService,
    toastService: ToastService,
    institutionSettingsFormService: InstitutionSettingsFormService,
    languageService: LanguageService,
    themeService: ThemeService
  ) {
    super(institutionService, toastService, authenticationService, institutionSettingsFormService, languageService, themeService);
  }

  resetForm(): void {
    this.formGroup = this.institutionSettingsFormService.createSuperAdminSettingsFormGroup(this.institution.settings);
  }
}
