<div class="header-controls-container gap-h-16"
  [ngClass]="{ 'not-clickable not-selectable disabled': isDisabled }">

  <div>
    <p *ngIf="calendarMode === 'day'" class="font-medium-19-23" [ngClass]="{ 'underline-fat': doesCalendarDisplayToday() }">
      {{ selectedDate | dfnsFormat : 'EEEE dd MMMM yyyy' }}
    </p>
    <p *ngIf="calendarMode === 'week'" class="font-medium-19-23" [ngClass]="{ 'underline-fat': doesCalendarDisplayToday() }">
      {{ selectedDate | dfnsFormat : 'MMMM yyyy' }}, {{ 'common.timeframes.singular.week' | translate | lowercase }} {{
      selectedDate | dfnsGetWeek }}
    </p>
    <p *ngIf="calendarMode === 'month'" class="font-medium-19-23" [ngClass]="{ 'underline-fat': doesCalendarDisplayToday() }">
      {{ selectedDate | dfnsFormat : 'MMMM yyyy' }}
    </p>
  </div>
  <div class="flex-row-c-c gap-h-16">
    <vh-icon
      class="clickable"
      vhAutoRefresh
      [isAutoRefreshEnabled]="isAutoRefreshEnabled"
      [matTooltip]="
        (isAutoRefreshEnabled
          ? 'admin.pages.dashboard.calendar.actions.disableAutoRefresh'
          : 'admin.pages.dashboard.calendar.actions.enableAutoRefresh'
        ) | translate
      "
      [src]="isAutoRefreshEnabled ? 'assets/icons/refresh-dot.svg' : 'assets/icons/refresh-off.svg'"
      [stroke]="theme.colorScheme().black"
      [width]="24"
      [height]="24"
      (click)="isAutoRefreshEnabled = !isAutoRefreshEnabled"
      (refreshRequested)="refreshRequested.emit('auto')">
    </vh-icon>
    <vh-icon
      class="clickable"
      [matTooltip]="'admin.pages.dashboard.calendar.actions.manualRefresh' | translate"
      src="assets/icons/reload.svg"
      [stroke]="theme.colorScheme().black"
      [width]="24"
      [height]="24"
      (click)="refreshRequested.emit('manual')">
    </vh-icon>
    <div class="vertical-divider"></div>
    <vh-icon
      class="clickable"
      src="assets/icons/export.svg"
      [matTooltip]="'admin.pages.dashboard.exportInstitutionData.tooltip' | translate"
      [width]="20"
      [height]="20"
      (click)="onExportClicked()">
    </vh-icon>
    <vh-icon
      [matTooltip]="
        (calendarMode === 'day'
          ? 'admin.pages.dashboard.visitsPrintout.printActionTooltip'
          : 'admin.pages.dashboard.visitsPrintout.printActionDisallowedTooltip'
        ) | translate
      "
      [ngClass]="{
        clickable: calendarMode === 'day' && !isLoading,
        'not-clickable': calendarMode !== 'day' || isLoading
      }"
      [src]="calendarMode === 'day' && !isLoading ? 'assets/icons/print.svg' : 'assets/icons/print-slash.svg'"
      [fill]="calendarMode === 'day' && !isLoading ? theme.colorScheme().black : theme.colorScheme().secondaryGrey"
      [width]="20"
      [height]="20"
      (click)="onPrintOverviewClicked()">
    </vh-icon>
    <div class="vertical-divider"></div>
    <vh-icon
      [matTooltip]="'admin.pages.dashboard.calendar.actions.zoomOut' | translate"
      [ngClass]="{
        clickable: calendarMode !== 'month' && zoomLevel !== minZoomLevel,
        'not-clickable': calendarMode === 'month' || zoomLevel === minZoomLevel
      }"
      src="assets/icons/search-minus.svg"
      [fill]="calendarMode !== 'month' && zoomLevel !== minZoomLevel ? theme.colorScheme().black : theme.colorScheme().secondaryGrey"
      (click)="updateZoomLevel(-zoomStepSize)">
    </vh-icon>
    <vh-icon
      [matTooltip]="'admin.pages.dashboard.calendar.actions.resetZoom' | translate"
      [ngClass]="{
        clickable: calendarMode !== 'month' && zoomLevel !== 0,
        'not-clickable': calendarMode === 'month' || zoomLevel === 0
      }"
      src="assets/icons/search-reset.svg"
      [fill]="calendarMode !== 'month' && zoomLevel !== 0 ? theme.colorScheme().black : theme.colorScheme().secondaryGrey"
      (click)="resetZoomLevel()">
    </vh-icon>
    <vh-icon
      [matTooltip]="'admin.pages.dashboard.calendar.actions.zoomIn' | translate"
      [ngClass]="{
        clickable: calendarMode !== 'month' && zoomLevel + zoomStepSize < maxZoomLevel,
        'not-clickable': calendarMode === 'month' || zoomLevel + zoomStepSize >= maxZoomLevel
      }"
      src="assets/icons/search-plus.svg"
      [fill]="calendarMode !== 'month' && zoomLevel + zoomStepSize < maxZoomLevel ? theme.colorScheme().black : theme.colorScheme().secondaryGrey"
      (click)="updateZoomLevel(zoomStepSize)">
    </vh-icon>
  </div>
  <div class="vertical-divider"></div>
  <vh-basic-events-calendar-controls
    [calendarMode]="calendarMode"
    [selectedDate]="selectedDate"
    (selectedDateChange)="selectedDateChange.emit($event)"
    (calendarModeChange)="calendarModeChange.emit($event)">
  </vh-basic-events-calendar-controls>
</div>
