import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { WalletService } from '@services/wallet.service';
import { BaseNavigationProfileComponent } from '@modules/account/components/base-navigation-profile/base-navigation-profile.component';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-navigation-profile',
  templateUrl: './navigation-profile.component.html',
  styleUrls: ['./navigation-profile.component.scss'],
})
@UntilDestroy()
export class NavigationProfileComponent extends BaseNavigationProfileComponent implements OnInit {
  @Input() expanded: boolean;

  protected detailsOpen: boolean = false;
  protected scrollStrategy: RepositionScrollStrategy;

  constructor(
    private readonly localStorageCacheService: LocalStorageCacheService,
    themeService: ThemeService,
    overlay: Overlay,
    authenticationService: AuthenticationService,
    walletService: WalletService
  ) {
    super(authenticationService, walletService, themeService);

    this.scrollStrategy = overlay.scrollStrategies.reposition();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.localStorageCacheService.observe(LOCAL_STORAGE_KEYS.navigationMenuExpanded)
      .pipe(untilDestroyed(this))
      .subscribe((isExpanded: boolean) => {
        this.expanded = isExpanded;
      });

    this.expanded = this.localStorageCacheService.get(LOCAL_STORAGE_KEYS.navigationMenuExpanded) || false;
  }
}
