import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Conversation } from '@models/conversation.model';
import { ConversationService } from '@services/conversation.service';
import { IObject } from '@app-types/iobject.type';

export interface TranscriptDialogData {
  conversation: Conversation;
}

@Component({
  selector: 'vh-transcript-dialog',
  templateUrl: './transcript-dialog.component.html',
  styleUrls: ['./transcript-dialog.component.scss'],
})
@UntilDestroy()
export class TranscriptDialogComponent implements OnInit {
  messages = [];
  conversation: Conversation;
  loading: boolean = true;

  constructor(
    protected readonly dialog: MatDialogRef<TranscriptDialogComponent>,
    protected readonly conversationService: ConversationService,
    @Inject(MAT_DIALOG_DATA) public data: TranscriptDialogData
  ) {
    this.conversation = data.conversation;
  }

  ngOnInit(): void {
    this.conversationService.getHumanReadableTranscript$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe((transcript: IObject[]) => {
        this.messages = transcript;
        this.loading = false;
      });
  }
}
