import { TranslateService } from '@ngx-translate/core';
import { CARE_PROGRAM_STEP_FORM_KEYS } from '@constants/form-keys/care-program-form-keys.constant';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormArray } from '@angular/forms';
import { ButtonStyle } from '@enums/button-style.enum';
import { Option } from '@interfaces/option.interface';
import { EventEmitter, Output } from '@angular/core';
import { getTimeUnitsAsOptions } from '@utils/helpers/date.util';
import { ServiceService } from '@services/service.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElementRef, ViewChild } from '@angular/core';
import { CareProgramFormService } from '@services/form-services/care-program-form.service';
import { IObject } from '@app-types/iobject.type';
import { debounceTime, Subject } from 'rxjs';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-care-program-step-settings-card',
  templateUrl: './care-program-step-card.component.html',
  styleUrl: './care-program-step-card.component.scss',
})
@UntilDestroy()
export class CareProgramStepCardComponent implements OnInit {
  protected readonly CARE_PROGRAM_STEP_FORM_KEYS: typeof CARE_PROGRAM_STEP_FORM_KEYS = CARE_PROGRAM_STEP_FORM_KEYS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  timeUnits: Option[] = getTimeUnitsAsOptions();
  serviceOptions: Option[];

  protected theme: Theme;

  private timeToLeadingSubject: Subject<number> = new Subject<number>();

  @Input() formGroup: UntypedFormGroup;
  @Input() isReadOnly: boolean;

  @Output() leadingChanged: EventEmitter<string> = new EventEmitter();
  @Output() removeStep: EventEmitter<string> = new EventEmitter();
  @Output() timeToLeadingChanged: EventEmitter<IObject> = new EventEmitter();

  get services(): FormArray {
    return this.formGroup.controls.services as FormArray;
  }

  @ViewChild('serviceSelector') serviceSelector: ElementRef<HTMLInputElement>;

  constructor(
    private readonly translate: TranslateService,
    private readonly serviceService: ServiceService,
    private readonly careProgramFormService: CareProgramFormService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.serviceService.getServiceOptionsForCurrentInstitution$().pipe(untilDestroyed(this)).subscribe((serviceOptions: Option[]) => {
      this.serviceOptions = serviceOptions;
    });
    this.timeToLeadingSubject.pipe(debounceTime(1000), untilDestroyed(this)).subscribe((time: number) => {
      this.onTimeToLeadingChanged();
    });
  }

  onTimeToLeadingInput(): void {
    if (!isNaN(this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')).value) && this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')).value !== null) {
      this.timeToLeadingSubject.next(this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')).value);
    }
  }

  findService(service: Option): string {
    return `${this.serviceOptions.findIndex((serviceOption: Option) => serviceOption.value === service.value)}`;
  }

  timeToLeadingWithUnit(): string {
    const time = this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')).value;
    const unit = this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingUnit')).value;

    return `${time} ${this.translate.instant(this.timeUnits.find((timeUnit: Option) => timeUnit.value === unit).label)}`;
  }

  onTextBoxClicked($event: Event): void {
    $event.stopPropagation();
  }

  onLeadingChanged(): void {
    this.leadingChanged.emit(this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('id')).value);
  }

  onTimeToLeadingChanged(): void {
    const id = this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('id')).value;
    const time = this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')).value;
    const unit = this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingUnit')).value;
    this.timeToLeadingChanged.emit({ id, time, unit });
  }

  onRemoveStepClicked(): void {
    this.removeStep.emit(this.formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('id')).value);
  }

  onAddServiceClicked(): void {
    this.services.push(this.careProgramFormService.createService());
  }

  onRemoveServiceClicked(service: UntypedFormGroup): void {
    this.services.removeAt(this.services.controls.indexOf(service));
  }
}
