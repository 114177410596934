import { Component, Input, TemplateRef } from '@angular/core';
import { PaymentMethod } from '@models/payment-method.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-payment-method-card',
  templateUrl: './payment-method-card.component.html',
  styleUrls: ['./payment-method-card.component.scss'],
})
export class PaymentMethodCardComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  protected theme: Theme;

  @Input() paymentMethod: PaymentMethod;
  @Input() menuTemplate: TemplateRef<{ paymentMethod: PaymentMethod; }>;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }
}
