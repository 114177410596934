import { Injectable } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private readonly translateService: TranslateService) {}

  getTitleOptions$(): Observable<Option[]> {
    return combineLatest([
      this.translateService.get('pages.register.titleInputOptions.dr'),
      this.translateService.get('pages.register.titleInputOptions.ms'),
      this.translateService.get('pages.register.titleInputOptions.mr'),
      this.translateService.get('pages.register.titleInputOptions.mx'),
    ]).pipe(map(([dr, ms, mr, mx]: [string, string, string, string]) => {
      return [
        {
          label: '',
          value: 'NONE',
        },
        {
          label: dr,
          value: 'DR',
        },
        {
          label: ms,
          value: 'MS',
        },
        {
          label: mr,
          value: 'MR',
        },
        {
          label: mx,
          value: 'MX',
        },
      ];
    }));
  }
}
