import { Directive } from '@angular/core';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Directive()
export abstract class BasePaymentStatusPageComponent {
  protected theme: Theme;

  constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly router: Router,
    protected readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  onFinishClicked(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}
