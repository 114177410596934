import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { Router } from '@angular/router';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { NAVIGATION } from '@constants/navigation.constant';
import { LanguageService } from '@services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@environments/environment';
import { DOCTOR_FORM_KEYS } from '@constants/form-keys/doctor-form-keys.constant';
import { NewOnboardingFormService } from '@services/form-services/new-onboarding-form.service';
import { Option } from '@interfaces/option.interface';
import { NewOnboardingService } from '@services/new-onboarding.service';
import { map, Observable, tap } from 'rxjs';
import { Specialty } from '@models/specialty.model';
import { SpecialtyService } from '@services/specialty.service';
import { ThemeService } from '@services/theming/theme.service';
import { IObject } from '@app-types/iobject.type';

@Component({
  selector: 'vh-onboarding-credentials-page',
  templateUrl: './onboarding-credentials-page.component.html',
  styleUrls: ['./onboarding-credentials-page.component.scss'],
})
@UntilDestroy()
export class OnboardingCredentialsPageComponent extends BaseOnboardingPageComponent implements OnInit {
  protected readonly REGISTER_FORM_KEYS: typeof REGISTER_FORM_KEYS = REGISTER_FORM_KEYS;
  protected readonly DOCTOR_FORM_KEYS: typeof DOCTOR_FORM_KEYS = DOCTOR_FORM_KEYS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  termsAndConditionsLink: string = environment.links.termsAndConditions;
  formGroup: UntypedFormGroup;
  isSubmitting: boolean = false;
  languageOptions: Option[];
  specialityOptions: Option[] = [];
  onboardingPayload: IObject;

  constructor(
    private readonly newOnboardingFormService: NewOnboardingFormService,
    private readonly specialtyService: SpecialtyService,
    private readonly languageService: LanguageService,
    private readonly newOnboardingService: NewOnboardingService,
    router: Router,
    themeService: ThemeService
  ) {
    super(router, themeService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.onboardingPayload = this.newOnboardingService.getOnboardingPayload();
    this.formGroup = this.newOnboardingFormService.createFormGroupForOnboardingAboutYourself(null, this.onboardingPayload?.first_name, this.onboardingPayload?.last_name, this.onboardingPayload?.language, this.onboardingPayload?.specialty_id);

    this.languageService.getPlatformLanguagesAsOptions$().subscribe((options: Option[]) => this.languageOptions = options);

    this.languageService.languageSetSuccessfully$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.refreshSpecialties();
      });
  }

  setStep(): void {
    this.state.goToStep(this.STEP_CREDENTIALS);
  }

  onNextClicked(): void {
    this.isSubmitting = true;

    this.newOnboardingService.setOnboardingPayload({
      ...this.formGroup.value,
      ...this.onboardingPayload,
      language: this.languageService.getPreferredLanguage(),
      specialty_id: this.formGroup.value.specialty_id,
    });

    void this.router.navigate([ONBOARDING_NAV.privacy.route]);

    this.isSubmitting = false;
  }

  private refreshSpecialties(): void {
    this.getSpecialtiesSuggestions$().subscribe();
  }

  getSpecialtiesSuggestions$ = (query?: string): Observable<Option[]> => {
    query = query?.trim().toLowerCase();

    return this.specialtyService.getSpecialties$()
      .pipe(
        map((specialties: Specialty[]) =>
          specialties
            .filter((specialty: Specialty) =>
              !query || specialty.name.toLowerCase().includes(query)
            )
            .map((specialty: Specialty) => ({
              label: specialty.name,
              value: specialty.id,
            }))
        ),
        tap((options: Option[]) => this.specialityOptions = options)
      );
  };
}
