<div class="calendar-container-print">
  <img class="vesalius-logo" [src]="theme.fullLogo()" />

  <ng-template *ngIf="state === 'empty'" [ngTemplateOutlet]="emptyTemplate"></ng-template>
  <ng-template *ngIf="state === 'list'" [ngTemplateOutlet]="listTemplate"></ng-template>
</div>

<ng-template #emptyTemplate>
  <p [vhMarginTop]="8" class="font-medium-16-19">{{ 'admin.pages.dashboard.visitsPrintout.noVisitsFound' | translate }}</p>
</ng-template>

<ng-template #listTemplate>
  <ng-container *ngFor="let room of rooms">
    <h2 [vhMarginTop]="8">{{ room.name }}: {{ selectedDate | dfnsFormat : DATE_FORMATS.clientDate }}</h2>

    <p class="font-medium-16-19" [vhMarginTop]="8" *ngIf="!visitsPerRoom.get(room.id)">
      {{ 'admin.pages.dashboard.visitsPrintout.noVisitsFound' | translate }}
    </p>

    <table *ngIf="visitsPerRoom.get(room.id)" [vhMarginTop]="8" class="page-break">
      <thead>
        <tr>
          <th>{{ 'admin.pages.dashboard.visitsPrintout.titleTime' | translate }}</th>
          <th>{{ 'admin.pages.dashboard.visitsPrintout.titlePatientName' | translate }}</th>
          <th>{{ 'admin.pages.dashboard.visitsPrintout.titlePatientBirthdate' | translate }}</th>
          <th>{{ 'admin.pages.dashboard.visitsPrintout.titlePhone' | translate }}</th>
          <th>{{ 'admin.pages.dashboard.visitsPrintout.titleService' | translate }}</th>
          <th>{{ 'admin.pages.dashboard.visitsPrintout.titleComments' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let visit of visitsPerRoom.get(room.id)">
          <td>
            {{
              'admin.pages.dashboard.visitsPrintout.durationFormat'
                | translate
                  : {
                      start: visit.start | dfnsFormat : DATE_FORMATS.clientTimeShort,
                      duration: visit.end | dfnsDifferenceInMinutes : visit.start
                    }
            }}
          </td>
          <td>{{ visit?.patient?.fullName }}</td>
          <td>{{ visit?.patient?.birthdate | dfnsFormat : DATE_FORMATS.clientDate }}</td>
          <td>{{ visit?.patient?.phone ? visit?.patient?.phone : '/' }}</td>
          <td>{{ visit?.service?.displayFullName }}</td>
          <td>{{ visit?.internalComment }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-template>
