<vh-centered-card-page>
  <ng-container *ngIf="currentRoute === NAVIGATION.login.path" [ngTemplateOutlet]="loginTemplate"></ng-container>
  <ng-container *ngIf="currentRoute === NAVIGATION.register.path" [ngTemplateOutlet]="registerTemplate"></ng-container>
</vh-centered-card-page>


<ng-template #loginTemplate>
  <vh-login
    [afterSuccessfulLoginFunction]="navigateToRedirectOrDashboardPage">
  </vh-login>
  <vh-horizontal-divider [vhMarginTop]="32"></vh-horizontal-divider>
  <p [vhMarginTop]="32" class="align-center">{{ 'pages.login.firstTimeHere' | translate }}</p>
  <div
    [class.flex-row-c-c]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthLarge]"
    [class.gap-h-32]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthLarge]"
    [class.gap-v-16]="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthLarge]"
    [class.flex-col-c-c]="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthLarge]"
    [vhMarginTop]="32">
    <vh-button
      buttonClass="wrap-text"
      [routerLink]="ONBOARDING_NAV.credentials.route"
      [label]="'pages.login.registerAsHealthProfessional' | translate"
      [style]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium] ? ButtonStyle.QUATERNARY : ButtonStyle.TERTIARY">
    </vh-button>
    <vh-button
      buttonClass="wrap-text"
      [routerLink]="NAVIGATION.register.route"
      [label]="'pages.login.registerAsPatient' | translate"
      [style]="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium] ? ButtonStyle.QUATERNARY : ButtonStyle.TERTIARY">
    </vh-button>
  </div>
</ng-template>

<ng-template #registerTemplate>
  <vh-register
    [loginButtonIsVisible]="true"
    [autoLogin]="true"
    [afterSuccessfulRegisterFunction]="navigateToLoginPage">
  </vh-register>
</ng-template>