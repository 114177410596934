<ng-container *ngIf="currentPage === 'usersOverview'" [ngTemplateOutlet]="overviewTemplate"></ng-container>
<ng-container *ngIf="currentPage === 'userCreate'" [ngTemplateOutlet]="createTemplate"></ng-container>

<ng-template #overviewTemplate>
  <vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
    <div class="gap-v-16 flex-col">
      <div class="flex-row-l-c gap-h-16">
        <vh-search-input
          class="search-input"
          [placeholder]="'admin.pages.settings.administrators.searchNameOrEmail' | translate"
          [value]="searchValue"
          (inputValueChange)="searchValue = $event; updateDisplayedUsers()">
        </vh-search-input>
        <vh-multi-select-input
          class="roles-input"
          [placeholder]="'common.fields.roles' | translate"
          [options]="options"
          [hasSelectAll]="true"
          [selectedOptions]="selectedRoleOptions"
          (selectedOptionsChange)="onRolesFilterChanged($event)">
        </vh-multi-select-input>
        <vh-button
          class="align-end"
          iconLeftPath="assets/icons/plus.svg"
          [iconLeftFill]="theme.colorScheme().white"
          [label]="'admin.pages.settings.administrators.addAdministrator' | translate"
          (buttonClick)="changePage('userCreate')"></vh-button>
      </div>

      <ng-container *ngIf="!isLoadingOverview">
        <table @fadeInOutAnimation>
          <thead>
            <tr>
              <th>{{ 'admin.pages.settings.administrators.overview.titleUser' | translate }}</th>
              <th>{{ 'admin.pages.settings.administrators.overview.titleUsername' | translate }}</th>
              <th>{{ 'admin.pages.settings.administrators.overview.titleRizivNumber' | translate }}</th>
              <th>{{ 'admin.pages.settings.administrators.overview.titleRoles' | translate }}</th>
              <th>{{ 'admin.pages.settings.administrators.overview.titleOrganisationPosition' | translate }}</th>
              <th>{{ 'admin.pages.settings.administrators.overview.titleLastActive' | translate }}</th>
              <th>{{ 'admin.pages.settings.administrators.overview.titleActions' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of displayedUsers"
              class="clickable hover-highlight"
              (click)="changePage('userCreate', user)"
              [ngClass]="{ 'not-clickable': !canViewUserDetails(user) }">
              <td class="flex-row-l-c gap-h-8">
                <div class="flex-col-c-c profile-avatar">
                  <span>{{ user.initials }}</span>
                </div>
                <div>
                  <p class="font-medium-16-19">{{ user.fullName }}</p>
                  <p>{{ user.email }}</p>
                </div>
              </td>
              <td>
                <p>{{ user.username }}</p>
              </td>
              <td>
                <p>{{ user.riziv ?? '/' }}</p>
              </td>
              <td>
                <p *ngFor="let role of user.roles | vhSortRoles">
                  {{ 'roles.' + role + '.displayName' | translate }}
                </p>
              </td>
              <td>
                <p>{{ (user.organisationPosition ? 'organisationPositions.' + user.organisationPosition + '.displayName' :  '/') | translate }}</p>
              </td>
              <td>
                <p *ngIf="user.lastActive">{{ user.lastActive | dfnsFormatDistanceToNow : { addSuffix: true } }}</p>
                <p *ngIf="!user.lastActive">{{ 'admin.pages.settings.administrators.overview.labelNeverActive' | translate }}</p>
              </td>
              <td>
                <div class="flex-row-se-c">
                  <vh-icon
                    *ngIf="canLinkRizivNumber(user)"
                    [src]="'assets/icons/link.svg'"
                    [fill]="theme.colorScheme().black"
                    [height]="18"
                    [hoverFill]="theme.colorScheme().primary"
                    (click)="onLinkRizivNumberClicked($event, user)"
                    [matTooltip]="'admin.pages.settings.administrators.linkRizivNumberTooltip' | translate"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="500"
                    [ariaLabel]="'admin.pages.settings.administrators.linkRizivNumberTooltip' | translate"></vh-icon>
                  <vh-icon
                    *ngIf="user.riziv"
                    [src]="'assets/icons/unlink.svg'"
                    [fill]="theme.colorScheme().black"
                    [height]="18"
                    [hoverFill]="theme.colorScheme().error"
                    (click)="onUnlinkRizivNumberClicked($event, user)"
                    [matTooltip]="'admin.pages.settings.administrators.unLinkRizivNumberTooltip' | translate"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="500"
                    [ariaLabel]="'admin.pages.settings.administrators.unLinkRizivNumberTooltip' | translate"></vh-icon>
                  <vh-icon
                    *ngIf="canSendPasswordResetEmail(user)"
                    [src]="'assets/icons/envelope-redo.svg'"
                    [fill]="theme.colorScheme().black"
                    [height]="18"
                    [hoverFill]="theme.colorScheme().primary"
                    (click)="onSendPasswordResetEmailClicked($event, user)"
                    [matTooltip]="'admin.pages.settings.administrators.sendPasswordResetTooltip' | translate"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="500"
                    [ariaLabel]="'admin.pages.settings.administrators.sendPasswordResetTooltip' | translate"></vh-icon>
                  <vh-icon
                    *ngIf="canRemoveUser(user)"
                    [src]="'assets/icons/trash-alt.svg'"
                    [fill]="theme.colorScheme().black"
                    [height]="18"
                    [hoverFill]="theme.colorScheme().error"
                    (click)="onRemoveUserClicked($event, user)"
                    [ariaLabel]="'common.remove' | translate"
                    [matTooltip]="'common.remove' | translate"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="500">
                  </vh-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="isLoadingOverview" [ngTemplateOutlet]="skeleton"></ng-container>
    </div>
  </vh-content-layout>
</ng-template>

<ng-template #createTemplate>
  <vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
    <vh-user-settings [navigateTo]="changePage" [addUser]="addUser" [selectedUser]="selectedUser"></vh-user-settings>
  </vh-content-layout>
</ng-template>

<ng-template #skeleton>
  <vh-skeleton-table [amountOfColumns]="4" [amountOfRows]="10"></vh-skeleton-table>
</ng-template>
