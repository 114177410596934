<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <vh-button-group
    [items]="cancelActionButtonOptions"
    [singleItemSelectable]="true"
    [shouldWrap]="true"
    [style]="ButtonGroupStyle.CONNECTED"
    (itemClick)="onCancelActionSelected($event)">
  </vh-button-group>

  <p *ngIf="visits?.length === 0 && !isLoadingVisits" [vhMarginTop]="16" class="font-medium-16-19 has-text-tertiary">
    {{ 'common.noResults' | translate }}
  </p>

  <div class="flex-row flex-wrap gap-v-16">
    <div *ngFor="let visit of visits" class="flex-col card" [vhMarginTop]="16">
      <div class="flex-row-r-c gap-h-8 align-end">
        <vh-icon
          class="clickable"
          src="assets/icons/eye.svg"
          [matTooltip]="'admin.pages.workLists.rescheduling.seeDetails' | translate"
          [hoverStroke]="theme.colorScheme().black"
          [stroke]="theme.colorScheme().secondaryGrey"
          (click)="onDetailsIconClick(visit)">
        </vh-icon>
        <vh-icon
          class="clickable"
          src="assets/icons/trash-alt.svg"
          [matTooltip]="'common.delete' | translate"
          [fill]="theme.colorScheme().secondaryGrey"
          [hoverFill]="theme.colorScheme().error"
          (click)="onDeleteClicked(visit)">
        </vh-icon>
      </div>

      <div [vhMarginTop]="8" class="visit-properties-list">
        <p>{{ 'admin.pages.dashboard.visitDialog.labelName' | translate }}</p>
        <div class="flex-row-l-c gap-h-8">
          <p *ngIf="visit.patient?.fullName">{{ visit.patient?.fullName }}</p>
          <p *ngIf="!visit.patient?.fullName">
            <i>{{ 'common.errors.noPatientName' | translate }}</i>
          </p>
          <vh-icon [src]="visit.patient?.genderIcon" width="16" height="16" [fill]="theme.colorScheme().primary"></vh-icon>
        </div>

        <p>{{ 'admin.pages.dashboard.visitDialog.labelPhone' | translate }}</p>
        <p *ngIf="visit.patient?.phone">
          <a [href]="visit.patient?.phoneHref">{{ visit.patient?.phone }}</a>
        </p>
        <p *ngIf="!visit.patient?.phone">/</p>

        <p>{{ 'admin.pages.dashboard.visitDialog.labelDate' | translate }}</p>
        <p>{{ visit.start | dfnsFormat : 'dd/MM/yyyy HH:mm' }}</p>

        <p>{{ 'admin.pages.dashboard.visitDialog.labelExamination' | translate }}</p>
        <p>{{ visit.service?.name }}</p>

        <p>{{ 'admin.pages.dashboard.cancelVisitDialog.actionLabel' | translate }}</p>
        <vh-select-input
          #actionSelectInput
          [options]="cancelActionOptions"
          [value]="visit.cancellationAction?.id">
        </vh-select-input>
      </div>
      <div class="flex-row gap-h-8 align-end" [vhMarginTop]="16">
        <!-- TODO: temporarily hidden until this feature fully works -->
        <vh-button
          *ngIf="false"
          [label]="'Herplannen' | translate"
          (buttonClick)="onRescheduleClicked(visit)">
        </vh-button>
        <vh-button
          [label]="'common.save' | translate"
          [isDisabled]="actionSelectInput.value === visit.cancellationAction?.id"
          (buttonClick)="onSaveClicked(visit, actionSelectInput.value)">
        </vh-button>
      </div>
    </div>
  </div>

</vh-content-layout>
