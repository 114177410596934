import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemeService } from '@services/theming/theme.service';

type Status = 'success' | 'error';

@Component({
  selector: 'vh-post-action-status',
  templateUrl: './post-action-status.component.html',
  styleUrls: ['./post-action-status.component.scss'],
})
export class PostActionStatusComponent implements OnInit, OnChanges {
  @Input() status: Status = 'success';
  @Input() title: string;
  @Input() message: string;
  @Input() actionText: string;
  @Input() isStroke: boolean = false;

  @Output() actionClick: EventEmitter<void> = new EventEmitter<void>();

  protected color: string;
  protected icon: string;

  constructor(private readonly themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.color = this.convertStatusToColor(this.status);
    this.icon = this.convertStatusToIcon(this.status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color && !changes.color.isFirstChange()) {
      this.color = this.convertStatusToColor(this.status);
      this.icon = this.convertStatusToIcon(this.status);
    }
  }

  private convertStatusToIcon(status: Status): string {
    switch (status) {
      case 'success':
        return 'assets/icons/circle-check.svg';
      case 'error':
        return 'assets/icons/circle-x.svg';
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }

  private convertStatusToColor(status: Status): string {
    switch (status) {
      case 'success':
        return this.themeService.currentTheme.colorScheme().success;
      case 'error':
        return this.themeService.currentTheme.colorScheme().error;
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  }
}

