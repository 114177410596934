import { ColorScheme, MetaConfig, Theme } from '@themes/theme.interface';
import { AbstractTheme } from '@themes/abstract.theme';

export class OrthoChatTheme extends AbstractTheme implements Theme {
  name(): string {
    return 'orthochat';
  }

  displayName(): string {
    return 'Orthochat';
  }

  metaConfig(): MetaConfig {
    const assetsFolder = this.getAssetsFolder();

    return {
      appleTouchIcon180x180: `assets/themes/orthochat/favicon/${assetsFolder}/apple-touch-icon.png`,
      colorScheme: 'light',
      favicon16x16: `assets/themes/orthochat/favicon/${assetsFolder}/favicon-16x16.png`,
      favicon32x32: `assets/themes/orthochat/favicon/${assetsFolder}/favicon-32x32.png`,
      manifest: `assets/themes/orthochat/favicon/${assetsFolder}/site.webmanifest`,
      msTileColor: this.colorScheme().primary,
      safariPinnedTabIcon: `assets/themes/orthochat/favicon/${assetsFolder}/safari-pinned-tab.svg`,
      safariPinnedTabIconColor: this.colorScheme().primary,
      themeColor: this.colorScheme().primary,
    };
  }

  colorScheme(): ColorScheme {
    return {
      // White
      white: '#ffffff',

      // Black
      black: '#2A3A51',

      // Colors
      primary: '#20BBC0',
      secondaryGreen: '#0B759F',

      accent: '#FF3366',

      // Greys
      border: '#D7DADE',

      backgroundGrey: '#F9FAFB',
      secondaryGrey: '#a8a8a8',

      inputPlaceholder: '#949CB1',

      // Status
      statusRemoved: '#de3c4b',
      statusPending: '#fe7f2d',
      statusOngoing: '#ffaf2d',
      statusFinished: '#80c665',
      statusExpired: '#f6a6ae',

      // Alerts
      alert: '#f46161',
      success: '#a1e887',
      warning: '#ffa739',
      error: '#de3c4b',

      // Varia
      googleMapsUserLocation: '#5384ED',
    };
  }

  logo(): string {
    return `/assets/themes/orthochat/logo/${this.getAssetsFolder()}/orthochat-logo.svg`;
  }

  logoText(): string {
    return `/assets/themes/orthochat/logo/${this.getAssetsFolder()}/orthochat-logo-text.svg`;
  }

  fullLogo(): string {
    return `/assets/themes/orthochat/logo/${this.getAssetsFolder()}/orthochat-logo-with-text.svg`;
  }
}
