import { Injectable } from '@angular/core';
import { Theme } from '@themes/theme.interface';
import { VesaliusTheme } from '@themes/vesalius.theme';
import { FaviconService } from '@services/theming/favicon.service';
import { OrthoChatTheme } from '@themes/ortho-chat.theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _currentTheme: Theme;

  constructor(private readonly faviconService: FaviconService) {
  }

  get currentTheme(): Theme {
    return this._currentTheme;
  }

  bootstrap(): void {
    this._currentTheme = this.determineThemeByCurrentDomain();
    this.applyTheme(this._currentTheme);
  }

  protected applyTheme(theme: Theme): void {
    this.applyCss(theme);
    this.faviconService.applyFromConfig(theme.metaConfig());
  }

  protected determineThemeByCurrentDomain(): Theme {
    if (window.location.hostname.includes('vesalius')) {
      return new VesaliusTheme();
    } else if (window.location.hostname.includes('orthochat')) {
      return new OrthoChatTheme();
    }

    return new VesaliusTheme();
  }

  protected applyCss(theme: Theme): void {
    document.body.setAttribute('data-theme', theme.name());
  }
}
