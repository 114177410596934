import { Injectable } from '@angular/core';
import { FlatSummary, InterpretedSummary, SummarySection, SummaryVersion2, ValueObject } from '@interfaces/summary.interface';

@Injectable({
  providedIn: 'root',
})
export class SummaryInterpreterService {
  interpretSummary(summaryJson: any): { interpretedSummary: InterpretedSummary; flatSummary: string; } {

    const parsedSummary = typeof summaryJson === 'string' ? JSON.parse(summaryJson) : summaryJson;

    const type = parsedSummary.type;
    const version = parsedSummary.version;

    switch (type) {
      case 'vesalius-summary':
        switch (version) {
          case 'v2.0.0':
            return this.handleVersion2(parsedSummary);
          default:
            return this.handleVersion1(parsedSummary);
        }
      default:
        switch (version) {
          case 'v2.0.0':
            return this.handleVersion2(parsedSummary);
          default:
            return this.handleVersion1(parsedSummary);
        }
    }
  }

  private handleVersion1(summaryJson: FlatSummary): { interpretedSummary: InterpretedSummary; flatSummary: string; } {
    const interpretedSummary: InterpretedSummary = Object.entries(summaryJson).map(
      ([key, values]: [string, Record<string, string>]): SummarySection => {
        const valueObjects: ValueObject[] = Object.entries(values).map(
          ([valueKey, value]: [string, string]): ValueObject => ({
            label: valueKey,
            value: value,
          })
        );

        return {
          key,
          label: key,
          values: valueObjects,
        };
      }
    );

    const flatSummary = this.formatFlatSummary(summaryJson);

    return { interpretedSummary, flatSummary };
  }

  private handleVersion2(summaryJson: SummaryVersion2): { interpretedSummary: InterpretedSummary; flatSummary: string; } {
    const interpretedSummary: InterpretedSummary = summaryJson.content.map(
      (section: SummarySection): SummarySection => ({
        key: section.key,
        label: section.label,
        score: section.score,
        values: section.values.map(
          (valueObject: ValueObject): ValueObject => ({
            key: valueObject.key,
            label: valueObject.label,
            value: valueObject.value,
            score: valueObject.score,
          })
        ),
      })
    );

    const flatSummary = this.formatFlatSummary(this.structureFlatSummary(summaryJson.content));

    return { interpretedSummary, flatSummary };
  }

  /**
   * Generates a flat summary and provides a formatted string representation of the summary.
   */
  private structureFlatSummary(content: SummarySection[]): FlatSummary {
    const flatSummary: Record<string, Record<string, string>> = {};

    for (const section of content) {
      const sectionLabel = section.label;
      flatSummary[sectionLabel] = {};

      for (const valueObject of section.values) {
        const label = valueObject.label;
        const value = String(valueObject.value);
        const score = valueObject.score;

        // Append score to value if present
        flatSummary[sectionLabel][label] = score ? `${value} - ${score}` : value;
      }
    }

    return flatSummary;
  }

  /**
   * Formats a flat summary into a human-readable string representation.
   */
  formatFlatSummary(flatSummary: FlatSummary): string {
    let result = '';
    for (const [section, values] of Object.entries(flatSummary)) {
      result += `${section}:\n`;
      for (const [key, value] of Object.entries(values)) {
        result += `  ${key}: ${value}\n`;
      }
    }

    return result.trim();
  }
}
