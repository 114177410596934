import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * This page is exactly the same as the @CenteredCardPageComponent, but it uses the router-outlet to display the content.
 */
@Component({
  selector: 'vh-centered-card-with-router-page',
  templateUrl: './centered-card-with-router.page.html',
})
export class CenteredCardWithRouterPageComponent {
  @Input() showBackButton: boolean = false;
  @Input() showLanguagePicker: boolean = true;

  @Output() backButtonClick: EventEmitter<void> = new EventEmitter<void>();
}
