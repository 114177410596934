import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PopoverService } from '@services/ui/popover.service';
import { ErrorMessageService } from '@services/error-message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { ConversationQuestionListService } from '@services/conversation-question-list.service';
import { ConversationQuestionList } from '@models/conversation-question-list.model';
import { Subscription } from 'rxjs';
import { MultiSelectInputComponent } from '@modules/shared/form/components/multi-select-input/multi-select-input.component';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversation-question-lists-select-input',
  templateUrl: '../../../shared/form/components/multi-select-input/multi-select-input.component.html',
  styleUrls: [
    '../../../shared/form/components/base-select-input/base-select-input.component.scss',
    '../../../shared/form/components/multi-select-input/multi-select-input.component.scss',
    '../../../shared/form/components/select-input/select-input.component.scss',
    '../../../shared/form/components/base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConversationQuestionListsSelectInputComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ConversationQuestionListsSelectInputComponent extends MultiSelectInputComponent implements OnInit, OnChanges {

  @Input() specialtyId: string | null = null;
  @Input() shouldAutomaticallyLoadStandardQuestions: boolean = true;

  @Output() optionsLoaded: EventEmitter<Option[]> = new EventEmitter<Option[]>();

  fetchSubscription: Subscription | null = null;

  constructor(
  @Optional() controlContainer: ControlContainer,
    @Optional() translateService: TranslateService,
    popoverService: PopoverService,
    errorMessageService: ErrorMessageService,
    themeService: ThemeService,
    private readonly conversationQuestionListService: ConversationQuestionListService
  ) {
    super(errorMessageService, themeService, controlContainer, translateService, popoverService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.shouldAutomaticallyLoadStandardQuestions) {
      this.loadConversationQuestions();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.specialtyId && !changes.specialtyId.isFirstChange()) {
      this.loadConversationQuestions();
    }
  }

  private loadConversationQuestions(): void {
    this.fetchSubscription?.unsubscribe();
    this.fetchSubscription = this.conversationQuestionListService.getStandardConversationQuestions$(this.specialtyId)
      .pipe(untilDestroyed(this))
      .subscribe((questionLists: ConversationQuestionList[]) => {
        this.options = this.mapOptions(questionLists);
        this.selectedOptions = [];
        this.writeValue(null);
        this.updateDisplayedValue();
        this.optionsLoaded.emit(this.options);
      });
  }

  private mapOptions(questionLists: ConversationQuestionList[]): Option[] {
    return questionLists.map((questionList: ConversationQuestionList): Option => {
      return {
        label: questionList.title,
        value: questionList.id,
      };
    });
  }
}
