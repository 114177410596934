<vh-centered-card-page>
  <section class="flex-col-c-c gap-v-16" [vhMarginTop]="16">
    <vh-status-message
      [isSuccessful]="true"
      [title]="'pages.register.success.title' | translate"
      [message]="'pages.register.success.message' | translate: { brand: theme.displayName() }">
    </vh-status-message>

    <vh-button
      class="align-center"
      [label]="'pages.register.success.startNow' | translate"
      (buttonClick)="onNextClicked()">
    </vh-button>
  </section>
</vh-centered-card-page>
