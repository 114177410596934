<vh-search-input
  [vhMarginTop]="24"
  [placeholder]="'admin.pages.createAppointment.step4.b.patientSearchInputLabel' | translate"
  [label]="'conversations.create.selectPatient.label' | translate"
  (inputValueChange)="onSearchValueChange$.next($event)"
  type="text">
</vh-search-input>

<div [vhMarginTop]="24">
  <ng-container *ngIf="!isLoading && users === null" [ngTemplateOutlet]="searchPatientMessage"></ng-container>

  <ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeletonTemplate"></ng-container>

  <ng-container *ngIf="!isLoading && users !== null && users.length === 0" [ngTemplateOutlet]="noResultsTemplate"></ng-container>
  
  <ng-container *ngIf="!isLoading && users" [ngTemplateOutlet]="resultsTemplate"></ng-container>
</div>

<hr [vhMarginTop]="24" />

<ng-template #resultsTemplate>
  <div class="flex-col gap-v-16">
    <vh-user-card
      *ngFor="let user of users"
      class="clickable"
      [user]="user"
      [isSelected]="selectedUser === user"
      [isSelectable]="true"
      (cardClick)="onUserCardClick($event)">
    </vh-user-card>
  </div>
</ng-template>

<ng-template #skeletonTemplate>
  <div class="flex-col gap-v-16">
    <vh-user-card
      *ngFor="let i of [].constructor(maxResults)"
      class="not-clickable"
      [cardIconRight]="null"
      [showSkeleton]="true">
    </vh-user-card>
  </div>
</ng-template>

<ng-template #noResultsTemplate>
  <div class="flex-row-c-n">
    <p [vhTextColor]="'var(--color-secondary-grey)'">
      {{ 'common.noResults' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #searchPatientMessage>
  <div class="flex-row-c-n">
    <p [vhMarginTop]="16" [vhTextColor]="'var(--color-secondary-grey)'">
      {{ 'admin.pages.createAppointment.step4.b.userSearchHint' | translate }}
    </p>
  </div>
</ng-template>
