<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
    <div class="flex-row-s-c navback">
        <vh-message class="font-regular-18-22" [ngClass]="{ clickable: !isLoading, 'not-clickable': isLoading }"
            [text]="getTitleKey() | translate"
            [iconLeftPath]="isLoading ? 'assets/icons/hourglass.svg' : 'assets/icons/arrow-left.svg'"
            [iconLeftFill]="theme.colorScheme().black" [iconLeftAlt]="getTitleKey() | translate" (messageClick)="navigateBack()">
        </vh-message>
        <vh-button *ngIf="!isLoading && !isReadOnly" [label]="'common.save' | translate"
            [isDisabled]="formGroup.invalid ||formGroup.pristine"
            (buttonClick)="onSaveClicked()"></vh-button>
    </div>
    <ng-container *ngIf="!isLoading" [ngTemplateOutlet]="careProgramForm"></ng-container>
    <ng-container *ngIf="isLoading" [ngTemplateOutlet]="careProgramSkeleton"></ng-container>
</vh-content-layout>

<ng-template #careProgramForm [formGroup]="formGroup">
    <div class="flex-row gap-h-32">

        <div class="flex-col gap-v-16 flex-8">
            <vh-text-input [formControlName]="CARE_PROGRAM_FORM_KEYS.get('name')" [placeholder]="'Naam' | translate"
                [isDisabled]="isLoading || isReadOnly"
                [message]="formGroup.get(CARE_PROGRAM_FORM_KEYS.get('name'))?.getError('required') ? 'Naam is verplicht' : ''"
                [label]="'admin.pages.settings.careProgram.generalSettings.nameLabel' | translate">
            </vh-text-input>
            <vh-textarea-input [vhMarginTop]="16" [formControlName]="CARE_PROGRAM_FORM_KEYS.get('description')"
                [wrapText]="true" [rows]="5" [isDisabled]="isLoading || isReadOnly" [resizable]="false"
                [placeholder]="'admin.pages.settings.careProgram.generalSettings.descriptiontext' | translate"
                [value]="formGroup.get('description')?.value" [label]="'admin.pages.settings.careProgram.generalSettings.descriptionLabel' |
            translate"></vh-textarea-input>
            <vh-horizontal-divider
                [title]="'admin.pages.settings.careProgram.generalSettings.steps' | translate"></vh-horizontal-divider>
            <ng-container *ngFor="let step of stepForms as UntypedFormGroup">
                <vh-care-program-step-settings-card
                  [formGroup]="step"
                  [isReadOnly]="isReadOnly"
                  (leadingChanged)="onLeadingChanged($event)"
                  (timeToLeadingChanged)="onTimeToLeadingChanged($event)"
                  (removeStep)="onRemoveStepClicked($event)">
                </vh-care-program-step-settings-card>
            </ng-container>
            <vh-button [vhMarginTop]="16" [style]="ButtonStyle.TERTIARY" *ngIf="!isReadOnly"
                [label]="'admin.pages.settings.careProgram.generalSettings.addStep' | translate"
                [iconLeftPath]="'assets/icons/plus.svg'" [iconLeftFill]="theme.colorScheme().black"
                [iconLeftHoverFill]="theme.colorScheme().primary" [iconLeftHeight]="18" [iconLeftWidth]="18" (buttonClick)="onAddStepClicked()"></vh-button>
        </div>

        <div class="flex-col flex-4">
            <div class="card flex-col">
                <vh-horizontal-divider
                    [title]="'admin.pages.settings.careProgram.generalSettings.title' | translate"></vh-horizontal-divider>
                <label [vhMarginTop]="16">{{'admin.pages.settings.careProgram.generalSettings.descriptionLabel' |
                    translate}}</label>
                <vh-textarea-input [formControlName]="CARE_PROGRAM_FORM_KEYS.get('description')"
                    [wrapText]="true"
                    [rows]="5"
                    [isDisabled]="isLoading"
                    [resizable]="false"
                    [placeholder]="'admin.pages.settings.careProgram.generalSettings.descriptiontext' | translate"
                    [value]="formGroup.get('description')?.value"></vh-textarea-input>
                <label [vhMarginTop]="16">{{'admin.pages.settings.careProgram.generalSettings.doctorsLabel' |
                    translate}}</label>
                <div class="pills-group">
                    <vh-pill [isPrimary]="true" *ngFor="let doctor of formGroup.get('doctors')?.value"
                        [label]="doctor.fullName" [iconRightPath]="'assets/icons/cancel.svg'" [iconRightWidth]="16"
                        [iconRightFill]="theme.colorScheme().white" [iconRightHoverFill]="theme.colorScheme().error"
                        (rightIconClick)="onRemoveDoctorClicked(doctor)"> </vh-pill>
                    <vh-button [style]="ButtonStyle.SKINNY" [iconLeftPath]="'assets/icons/plus.svg'"
                        [iconLeftFill]="theme.colorScheme().white" [iconLeftWidth]="16" (buttonClick)="OnAddDoctorClicked()" />
                </div>
            </div>

            <div class="card flex-col" [vhMarginTop]="32">
                <vh-horizontal-divider
                    [title]="'admin.pages.settings.careProgram.generalSettings.status' | translate" />
                <div class="flex-row-se-c" [vhMarginTop]="8">
                    <vh-button *ngIf="formGroup.get('status')?.value==='draft' && formGroup.pristine"
                        [label]="'admin.pages.settings.careProgram.generalSettings.activate' | translate"
                        [style]="ButtonStyle.BORDEREDPRIMARY"
                        [iconLeftPath]="'assets/icons/check-circle.svg'" [iconLeftWidth]="18" [iconLeftHeight]="18"
                        [iconLeftFill]="theme.colorScheme().primary" [iconLeftHoverFill]="theme.colorScheme().white"
                        (buttonClick)="onActivateClicked()"></vh-button>
                    <vh-button *ngIf="formGroup.get('status')?.value!=='archived'"
                        [label]="'admin.pages.settings.careProgram.generalSettings.archive' | translate"
                        [style]="ButtonStyle.BORDEREDERROR" (buttonClick)="onArchiveClicked()"
                        [iconLeftPath]="'assets/icons/trash-alt.svg'" [iconLeftWidth]="18" [iconLeftHeight]="18"
                        [iconLeftFill]="theme.colorScheme().error" [iconLeftHoverFill]="theme.colorScheme().white"></vh-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
