<vh-dialog-frame
  [title]="data.title | translate"
  class="dialog-frame"
  (closeClicked)="close()">
  <div class="dialog-content">
    <div class="star-rating" *ngIf="data.showStarRating">
      <div class="star-container"
           *ngFor="let star of stars; let i = index"
           (mouseenter)="onStarHover(i)"
           (mouseleave)="onStarLeave()"
           (click)="rate(i)">
        <vh-icon
          src="assets/icons/star.svg"
          [fill]="star ? theme.colorScheme().primary : theme.colorScheme().secondaryGrey"
          [stroke]="i === hoveredIndex ? theme.colorScheme().primary : 'none'"
          [width]="24"
          [height]="24">
        </vh-icon>
      </div>
    </div>

    <textarea
      *ngIf="data.showTextarea"
      [(ngModel)]="message"
      [placeholder]="data.textareaPlaceholder | translate"
      rows="4">
    </textarea>
  </div>

  <mat-dialog-actions [vhMarginTop]="16">
    <vh-button
      [iconRightPath]="'assets/icons/send.svg'"
      [label]="data.submitButtonText | translate"
      [isDisabled]="isLoading || (!data.showTextarea && !rating)"
      [style]="ButtonStyle.PRIMARY"
      [alignment]="'center'"
      (buttonClick)="submit()">
    </vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>
