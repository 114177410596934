import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MASKS } from '@constants/masks.constant';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { User } from '@models/user.model';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly MASKS: typeof MASKS = MASKS;

  protected theme: Theme;

  selectedUser: User | null = null;

  @Input() showSkeleton: boolean = false;
  @Input() user: User | null = null;
  @Input() cardIconRight: string | null = 'assets/icons/arrow-right.svg';
  @Input() isSelected: boolean = false;
  @Input() isSelectable: boolean = false;

  @Output() cardClick: EventEmitter<User> = new EventEmitter<User>();

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  selectUser(user: User): void {
    if (this.isSelectable) {
      this.cardClick.emit(user);
    }
  }
}
