import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonGroupStyle } from '@enums/button-style.enum';
import { addDays, addMonths, addWeeks, isSameMonth, isSameWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { isWithinRealisticDateBoundaries } from '@utils/helpers/date.util';
import { EventsCalendarMode } from '@modules/shared/calendar/components/events-calendar/events-calendar.component';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-basic-events-calendar-controls',
  templateUrl: './basic-events-calendar-controls.component.html',
  styleUrls: ['./basic-events-calendar-controls.component.scss'],
})
export class BasicEventsCalendarControlsComponent implements OnInit, OnChanges {
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;
  protected readonly ButtonGroupStyle: typeof ButtonGroupStyle = ButtonGroupStyle;

  protected theme: Theme;

  @Input() isDisabled: boolean = false;
  @Input() calendarMode: EventsCalendarMode;
  @Input() selectedDate: Date = new Date();
  @Input() supportedModes: EventsCalendarMode[] = ['day', 'week', 'month'];

  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() calendarModeChange: EventEmitter<typeof this.calendarMode> = new EventEmitter<typeof this.calendarMode>();

  calenderModeOptions: ButtonOption[];

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.generateCalendarModeButtonOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.calendarMode && !changes.calendarMode.firstChange) {
      this.refreshCalendarModeOptions(this.supportedModes.indexOf(this.calendarMode));
    }
  }

  onSpecificDateSelected(value: string): void {
    const parsedDate: Date = new Date(value);

    if (!value || isWithinRealisticDateBoundaries(parsedDate)) {
      return;
    }

    this.selectedDate = parsedDate;
    this.selectedDateChange.emit(this.selectedDate);
  }

  onCalendarModeChangeClicked(index: number): void {
    // Best effort try to show the current day depending on what view we came from
    if (this.calendarMode === 'week') {
      this.selectedDate = isSameWeek(this.selectedDate, new Date()) ? new Date() : this.selectedDate;
    } else if (this.calendarMode === 'month') {
      this.selectedDate = isSameMonth(this.selectedDate, new Date()) ? new Date() : this.selectedDate;
    }

    switch (index) {
      case 0:
        this.calendarMode = 'day';
        this.selectedDate = startOfDay(this.selectedDate);
        break;
      case 1:
        this.calendarMode = 'week';
        this.selectedDate = startOfWeek(this.selectedDate, { weekStartsOn: 1 });
        break;
      default:
        this.calendarMode = 'month';
        this.selectedDate = startOfMonth(this.selectedDate);
        break;
    }

    this.refreshCalendarModeOptions(index);

    this.calendarModeChange.emit(this.calendarMode);
    this.selectedDateChange.emit(this.selectedDate);
  }

  updateSelectedDate(amountToAddOrSubtract?: number): void {
    if (amountToAddOrSubtract === 0) {
      this.selectedDate = startOfDay(new Date());
    }

    switch (this.calendarMode) {
      case 'day':
        this.selectedDate = startOfDay(addDays(this.selectedDate, amountToAddOrSubtract));
        break;
      case 'week':
        this.selectedDate = startOfWeek(addWeeks(this.selectedDate, amountToAddOrSubtract), { weekStartsOn: 1 });
        break;
      case 'month':
        this.selectedDate = startOfMonth(addMonths(this.selectedDate, amountToAddOrSubtract));
        break;
    }

    this.selectedDateChange.emit(this.selectedDate);
  }

  private refreshCalendarModeOptions(index: number): void {
    this.calenderModeOptions.forEach((option: ButtonOption, i: number) => {
      option.isActive = index === i;
      option.isClickable = index !== i;
    });
  }

  private generateCalendarModeButtonOptions(): void {
    this.calenderModeOptions = [];

    if (this.supportedModes.includes('day')) {
      this.calenderModeOptions.push({
        id: 'day',
        isActive: this.calendarMode === 'day',
        isClickable: this.calendarMode !== 'day',
        label: 'common.timeframes.singular.day',
      });
    }

    if (this.supportedModes.includes('week')) {
      this.calenderModeOptions.push({
        id: 'week',
        isActive: this.calendarMode === 'week',
        isClickable: this.calendarMode !== 'week',
        label: 'common.timeframes.singular.week',
      });
    }

    if (this.supportedModes.includes('month')) {
      this.calenderModeOptions.push({
        id: 'month',
        isActive: this.calendarMode === 'month',
        isClickable: this.calendarMode !== 'month',
        label: 'common.timeframes.singular.month',
      });
    }
  }
}
