import { Component, HostListener, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { PopoverService } from '@services/ui/popover.service';
import { generateRandomString } from '@utils/helpers/string.util';
import { ErrorMessageService } from '@services/error-message.service';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  templateUrl: './base-select-input.component.html',
  styleUrls: ['./base-select-input.component.scss'],
})
@UntilDestroy()
export abstract class BaseSelectInputComponent extends BaseInputComponent implements OnInit {
  @Input() options: Option[];
  @Input() hasEmptyOption: boolean;
  @Input() maxShownOptions: number;
  @Input() hasSelectAll: boolean;
  @Input() maxOptionsHeight: string;

  optionsHeight: string;

  @Input() displayedValue: boolean | string | number | string[];
  selectedOption: Option;

  readonly popoverId: string;

  constructor(
    errorMessageService: ErrorMessageService,
    themeService: ThemeService,
    @Optional() controlContainer: ControlContainer,
    @Optional() protected readonly translateService: TranslateService,
    protected readonly popoverService: PopoverService
  ) {
    super(controlContainer, errorMessageService, themeService);

    // Defaults
    this.maxShownOptions = 5;
    this.popoverId = generateRandomString();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Line height option label + padding-top + padding-bottom
    const optionHeight: number = 20 + 8 + 8;
    const optionsAmount: number = (this.options?.length ?? 0) + (this.hasEmptyOption ? 1 : 0);
    // Make sure px CSS variables are available for the possible outcomes
    this.optionsHeight = `var(--${optionHeight * (optionsAmount < this.maxShownOptions ? optionsAmount : this.maxShownOptions)}px)`;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(): void {
    if (this.popoverService.isOpen(this.popoverId)) {
      this.popoverService.close(this.popoverId);
    }
  }

  handleOptionClick(value: number | string | string[] | boolean | null): void {
    this.popoverService.close(this.popoverId);
    this.handleValueChangeWithCheck(value);
    this.updateDisplayedValue();
  }

  updateDisplayedValue(): void {
    if (this.value === 'all') {
      this.translateService.get('common.all')
        .pipe(untilDestroyed(this))
        .subscribe((translation: string) => {
          this.selectedOption = { value: 'all', label: translation };
          this.displayedValue = translation;
        });

      return;
    }

    if (this.value === undefined) {
      return;
    }

    this.selectedOption = this.options?.find((option: Option): boolean => option.value === this.value) ?? null;
    this.displayedValue = this.selectedOption?.label ?? null;
  }
}
