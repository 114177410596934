<div class="flex-col-c-c gap-v-4">
  <p>
    {{
      'admin.pages.dashboard.rescheduleConfirmation.message'
        | translate
          : {
              previousDate: visit.start | dfnsFormat : 'dd/MM/yyyy HH:mm',
              newDate: newStartDateTime | dfnsFormat : 'dd/MM/yyyy HH:mm'
            }
    }}
  </p>
  <vh-message
    class="not-clickable"
    [ngClass]="{
      'has-text-black': visitReschedulingImpact === null,
      'has-text-alert': visitReschedulingImpact?.hasImpact() === true,
      'has-text-primary': visitReschedulingImpact?.hasImpact() === false
    }"
    [iconLeftClass]="visitReschedulingImpact === null ? 'anim-rotating' : ''"
    [iconLeftPath]="
      visitReschedulingImpact === null
        ? 'assets/icons/sync-secondary.svg'
        : visitReschedulingImpact?.hasImpact()
        ? 'assets/icons/exclamation-triangle.svg'
        : 'assets/icons/check-circle.svg'
    "
    [iconLeftFill]="
      visitReschedulingImpact === null ? theme.colorScheme().black : visitReschedulingImpact?.hasImpact() ? theme.colorScheme().alert : theme.colorScheme().primary
    "
    [text]="visitReschedulingImpactMessage | translate">
  </vh-message>
  <p class="font-light-12-14" [vhMarginTop]="8">
    {{ 'admin.pages.dashboard.rescheduleConfirmation.disclaimer' | translate }}
  </p>
  <vh-message
    *ngIf="overRuleAttemptNonAdmin"
    class="not-clickable has-text-alert"
    [iconLeftPath]="'assets/icons/times-circle.svg'"
    [iconLeftFill]="theme.colorScheme().alert"
    [text]="overRuleAttemptMessage | translate">
  </vh-message>
  <div [vhMarginTop]="16" class="flex-row-c-c gap-h-8">
    <vh-button
      [label]="'common.ok' | translate"
      [isDisabled]="isConfirmDisabled()"
      [iconLeftFill]="theme.colorScheme().white"
      [matTooltip]="confirmDisabledMessage | translate"
      (buttonClick)="onConfirmClicked()">
    </vh-button>
    <vh-button
      [label]="'common.cancel' | translate"
      [style]="ButtonStyle.TERTIARY"
      [isDisabled]="isSavingVisit"
      (buttonClick)="cancelClicked.emit()">
    </vh-button>
  </div>
</div>
