import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Survey } from '@models/survey.model';
import { SurveyFormService } from '@services/form-services/survey-form.service';
import { Option } from '@interfaces/option.interface';
import { SurveyService } from '@services/survey.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SURVEY_FORM_KEYS } from '@constants/form-keys/survey-form-keys.constant';
import { AuthenticationService } from '@services/authentication.service';
import { getQuestionTypeLabelKey, QuestionType } from '@enums/question-type.enum';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { IObject } from '@app-types/iobject.type';
import { AnswerOption } from '@models/answer-option.model';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-survey-settings',
  templateUrl: './survey-settings.component.html',
  styleUrls: ['./survey-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class SurveySettingsComponent implements OnInit, OnChanges {
  protected readonly SURVEY_FORM_KEYS: typeof SURVEY_FORM_KEYS = SURVEY_FORM_KEYS;

  protected theme: Theme;

  selectedLanguage: string;
  defaultLanguage: string;
  questions: UntypedFormArray;
  formGroup: UntypedFormGroup;

  errorMessageTranslationKey: string;

  questionTypeOptions: Option[];

  isSaving: boolean = false;

  @Input() isLoading: boolean;
  @Input() survey: Survey;

  constructor(
    private readonly surveyFormService: SurveyFormService,
    private readonly surveyService: SurveyService,
    private readonly translate: TranslateService,
    private readonly authenticationService: AuthenticationService,
    private readonly ref: ChangeDetectorRef,
    private readonly alertDialogService: AlertDialogService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  get questionForms(): UntypedFormGroup[] {
    return this.questions?.controls as UntypedFormGroup[];
  }

  ngOnInit(): void {
    this.selectedLanguage = this.authenticationService.institution.settings.defaultLanguage;
    this.defaultLanguage = this.authenticationService.institution.settings.defaultLanguage;
    this.buildQuestionTypeOptions();
    this.resetFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.survey) {
      this.resetFormGroup();
    }
  }

  saveSurvey(): void {
    if (this.survey) {
      this.updateSurvey(this.survey);

      return;
    }

    this.isSaving = true;

    const payload: IObject = this.surveyFormService.formGroupToRequestBody(this.formGroup, this.selectedLanguage);
    this.surveyService.createSurvey$(payload, true)
      .pipe(untilDestroyed(this))
      .subscribe((survey: Survey) => {
        this.isSaving = false;
        this.survey = survey;
        this.resetFormGroup();
      });
  }

  updateSurvey(survey: Survey): void {
    this.isSaving = true;

    const payload: IObject = this.surveyFormService.formGroupToRequestBody(this.formGroup, this.selectedLanguage);
    this.surveyService.updateSurvey$(survey.id, payload, true)
      .pipe(untilDestroyed(this))
      .subscribe((updatedSurvey: Survey) => {
        this.isSaving = false;
        this.survey = updatedSurvey;
        this.resetFormGroup();
        this.ref.markForCheck();
      });
  }

  removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }

  addQuestion(): void {
    this.questions.push(this.surveyFormService.createQuestion(null, this.questions.controls.length));
  }

  onLanguageChanged(language: string): void {
    const previousLanguage: string = this.selectedLanguage;

    if (this.formGroup.dirty) {
      this.alertDialogService.open({
        titleTranslationKey: 'admin.pages.settings.surveys.create.changeLanguageDialog.title',
        messageTranslationKey: 'admin.pages.settings.surveys.create.changeLanguageDialog.message',
        showCancelButton: true,
      })
        .pipe(untilDestroyed(this))
        .subscribe((result: boolean) => {
          if (result) {
            this.onLanguageChangeConfirmed(language);

            return;
          }

          // TODO: when canceling the language change, the form is not reset to the previous language
          this.selectedLanguage = previousLanguage;
        });

      return;
    }

    this.onLanguageChangeConfirmed(language);
  }

  onLanguageChangeConfirmed(language: string): void {
    this.isLoading = true;
    this.selectedLanguage = language;
    this.resetFormGroup();

    // Without the minimal timeout, the loading skeleton is not shown and the application hangs for a second
    setTimeout(() => {
      this.isLoading = false;
      this.ref.markForCheck();
    }, 10);
  }

  onQuestionDropped(event: CdkDragDrop<Event>): void {
    this.moveItemInFormArray(this.questions, event.previousIndex, event.currentIndex);

    for (let i = 0; i < this.questions.length; i++) {
      const current = this.questions.at(i);
      current.value.list_position_number = i + 1;
    }
  }

  private moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }

  private resetFormGroup(): void {
    this.formGroup = this.surveyFormService.createFormGroup(this.survey, this.selectedLanguage);
    this.questions = this.formGroup.controls.questions as FormArray;
  }

  private buildQuestionTypeOptions(): void {
    this.questionTypeOptions = Object.keys(QuestionType).map((key: string) => ({
      label: this.translate.instant(getQuestionTypeLabelKey(QuestionType[key])),
      value: QuestionType[key],
    }));
  }

  getTranslatedAnswerOptionsPreview(questionIndex: number): string[] {
    if (this.selectedLanguage === this.defaultLanguage) {
      return [];
    }

    return this.survey?.questions[questionIndex].answerOptions?.map((answerOption: AnswerOption) => this.translate.instant('common.i18nOriginalValue', { value: answerOption.value.get(this.defaultLanguage) }));
  }
}

