import { Component,
  Inject,
  OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { ButtonStyle } from '@enums/button-style.enum';
import { Visit } from '@models/visit.model';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { UntypedFormGroup } from '@angular/forms';
import { CancelVisitFormService } from '@services/form-services/cancel-visit-form.service';
import { IObject } from '@app-types/iobject.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ImpactedPatientsDialogData {
  visits: Visit[];
}

export interface ImpactedPatientsDialogResult {
  status: ImpactedPatientsDialogResultStatus;
  formData?: IObject | null;
}

export type ImpactedPatientsDialogResultStatus = 'ok' | 'cancel';

@Component({
  selector: 'vh-impacted-patients-dialog',
  templateUrl: './impacted-patients-dialog.component.html',
  styleUrls: ['./impacted-patients-dialog.component.scss'],
})
@UntilDestroy()
export class ImpactedPatientsDialogComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly DateFormat: typeof DATE_FORMATS = DATE_FORMATS;

  visits: Visit[];

  showCopiedToClipboardConfirmation: boolean;
  cancellationFormGroup: UntypedFormGroup;

  selectedTab: number = 1;
  tabLabelTranslationKeys: string[] = [
    'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.tabTitleCancellationForm',
    'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.tabTitleAppointmentsOverview',
  ];

  private patientsList: string;
  private isClosing: boolean = false;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly cancelVisitFormService: CancelVisitFormService,
    private readonly dialog: MatDialogRef<ImpactedPatientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ImpactedPatientsDialogData
  ) {
    this.visits = data.visits;
  }

  ngOnInit(): void {
    this.cancellationFormGroup = this.cancelVisitFormService.createFormGroup();
    this.generatePatientsList();
    this.dialog.beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.isClosing) {
          this.close('cancel');
        }
      });
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.patientsList);
    this.showCopiedToClipboardConfirmation = true;
  }

  onConfirmClicked(): void {
    this.close('ok');
    this.dialog.close({ status: 'ok', formData: this.cancellationFormGroup.value });
  }

  onCancelClick(): void {
    this.close('cancel');
  }

  generatePatientsList = (): void => {
    this.patientsList = '';

    this.visits?.forEach((visit: Visit) => {
      this.patientsList += `${format(visit.start, DATE_FORMATS.clientDateTimeShort)};${format(visit.end, DATE_FORMATS.clientDateTimeShort)};${visit.patient?.fullName};${visit.patient?.email};${visit.patient?.phone} \n`;
    });
  };

  onTabClicked(selectedTab: number): void {
    this.selectedTab = selectedTab;
  }

  onFormGroupUpdated(formGroup: UntypedFormGroup): void {
    this.cancellationFormGroup = formGroup;
  }

  close(status: ImpactedPatientsDialogResultStatus, formData: IObject | null = null): void {
    this.isClosing = true;

    const result: ImpactedPatientsDialogResult = { status: status };

    if (formData) {
      result.formData = formData;
    }

    this.dialog.close(result);
  }
}
