<vh-page [logoIsHiddenOnMobile]="false" [navigationBarContentTemplate]="navigationBarContentTemplate" [expanded]="expanded">
  <div class="flex-col container">
    <div class="header">
      <vh-content-layout [hasHorizontalSpacing]="true">
        <ng-container [ngTemplateOutlet]="headerContentTemplate"></ng-container>
      </vh-content-layout>
    </div>
    <div class="content">
      <vh-content-layout [hasHorizontalSpacing]="hasHorizontalContentSpacing"
        [hasVerticalSpacing]="hasVerticalContentSpacing">
        <ng-content></ng-content>
      </vh-content-layout>
    </div>
  </div>
</vh-page>

<ng-template #navigationBarContentTemplate>
  <div class="navigation-bar-items"
    [ngClass]="{ 'collapsed': !expanded }">
    <ng-container *ngFor="let item of navigationItems">
      <a
      [routerLink]="item.route"
      routerLinkActive="active"
      #rla="routerLinkActive"
      (mouseover)="hoveredItem = item"
      (mouseleave)="hoveredItem = null">
      <vh-icon
        *ngIf="item.labelTranslationKey"
        [src]="'assets/icons/' + item.icon + '.svg'"
        width="24"
        height="24"
        [fill]="item.stroke ? 'transparent' : (isItemActiveOrHovered(item, rla.isActive) ? theme.colorScheme().secondaryGreen : theme.colorScheme().inputPlaceholder)"
        [stroke]="item.stroke ? (isItemActiveOrHovered(item, rla.isActive) ? theme.colorScheme().secondaryGreen : theme.colorScheme().inputPlaceholder) : 'transparent'">
      </vh-icon>
      <span class="font-regular-18-22" [routerLinkActive]="'active'">
        {{ item.labelTranslationKey | translate }}
      </span>
    </a>
    </ng-container>
  </div>

  <div class="expand-toggle" (click)="toggleExpand()" [ngClass]="{ 'collapsed': !expanded }"
    *ngIf="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]">
    <img class="logo" src="/assets/icons/double-arrow.svg" />
  </div>

  <ng-container [ngTemplateOutlet]="profileTemplate"></ng-container>
</ng-template>
