<!-- this HTML contains hardcoded translations -->
<vh-expandable-card [formGroup]="formGroup">
    <div header class="card-header flex-row-s-t">
        <div class="flex-row-s-c">
            <div class="flex-row-l-c gap-h-8"><label>{{'admin.pages.settings.careProgram.step.name' | translate}}</label>
                <vh-text-input
                  [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('name')"
                  [isDisabled]="isReadOnly"
                  [placeholder]="'admin.pages.settings.careProgram.step.name' | translate"
                  [message]="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('name'))?.getError('required') ? 'Naam is verplicht' : ''"
                  (inputClick)="onTextBoxClicked($event)"
                  (keydown.space)="$event.stopPropagation()">
                </vh-text-input>
            </div>
            <vh-radio-checkbox
              [value]="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('leading')).value"
              [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('leading')"
              [label]="'admin.pages.settings.careProgram.step.leading' | translate"
              [isDisabled]="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('leading'))?.value || isReadOnly"
              [allowDisabledStyle]="false"
              (valueChanged)="onLeadingChanged()">
            </vh-radio-checkbox>
        </div>
      <vh-icon *ngIf="!formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('leading'))?.value && !isReadOnly"
               [src]="'assets/icons/trash-alt.svg'"
               [fill]="theme.colorScheme().secondaryGrey"
               [height]="18"
               [hoverFill]="theme.colorScheme().error"
               [ariaLabel]="'common.remove' | translate"
               [matTooltip]="'common.remove' | translate"
               matTooltipPosition="above" [matTooltipShowDelay]="500"
               (click)="onRemoveStepClicked()">
      </vh-icon>
    </div>
    <div header-closed class="card-header flex-row gap-h-8">
      <vh-icon src='assets/icons/calendar-alt.svg' height="18" width="18" [fill]="theme.colorScheme().black" />
      <label [innerText]="timeToLeadingWithUnit()"></label>
    </div>
    <div content [vhMarginTop]="16">
        <vh-horizontal-divider [verticalMargin]="8" />

      @if (formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('leading'))?.value) {
        <div class="flex-row gap-h-8">
          <vh-icon src='assets/icons/calendar-alt.svg' height="18" width="18" [fill]="theme.colorScheme().black"/>
          <label *ngIf="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('leading'))?.value" [innerHtml]="'admin.pages.settings.careProgram.step.leading' | translate"></label>
        </div>
      } @else {
        <div class="flex-row gap-h-8">
          <div class="flex-col">
            <h2 [innerHTML]="'admin.pages.settings.careProgram.step.timeSettings' | translate"></h2>
            <div [vhMarginTop]="16" class="fixed-row-3 gap-h-8">
              <label [innerHTML]="'admin.pages.settings.careProgram.step.timeToLeading' | translate"></label>
              <vh-text-input
                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')"
                [isDisabled]="isReadOnly"
                (inputValueChange)="onTimeToLeadingInput()">
              </vh-text-input>
              <vh-select-input
                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingUnit')"
                [options]="timeUnits"
                [message]="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeading'))?.getError('required') ? 'Tijdseenheid is verplicht' : ''"
                (inputValueChange)="onTimeToLeadingChanged()"
                [isDisabled]="isReadOnly">
              </vh-select-input>
            </div>
            <div [vhMarginTop]="8" class="fixed-row-3 gap-h-8">
              <label [innerHTML]="'admin.pages.settings.careProgram.step.marginBefore' | translate"></label>
              <vh-text-input
                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('marginBefore')"
                [isDisabled]="isReadOnly">
              </vh-text-input>
              <vh-select-input
                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('marginBeforeUnit')"
                [isDisabled]="isReadOnly"
                [options]="timeUnits"
                [message]="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('marginBefore'))?.getError('required') ? 'Tijdseenheid is verplicht' : ''">
              </vh-select-input>
            </div>
            <div [vhMarginTop]="8" class="fixed-row-3 gap-h-8">
              <label [innerHTML]="'admin.pages.settings.careProgram.step.marginAfter' | translate"></label>
              <vh-text-input
                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('marginAfter')"
                [isDisabled]="isReadOnly">
              </vh-text-input>
              <vh-select-input
                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('marginAfterUnit')"
                [isDisabled]="isReadOnly"
                [options]="timeUnits"
                [message]="formGroup.get(CARE_PROGRAM_STEP_FORM_KEYS.get('marginAfter'))?.getError('required') ? 'Tijdseenheid is verplicht' : ''">
              </vh-select-input>
            </div>
          </div>
        </div>
        }
        <vh-horizontal-divider [verticalMargin]="8" />
        <div class="flex-row">
            <div *ngIf="serviceOptions?.length>0" class="flex-col">
                <h2 [innerHTML]="'admin.pages.settings.careProgram.step.services' | translate"></h2>
                <div [vhMarginTop]="8" class="flex-col" formArrayName="services">
                    <ng-container *ngFor="let service of services.controls;let i=index">
                        <div [vhMarginTop]="4" class="fixed-row-2 gap-h-8" [formGroup]="service">
                            <label [innerHTML]="'admin.pages.settings.careProgram.step.serviceCount' | translate : {number: i + 1}"></label>
                            <div class="flex-row-l-c gap-h-8">
                              <vh-select-input
                                [isDisabled]="isReadOnly"
                                [formControlName]="CARE_PROGRAM_STEP_FORM_KEYS.get('service')"
                                [options]="serviceOptions">
                              </vh-select-input>
                              <vh-icon
                                *ngIf="!isReadOnly"
                                [src]="'assets/icons/trash-alt.svg'"
                                [fill]="theme.colorScheme().secondaryGrey"
                                [height]="18"
                                [hoverFill]="theme.colorScheme().error"
                                [ariaLabel]="'common.remove' | translate"
                                [matTooltip]="'common.remove' | translate"
                                matTooltipPosition="above"
                                [matTooltipShowDelay]="500"
                                (click)="onRemoveServiceClicked(service)">
                              </vh-icon>
                            </div>
                        </div>
                    </ng-container>
                  <vh-button
                    *ngIf="!isReadOnly"
                    [vhMarginTop]="16"
                    [style]="ButtonStyle.TERTIARY"
                    [label]="'admin.pages.settings.careProgram.step.addService' | translate"
                    [iconLeftPath]="'assets/icons/plus.svg'"
                    [iconLeftFill]="theme.colorScheme().black"
                    [iconLeftHoverFill]="theme.colorScheme().primary"
                    [iconLeftHeight]="18"
                    [iconLeftWidth]="18"
                    (buttonClick)="onAddServiceClicked()">
                  </vh-button>
                </div>
            </div>
        </div>
    </div>
</vh-expandable-card>
