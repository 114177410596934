<div class="container flex-col">
  <div class="flex-col content">
    <vh-content-layout
        [hasHorizontalSpacing]="contentHasHorizontalSpacing"
        [hasVerticalSpacing]="contentHasVerticalSpacing"
        [maxWidth]="contentMaxWidth"
        (layoutScroll)="setContentHasReachedScrollEnd($event)"
        (layoutResize)="setContentHasReachedScrollEnd($event)">
      <div class="wizard-header">
        <ng-template [ngTemplateOutlet]="leftHeaderTemplate"></ng-template>
        <vh-wizard-progress-indicator
          [state]="state"
          [maxWidth]="progressIndicatorMaxWidth"
          (stepClicked)="stepClicked.emit($event)">
        </vh-wizard-progress-indicator>
        <ng-template [ngTemplateOutlet]="rightHeaderTemplate"></ng-template>
      </div>
      <ng-content></ng-content>
    </vh-content-layout>
  </div>

  <div *ngIf="!state.isAtStart()" class="flex-row-c-n nav-controls" [ngClass]="{ shadow: !contentHasReachedScrollEnd }">
    <vh-button
      *ngIf="!state.previousButtonIsHidden"
      [label]="'common.back' | translate"
      [style]="ButtonStyle.TERTIARY"
      [iconLeftPath]="'assets/icons/arrow-left.svg'"
      [iconLeftFill]="theme.colorScheme().primary"
      (buttonClick)="previousStepClicked.emit()">
    </vh-button>
    <vh-button
      *ngIf="!state.nextButtonIsHidden"
      [label]="state.nextButtonLabelTranslationKey || 'common.next' | translate"
      [isDisabled]="state.nextButtonIsDisabled"
      iconRightPath="assets/icons/arrow-right.svg"
      [iconRightFill]="theme.colorScheme().primary"
      (buttonClick)="nextStepClicked.emit()">
    </vh-button>
  </div>
</div>
