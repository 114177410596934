import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  protected theme: Theme;

  @Input() isDisabled: boolean;
  @Input() backTo: string;
  @Input() marginTop: number = 0;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  handleButtonClick = (): void => {
    if (this.isDisabled) {
      return;
    }

    this.buttonClick.emit();
  };
}
