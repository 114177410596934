import { Component, OnInit } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';
import { User } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { BaseBookingFlowPageComponent } from '@modules/booking/pages/base-booking-flow-page/base-booking-flow.page';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { getBackOfficeRoles, getEditorRoles } from '@enums/role.enum';
import { Mode } from '@enums/mode.enum';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

type PageState = 'loading' | 'account_confirmation' | 'register_or_login' | 'select_patient';

@Component({
  selector: 'vh-select-patient-page',
  templateUrl: './select-patient-page.component.html',
  styleUrls: ['./select-patient-page.component.scss'],
})
@UntilDestroy()
export class SelectPatientPageComponent extends BaseBookingFlowPageComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  currentPage: PageState = 'loading';

  selectedUser: User | null = null;

  currentUser: User | null;
  logoutErrorMessageTranslationKey: string;
  loginLoading: boolean = false;
  isLoadingNextStep: boolean = false;
  theme: Theme;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly themeService: ThemeService,
    bookingState: BookingFlowStateService,
    bookingFlowNavigationService: BookingFlowNavigationService,
    bookingFlowDialogService: BookingFlowDialogService
  ) {
    super(bookingState, bookingFlowNavigationService, bookingFlowDialogService);
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser;
    this.currentPage = this.determineState();
    this.theme = this.themeService.currentTheme;
  }

  onPatientSelected(patient: User | null, proceedToNextStep: boolean = false): void {
    this.selectedUser = patient;

    if (this.currentUser?.hasAnyRole(getBackOfficeRoles()) && patient.id !== this.currentUser?.id) {
      this.bookingState.booking.selectPatient(patient);
    } else {
      this.bookingState.booking.selectPatient(this.currentUser);
    }

    if (proceedToNextStep) {
      this.goToNextStep();
    }
  }

  goToNextStep(): void {
    this.isLoadingNextStep = true;

    this.bookingState.syncToServer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.navigateToNextPage();
      });
  }

  onUserRegisteredOrLoggedInSuccessfully = (newlyRegisteredUser: User = null): void => {
    this.loginLoading = true;
    // If the user has just logged in, we need to refresh the currentUser
    this.currentUser = this.authenticationService.currentUser;

    this.onPatientSelected(newlyRegisteredUser, true);
  };

  logout = (): void => {
    this.logoutErrorMessageTranslationKey = null;

    this.authenticationService.logout$()
      .subscribe({
        next: (): void => {
          this.currentPage = 'register_or_login';
        },
        error: (): void => {
          this.logoutErrorMessageTranslationKey = 'common.unknownError';
        },
      });
  };

  private determineState(): PageState {
    if (!this.currentUser && this.authenticationService.mode === Mode.PRIVATE) {
      return 'register_or_login';
    }

    if (this.currentUser && this.authenticationService.mode === Mode.PRIVATE) {
      return 'account_confirmation';
    }

    if (this.currentUser.hasAnyRole(getEditorRoles())) {
      return 'select_patient';
    }

    return 'loading';
  }

  private navigateToNextPage(): void {
    this.navigationService.navigateToBookingConfirmationPage(this.bookingState.pendingBookingId);
  }
}
