import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { MASKS } from '@constants/masks.constant';
import { DoctorService } from '@services/doctor.service';
import { concat, last } from 'rxjs';
import { DoctorFormService } from '@services/form-services/doctor-form.service';
import { DOCTOR_FORM_KEYS } from '@constants/form-keys/doctor-form-keys.constant';
import { LeadsService } from '@services/leads.service';
import { ToastService } from '@services/ui/toast.service';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { ConversationQuestionListsSelectInputComponent } from '@modules/conversation/components/conversation-question-lists-select-input/conversation-question-lists-select-input.component';
import { ConversationQuestionListService } from '@services/conversation-question-list.service';
import { Option } from '@interfaces/option.interface';
import { WalletService } from '@services/wallet.service';
import { DoctorOnboardingState } from '@enums/doctor-onboarding-state.enum';
import { UserService } from '@services/user.service';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-onboarding-finishing-up-page',
  templateUrl: './onboarding-finishing-up-page.component.html',
  styleUrls: ['./onboarding-finishing-up-page.component.scss'],
})
@UntilDestroy()
export class OnboardingFinishingUpPageComponent extends BaseOnboardingPageComponent implements OnInit, AfterViewInit {
  protected readonly MASKS: typeof MASKS = MASKS;
  // protected readonly PROFILE_FORM_KEYS: typeof PROFILE_FORM_KEYS = PROFILE_FORM_KEYS;
  protected readonly DOCTOR_FORM_KEYS: typeof DOCTOR_FORM_KEYS = DOCTOR_FORM_KEYS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  @ViewChild('conversationQuestionListsSelectInput') conversationQuestionListsSelectInput: ConversationQuestionListsSelectInputComponent;

  // userFormGroup: UntypedFormGroup;
  doctorFormGroup: UntypedFormGroup;
  currentDate: string;
  isSubmitting: boolean = false;
  hasSentLeadsEmail: boolean = false;
  demoTokensAmount: number;

  constructor(
    private readonly userService: UserService,
    private readonly doctorService: DoctorService,
    private readonly doctorFormService: DoctorFormService,
    private readonly authenticationService: AuthenticationService,
    private readonly leadsService: LeadsService,
    private readonly toastService: ToastService,
    private readonly conversationQuestionListService: ConversationQuestionListService,
    private readonly walletService: WalletService,
    router: Router,
    themeService: ThemeService
  ) {
    super(router, themeService);

    this.currentDate = format(new Date(), DATE_FORMATS.serverDate);
  }

  ngAfterViewInit(): void {
    this.conversationQuestionListsSelectInput.isDisabled = true;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authenticationService.getCurrentUser$().pipe(untilDestroyed(this)).subscribe();

    this.walletService
      .getDemoTokensAmount$()
      .pipe(untilDestroyed(this))
      .subscribe((amount: number) => {
        this.demoTokensAmount = amount;
      });

    // this.userFormGroup = this.userFormService.createOnboardingFinishingUpFormGroup();
    this.doctorFormGroup = this.doctorFormService.createSpecialtyOnlyFormGroup();
  }

  onConversationQuestionsLoaded(options: Option[]): void {
    this.conversationQuestionListsSelectInput.isDisabled = options?.length === 0;

    if (options.length > 0) {
      this.conversationQuestionListsSelectInput.selectedOptions = [options[0]];
      this.conversationQuestionListsSelectInput.updateDisplayedValue();
    }

    if (!!this.doctorFormGroup.get('specialty_id').value && options?.length === 0) {
      this.sendMissingQuestionListEmail();
    }
  }

  onNextClicked(): void {
    this.isSubmitting = true;

    const userPayload = {
      // ...this.userFormGroup.value,
      doctor_onboarding_state: DoctorOnboardingState.DONE,
    };

    const selectedQuestionListIds: string[] = this.conversationQuestionListsSelectInput.selectedOptions.map(
      (option: Option) => option.value as string
    );

    concat(
      this.doctorService.updateDoctor$(this.authenticationService.currentUser.doctor.id, this.doctorFormGroup.value),
      this.conversationQuestionListService.attachConversationQuestionListsToInstitution$(
        selectedQuestionListIds,
        this.authenticationService.institution.id
      ),
      this.userService.updateUser$(this.authenticationService.currentUser.id, userPayload)
    )
      .pipe(last())
      .subscribe({
        next: () => {
          void this.router.navigate([ONBOARDING_NAV.success.route]);
        },
        error: () => {
          this.isSubmitting = false;
          this.toastService.showError('common.errors.generic').pipe(untilDestroyed(this)).subscribe();
        },
      });
  }

  setStep(): void {
    this.state.goToStep(this.STEP_FINISHING_UP);
  }

  private sendMissingQuestionListEmail(): void {
    if (this.hasSentLeadsEmail) {
      return;
    }

    concat(
      this.doctorService.updateDoctor$(this.authenticationService.currentUser.doctor.id, this.doctorFormGroup.value),
      this.leadsService.sendMissingQuestionListLeadEmail()
    )
      .pipe(last())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.hasSentLeadsEmail = true;
      });
  }
}
