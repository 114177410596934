import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { Bucket } from 'src/app/models/bucket.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { ToastService } from '@services/ui/toast.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WalletService } from '@services/wallet.service';
import { Option } from '@interfaces/option.interface';
import { PaymentMethod } from '@models/payment-method.model';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';
import { Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-buckets-overview-page',
  templateUrl: './buckets-overview-page.component.html',
  styleUrls: ['./buckets-overview-page.component.scss'],
})
@UntilDestroy()
export class BucketsOverviewPageComponent implements OnInit {
  protected theme: Theme;

  navigationItems: NavigationItem[];
  buckets: Bucket[] = [];
  selectedBucket: Bucket | null = null;
  paymentMethodOptions: Option[] = null;
  isPurchaseInProgress: boolean = false;
  defaultPaymentMethod: PaymentMethod | null = null;
  customAmount: number | null = null;
  selectedPaymentMethodId: string | null = null;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly toastService: ToastService,
    private readonly walletService: WalletService,
    private readonly router: Router,
    private readonly themeService: ThemeService
  ) {
    this.navigationItems = getNavigationItems();
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.fetchBuckets();
    this.fetchPaymentMethods();
  }

  get canMakePurchase(): boolean {
    return !!this.selectedPaymentMethodId && (!!this.selectedBucket || this.customAmount > 0);
  }

  onBuyClicked(): void {
    this.makePurchase();
  }

  onBucketSelected(bucket: Bucket): void {
    this.selectedBucket = bucket.id === this.selectedBucket?.id ? null : bucket;
    this.customAmount = null;
  }

  onManagePaymentMethodsClicked(): void {
    void this.router.navigate([NAVIGATION.paymentMethods.route]);
  }

  private makePurchase(): void {
    this.isPurchaseInProgress = true;

    this.paymentService.buyBucket$(
      this.selectedPaymentMethodId,
      this.selectedBucket?.id ?? null,
      this.customAmount ?? null
    )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: { order_id: string; }): void => {
          void this.router.navigate([PAYMENT_NAV.redirectWhenPaymentFinished.route], { queryParams: { order_id: response.order_id } });
        },
        error: (error: HttpErrorResponse): void => {
          this.isPurchaseInProgress = false;
          this.toastService.showError('common.errors.generic')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  private fetchBuckets(): void {
    this.paymentService.getAvailableBuckets$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (buckets: Bucket[]) => {
          this.buckets = buckets;
        },
      });
  }

  private fetchPaymentMethods(): void {
    this.walletService.getConfiguredPaymentMethods$()
      .pipe(untilDestroyed(this))
      .subscribe((paymentMethods: PaymentMethod[]) => {
        this.paymentMethodOptions = this.convertPaymentMethodsToOptions(paymentMethods);

        this.defaultPaymentMethod = paymentMethods.find((paymentMethod: PaymentMethod): boolean => paymentMethod.isDefault);
        if (this.defaultPaymentMethod) {
          this.selectedPaymentMethodId = this.defaultPaymentMethod.id;
        }
      });
  }

  private convertPaymentMethodsToOptions(paymentMethods: PaymentMethod[]): Option[] {
    return paymentMethods.map((paymentMethod: PaymentMethod): Option => {
      return {
        label: paymentMethod.creditCard.displayName,
        value: paymentMethod.id,
      };
    });
  }

  onPaymentMethodSelected($event: string): void {
    this.selectedPaymentMethodId = this.paymentMethodOptions.find((paymentMethod: Option): boolean => paymentMethod.value === $event)?.value as string ?? null;
  }
}
