import { Component, EventEmitter, forwardRef, Input, OnChanges, Optional, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { ErrorMessageService } from '@services/error-message.service';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-radio-checkbox',
  templateUrl: './radio-checkbox.component.html',
  styleUrls: [
    './radio-checkbox.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioCheckboxComponent),
      multi: true,
    },
  ],
})
export class RadioCheckboxComponent extends BaseInputComponent implements OnChanges {
  @Input() checkBoxStyle: string;
  @Input() allowDisabledStyle: boolean = true;
  @Input() borderColor: string;
  @Input() fillColor: string;
  @Input() fillColorUnchecked: string;
  @Input() updateValueOnClick: boolean = true;

  /**
   * This accessor can be used if we use the component outside a form
   */
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  @Optional() controlContainer: ControlContainer,
    errorMessageService: ErrorMessageService,
    themeService: ThemeService
  ) {
    super(controlContainer, errorMessageService, themeService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const valueChange: SimpleChange = changes.value;

    // Update the form control when the value gets updated from the input decorator
    if (valueChange && valueChange.currentValue !== valueChange.previousValue) {
      this.formControlOnValueChangeFunction?.(this.value);
    }
  }

  handleInputClick($event: Event): void {
    $event.stopPropagation();
    if (this.isDisabled) {
      return;
    }

    if (!this.updateValueOnClick) {
      return;
    }

    this.value = !this.value;

    this.valueChanged.emit(this.value);
    this.formControlOnValueChangeFunction?.(this.value);
  }
}
