import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { passwordValidator } from '@utils/validators/password.validator';
import { atLeastOneRequired } from '@utils/validators/at-least-one-required.validator';

@Injectable({
  providedIn: 'root',
})
export class RegisterFormService extends AbstractFormService {

  /**
   * Create a basic form group with only the required fields (first name, last name, username and password) for the registration form.
   */
  createFormGroupForHealthCareProvider(): UntypedFormGroup {
    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('firstName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('lastName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('email')]: [
        null,
        [Validators.required, Validators.email],
      ],
      [REGISTER_FORM_KEYS.get('username')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('password')]: [
        null,
        [
          Validators.required,
          passwordValidator(),
        ],
      ],
      [REGISTER_FORM_KEYS.get('accepted_terms')]: [
        false,
        [Validators.requiredTrue],
      ],
    });
  }

  /**
   * Create a form group with all possible fields for the registration form.
   */
  createFormGroup(hasPasswordInput: boolean, hasPrivacyStatementInput: boolean, isSelfRegistering: boolean, enableEmailOrPhoneValidation: boolean): UntypedFormGroup {
    const validators = {
      validators: [],
    };

    if (enableEmailOrPhoneValidation) {
      { atLeastOneRequired(REGISTER_FORM_KEYS.get('email'), REGISTER_FORM_KEYS.get('phone')); }
    }

    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('firstName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('lastName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('birthdate')]: [
        null,
        isSelfRegistering ? [Validators.required] : [],
      ],
      [REGISTER_FORM_KEYS.get('username')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('gender')]: [
        null,
        isSelfRegistering ? [Validators.required] : [],
      ],
      [REGISTER_FORM_KEYS.get('language')]: [
        null,
        isSelfRegistering ? [Validators.required] : [],
      ],
      [REGISTER_FORM_KEYS.get('socialSecurityNumber')]: [
        null,
        null,
      ],
      [REGISTER_FORM_KEYS.get('phone')]: [
        null,
        null,
      ],
      [REGISTER_FORM_KEYS.get('email')]: [
        null,
        [Validators.email],
      ],
      [REGISTER_FORM_KEYS.get('password')]: [
        null,
        [
          ...hasPasswordInput
            ? [
              Validators.required,
              passwordValidator(),
            ]
            : [],
        ],
      ],
      [REGISTER_FORM_KEYS.get('accepted_privacy_policy')]: [
        false,
        [...hasPrivacyStatementInput ? [Validators.requiredTrue] : []],
      ],
    }, validators);
  }
}
