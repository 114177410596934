import { Component, Input, OnInit } from '@angular/core';
import { UploadInputFormComponent } from '@modules/shared/form/components/upload-input-form/upload-input-form.component';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-upload-images-input-form',
  templateUrl: './upload-images-input-form.component.html',
  styleUrls: ['./upload-images-input-form.component.scss'],
})
export class UploadImagesInputFormComponent extends UploadInputFormComponent implements OnInit {
  @Input() originalImageUrl: string;

  constructor(themeService: ThemeService) {
    super(themeService);

    this.maxFiles = 1;
    this.allowedExtensions = ['.jpeg', '.jpg', '.png', '.gif'];
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.originalImageUrl) {
      this.files.push({
        filename: 'Logo',
        url: this.originalImageUrl,
      });
    }
  }

  protected handleFileSelected(files: FileList): void {
    if (this.maxFiles === 1) {
      this.onDeleteFileClicked(0);
    }

    super.handleFileSelected(files);
  }
}
