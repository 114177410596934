import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { BaseSelectInputComponent } from '@modules/shared/form/components/base-select-input/base-select-input.component';

@Component({
  selector: 'vh-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: [
    './multi-select-input.component.scss',
    '../select-input/select-input.component.scss',
    '../base-select-input/base-select-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectInputComponent),
      multi: true,
    },
  ],
})
export class MultiSelectInputComponent extends BaseSelectInputComponent implements OnInit {
  @Input() hasSelectAll: boolean = false;
  @Input() defaultValue: Option = null;
  @Input() selectedOptions: Option[] = [];

  @Output() selectedOptionsChange: EventEmitter<Option[]> = new EventEmitter<Option[]>();

  ngOnInit(): void {
    super.ngOnInit();

    this.updateDisplayedValue();
  }

  handleOptionClick(value: string): void {
    const hasSelectedOption: boolean = this.selectedOptions.some((option: Option): boolean => option.value === value);

    if (hasSelectedOption) {
      this.selectedOptions = this.selectedOptions.filter((option: Option): boolean => option.value !== value);
    } else {
      this.selectedOptions.push(this.options.find((option: Option): boolean => option.value === value));
    }

    this.selectedOptionsChange.emit(this.selectedOptions);

    super.handleOptionClick(value);
  }

  handleSelectAllClick(): void {
    this.selectedOptions = this.selectedOptions.length === this.options.length
      ? this.selectedOptions = []
      : this.selectedOptions = this.options;
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  selectAllButtonText(): string {
    return this.selectedOptions.length === this.options.length ? 'common.selectNone' : 'common.selectAll';
  }

  toggleOptions(): void {
    this.popoverService.toggle(this.popoverId);
  }

  updateDisplayedValue(): void {
    super.updateDisplayedValue();

    if (!this.selectedOptions || this.selectedOptions.length === 0) {
      this.displayedValue = '';

      return;
    }

    this.displayedValue = this.selectedOptions.map((option: Option) => option.label).join(', ');
  }
}
