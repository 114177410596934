<div class="gradient-container">
  <div class="gradient-inner-container">
    <div class="header">
      <svg-icon class="logo" [src]="theme.fullLogo()" />
      <p class="info">{{'conversations.feedback.intro' | translate}}</p>
    </div>

    <div class="feedback-container">
      <div class="star-rating">
        <div class="star-container"
            *ngFor="let star of stars; let i = index"
            (mouseenter)="onStarHover(i)"
            (mouseleave)="onStarLeave()"
            (click)="onStarClick(i)">
          <vh-icon
            src="assets/icons/star.svg"
            [fill]="star ? theme.colorScheme().primary : theme.colorScheme().tertiary"
            [stroke]="i === hoveredIndex ? theme.colorScheme().primary : 'none'"
            [width]="44"
            [height]="44">
          </vh-icon>
        </div>
      </div>

      <textarea
        [(ngModel)]="message"
        [placeholder]="'conversations.feedback.textAreaPlaceholder' | translate"
        rows="4">
      </textarea>

      <vh-button
        [vhMarginTop]="16"
        [iconRightPath]="'assets/icons/send.svg'"
        [label]="'conversations.feedback.button' | translate"
        [isDisabled]="isLoading"
        [alignment]="'center'"
        (buttonClick)="onSubmitFeedbackClicked()">
      </vh-button>
    </div>
  </div>
</div>
