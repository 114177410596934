import { Component, OnInit } from '@angular/core';
import { NAVIGATION } from '@constants/navigation.constant';
import { User } from '@models/user.model';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/authentication.service';
import { ButtonStyle } from '@enums/button-style.enum';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { UntypedFormGroup } from '@angular/forms';
import { UserFormService } from '@services/form-services/user-form.service';
import { PROFILE_FORM_KEYS } from '@constants/form-keys/profile-form-keys.constant';
import { Option } from '@interfaces/option.interface';
import { LanguageService } from '@services/language.service';
import { GenderService } from '@services/gender.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MASKS } from '@constants/masks.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { format } from 'date-fns';
import { ToastService } from '@services/ui/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { triggerFormValidation } from '@utils/helpers/form.util';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { TitleService } from '@services/title.service';
import { Mode } from '@enums/mode.enum';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
@UntilDestroy()
export class ProfilePageComponent implements OnInit {
  protected readonly Mode: typeof Mode = Mode;
  protected readonly MASKS: typeof MASKS = MASKS;
  protected readonly PROFILE_FORM_KEYS: typeof PROFILE_FORM_KEYS = PROFILE_FORM_KEYS;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  protected theme: Theme;

  mode: 'read' | 'edit' = 'read';
  user: User;
  formGroup: UntypedFormGroup;
  genderOptions: Option[];
  titleOptions: Option[];
  updateUserSubscription: Subscription;
  deleteUserSubscription: Subscription;
  verificationEmailRequestWasSent: boolean;
  isSendingVerificationEmail: boolean;
  currentDate: string;

  constructor(
    private readonly genderService: GenderService,
    private readonly titleService: TitleService,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly alertDialogService: AlertDialogService,
    private readonly userFormService: UserFormService,
    private readonly languageService: LanguageService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  get translatedGender(): string {
    return this.genderOptions?.find((option: Option) => this.user?.gender === option.value)?.label ??
      this.user?.gender ??
      '';
  }

  get translatedTitle(): string {
    return this.titleOptions?.find((option: Option) => this.user?.title === option.value)?.label ??
      this.user?.title ??
      '';
  }

  ngOnInit(): void {
    this.currentDate = format(new Date(), DATE_FORMATS.serverDate);
    this.genderService.getGenderOptions$().pipe(untilDestroyed(this)).subscribe((options: Option[]) => this.genderOptions = options);
    this.titleService.getTitleOptions$().pipe(untilDestroyed(this)).subscribe((options: Option[]) => this.titleOptions = options);
    this.fetchUser();
  }

  fetchUser(): void {
    // Set the user from localstorage and meanwhile refresh by fetching the latest info from the server
    this.user = this.authService.currentUser;
    this.formGroup = this.userFormService.createFormGroup(this.user);

    this.authService.getCurrentUser$()
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        this.user = user;
        this.formGroup = this.userFormService.createFormGroup(this.user);
      });
  }

  openDeleteAccountConfirmationDialog(): void {
    this.alertDialogService.open({
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
      titleTranslationKey: 'pages.profile.removeConfirmationDialog.title',
      messageTranslationKey: 'pages.profile.removeConfirmationDialog.message',
    }).pipe(untilDestroyed(this))
      .subscribe((isOkay: boolean) => {
        if (isOkay) {
          this.onDeleteAccountConfirmed();
        }
      });
  }

  onEditProfileClicked(): void {
    this.mode = 'edit';
  }

  onDeleteAccountConfirmed(): void {
    this.deleteUserSubscription?.unsubscribe();
    this.deleteUserSubscription = this.userService.deleteUser$(this.user)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.deleteUserSubscription = null;
        this.authService.removeLoggedInUser();
        this.formGroup = this.userFormService.createFormGroup(this.user);
        this.mode = 'read';
        void this.router.navigate([NAVIGATION.root.route]);
      });
  }

  onSaveProfileClicked(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.updateUserSubscription?.unsubscribe();
    this.updateUserSubscription = this.userService.updateUser$(this.user.id, this.formGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (user: User) => {
          this.updateUserSubscription = null;
          this.user = user;
          this.languageService.setLanguage(this.user.language, false);
          this.formGroup = this.userFormService.createFormGroup(this.user);
          this.mode = 'read';
        },
        error: (err: HttpErrorResponse) => {
          this.updateUserSubscription = null;
          this.toastService.showError(err?.status === 422 ? 'common.errors.genericValidation' : 'common.errors.generic', 'common.ok')
            .pipe(untilDestroyed(this))
            .subscribe();
          triggerFormValidation(this.formGroup);
        },
      });
  }

  onCancelUpdateClicked(): void {
    if (this.user.language !== this.formGroup.get(PROFILE_FORM_KEYS.get('language')).value) {
      this.languageService.setLanguage(this.user.language, false);
    }

    this.mode = 'read';
    this.formGroup = this.userFormService.createFormGroup(this.user);
  }

  onRequestSentVerificationEmailClicked(): void {
    this.isSendingVerificationEmail = true;

    this.userService.sendVerificationEmail$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.verificationEmailRequestWasSent = true;
        this.isSendingVerificationEmail = false;
      });
  }
}
