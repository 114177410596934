import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { ButtonStyle } from '@enums/button-style.enum';
import { ConversationService } from '@services/conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeedbackService } from '@services/feedback.service';
import { ToastService } from '@services/ui/toast.service';
import { UrlParserService } from '@services/url-parser.service';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { IObject } from '@app-types/iobject.type';
import { LanguageService } from '@services/language.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversation-feedback-page',
  templateUrl: './conversation-feedback-page.component.html',
  styleUrls: ['./conversation-feedback-page.component.scss'],
})
@UntilDestroy()
export class ConversationFeedbackPageComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ConversationStatus: typeof ConversationStatus = ConversationStatus;

  protected theme: Theme;

  message: string = '';
  isLoading: boolean = false;
  rating: number | null = null;
  stars: boolean[] = Array(5).fill(false);
  hoveredIndex: number = -1;
  conversationUuid: string = '';

  constructor(
    protected readonly conversationService: ConversationService,
    private readonly feedbackService: FeedbackService,
    private readonly urlParserService: UrlParserService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute,
    private readonly languageService: LanguageService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: IObject) => {
      this.updateStars(params.score);
      this.rating = params.score;

      this.languageService.setLanguage(params.language, false);
    });

    this.conversationUuid = this.urlParserService.extractUuid(this.router.url);
  }

  updateStars(rating: number): void {
    this.stars = this.stars.map((_, index) => index < rating);
  }

  onStarClick(index: number): void {
    this.rating = index + 1;
    this.stars = this.stars.map((_, i) => i <= index);
    this.hoveredIndex = -1;
  }

  onStarHover(index: number): void {
    this.hoveredIndex = index;
  }

  onStarLeave(): void {
    this.hoveredIndex = -1;
  }

  onSubmitFeedbackClicked(): void {
    this.feedbackService.updateConversationFeedback({ rating: this.rating, message: this.message, conversation_uuid: this.conversationUuid })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          void this.router.navigate([CONVERSATION_NAV.feedbackSuccess.route]);
        },
        error: () => {
          this.toastService.showError('common.errors.generic')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }
}
