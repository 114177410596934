import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Service } from '@models/service.model';

@Component({
  selector: 'vh-selected-services-card',
  templateUrl: './selected-services-card.component.html',
  styleUrls: ['./selected-services-card.component.scss'],
})
export class SelectedServicesCardComponent {
  @Input() services: Service[] = [];
  @Input() title: string | null = null;
  @Input() subtitle: string | null = null;
  @Input() description: string | null = null;

  @Output() serviceRemoved: EventEmitter<Service> = new EventEmitter<Service>();
}
