import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from '@services/survey.service';
import { AnswerList } from '@models/answer-list.model';
import { AnswerListFormService } from '@services/form-services/answer-list-form.service';
import { FormArray, FormGroup } from '@angular/forms';
import { AnswerOption } from '@models/answer-option.model';
import { Option } from '@interfaces/option.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IObject } from '@app-types/iobject.type';
import { Answer } from '@models/answer.model';
import { NAVIGATION } from '@constants/navigation.constant';
import { ToastService } from '@services/ui/toast.service';
import { AnswerListTrackingEventService } from '@services/tracking/answer-list-tracking-event.service';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { debounce } from '@utils/decorators/debounce.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vh-survey-page',
  templateUrl: './survey-page.component.html',
  styleUrls: ['./survey-page.component.scss'],
})
@UntilDestroy()
export class SurveyPageComponent implements OnInit {
  answerList: AnswerList;

  formGroup: FormGroup;
  answers: FormArray;
  isDisabled: boolean = false;

  formGroupChangedSubscription: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly surveyService: SurveyService,
    private readonly answerListFormService: AnswerListFormService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly answerListTrackingEventService: AnswerListTrackingEventService
  ) {}

  ngOnInit(): void {
    this.loadSurvey();
  }

  loadSurvey(): void {
    const visitId: string = this.activatedRoute.snapshot.params.visitId;
    const taskId: string = this.activatedRoute.snapshot.params.taskId;

    this.surveyService.getSurveyTask$(visitId, taskId)
      .pipe(untilDestroyed(this))
      .subscribe((answerList: AnswerList) => {
        this.answerList = answerList;
        this.isDisabled = answerList.status !== 'CREATED';
        answerList.answers = answerList.answers.filter((a: Answer) => a.question.isVisibleForPatient);
        this.resetFormGroup(answerList);
      });
  }

  resetFormGroup(answerList: AnswerList): void {
    this.formGroup = this.answerListFormService.createFormGroup(answerList);
    this.answers = this.formGroup.controls.answers as FormArray;

    this.formGroupChangedSubscription = this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.autoSaveAnswerList();
      });
  }

  onOptionSelected(answerOptionId: string, form: FormGroup): void {
    const option = form.value.answer_options.find((ao: Option) => answerOptionId === ao.value);

    if (!option) {
      return;
    }

    const answerOption = new AnswerOption(option.value, form.value.question_id, option.label);

    // A selectbox cannot contain more than 1 answer option
    form.value.values_answer_option.pop();
    form.value.values_answer_option.push(answerOption.toJson());
  }

  valuesAnswerOptionContainsAnswerOption(answerForm: FormGroup, answerOption: AnswerOption): boolean {
    return !!answerForm.value.values_answer_option.find((ao: AnswerOption) => ao.id === answerOption.id);
  }

  onCheckboxChanged(answerOption: AnswerOption, form: FormGroup): void {
    // Prevent saving double values
    const answerOptionJson = answerOption.toJson();
    if (form.value.values_answer_option.find((ao: IObject) => ao.id === answerOptionJson.id)) {
      form.value.values_answer_option = form.value.values_answer_option.filter((ao: IObject) => ao.id !== answerOptionJson.id);

      return;
    }

    form.value.values_answer_option.push(answerOptionJson);
  }

  onResetAnswerOptionClicked(form: FormGroup): void {
    form.value.values_answer_option.pop();
    form.get('selected_option').setValue('');
  }

  submitAnswerList(): void {
    this.formGroup.value.status = 'SUBMITTED';

    this.saveAnswerList();
  }

  @debounce(500)
  private autoSaveAnswerList(): void {
    this.saveAnswerList();
  }

  saveAnswerList(): void {
    const taskId: string = this.activatedRoute.snapshot.params.taskId;

    this.surveyService.submitAnswerList$(this.formGroup.value, taskId)
      .subscribe(() => {
        this.answerListTrackingEventService.track(TrackingEventType.ANSWER_LIST_ANSWERS_EDITED, this.answerList);

        if (this.formGroup.value.status === 'SUBMITTED') {
          this.navigateToSuccessPage();

          return;
        }

        this.toastService.showInfo('pages.survey.saveSuccessToast', 'common.ok', { duration: 500 }).subscribe();
      });
  }

  private navigateToSuccessPage(): void {
    const route: string = NAVIGATION.surveySubmitSuccess.route
      .replace(`:${NAVIGATION.surveySubmitSuccess.params.taskId}`, this.activatedRoute.snapshot.params.taskId)
      .replace(`:${NAVIGATION.surveySubmitSuccess.params.visitId}`, this.activatedRoute.snapshot.params.visitId);

    void this.router.navigate([route]);
  }
}
