import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CREATE_INSTITUTION_FORM_KEYS } from '@constants/form-keys/create-institution-form-keys.constant';

@Injectable({
  providedIn: 'root',
})
export class InstitutionFormService extends AbstractFormService {
  createFormGroup(name?: string, country?: string): UntypedFormGroup {
    return this.formBuilder.group({
      [CREATE_INSTITUTION_FORM_KEYS.get('name')]: [
        name,
        [Validators.required],
      ],
      [CREATE_INSTITUTION_FORM_KEYS.get('country')]: [
        country,
        [Validators.required],
      ],
    });
  }
}
