<vh-back-button (buttonClick)="goBack()"></vh-back-button>

<vh-conversation-info
  [patient]="user"
  [email]="user.email ?? createConversationService.email"
  [phone]="user.phone ?? createConversationService.phone"
  [doctor]="doctor"
  [questionList]="questionList.title"
  [institution]="institution"
  [language]="language"
  [dueDate]="dueDate">
</vh-conversation-info>

<div class="card-info">
  <vh-icon
    src="assets/icons/wallet.svg"
    width="16"
    height="16">
  </vh-icon>
  <p>{{ 'conversations.create.confirmation.cost' | translate }}
    <span class="cost">
      {{ conversationCost }}
      {{ 'conversations.create.confirmation.token' | translate }}
    </span>
  </p>
</div>

<vh-select-input
  [vhMarginTop]="8"
  [label]="'conversations.create.confirmation.communicationMethod' | translate"
  [options]="communicationMethods"
  [value]="selectedCommunicationMethod"
  (inputValueChange)="onCommunicationMethod($event)">
</vh-select-input>

<div *ngIf="selectedCommunicationMethod === CommunicationMethod.WHATSAPP && user.hasActiveWhatsAppConversation"
  class="flex-col gap-v-4">
  <p class="error-message font-medium-18-22">{{ 'common.errors.conversationWhatsAppActive' | translate }}</p>

  <!-- <vh-message
    *ngIf="activeConversationId"
    class="font-medium-16-19 clickable"
    [text]="'conversations.summary.openConversation' | translate"
    iconRightPath="assets/icons/external-link-alt.svg"
    [iconRightFill]="theme.colorScheme().black"
    [iconRightHeight]="12"
    [iconRightWidth]="12"
    (messageClick)="openConversation()"></vh-message> -->
</div>

<div class="flex-col gap-v-4">
  <div class="flex-row-l-c gap-h-4">
    <vh-checkbox
      [label]="'conversations.create.planning.resultsEmail' | translate"
      (valueChanged)="toggleResultsEmail($event)">
    </vh-checkbox>
    <vh-icon
      [src]="'assets/icons/question-circle.svg'"
      [matTooltip]="'conversations.create.planning.resultsEmailInfo' | translate"
      width="18"
      height="18"
      [fill]="theme.colorScheme().black">
    </vh-icon>
  </div>
  <vh-text-input
    type="text"
    [isDisabled]="!summaryEmail"
    [value]="authenticationService.currentUser.email"
    [placeholder]="'conversations.create.planning.resultsEmailPlaceholder' | translate"
    (inputValueChange)="onResultsEmailChange($event)">
  </vh-text-input>
</div>

<vh-button
  [isDisabled]="selectedCommunicationMethod === CommunicationMethod.WHATSAPP && user.hasActiveWhatsAppConversation || isCreatingConversation"
  [label]="'common.confirm' | translate"
  [vhMarginTop]="32"
  (buttonClick)="goToNextStep()"
  [centered]="true"
  [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
  [iconRightWidth]="20"
  [iconRightHeight]="20"
  [iconRightStroke]="theme.colorScheme().white">
</vh-button>
