import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit {
  labelLineHeight: string;
  iconFillRight: string;
  iconFillLeft: string;

  @Input() label: string;
  @Input() alignment: 'left' | 'right' | 'center';
  @Input() isHoverable: boolean = false;
  @Input() isPrimary: boolean = false;
  @Input() iconLeftHeight: number;
  @Input() iconLeftWidth: number;
  @Input() iconLeftPath: string;
  @Input() iconLeftFill: string;
  @Input() iconLeftHoverFill: string;

  @Input() iconRightHeight: number;
  @Input() iconRightWidth: number;
  @Input() iconRightPath: string;
  @Input() iconRightFill: string;
  @Input() iconRightHoverFill: string;

  @Output() pillClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() rightIconClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() leftIconClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.iconFillLeft = this.iconLeftFill;
    this.iconFillRight = this.iconRightFill;
  }

  onLeftIconClick($event: Event): void {
    $event.stopPropagation();
    this.leftIconClick.emit();
  }

  onRightIconClick($event: Event): void {
    $event.stopPropagation();
    this.rightIconClick.emit();
  }

  onPillClicked($event: Event): void {
    $event.stopPropagation();
    this.pillClick.emit();
  }

  onParentHover(): void {
    if (this.isHoverable) {
      this.iconFillLeft = this.themeService.currentTheme.colorScheme().white;
      this.iconFillRight = this.themeService.currentTheme.colorScheme().white;
    }
  }

  onParentHoverOut(): void {
    this.iconFillLeft = this.iconLeftFill;
    this.iconFillRight = this.iconRightFill;
  }

}
