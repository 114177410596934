<div [ngStyle]="{ 'text-align': alignment }" [ngClass]="{ 'centered': centered }" (mouseover)="onMouseOver()"
  (mouseout)="onMouseOut()">
  <button
    [disabled]="isDisabled || isLoading"
    [type]="type"
    class="flex-row-c-c"
    [class.clickable]="addClickableClass"
    [class.is-disabled]="isDisabled || isLoading"
    [class.inactive]="style === ButtonStyle.INACTIVE"
    [class.inactive-square]="style === ButtonStyle.INACTIVE_SQUARE"
    [class.square]="style === ButtonStyle.SQUARE"
    [class.primary]="style === ButtonStyle.PRIMARY"
    [class.secondary]="style === ButtonStyle.SECONDARY"
    [class.tertiary]="style === ButtonStyle.TERTIARY"
    [class.quaternary]="style === ButtonStyle.QUATERNARY"
    [class.quinary]="style === ButtonStyle.QUINARY"
    [class.validation-error-highlight]="style === ButtonStyle.VALIDATION_ERROR"
    [class.danger]="style === ButtonStyle.DANGER"
    [class.warning]="style === ButtonStyle.WARNING"
    [class.round-corner]="cornerStyle === ButtonCornerStyle.ROUND"
    [class.subtle-round-corner]="cornerStyle === ButtonCornerStyle.SUBTLE_ROUND"
    [class.round-left]="cornerStyle === ButtonCornerStyle.ROUND_LEFT"
    [class.round-right]="cornerStyle === ButtonCornerStyle.ROUND_RIGHT"
    [class.side-toggle]="style === ButtonStyle.SIDE_TOGGLE"
    [class.skinny]="style === ButtonStyle.SKINNY"
    [class.low]="style === ButtonStyle.LOW"
    [class.borderedPrimary]="style === ButtonStyle.BORDEREDPRIMARY"
    [class.borderedError]="style === ButtonStyle.BORDEREDERROR"
    [class.iconOnly]="style === ButtonStyle.ICON_ONLY"
    [ngClass]="buttonClass"
    (click)="handleButtonClick()">
    <vh-icon
      *ngIf="iconLeftPath"
      [ngClass]="{ 'spacing-right': !!label }"
      [src]="iconLeftPath"
      [height]="iconLeftHeight"
      [width]="iconLeftWidth"
      [stroke]="iconLeftStroke"
      [fill]="leftIconFill"
      [hoverFill]="iconLeftHoverFill"
      [ariaLabel]="iconLeftAlt">
    </vh-icon>
    <span [ngClass]="labelClass" [innerHTML]="label" [ngStyle]="{ 'line-height': labelLineHeight }"></span>
    <vh-icon
      *ngIf="iconRightPath"
      [ngClass]="{ 'spacing-left': !!label }"
      [src]="iconRightPath"
      [height]="iconRightHeight"
      [width]="iconRightWidth"
      [stroke]="iconRightStroke"
      [fill]="rightIconFill"
      [hoverFill]="iconRightHoverFill"
      [ariaLabel]="iconRightAlt">
    </vh-icon>
  </button>
  <vh-loader class="loader" *ngIf="isLoading"></vh-loader>
</div>