import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';

@Component({
  selector: 'vh-back-and-forward-buttons',
  templateUrl: './back-and-forward-buttons.component.html',
  styleUrls: ['./back-and-forward-buttons.component.scss'],
})
export class BackAndForwardButtonsComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  @Input() showBackButton: boolean = true;
  @Input() showForwardButton: boolean = true;

  @Input() backIconPath: string = 'assets/icons/arrow-narrow-left.svg';
  @Input() forwardIconPath: string = 'assets/icons/arrow-narrow-right.svg';

  @Input() backIconStroke: string = 'primary';
  @Input() forwardIconStroke: string = 'white';

  @Input() backLabel: string = 'common.back';
  @Input() forwardLabel: string = 'common.next';

  @Input() backDisabled: boolean = false;
  @Input() forwardDisabled: boolean = false;

  @Output() backClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() forwardClick: EventEmitter<void> = new EventEmitter<void>();

  onBack(): void {
    this.backClick.emit();
  }

  onForward(): void {
    this.forwardClick.emit();
  }
}
