<ng-container *ngIf="mode === 'read' && !isLoading" [ngTemplateOutlet]="readModeTemplate"></ng-container>
<ng-container *ngIf="mode === 'read' && isLoading" [ngTemplateOutlet]="skeletonTemplate"></ng-container>
<ng-container *ngIf="mode === 'edit'" [ngTemplateOutlet]="editModeTemplate"></ng-container>

<ng-template #readModeTemplate>
  <div class="card">
    <div class="card-title flex-row gap-h-8">
      <vh-color-picker [value]="serviceGroup?.color" [isReadonly]="true" [isDisabled]="true"></vh-color-picker>
      <h2 vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="isLoading">{{ serviceGroup?.name }}</h2>
      <div class="flex-row-r-c edit-controls">
        <vh-icon
          class="clickable"
          src="assets/icons/pen-alt.svg"
          [hoverFill]="theme.colorScheme().black"
          (click)="onEditServiceGroupClicked()"></vh-icon>
        <vh-icon
          class="clickable"
          src="assets/icons/trash-alt.svg"
          [hoverFill]="theme.colorScheme().error"
          (click)="onRemoveServiceGroupClicked(serviceGroup)"></vh-icon>
      </div>
    </div>
    <div class="pills-group" [vhMarginTop]="8">
      <vh-pill *ngFor="let service of serviceGroup?.services" [label]="service.name"> </vh-pill>
    </div>
  </div>
</ng-template>

<ng-template #editModeTemplate>
  <div class="card" [formGroup]="formGroup">
    <vh-text-input
      [formControlName]="SERVICE_GROUP_FORM_KEYS.get('name')"
      [label]="'admin.pages.settings.serviceGroups.settings.name' | translate">
    </vh-text-input>

    <vh-color-picker
      [vhMarginTop]="8"
      [formControlName]="SERVICE_GROUP_FORM_KEYS.get('color')"
      [label]="'admin.pages.settings.serviceGroups.settings.color' | translate">
    </vh-color-picker>

    <vh-select-input
      matTooltipPosition="after"
      [matTooltipDisabled]="serviceOptions?.length !== 0"
      [matTooltip]="'admin.pages.settings.serviceGroups.allServiceGroupsCoupledTooltip' | translate"
      [vhMarginTop]="8"
      [label]="'admin.pages.settings.serviceGroups.settings.servicesLabel' | translate"
      [placeholder]="'admin.pages.settings.serviceGroups.settings.services' | translate"
      [formControlName]="SERVICE_GROUP_FORM_KEYS.get('services')"
      [options]="serviceOptions"
      [isDisabled]="serviceOptions?.length === 0"
      (inputValueChange)="onServiceSelected($event)">
    </vh-select-input>

    <div class="pills-group" [vhMarginTop]="8">
      <vh-pill
        *ngFor="let service of serviceGroup?.services"
        @popInOutAnimation
        [iconRightFill]="theme.colorScheme().secondaryGrey"
        [iconRightHoverFill]="theme.colorScheme().error"
        iconRightPath="assets/icons/cancel.svg"
        iconRightWidth="16"
        iconRightHeight="16"
        [label]="service.name"
        (rightIconClick)="onRemoveServiceClicked(service)">
      </vh-pill>
    </div>

    <ng-container *vhRequiresModule="ModuleKey.SURVEY">
      <vh-select-input
        [label]="'common.fields.survey' | translate"
        [vhMarginTop]="18"
        [placeholder]="'admin.pages.settings.serviceGroups.settings.surveys' | translate"
        [options]="surveyOptions"
        [isDisabled]="surveyOptions?.length === 0"
        (inputValueChange)="onSurveySelected($event)">
      </vh-select-input>

      <div *ngIf="surveys?.length > 0" class="survey-row header" [vhMarginTop]="18">
        <p>{{ 'admin.pages.settings.serviceGroups.settings.name' | translate }}</p>
        <p>{{ 'admin.pages.settings.serviceGroups.settings.activeFrom' | translate }}</p>
        <p>{{ 'admin.pages.settings.serviceGroups.settings.activeTo' | translate }}</p>
      </div>

      <ng-container formArrayName="surveys">
        <ng-container [formGroupName]="i" *ngFor="let surveyForm of surveys.controls; let i = index">
          <div class="survey-row" [vhMarginTop]="8" [formGroup]="surveyForm">
            <div class="flex-row">
              <p>{{ surveyForm.get('name').value }}</p>

              <vh-icon
                *ngIf="surveyForm.get('is_active').value"
                src="assets/icons/check-circle.svg"
                style="margin-left: 0.5rem"
                width="16"
                height="16"
                [fill]="theme.colorScheme().primary">
              </vh-icon>
            </div>

            <vh-text-input
              type="date"
              formControlName="active_from"
              [min]="currentDateString"
              [max]="DATE_BOUNDARIES.max.dateString"
              [value]="surveyForm.active_from ? (surveyForm.get(active_from).value | date : 'yy/M/d') : null"
              [placeholder]="'dd/mm/yyyy'"
              (change)="activeFromChange($event, i)">
            </vh-text-input>

            <vh-text-input
              type="date"
              [min]="currentDateString"
              [max]="DATE_BOUNDARIES.max.dateString"
              [value]="surveyForm.active_to ? surveyForm.active_to : null"
              [placeholder]="'dd/mm/yyyy'"
              (change)="activeToChange($event, surveyForm)">
            </vh-text-input>

            <div class="flex-row">
              <vh-icon
                src="assets/icons/cross.svg"
                width="16"
                height="16"
                [fill]="theme.colorScheme().secondaryGrey"
                [hoverFill]="theme.colorScheme().error"
                (click)="onRemoveSurveyClicked(survey, i)">
              </vh-icon>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <vh-message
      *ngIf="errorMessageTranslationKey"
      [vhMarginTop]="16"
      [vhFontClass]="'font-regular-18-22'"
      [vhTextColor]="'var(--color-alert)'"
      [text]="errorMessageTranslationKey | translate"></vh-message>
    <div [vhMarginTop]="16" class="flex-row-r-c">
      <vh-button
        [label]="'common.cancel' | translate"
        [style]="ButtonStyle.TERTIARY"
        [isDisabled]="!!createServicesSubscription || !!updateServicesSubscription"
        (buttonClick)="onCancelClick()"></vh-button>
      <vh-button
        [label]="'common.save' | translate"
        [style]="ButtonStyle.PRIMARY"
        (buttonClick)="serviceGroup?.id === null ? createServiceGroup() : updateServiceGroup(serviceGroup)"
        [isDisabled]="formGroup.invalid || !!createServicesSubscription || !!updateServicesSubscription"></vh-button>
    </div>
  </div>
</ng-template>

<ng-template #skeletonTemplate>
  <div class="flex-col gap-v-16">
    <div class="card flex-col skeleton-container" *ngFor="let i of [].constructor(amountOfSkeletons)">
      <div class="card-title flex-row gap-h-16">
        <div vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="isLoading"></div>
        <h2 vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="isLoading"></h2>
      </div>
      <div class="pills-group flex-row-l-c gap-h-8">
        <div vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="isLoading"></div>
        <div vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="isLoading"></div>
        <div vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="isLoading"></div>
      </div>
    </div>
  </div>
</ng-template>
