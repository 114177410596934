<vh-base-card [isLoading]="skeletonIsEmpty">
  <div class="flex-row card gap-h-16">
    <div *ngIf="!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]" class="flex-row-c-c icon-container has-background-grey">
      <vh-icon src="assets/icons/hospital.svg" width="40" height="40"></vh-icon>
    </div>

    <div class="flex-col">
      <p class="font-regular-18-22">{{ institution.parent ? institution.parent.name : institution.name }}</p>
      <p
        *ngIf="institution.parent && institution.parent.name.toLowerCase() !== institution.name.toLowerCase()"
        [vhMarginTop]="4"
        class="has-text-tertiary">
        {{ institution.name }}
      </p>

      <div class="flex-row">
        <div class="icon-with-text-grid" [vhMarginTop]="4">
          <vh-icon [vhMarginTop]="6" src="assets/icons/marker.svg"></vh-icon>
          <div>
            <p>{{ institution.address?.fullStreet }}</p>
            <p [vhMarginTop]="4">{{ institution.address?.postalCode }} {{ institution.address?.city }}</p>
          </div>
          <ng-container *ngIf="institution.phones?.length > 0">
            <vh-icon [vhMarginTop]="4" height="16" width="16" src="assets/icons/phone.svg"></vh-icon>
            <div class="phones-container flex-row-l-c">
              <p *ngFor="let phone of institution.phones" [vhMarginTop]="4">
                <span><a [href]="phone.valueHref">{{ phone.value }}</a></span>
                <span class="has-text-tertiary" *ngIf="phone.description"> ({{ phone.description }})</span>
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="flex-col modality-list">
      <vh-button-group [items]="modalityOptions" [shouldWrap]="false"></vh-button-group>
      <vh-message
        [vhTextColor]="'var(--color-primary)'"
        [iconRightPath]="'assets/icons/arrow-right.svg'"
        [iconRightFill]="theme.colorScheme().primary"
        [text]="'pages.dashboard.moreInfoLabel' | translate"
        (messageClick)="moreInfoClick.emit(institution)"></vh-message>
    </div>
  </div>
</vh-base-card>
