import { Component, Input, OnInit } from '@angular/core';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { CommunicationMethod } from '@enums/communication-methods';
import { ConversationStatus, isConversationInactive } from '@enums/conversation-status.enum';
import { Conversation } from '@models/conversation.model';
import { Institution } from '@models/institution.model';
import { User } from '@models/user.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConversationService } from '@services/conversation.service';
import { finalize, switchMap, timer } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ConversationNavigationService } from '@modules/conversation/services/conversation-flow-navigation.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-conversation-info',
  templateUrl: './conversation-info.component.html',
  styleUrls: ['./conversation-info.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('500ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
@UntilDestroy()
export class ConversationInfoComponent implements OnInit {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ConversationStatus: typeof ConversationStatus = ConversationStatus;
  protected readonly COMMUNICATION_METHODS: typeof CommunicationMethod = CommunicationMethod;
  protected readonly isConversationInactive: (status: ConversationStatus) => boolean = isConversationInactive;

  protected theme: Theme;

  @Input() patient: User;
  @Input() language: string;
  @Input() email: string | null;
  @Input() phone: string | null;
  @Input() doctor: User;
  @Input() institution: Institution;
  @Input() questionList: string;
  @Input() dueDate: Date;
  @Input() communicationMethod: CommunicationMethod;
  @Input() transparent: boolean = false;
  @Input() vertical: boolean = false;
  @Input() conversation: Conversation;

  activeWhatsapp: boolean;
  whatsappDisabled: boolean = false;
  error: string;
  activeConversationId: string;

  constructor(
    private readonly conversationService: ConversationService,
    private readonly conversationNavigationService: ConversationNavigationService,
    private readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  ngOnInit(): void {
    this.patient ??= this.conversation?.patient;
    this.email ??= this.patient?.email;
    this.phone ??= this.patient?.phone;
    this.doctor ??= this.conversation?.doctor;
    this.institution ??= this.conversation?.institution;
    this.activeWhatsapp ??= this.conversation?.activeWhatsapp;
    this.questionList ??= this.conversation?.questionList;
    this.dueDate ??= this.conversation?.dueDate;
    this.communicationMethod ??= this.conversation?.communicationMethod;
    this.language ??= this.conversation?.language;
  }

  openConversation(): void {
    this.conversationNavigationService.navigateToDetails(this.activeConversationId, true);
    this.error = null;
    this.activeConversationId = null;
  }

  toggleWhatsApp(): void {
    this.whatsappDisabled = true;

    const minDelay$ = timer(500);

    this.conversationService.toggleWhatsApp$(this.conversation.id, this.activeWhatsapp)
      .pipe(
        untilDestroyed(this),
        switchMap(response => minDelay$.pipe(switchMap(() => [response]))),
        finalize(() => {
          this.whatsappDisabled = false;
        })
      )
      .subscribe({
        next: () => {
          this.activeWhatsapp = !this.activeWhatsapp;
        },
        error: error => {
          this.error = error.error.message;
          this.activeConversationId = Conversation.fromJson(error.error.conversation)?.id;
        },
      });
  }

  resendEmail(): void {
    this.conversationService.resendEmail$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
