import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethod } from '@models/payment-method.model';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-payment-method-settings-menu',
  templateUrl: './payment-method-settings-menu.component.html',
  styleUrls: ['./payment-method-settings-menu.component.scss'],
})
export class PaymentMethodSettingsMenuComponent {
  protected theme: Theme;

  @Input() paymentMethod: PaymentMethod;
  @Input() canDelete: boolean = true;
  @Input() canMarkAsDefault: boolean = true;

  @Output() markAsDefaultClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }

  onMarkAsDefaultClicked(): void {
    if (this.canMarkAsDefault) {
      this.markAsDefaultClick.emit();
    }
  }

  onDeleteClicked(): void {
    if (this.canDelete) {
      this.deleteClick.emit();
    }
  }
}
