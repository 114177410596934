import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@services/theming/theme.service';
import { Theme } from '@themes/theme.interface';

@Component({
  selector: 'vh-dialog-frame',
  templateUrl: './dialog-frame.component.html',
  styleUrls: ['./dialog-frame.component.scss'],
})
export class DialogFrameComponent {
  @Input() title: string;
  @Input() marginBetweenTitleAndBody: number = 16;
  @Input() showCloseButton: boolean = true;

  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();

  protected theme: Theme;

  constructor(private readonly themeService: ThemeService) {
    this.theme = this.themeService.currentTheme;
  }
}
