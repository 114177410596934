<div class="container border-medium">
  <vh-language-picker
    *ngIf="isMultiLanguage"
    [useShortLanguageNames]="true"
    [scopeToInstitutionSettings]="true"
    [changeAppLanguageOnValueChange]="false"
    [automaticallyStoreServerSideOnChange]="false"
    [value]="selectedLanguage"
    (languageChanged)="languageChanged.emit($event)">
  </vh-language-picker>
  <div class="editor" quill-editor-element></div>
</div>

