import { NgModule } from '@angular/core';
import { CheckboxComponent } from '@modules/shared/form/components/checkbox/checkbox.component';
import { ColorPickerComponent } from '@modules/shared/form/components/color-picker/color-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguagePickerComponent } from '@modules/shared/form/components/language-picker/language-picker.component';
import { MultiSelectInputComponent } from '@modules/shared/form/components/multi-select-input/multi-select-input.component';
import { QuillEditorComponent } from 'ngx-quill';
import { RadioCheckboxComponent } from '@modules/shared/form/components/radio-checkbox/radio-checkbox.component';
import { SelectInputComponent } from '@modules/shared/form/components/select-input/select-input.component';
import { CoreModule } from '@modules/shared/core/core.module';
import { SuggestionsTextInputComponent } from '@modules/shared/form/components/suggestions-text-input/suggestions-text-input.component';
import { SwitchComponent } from '@modules/shared/form/components/switch/switch.component';
import { TextareaInputComponent } from '@modules/shared/form/components/textarea-input/textarea-input.component';
import { TextInputComponent } from '@modules/shared/form/components/text-input/text-input.component';
import { UsernameInputComponent } from '@modules/shared/form/components/username-input/username-input.component';
import { WysiwygInputComponent } from '@modules/shared/form/components/wysiwyg-input/wysiwyg-input.component';
import { YesNoInputComponent } from '@modules/shared/form/components/yes-no-input/yes-no-input.component';
import { NgxMaskDirective } from 'ngx-mask';
import { UploadInputFormComponent } from '@modules/shared/form/components/upload-input-form/upload-input-form.component';
import { RunFormValidationDirective } from '@modules/shared/form/directives/run-form-validation.directive';
import { UploadedFileComponent } from '@modules/shared/form/components/uploaded-file/uploaded-file.component';
import { SearchInputComponent } from '@modules/shared/form/components/search-input/search-input.component';
import { PasswordInputComponent } from '@modules/shared/form/components/password-input/password-input.component';
import { PasswordFeedbackComponent } from '@modules/shared/form/components/password-feedback/password-feedback.component';
import { TimezonePickerComponent } from '@modules/shared/form/components/timezone-picker/timezone-picker.component';
import { AddressSuggestionsInputComponent } from '@modules/shared/form/components/address-suggestions-input/address-suggestions-input.component';
import { CreditCardFormComponent } from '@modules/shared/form/components/credit-card-form/credit-card-form.component';
import { UserSearchComponent } from '@modules/shared/form/components/user-search/user-search.component';
import { NgxMaterialIntlTelInputComponent } from 'ngx-material-intl-tel-input';
import { PhoneInputComponent } from '@modules/shared/form/components/phone-input/phone-input.component';
import { UploadedImageComponent } from '@modules/shared/form/components/uploaded-image/uploaded-image.component';
import { UploadImagesInputFormComponent } from '@modules/shared/form/components/upload-images-input-form/upload-images-input-form.component';

const sharedIngredients = [
  CheckboxComponent,
  ColorPickerComponent,
  LanguagePickerComponent,
  MultiSelectInputComponent,
  RadioCheckboxComponent,
  SelectInputComponent,
  SuggestionsTextInputComponent,
  SwitchComponent,
  TextareaInputComponent,
  TextInputComponent,
  UsernameInputComponent,
  WysiwygInputComponent,
  YesNoInputComponent,
  UploadInputFormComponent,
  UploadImagesInputFormComponent,
  RunFormValidationDirective,
  UploadedFileComponent,
  UploadedImageComponent,
  SearchInputComponent,
  PasswordInputComponent,
  PasswordFeedbackComponent,
  TimezonePickerComponent,
  AddressSuggestionsInputComponent,
  CreditCardFormComponent,
  UserSearchComponent,
  PhoneInputComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    QuillEditorComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaterialIntlTelInputComponent,
  ],
  exports: [
    ...sharedIngredients,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialIntlTelInputComponent,
  ],
})
export class FormModule {}
