<ng-container *ngIf="mode === 'read'">
  <td class="cell-align-center">
    <p>{{ service.name }}</p>
  </td>
  <td class="cell-align-center">
    <p class="font-medium-16-19">{{ service.code }}</p>
  </td>
  <td class="cell-align-center">
    <p>{{ service.duration }}</p>
  </td>
  <td class="cell-align-center">
    <p>{{ service.alternativeName?.get(selectedLanguage) ?? '/' }}</p>
  </td>
  <td class="cell-align-center">
    <vh-icon
      [src]="service.canBeBookedByPatient ? 'assets/icons/check-circle.svg' : 'assets/icons/times-circle.svg'"
      [fill]="service.canBeBookedByPatient ? theme.colorScheme().primary : theme.colorScheme().warning"
      [height]="16"
      [width]="16">
    </vh-icon>
  </td>
  <td class="cell-align-center">
    <vh-icon
      [src]="service.isPublic ? 'assets/icons/check-circle.svg' : 'assets/icons/times-circle.svg'"
      [fill]="service.isPublic ? theme.colorScheme().primary : theme.colorScheme().warning"
      [height]="16"
      [width]="16">
    </vh-icon>
  </td>
  <td class="cell-align-center">
    <div class="flex-row-l-c gap-h-8">
      <vh-icon class="clickable" src="assets/icons/pen-alt.svg" [hoverFill]="theme.colorScheme().black" (click)="onEditServiceClicked()"></vh-icon>
      <vh-icon
        class="clickable"
        src="assets/icons/trash-alt.svg"
        [hoverFill]="theme.colorScheme().error"
        (click)="onRemoveServiceClicked(service)"></vh-icon>
    </div>
  </td>
</ng-container>

<ng-container *ngIf="mode === 'edit'" [formGroup]="formGroup">
  <td>
    <vh-select-input
      [options]="options"
      [isDisabled]="serviceOptions?.length === 0"
      [formControlName]="SERVICE_FORM_KEYS.get('service')"
      [placeholder]="'admin.pages.settings.services.settings.service' | translate">
    </vh-select-input>
  </td>
  <td>
    <vh-text-input
      [formControlName]="SERVICE_FORM_KEYS.get('code')"
      [placeholder]="'admin.pages.settings.services.settings.code' | translate">
    </vh-text-input>
  </td>
  <td>
    <vh-text-input
      type="number"
      [formControlName]="SERVICE_FORM_KEYS.get('duration')"
      [placeholder]="'admin.pages.settings.services.settings.duration' | translate">
    </vh-text-input>
  </td>
  <td>
    <vh-text-input
      [formControlName]="SERVICE_FORM_KEYS.get('alternative_name')"
      [placeholder]="'admin.pages.settings.services.settings.alternativeName' | translate"
      [message]="
        (service?.alternativeName && selectedLanguage !== defaultLanguage ? 'common.i18nOriginalValue' : '')
          | translate : { value: service?.alternativeName?.get(defaultLanguage) ?? '/' }
          | translate
      ">
    </vh-text-input>
  </td>
  <td class="cell-align-center">
    <vh-checkbox
      [formControlName]="SERVICE_FORM_KEYS.get('can_be_booked_by_patient')"
      [placeholder]="'admin.pages.settings.services.settings.code' | translate">
    </vh-checkbox>
  </td>
  <td class="cell-align-center">
    <vh-checkbox
      [formControlName]="SERVICE_FORM_KEYS.get('is_public')"
      [placeholder]="'admin.pages.settings.services.settings.code' | translate">
    </vh-checkbox>
  </td>
  <td class="cell-align-center">
    <div class="flex-row-l-c gap-h-8">
      <vh-icon
        src="assets/icons/save.svg"
        width="24"
        height="24"
        [fill]="theme.colorScheme().secondaryGrey"
        [hoverFill]="theme.colorScheme().primary"
        [ngClass]="{ clickable: formGroup.valid && !attachServiceSubscription }"
        [disabled]="!formGroup.valid || !!attachServiceSubscription"
        (click)="attachService()">
      </vh-icon>
      <vh-icon
        src="assets/icons/cross.svg"
        width="16"
        height="16"
        [fill]="theme.colorScheme().secondaryGrey"
        [hoverFill]="theme.colorScheme().error"
        [ngClass]="{
          clickable: !attachServiceSubscription && canRemove,
          'not-clickable': attachServiceSubscription || !canRemove
        }"
        [disabled]="!!attachServiceSubscription"
        (click)="onCancelClick()">
      </vh-icon>
    </div>
  </td>
</ng-container>
