import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Feedback, FeedbackDialogData } from '@modules/shared/core/dialogs/feedback-dialog/feedback-dialog.interface';

@Component({
  selector: 'vh-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
@UntilDestroy()
export class FeedbackDialogComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  @Output() submitClicked: EventEmitter<void> = new EventEmitter<void>();

  message: string = '';
  isLoading: boolean = false;
  rating: number | null = null;
  stars: boolean[] = Array(5).fill(false);
  hoveredIndex: number = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FeedbackDialogData,
    private readonly dialogRef: MatDialogRef<FeedbackDialogComponent>
  ) {}

  close(feedback?: Feedback): void {
    this.dialogRef.close(feedback);
  }

  submit(): void {
    this.close({
      message: this.message,
      rating: this.rating,
    });
  }

  updateStars(rating: number): void {
    this.stars = this.stars.map((_, index) => index < rating);
  }

  rate(index: number): void {
    this.rating = index + 1;
    this.stars = this.stars.map((_, i) => i <= index);
    this.hoveredIndex = -1;
  }

  onStarHover(index: number): void {
    this.hoveredIndex = index;
  }

  onStarLeave(): void {
    this.hoveredIndex = -1;
  }
}
