<vh-tertiary-page>
  <ng-container *ngIf="state === 'loading'" [ngTemplateOutlet]="loadingTemplate"></ng-container>
  <ng-container *ngIf="state === 'error'" [ngTemplateOutlet]="errorTemplate"></ng-container>
  <ng-container *ngIf="state === 'loaded'" [ngTemplateOutlet]="bodyTemplate"></ng-container>
  <ng-container *ngIf="state === 'empty'" [ngTemplateOutlet]="noItemsTemplate"></ng-container>
</vh-tertiary-page>

<ng-template #bodyTemplate>
  <h1>{{ 'pages.viewAppointmentTasks.title' | translate }}</h1>
  <vh-task-list
    *ngIf="visit.allTasks?.length"
    [vhMarginTop]="8"
    [visit]="visit"
    [title]="'pages.viewAppointmentTasks.listLabel' | translate"></vh-task-list>
  <ng-container *ngIf="showAfterBookingInformation && requiredTasksAreCompleted">
    <p>{{ 'pages.viewAppointmentTasks.text1' | translate }}</p>
    <p>{{ 'pages.viewAppointmentTasks.text2' | translate }}</p>
    <p>{{ 'pages.viewAppointmentTasks.text3' | translate }}</p>
    <p>
      {{ 'pages.viewAppointmentTasks.text4' | translate }}
      <a class="clickable" target="_blank">{{ 'pages.viewAppointmentTasks.clickHereText' | translate }}</a
      >.
    </p>
    <p>
      {{ 'pages.viewAppointmentTasks.text5' | translate }}
      <a class="clickable" target="_blank">{{ 'pages.viewAppointmentTasks.clickHereText' | translate }}</a
      >.
    </p>
    <p>
      {{ 'pages.viewAppointmentTasks.text6' | translate: { brand: theme.displayName() } }}
      <a class="clickable" target="_blank">{{ 'pages.viewAppointmentTasks.clickHereText' | translate }}</a
      >.
    </p>
  </ng-container>
</ng-template>

<ng-template #noItemsTemplate>
  <vh-message class="font-medium-16-19 has-text-tertiary not-clickable" [text]="'pages.viewAppointmentTasks.noTasksFound' | translate">
  </vh-message>
</ng-template>

<ng-template #loadingTemplate>
  <vh-loader></vh-loader>
</ng-template>

<ng-template #errorTemplate>
  <vh-message class="font-medium-16-19 has-text-error not-clickable" [text]="'common.unknownError' | translate"> </vh-message>
</ng-template>
