<div
  [ngClass]="{'disabled' : isDisabled}"
  class="clickable"
  (click)="handleButtonClick()"
  [vhMarginTop]="marginTop">

  <vh-icon
    src="assets/icons/angle-right-b.svg"
    [fill]="theme.colorScheme().black"
    width="16"
    height="16">
  </vh-icon>

  <span *ngIf="!backTo">{{ 'common.back' | translate }}</span>
  <span *ngIf="backTo">
    {{ 'common.backTo' | translate : { backTo: (backTo | lowercase) } }}
  </span>
</div>
