<div class="card" >
    <div class="flex-row name gap-h-8">
        <vh-icon
        [ngClass]="{ 'is-disabled': card.isReadOnly }"
        *ngIf="card.isReadOnly"
        src="assets/icons/archive-alt.svg"
        width="20"
        height="20"/>
        <h2 [ngClass]="{ 'is-disabled': card.isReadOnly }" class= "clickable" (click)="onCardClicked($event)">{{ card.name }}</h2>
        <span class="font-regular-18-22" [ngClass]="{ 'is-disabled': card.isReadOnly }">
        - {{ card.statusTranslationKey | translate }}</span>
        <div class="flex-row-r-c edit-controls">
          <vh-icon
            *ngIf="card.isEditable"
            class="clickable"
            src="assets/icons/pen-alt.svg"
            [hoverFill]="theme.colorScheme().black"
            (click)="onEditClicked($event)">
          </vh-icon>
          <vh-icon
            *ngIf="card.isDuplicable"
            class="clickable"
            src="assets/icons/copy.svg"
            [hoverStroke]="theme.colorScheme().black"
            (click)="onCopyClicked($event)">
          </vh-icon>
          <vh-icon
            *ngIf="!card.isReadOnly"
            class="clickable"
            src="assets/icons/trash-alt.svg"
            [hoverFill]="theme.colorScheme().error"
            (click)="onRemoveClicked($event)">
          </vh-icon>
          <vh-icon
            *ngIf="card.isReadOnly"
            class="clickable"
            src="assets/icons/eye.svg"
            [hoverFill]="theme.colorScheme().black"
            (click)="onEditClicked($event)">
          </vh-icon>
        </div>
    </div>
    <ng-content></ng-content>
</div>
