import { IObject } from '@app-types/iobject.type';
import { untilDestroyed } from '@ngneat/until-destroy';
import { InstitutionSettings } from '@models/institution-settings.model';
import { Institution } from '@models/institution.model';
import { UntypedFormGroup } from '@angular/forms';
import { InstitutionService } from '@services/institution.service';
import { ToastService } from '@services/ui/toast.service';
import { INSTITUTION_SETTINGS_FORM_KEYS } from '@constants/form-keys/institution-settings-form-keys.constant';
import { Directive, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionSettingsFormService } from '@services/form-services/institution-settings-form.service';
import { Option } from '@interfaces/option.interface';
import { LanguageService } from '@services/language.service';
import { Theme } from '@themes/theme.interface';
import { ThemeService } from '@services/theming/theme.service';

@Directive()
export abstract class BaseSettingsPageComponent implements OnInit {
  protected readonly INSTITUTION_SETTINGS_FORM_KEYS: typeof INSTITUTION_SETTINGS_FORM_KEYS = INSTITUTION_SETTINGS_FORM_KEYS;
  protected readonly SAVE_SUCCESS_TOAST_DURATION: number = 5000;

  protected formGroup: UntypedFormGroup;
  protected institution: Institution;
  protected institutionLanguageOptions: Option[] = [];
  protected allLanguageOptions: Option[] = [];
  protected theme: Theme;

  constructor(
    protected readonly institutionService: InstitutionService,
    protected readonly toastService: ToastService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly institutionSettingsFormService: InstitutionSettingsFormService,
    protected readonly languageService: LanguageService,
    protected readonly themeService: ThemeService
  ) {
    this.theme = this.themeService.currentTheme;
  }

  abstract resetForm(): void;

  ngOnInit(): void {
    this.institution = this.authenticationService.institution;
    this.resetForm();
    this.reloadInstitution();
  }

  protected reloadInstitution(): void {
    this.institutionService.getInstitutionByIdOrSlug$(this.institution.id, true)
      .pipe(untilDestroyed(this))
      .subscribe((institution: Institution): void => {
        this.institution = institution;
        this.resetForm();
      });
  }

  protected saveSettings(): void {
    const payload: IObject = this.buildSavePayload();

    this.institutionService.updateSettings$(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (settings: InstitutionSettings): void => {
          this.institution.settings = settings;
          this.showSaveConfirmation();
        },
        error: (): void => {
          this.showGenericErrorToast();
        },
      });
  }

  protected buildSavePayload(): IObject {
    return {
      ...this.institution.settings.toJson(),
      ...this.formGroup.value,
    };
  }

  protected showSaveConfirmation(): void {
    this.toastService.showInfo('admin.pages.settings.generalSettings.savedConfirmationText', 'common.ok', { duration: this.SAVE_SUCCESS_TOAST_DURATION })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  protected showGenericErrorToast(): void {
    this.toastService.showError('common.unknownError', 'common.ok')
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  protected loadVesaliusLanguages(): void {
    this.languageService.getPlatformLanguagesAsOptions$()
      .pipe(untilDestroyed(this))
      .subscribe((options: Option[]) => {
        this.allLanguageOptions = options;
        this.loadSupportedLanguagesOfInstitution();
      });
  }

  protected loadSupportedLanguagesOfInstitution(): void {
    this.institutionLanguageOptions = [];

    this.institution.settings.supportedLanguages.forEach((language: string): void => {
      this.languageService.getLanguageName$(language)
        .pipe(untilDestroyed(this))
        .subscribe((languageName: string) =>
          this.institutionLanguageOptions.push({ value: language, label: languageName })
        );
    });
  }
}
