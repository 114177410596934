<div class="flex-row container">
  <vh-navigation-return-link [color]="'var(--color-black)'" (navigateBackClick)="exit()"></vh-navigation-return-link>
  <img *ngIf="doctorIsVisible" src="assets/images/doctor.svg" [alt]="'common.doctorThumbsUpImage' | translate" />
  <div class="flex-row gap-h-16 background">
    <div class="void" [ngStyle]="{ flex: doctorIsVisible ? 4 : 5 }"></div>
    <div [ngStyle]="{ flex: doctorIsVisible ? 6 : 5 }"></div>
  </div>
  <div class="content">
    <vh-content-layout
      [hasHorizontalSpacing]="true"
      [hasVerticalSpacing]="true"
      [isVerticallyCenterAligned]="true"
      [isHorizontallyCenterAligned]="true">
      <ng-content></ng-content>
    </vh-content-layout>
  </div>
</div>
