import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { TextInputStyle } from '@enums/text-input-style.enum';
import { UsernameService } from '@services/username.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TextInputComponent } from '@modules/shared/form/components/text-input/text-input.component';
import { parse, isValid, isPast } from 'date-fns';
import { Subscription } from 'rxjs';
import { debounce } from '@utils/decorators/debounce.decorator';
import { ErrorMessageService } from '@services/error-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ThemeService } from '@services/theming/theme.service';

@Component({
  selector: 'vh-username-input',
  templateUrl: './username-input.component.html',
  styleUrls: [
    './username-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UsernameInputComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class UsernameInputComponent extends BaseInputComponent implements OnInit, OnChanges {
  @Input() style: TextInputStyle = TextInputStyle.DEFAULT;

  @Input() firstname: string;
  @Input() lastname: string;
  @Input() birthdate: string;
  @Input() emailField: string;
  @Input() showValidationIcon: boolean = true;
  @Input() shouldGenerateSuggestions: boolean = true;

  @ViewChild('inputElement') inputElement: TextInputComponent;

  hasPerformedInitialSuggestion: boolean;
  isValid: boolean;

  suggestionSubscription: Subscription;
  checkIfUniqueSubscription: Subscription;
  isLoading: boolean;

  constructor(
    controlContainer: ControlContainer,
    errorMessageService: ErrorMessageService,
    themeService: ThemeService,
    private readonly usernameService: UsernameService
  ) {
    super(controlContainer, errorMessageService, themeService);
  }

  get icon(): string {
    if (this.isLoading) {
      return 'assets/icons/sync-secondary.svg';
    }

    return this.isValid
      ? 'assets/icons/check-circle.svg'
      : 'assets/icons/times-circle.svg';
  }

  get iconColor(): string {
    if (this.isLoading) {
      return this.theme.colorScheme().black;
    }

    return this.isValid
      ? this.theme.colorScheme().success
      : this.theme.colorScheme().error;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.canSuggestUsername() && !this.formControl?.dirty) {
      this.generateSuggestion();
    }

    if (this.formControl?.dirty && changes.value) {
      this.checkIfUnique();
    }

    if (changes.firstname || changes.lastname || changes.emailField || changes.birthdate) {
      this.generateSuggestion();
    }
  }

  private canSuggestUsername(): boolean {
    if (!this.shouldGenerateSuggestions) {
      return false;
    }

    const birthdate: Date = parse(this.birthdate, 'yyyy-mm-dd', Date.now());

    return this.birthdate
      ? this.firstname && this.lastname && isValid(birthdate) && birthdate.getFullYear() > 1900 && isPast(birthdate)
      : !!(this.firstname && this.lastname);
  }

  @debounce(500)
  checkIfUnique(): void {
    if (!this.inputElement.value) {
      return;
    }

    this.isLoading = true;

    this.checkIfUniqueSubscription?.unsubscribe();
    this.checkIfUniqueSubscription = this.usernameService.isUsernameUnique$(this.inputElement.value as string)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (isUnique: boolean) => {
          this.isLoading = false;
          this.checkIfUniqueSubscription = null;
          this.isValid = isUnique;

          if (!this.isValid) {
            this.errorMessage = 'pages.register.usernameAlreadyTaken';
          }
        },
        error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.isValid = false;
          this.checkIfUniqueSubscription = null;

          if (err.status === 422) {
            this.errorMessage = err.error?.data?.username[0] ?? 'common.unknownError';

            return;
          }

          this.errorMessage = 'common.unknownError';
        },
      });
  }

  @debounce(500)
  private generateSuggestion(): void {
    if (!this.canSuggestUsername() || this.isDisabled) {
      return;
    }

    this.isLoading = true;

    this.suggestionSubscription?.unsubscribe();
    this.suggestionSubscription = this.usernameService.getSuggestion$(this.firstname, this.lastname, this.emailField ?? null, this.birthdate ?? null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (suggestion: string) => {
          this.isLoading = false;
          this.suggestionSubscription = null;
          this.formControl.setValue(suggestion);
          this.isValid = true;
          this.hasPerformedInitialSuggestion = true;
        },
        error: () => {
          this.isLoading = false;
          this.isValid = false;
          this.suggestionSubscription = null;
          this.errorMessage = 'common.unknownError';
        },
      });
  }
}
